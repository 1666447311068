/**
 * LifeRamp
 *
 * @author      Ummar Hamid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { View, Image, TouchableOpacity, StyleSheet } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { EvilIcons, FontAwesome } from "@expo/vector-icons";

import { SpacingH, FontSize, SpacingW } from "../../../themes/sizes";
import { Colors } from "../../../themes/colors";
import ContainerView from "../../../components/main/ContainerView";
import MainMediumText from "../../../components/text/MainMediumText";
import HeroText from "../../../components/text/HeroText";
import PastSessions from "../journey/PastSessions";
import UpcomingSessions from "../journey/UpcomingSessions";

import { setIsSpinning } from "../../../state/slices/spinnerSlice";
import { createOrGetConversation } from "../../../chat";
import { materialTopTabs } from "../../../themes/styles";
import ClientGoals from "./ClientGoals";

const ClientTabs = createMaterialTopTabNavigator();

export default ({ navigation, route }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.profile);
    const client = route.params?.client || false;

    const messageClient = async (client) => {
        dispatch(setIsSpinning(true));
        const threadId = await createOrGetConversation(user, client);
        navigation.navigate("ChatStackScreen", { screen: "Chat", params: { threadId: threadId, user: client } });
        dispatch(setIsSpinning(false));
    };

    return (
        <ContainerView>
            <View style={styles.topContainer}>
                <TouchableOpacity onPress={() => navigation.goBack()}>
                    <EvilIcons name="chevron-left" size={42} color={Colors.BLUE_GREY} />
                </TouchableOpacity>
                <Image source={{ uri: client.image_profile }} style={{ width: 128, height: 128, borderRadius: 64, borderColor: Colors.BLUE_GREY, borderWidth: 1 }} />
                <TouchableOpacity onPress={() => { messageClient(client) }}>
                    <FontAwesome name="envelope-o" size={24} color={Colors.BLUE_GREY} />
                </TouchableOpacity>
            </View>
            <View style={{ justifyContent: "center", alignItems: "center", marginTop: SpacingH.s1, marginBottom: SpacingH.s2 }}>
                <HeroText style={{ fontSize: FontSize.BIG, marginBottom: SpacingH.s1 }}>{client.name}</HeroText>
                <MainMediumText style={{ fontSize: FontSize.NORMAL }}>Georgia State University</MainMediumText>
            </View>
            <ClientTabs.Navigator tabBarOptions={materialTopTabs}>
                <ClientTabs.Screen name="UpcomingSessions" component={UpcomingSessions} initialParams={{ client }} options={{ title: "Upcoming" }} />
                <ClientTabs.Screen name="PastSessions" component={PastSessions} initialParams={{ client }} options={{ title: "Past" }} />
                <ClientTabs.Screen name="Goals" component={ClientGoals} initialParams={{ client }} options={{ title: "Goals" }} />
            </ClientTabs.Navigator>
        </ContainerView>
    );
}

const styles = StyleSheet.create({
    topContainer: {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        marginLeft: SpacingW.s4,
        marginRight: SpacingW.s6,
    },
});
