/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, View, Animated } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Gradients } from "../../themes/colors";

export default ({ style, currentProgress, totalProgress, animDuration, onAnimComplete = () => { } }) => {

    const ProgressAnim = React.useRef(new Animated.Value(0)).current;

    React.useEffect(() => {
        if (totalProgress > 0) {
            ProgressAnimStart((currentProgress + 1) / (totalProgress + 1));
        }
    }, [currentProgress, totalProgress]);


    const ProgressAnimStart = (toVal) => {
        Animated.timing(ProgressAnim, {
            toValue: toVal,
            duration: animDuration,
            useNativeDriver: false,
        }).start(() => {
            if (currentProgress === totalProgress) {
                onAnimComplete();
            }
        });
    };

    return (
        <View style={styles.progressBarWrapper}>
            <Animated.View style={{ flex: ProgressAnim }}>
                <LinearGradient
                    style={[
                        styles.progressBar,
                        // { flex: progress ? progress : '1' },
                        style,
                    ]}
                    start={[1, 0]}
                    end={[0, 1]}
                    colors={[Gradients.GREEN_BLUE.Start, Gradients.GREEN_BLUE.End]}>
                </LinearGradient>
            </Animated.View>

        </View>
    );
}

const styles = StyleSheet.create({
    progressBarWrapper: {
        flex: 0,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
    },
    progressBar: {
        flex: .8,
        height: 8,
        // borderBottomRightRadius: 4,
        // borderTopRightRadius: 4
    },
});
