/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import mime from "mime";

import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";

import { User } from "../api";

export const MessageTypes = {
    TEXT: "text",
    IMAGE: "image",
    AUDIO: "audio",
    VIDEO: "video",
    DOCUMENT: "document",
    SYSTEM: "system",
};

const db = firebase.database();

const RTDB_CHAT_BASE = "chat/";
const RTDB_CHAT_CONVERSATIONS = RTDB_CHAT_BASE + "conversations/";
const RTDB_CHAT_MESSAGES = RTDB_CHAT_BASE + "messages/";
const RTDB_CHAT_MEMBERS = RTDB_CHAT_BASE + "members/";

export const conversationsRef = userUuid => db.ref(RTDB_CHAT_CONVERSATIONS + userUuid);
export const messagesRef = threadId => db.ref(RTDB_CHAT_MESSAGES + threadId);
export const membersRef = threadId => db.ref(RTDB_CHAT_MEMBERS + threadId);

export const createOrGetConversation = async (user, otherUser) => {
    let threads = await conversationsRef(user.uuid).orderByChild("uuid").equalTo(otherUser.uuid).once("value", snapshot => {
        if (!snapshot.exists()) {
            const threadId = conversationsRef(user.uuid).push().key;

            // Setup the conversation
            conversationsRef(user.uuid).child(threadId).set({
                id: threadId,
                uuid: otherUser.uuid,
                name: otherUser.name,
                image: otherUser.image_profile,
                hasInstantZoom: otherUser.hasInstantZoom,
                lastUpdatedAt: firebase.database.ServerValue.TIMESTAMP,
                message: null,
            });
            conversationsRef(otherUser.uuid).child(threadId).set({
                id: threadId,
                uuid: user.uuid,
                name: user.name,
                image: user.image_profile,
                hasInstantZoom: user.hasInstantZoom,
                lastUpdatedAt: firebase.database.ServerValue.TIMESTAMP,
                message: null,
            });

            // Set up conversation members
            membersRef(threadId).child(user.uuid).set(user);
            membersRef(threadId).child(otherUser.uuid).set(otherUser);
        }
    });

    if (!threads.val()) {
        threads = await conversationsRef(user.uuid).orderByChild("uuid").equalTo(otherUser.uuid).once("value", snapshot => {});
    }

    return Object.keys(threads.val())[0];
};

export const createMessageKey = threadId => messagesRef(threadId).push().key;

export const uploadAndGetUrl = async (threadId, key, uri, fileName = false) => {
    const baseName = fileName ? fileName : uri.split("/").pop();
    const ref = firebase.storage().ref("message-attachments/" + threadId + "/" + key + "/" + baseName);

    const response = await fetch(uri);
    const blob = await response.blob();

    if (blob.size > 10 * 1024 * 1024) {
        alert("File too big. Max 10MB");
        return null;
    }

    await ref.put(blob, { contentType: mime.getType(uri) });
    return ref.getDownloadURL();
};

export const createMessage = (threadId, { id, type, sender, text = null, imageSource = null, audioSource = null, videoSource = null, documentSource = null, info = {} }) => {
    const m = {
        id, type, sender, likedBy: null,
        sentAt: firebase.database.ServerValue.TIMESTAMP,
        text, imageSource, audioSource, videoSource, documentSource, info
    };

    messagesRef(threadId).child(m.id).set(m);
};

export const sendTextMessage = (threadId, text, user, dispatch, toUser) => {
    const id = createMessageKey(threadId);
    createMessage(threadId, {
        id,
        type: MessageTypes.TEXT,
        sender: user.uuid,
        text,
    });

    dispatch(User.chatNotify({ threadId, uuid: toUser.uuid, message: text }));
};

export const sendImageMessage = (threadId, uri, user, info, dispatch, toUser) => {
    const id = createMessageKey(threadId);
    createMessage(threadId, {
        id,
        type: MessageTypes.IMAGE,
        sender: user.uuid,
        imageSource: null,
        info,
    });
    uploadAndGetUrl(threadId, id, uri).then(url => {
        url ? messagesRef(threadId).child(id).child("imageSource").set(url) : messagesRef(threadId).child(id).remove()
    });

    dispatch(User.chatNotify({ threadId, uuid: toUser.uuid, message: "New image" }));
};

export const sendAudioMessage = (threadId, uri, user, info, dispatch, toUser) => {
    const id = createMessageKey(threadId);
    createMessage(threadId, {
        id,
        type: MessageTypes.AUDIO,
        sender: user.uuid,
        audioSource: null,
        info,
    });
    uploadAndGetUrl(threadId, id, uri).then(url => {
        url ? messagesRef(threadId).child(id).child("audioSource").set(url) : messagesRef(threadId).child(id).remove()
    });

    dispatch(User.chatNotify({ threadId, uuid: toUser.uuid, message: "New audio" }));
};

export const sendVideoMessage = (threadId, uri, user, dispatch, toUser) => {
    const id = createMessageKey(threadId);
    createMessage(threadId, {
        id,
        type: MessageTypes.VIDEO,
        sender: user.uuid,
        videoSource: null,
    });
    uploadAndGetUrl(threadId, id, uri).then(url => {
        url ? messagesRef(threadId).child(id).child("videoSource").set(url) : messagesRef(threadId).child(id).remove()
    });

    dispatch(User.chatNotify({ threadId, uuid: toUser.uuid, message: "New video" }));
};

export const sendDocumentMessage = (threadId, uri, user, info, dispatch, toUser) => {
    const id = createMessageKey(threadId);
    createMessage(threadId, {
        id,
        type: MessageTypes.DOCUMENT,
        sender: user.uuid,
        documentSource: null,
        info,
    });

    uploadAndGetUrl(threadId, id, uri, info.name).then(url => {
        url ? messagesRef(threadId).child(id).child("documentSource").set(url) : messagesRef(threadId).child(id).remove()
    });

    dispatch(User.chatNotify({ threadId, uuid: toUser.uuid, message: "New document" }));
};

export const unreadCount = async (threadId, user) => {
    let messages = await messagesRef(threadId).orderByChild("sentAt").limitToLast(5).once("value", _ => {});

    let uC = -1;
    if (messages.val()) {
        uC = 0;
        messages.forEach(m => {
            m = m.val();
            if (m.sender != user.uuid && !m.readAt) {
                uC++;
            }
        });
    }

    return uC;
};

export const markAsRead = (threadId, m) => {
    !m.readAt && messagesRef(threadId).child(m.id).set({ ...m, readAt: firebase.database.ServerValue.TIMESTAMP });
};

export const setupNewMessageListener = (threadId, cb) => {
    messagesRef(threadId).orderByChild("sentAt").startAt(Date.now()).on("child_added", _ => cb());
    return messagesRef(threadId);
};
