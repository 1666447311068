/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { Text, StyleSheet } from "react-native";
import Fonts from "../../themes/fonts";
import { FontSize } from "../../themes/sizes";
import { GlobalStyles } from "../../themes/styles";

export default ( props ) => {
    const { children, style} = props;
    return <Text {...props} style={[GlobalStyles.text, styles.textStyle, style]}>{children}</Text>
};

const styles = StyleSheet.create({
    textStyle: {
        fontFamily: Fonts.MainMedium,
        fontSize: FontSize.NORMAL ,
    },
});
