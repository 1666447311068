/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { Image, TouchableOpacity } from "react-native";
import ContainerView from "../../components/main/ContainerView";
import MainMediumText from "../../components/text/MainMediumText";
import { Colors } from "../../themes/colors";
import { FontSize, Size, SpacingH } from "../../themes/sizes";

export default ({ onDismiss }) => (
    <ContainerView style={{ alignItems: "center" }}>
        <Image source={require("../../assets/illustrations/offline_notice.png")} style={{ marginTop: Size.h15, width: Size.w100, height: Size.w100 * 7 / 9 }} />
        <MainMediumText style={{ textAlign: "center", marginTop: SpacingH.s5, color: Colors.BLUE_GREY, fontSize: FontSize.SMALL }}>
            You are offline! {"\n"} Please check your internet connection.
        </MainMediumText>
        <TouchableOpacity style={{ marginTop: Size.h5 }} onPress={onDismiss}>
            <MainMediumText>Dismiss</MainMediumText>
        </TouchableOpacity>
    </ContainerView>
);
