/**
 * LifeRamp
 *
 * @author      Anees Muzzafer
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyleSheet, TouchableOpacity, View, Image } from "react-native";

import { Colors } from "../../themes/colors";
import { Size, SpacingH, SpacingW } from "../../themes/sizes";
import MainMediumText from "../../components/text/MainMediumText";

import { User } from "../../api";
import { setIsSpinning } from "../../state/slices/spinnerSlice";
import { reset } from "../../state/store";

export default ({ navigation }) => {
    const dispatch = useDispatch();
    const expo_push_token = useSelector(state => state.user.expoPushToken);
    const signOut = () => {
        dispatch(setIsSpinning(true));
        dispatch(User.signOut({ expo_push_token })).then(() => {
            dispatch(setIsSpinning(false));
            reset();
        }).catch(_ => reset());
    };

    return (
        <View style={{ flex: 1, backgroundColor: Colors.WHITE, paddingVertical: SpacingH.s2 }}>

            <View style={{ justifyContent: "center", alignItems: "center", marginTop: SpacingH.s3 + Size.adjustedStatusBarHeight }}>
                <Image source={require("../../assets/LifeRamp.png")} style={{ width: Size.w50, height: Size.w50 * 0.195, marginBottom: SpacingH.s2 }} resizeMode="contain" />
            </View>

            <View style={styles.confirmationText}>
                <MainMediumText>Are you sure you want to logout?</MainMediumText>
            </View>
            <View style={styles.buttonsWrapper}>
                <TouchableOpacity onPress={signOut}>
                    <View style={styles.confirm}>
                        <MainMediumText>Logout</MainMediumText>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => { navigation.goBack(); }}>
                    <View style={styles.cancel}>
                        <MainMediumText>Cancel</MainMediumText>
                    </View>
                </TouchableOpacity>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    confirmationText: {
        alignSelf: "center",
        marginTop: Size.h20,
    },
    buttonsWrapper: {
        flexDirection: "row",
        justifyContent: "center",
        marginTop: Size.h5
    },
    confirm: {
        width: Size.w40,
        height: Size.h5,
        backgroundColor: Colors.SOFT_GREY,
        justifyContent: "center",
        alignItems: "center",
        marginHorizontal: SpacingW.s1,
        borderRadius: 3
    },
    cancel: {
        width: Size.w40,
        height: Size.h5,
        justifyContent: "center",
        alignItems: "center",
        marginHorizontal: SpacingW.s1,
        borderRadius: 3,
        borderWidth: 1,
        borderColor: Colors.SOFT_GREY
    },
});
