/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { Text, StyleSheet, View, Pressable, Image } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import LikeIcon from "../icons/LikeIcon";
import CommentIcon from "../icons/CommentIcon";
import { Entypo } from "@expo/vector-icons";

import { Colors } from "../../themes/colors";
import { FontSize, SpacingH, SpacingW, hp, Size } from "../../themes/sizes";
import TimeAgo from "../utils/TimeAgo";

import { Posts } from "../../api";
import { addPostReaction, removePostReaction } from "../../state/slices/circleSlice";
import HeroText from "../text/HeroText";
import ReactionText from "../utils/ReactionText";
import MainMediumText from "../text/MainMediumText";
import MainRegularText from "../text/MainRegularText";

export default ({ uuid, user, tags, title, content, image, postedAt, reactions, reactionCounts, onUserPress = () => { }, onPostPress, onKebabPress, baseView, type }) => {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.user.profile);
    let hasLiked = reactions.find(r => r.user.uuid == currentUser.uuid && r.type == "like");

    const parseContent = (content) => {
        if(!content) return;

        let pattern = /(((https?:\/\/))[^\s]+)/g;
        return content.replace(pattern, (url) => {
           return url.replace(/(^\w+:|^)\/\//, "");
        });
    };

    const handleLikePress = () => {
        const reactionType = "like";

        if (hasLiked) {
            dispatch(removePostReaction({ uuid, type: reactionType, user: currentUser, postType: type }))
            dispatch(Posts.removeReaction({ uuid }));
        } else {
            dispatch(addPostReaction({ uuid, type: reactionType, user: currentUser, postType: type }))
            dispatch(Posts.reactToPost({ uuid, type: reactionType }));
        }
    };

    return (
        <View style={styles.cardWrapper}>
            <View style={styles.cardTitle}>
                <Pressable style={styles.cardTitleOwner} onPress={() => onUserPress(user.uuid)}>
                    <Image source={{ uri: user.image_profile }} style={styles.ownerImage} />
                    <View style={{ paddingTop: SpacingH.s1 }}>
                        <MainMediumText style={{ fontSize: FontSize.SMALL }}>{user.name}</MainMediumText>
                        <TimeAgo date={postedAt} />
                    </View>
                </Pressable>
                <Pressable style={[styles.cardTitleTag, { paddingTop: SpacingH.s1 }]}>
                    {tags && tags.map(t => <Text style={[styles.cardTitleTagItem]} key={t.uuid}>{t.name}</Text>)}
                </Pressable>
                {baseView !== "Profile" &&
                    <Pressable style={styles.kebabWrapper} hitSlop={24} onPress={onKebabPress} >
                        <Entypo name="dots-three-vertical" size={16} color={Colors.DARK_GREY} />
                    </Pressable>
                }
            </View>
            <Pressable onPress={() => onPostPress(uuid)}>
                <View style={styles.cardContent}>
                    <HeroText style={{ fontSize: FontSize.NORMAL, marginBottom: SpacingH.s2, paddingRight: SpacingW.s2 }} numberOfLines={2}>{title}</HeroText>
                    <MainRegularText style={{ fontSize: FontSize.SMALL, textAlign: "justify" }} numberOfLines={4}>{parseContent(content)}</MainRegularText>
                </View>
                {image &&
                    <View style={styles.cardImageWrapper}>
                        <Image source={{ uri: image }} style={styles.cardImage} resizeMode="cover" />
                    </View>
                }
            </Pressable>
            {baseView !== "Profile" &&
                <View style={styles.cardReaction}>
                    <ReactionText reactions={reactions} user={currentUser} />
                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                        <LikeIcon onPress={handleLikePress} isLiked={hasLiked} />
                    </View>
                </View>
            }
        </View>
    );
};

const styles = StyleSheet.create({
    cardWrapper: {
        flexDirection: "column",
        // height: hp("40%"),
        backgroundColor: Colors.WHITE,
        overflow: "hidden",
        paddingVertical: SpacingH.s3,
        paddingHorizontal: SpacingW.s4,
        borderBottomColor: Colors.SOFT_GREY,
        borderBottomWidth: 1,
    },
    cardTitle: {
        flexDirection: "row",
    },
    cardTitleOwner: {
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        marginRight: SpacingW.s4,
    },
    ownerImage: {
        height: 48,
        width: 48,
        borderRadius: 24,
        marginRight: SpacingW.s2,
    },
    cardTitleTag: {
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
    },
    cardTitleTagItem: {
        fontSize: FontSize.SMALL,
        color: Colors.DEEP_TEAL,
        marginRight: SpacingW.s1,
        marginBottom: SpacingH.s1,
    },
    kebabWrapper: {
        width: Size.w10,
        height: Size.h5,
        justifyContent: "center",
        alignItems: "flex-end",
    },
    cardContent: {
        marginVertical: SpacingH.s2,
    },
    cardImageWrapper: {
        marginBottom: SpacingH.s2,
    },
    cardImage: {
        height: hp("23%"),
        width: "100%",
        borderRadius: hp("1.5%"),
        margin: "auto",
    },
    cardReaction: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingRight: SpacingW.s1,
    },
});
