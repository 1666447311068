/**
 * LifeRamp
 *
 * @author      Chetanya Gupta
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from 'react';
import { StyleSheet, View, TextInput } from 'react-native';
import { Colors } from "../../themes/colors";
import { FontSize, Size } from '../../themes/sizes';
import Fonts from "../../themes/fonts";

export default (props) => (
    <View style={[styles.containerStyle, props.containerStyle]}>
        <TextInput
            maxLength={5000}
            {...props}
            style={[styles.boxInput, props.style]}
            ref={props.refCallback}
        />
    </View>
);

const styles = StyleSheet.create({
    boxInput: {
        height: 45,
        paddingHorizontal: 12,
        color: Colors.TEXT_PRIMARY,
        fontSize: FontSize.NORMAL,
        borderColor: Colors.TEXT_BLACK,
        borderRadius: 4,
        borderWidth: 1,
        fontFamily: Fonts.Main,
        width: Size.w10,
        textAlign: "center"
    },
});
