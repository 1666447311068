/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { Image, View } from "react-native";
import { Size, wp } from "../../themes/sizes";

export default ({ isVisible }) => {
    return (
        <>
            {isVisible &&
                <View style={{ alignItems: "center", position: "absolute", top: 0, left: 0, width: wp(100), height: "100%", zIndex: 1, backgroundColor: "#00000090" }}>
                    <Image source={require("../../assets/icons/dark/spinner.gif")} style={{ marginTop: Size.h40, paddingTop: Size.h5, width: 80, resizeMode: "cover", height: 80, position: "relative", }} />
                </View>
            }
        </>
    )
};
