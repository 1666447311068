/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, View, TouchableOpacity, Image, Alert } from "react-native";
import { useSelector, useDispatch } from "react-redux";

import { FontSize, SpacingH, SpacingW, Size, isWeb } from "../../themes/sizes";
import { Colors } from "../../themes/colors";

import { EvilIcons, Feather } from "@expo/vector-icons";
import MainMediumText from "../text/MainMediumText";

import * as WebBrowser from "expo-web-browser";
import { CoachingSessions } from "../../api";
import { setIsSpinning } from "../../state/slices/spinnerSlice";
import { resetInstantZoomURL } from "../../state/slices/coachingSessionSlice";
import { setCurrentThreadId } from "../../state/slices/chatSlice";

export default ({ children, style, navigation }) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.chat);
    const canCreateSession = useSelector(state => state.user.profile.canCreateSession);

    const instantZoomURL = useSelector(state => state.coachingSession.instantZoomURL);
    const instantZoom = () => {
        if (!canCreateSession) {
            Alert.alert("Limit reached", "Sorry, you've exhausted your session limit.");
            return;
        }

        if (state.chatWithUser && state.chatWithUser.hasInstantZoom) {
            dispatch(setIsSpinning(true));
            dispatch(CoachingSessions.createInstantSession({ user: state.chatWithUser }));
        }
    };

    React.useEffect(() => {
        if (instantZoomURL) {
            dispatch(setIsSpinning(false));
            WebBrowser.openBrowserAsync(instantZoomURL);
            dispatch(resetInstantZoomURL());
        }
    }, [instantZoomURL]);

    const resetCurrentThreadId = () => dispatch(setCurrentThreadId(null));

    return (
        <View style={styles.header}>
            <TouchableOpacity onPress={() => { resetCurrentThreadId(); navigation.goBack(); }}>
                <EvilIcons name="chevron-left" size={42} color={Colors.LIGHT_GREY} />
            </TouchableOpacity>

            <View style={styles.circleContainer}>
                <Image source={{ uri: state.chatWithUser ? state.chatWithUser.image_profile : null }} style={styles.circleImage} />
                <MainMediumText style={{ fontSize: FontSize.SMALL }}>{state.chatWithUser ? state.chatWithUser.name : ''}</MainMediumText>
            </View>
            <TouchableOpacity onPress={() => {
                state.chatWithUser && state.chatWithUser.hasInstantZoom &&
                Alert.alert(
                    "Start an instant session?",
                    "This would count towards your monthly session limit.",
                    [
                        {
                            text: "Start now",
                            onPress: () => {instantZoom()},
                            style: "destructive"
                        },
                        {
                            text: "Cancel",
                            style: "cancel"
                        },
                    ]
                );
            }}>
                <Feather name="video" size={24} color={Colors.LIGHT_GREY} style={{ opacity: !state.chatWithUser || !state.chatWithUser.hasInstantZoom ? 0 : 1 }} />
            </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
    header: {
        flexDirection: "row",
        marginTop: Size.adjustedStatusBarHeight,
        alignItems: "center",
        backgroundColor: Colors.WHITE,
        paddingVertical: SpacingH.s1,
        paddingLeft: SpacingH.s0,
        paddingRight: SpacingW.s4,
        borderBottomColor: Colors.SOFT_GREY,
        borderBottomWidth: 1,
    },
    circleContainer: {
        flex: 1,
        alignItems: "center",
    },
    circleImage: {
        height: 42,
        width: 42,
        borderRadius: 21,
        marginVertical: SpacingH.s1,
    },
});
