/**
 * LifeRamp
 *
 * @author      Anees Muzzafer
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from 'react';
import { Animated, Easing, View, StyleSheet } from "react-native";
import Svg, { Path } from "react-native-svg";
import { Colors } from "../../themes/colors";
import MainMediumText from '../text/MainMediumText';

const ChatLikeIcon = React.forwardRef((props, ref) => {
    const { width = 80, height = 80, likes, isReceive, onAnimComplete } = props;

    const [iconWidth, setIconWidth] = React.useState(14);
    const [iconHeight, setIconHeight] = React.useState(16);
    const [iconColor, setIconColor] = React.useState(Colors.HEART_RED);
    const [iconStrokeColor, setIconStrokeColor] = React.useState(Colors.HEART_RED);
    const [borderColor, setBorderColor] = React.useState(Colors.WHITE);
    const [showNumber, setShowNumber] = React.useState(likes > 1 ? true : false);

    const dx = React.useRef(new Animated.Value(width)).current;
    const dy = React.useRef(new Animated.Value(height)).current;
    const likeAnim = React.useRef(new Animated.Value(0)).current;
    const unlikeAnimVar = React.useRef(new Animated.Value(1)).current;
    const unlikeNumber = React.useRef(new Animated.Value(1)).current;

    const dx2 = React.useRef(new Animated.Value(width)).current;
    const dy2 = React.useRef(new Animated.Value(height)).current;
    const likeAnim2 = React.useRef(new Animated.Value(0)).current;

    const scaleAnim = () => {
        setBorderColor(Colors.WHITE);
        Animated.spring(likeAnim, {
            toValue: 1,
            friction: 4,
            useNativeDriver: true,
        }).start(() => {
            setIconWidth(14);
            setIconHeight(16);
            setIconColor(Colors.HEART_RED);
            setIconStrokeColor(Colors.HEART_RED);
            setBorderColor(Colors.WHITE);
            likeAnimXY();
        });
    };


    React.useImperativeHandle(ref, () => ({
        doScale() {
            scaleAnim();

        },
        oneTwo() {
            dx2.setValue(width);
            dy2.setValue(height);
            likeAnim2.setValue(0);
            scaleAnim2();
        },
        twoOne() {
            dx2.setValue(width);
            dy2.setValue(height);
            likeAnim2.setValue(0);
            disappearNumber();
        },
        oneZero() {
            disappearLike();
        }
    }));


    const disappearLike = () => {
        Animated.timing(unlikeAnimVar, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true,
        }).start(() => { onAnimComplete(); });
    };

    const disappearNumber = () => {
        Animated.timing(unlikeNumber, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true,
        }).start(() => { setShowNumber(false); });
    };


    const riseUpNumber = unlikeNumber.interpolate({
        inputRange: [0, 1],
        outputRange: [-100, 0]
    });

    const likeAnimXY = () => {
        Animated.timing(dx, {
            toValue: 0,
            duration: 300,
            useNativeDriver: true,
            easing: Easing.linear,
        }).start();

        Animated.timing(dy, {
            toValue: 0,
            duration: 300,
            useNativeDriver: true,
            easing: Easing.cubic,
        }).start();
    };

    const riseUp = unlikeAnimVar.interpolate({
        inputRange: [0, 1],
        outputRange: [-100, 0]
    });


    const scaleAnim2 = () => {
        Animated.spring(likeAnim2, {
            toValue: 1,
            friction: 4,
            useNativeDriver: true,
        }).start(() => {
            setIconWidth(14);
            setIconHeight(16);
            setIconColor(Colors.HEART_RED);
            setIconStrokeColor(Colors.HEART_RED);
            likeAnimXY2();
        });
    };
    const likeAnimXY2 = () => {
        Animated.timing(dx2, {
            toValue: 0,
            duration: 300,
            useNativeDriver: true,
            easing: Easing.linear,
        }).start(() => { setShowNumber(true) });

        Animated.timing(dy2, {
            toValue: 0,
            duration: 300,
            useNativeDriver: true,
            easing: Easing.cubic,
        }).start();
    };

    return (
        <View style={[{ position: "absolute", zIndex: 2 }, isReceive ? styles.receivePosition : styles.sendPosition]}>
            <Animated.View style={[{ position: "absolute", transform: [{ translateY: riseUp }], opacity: unlikeAnimVar, zIndex: 2, },]}>
                <Animated.View style={{ position: "absolute", transform: [{ translateX: dx }, { translateY: dy }, { scale: likeAnim }] }}>
                    <View style={[isReceive ? styles.receiveLikeIconContainer : styles.sendLikeIconContainer, { borderColor: borderColor }]}>
                        {showNumber &&
                            <Animated.View style={[styles.numberOfLikes, isReceive ? styles.receiveNumberPosition : styles.sendNumberPosition, { opacity: unlikeNumber, transform: [{ translateY: riseUpNumber }] }]}>
                                <MainMediumText style={{ fontSize: 6, color: Colors.WHITE }}>{likes}</MainMediumText>
                            </Animated.View>}
                        <Svg width={iconWidth} height={iconHeight} viewBox="0 0 23 20">
                            <Path id="svg_1" d="m11.03976,18.44722l-8.52586,-8.47685c-1.97069,-1.95462 -1.97069,-5.12315 0,-7.05719c1.9707,-1.93404 5.16531,-1.95461 7.11526,0l1.4106,1.39909l1.41061,-1.39909c1.9707,-1.95461 5.1653,-1.95461 7.11526,0c1.94995,1.95462 1.97069,5.12315 0,7.05719l-8.52587,8.47685z" strokeWidth="1.2" stroke={iconStrokeColor} fill={iconColor} />
                        </Svg>
                    </View>
                </Animated.View>
            </Animated.View>
            <Animated.View style={{
                position: "absolute",
                opacity: likeAnim2,
                transform: [{ translateX: dx2 }, { translateY: dy2 }, { scale: likeAnim2 }]
            }}
            >
                <View style={[styles.sendLikeIconContainer, { backgroundColor: isReceive ? Colors.SOFT_GREY : Colors.LIGHT_TEAL }]}>
                    <Svg width={iconWidth} height={iconHeight} viewBox="0 0 23 20">
                        <Path id="svg_1" d="m11.03976,18.44722l-8.52586,-8.47685c-1.97069,-1.95462 -1.97069,-5.12315 0,-7.05719c1.9707,-1.93404 5.16531,-1.95461 7.11526,0l1.4106,1.39909l1.41061,-1.39909c1.9707,-1.95461 5.1653,-1.95461 7.11526,0c1.94995,1.95462 1.97069,5.12315 0,7.05719l-8.52587,8.47685z" strokeWidth="1.2" stroke={iconStrokeColor} fill={iconColor} />
                    </Svg>
                </View>
            </Animated.View>
        </View>
    );
});

const styles = StyleSheet.create({
    sendLikeIconContainer: {
        backgroundColor: Colors.LIGHT_TEAL,
        width: 30,
        height: 30,
        position: "absolute",
        top: -15,
        borderRadius: 15,
        borderWidth: 3,
        borderColor: Colors.WHITE,
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 2,
        zIndex: 2,
    },
    receiveLikeIconContainer: {
        backgroundColor: Colors.SOFT_GREY,
        width: 30,
        height: 30,
        position: "absolute",
        top: -15,
        borderRadius: 15,
        borderWidth: 3,
        borderColor: Colors.WHITE,
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 2,
        zIndex: 2
    },
    numberOfLikes: {
        width: 10,
        height: 10,
        backgroundColor: Colors.HEART_RED,
        position: "absolute",
        borderRadius: 5,
        top: -5,
        justifyContent: "center",
        alignItems: "center"
    },
    receivePosition: {
        right: 15,
    },
    sendPosition: {
        left: -15,
    },
    receiveNumberPosition: {
        right: 0,
    },
    sendNumberPosition: {
        left: 0,
    }
});

export default ChatLikeIcon;