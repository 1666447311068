/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiThunkHandler } from "./utils";

const getResource = createAsyncThunk(
    "resources/getResource",
    async ({ uuid }, thunkAPI) => apiThunkHandler(axios.get("/resources/" + uuid), thunkAPI)
);

const getResources = createAsyncThunk(
    "resources/getResources",
    async ({ category, page }, thunkAPI) => apiThunkHandler(axios.get("/resources/list/" + encodeURIComponent(category) + "?page=" + page), thunkAPI)
);

const refreshResources = createAsyncThunk(
    "resources/refreshResources",
    async (_, thunkAPI) => apiThunkHandler(axios.get("/resources"), thunkAPI)
);

const markAsFavorite = createAsyncThunk(
    "resources/markAsFavorite",
    async ({ uuid }, thunkAPI) => apiThunkHandler(axios.post("/resources/" + uuid + "/favorite"), thunkAPI)
);

const unmarkAsFavorite = createAsyncThunk(
    "resources/unmarkAsFavorite",
    async ({ uuid }, thunkAPI) => apiThunkHandler(axios.delete("/resources/" + uuid + "/favorite"), thunkAPI)
);

const getFavoriteResources = createAsyncThunk(
    "resources/getFavoriteResources",
    async ({ page }, thunkAPI) => apiThunkHandler(axios.get("/resources/favorites?page=" + page), thunkAPI)
);

const refreshFavoriteResources = createAsyncThunk(
    "resources/refreshFavoriteResources",
    async (_, thunkAPI) => apiThunkHandler(axios.get("/resources/favorites"), thunkAPI)
);

export default {
    getResource,

    getResources,
    refreshResources,

    markAsFavorite,
    unmarkAsFavorite,

    getFavoriteResources,
    refreshFavoriteResources,
};
