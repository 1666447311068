/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import { Colors } from "../../themes/colors";
import { SpacingH, SpacingW } from "../../themes/sizes";
import ContainerView from "../../components/main/ContainerView";
import ConversationListItem from "../../components/cards/ConversationListItem";
import PlaceHolder from "../../components/utils/PlaceHolder";
import SearchInput from "../../components/input/SearchInput";

import { conversationsRef } from "../../chat";

export default ({ navigation }) => {
    const user = useSelector(state => state.user.profile);

    const [conversations, setConversations] = React.useState([]);
    const [search, setSearch] = React.useState("");
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        conversationsRef(user.uuid).orderByChild("lastUpdatedAt").once("value", recentSnapshot => {
            let cons = [];
            recentSnapshot.forEach(c => { cons.push(c.val()); });
            setConversations(cons);
            setLoading(false);

            conversationsRef(user.uuid).orderByChild("lastUpdatedAt").startAt(Date.now()).on("child_added", c => setConversations([c.val(), ...conversations]));
            conversationsRef(user.uuid).on("child_removed", c => setConversations(conversations.filter(o => o.id !== c.id)));
            conversationsRef(user.uuid).on("child_changed", c => {
                let cons = conversations.concat();
                cons.splice(cons.indexOf(cons.find(o => o.id === c.id)), 1, c);
                setConversations(cons);
            });
        });

        return () => { conversationsRef(user.uuid).off(); };
    }, []);

    const filteredConversations = search !== "" ? conversations.filter(c => c.name.includes(search)) : conversations;

    return (
        <ContainerView style={{ flex: 1, backgroundColor: Colors.WHITE }} hasHeader>
            <View style={{ paddingHorizontal: SpacingW.s4, marginTop: SpacingH.s1 }}>
                <SearchInput
                    placeholder={"Search chats"}
                    value={search}
                    onChangeText={s => setSearch(s)}
                    isShaded
                />
            </View>
            <FlatList
                data={filteredConversations}
                renderItem={({ item }) => <ConversationListItem {...item} onPress={() => { navigation.navigate("Chat", { threadId: item.id, user: { uuid: item.uuid, image_profile: item.image, name: item.name, hasInstantZoom: item.hasInstantZoom }, isCoach: item.hasInstantZoom }) }} />}
                ListEmptyComponent={<PlaceHolder status={loading} type={"peopleCard"} />}
                keyExtractor={item => item.id}
            />
        </ContainerView>
    );
};
