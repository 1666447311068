/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    error: "",

    chatWithUser: null,
    messages: [],

    coachUnreadCount: 0,

    currentThreadId: null,
};

export const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        resetMessages(state) {
            state.messages = [];
        },
        setChatWithUser(state, { payload: { user } }) {
            state.chatWithUser = user;
        },
        setMessages(state, { payload: { messages } }) {
            state.messages = messages;
        },
        updateMessage(state, { payload: { message } }) {
            state.messages.splice(state.messages.indexOf(state.messages.find(m => m.id == message.id)), 1, message);
        },
        newMessage(state, { payload: { message } }) {
            state.messages.push(message);
        },
        delMessage(state, { payload: { message } }) {
            state.messages = state.messages.filter(m => m.id !== message.id);
        },

        setCoachUnreadCount(state, { payload }) {
            state.coachUnreadCount = payload;
        },

        setCurrentThreadId(state, { payload }) {
            state.currentThreadId = payload;
        },
    },
});

export const {
    resetMessages,
    setChatWithUser,
    setMessages,
    newMessage,
    updateMessage,
    delMessage,

    setCoachUnreadCount,
    setCurrentThreadId,
} = chatSlice.actions;

export default chatSlice.reducer;
