/**
 * LifeRamp
 *
 * @author      Waris Anwar, Burhan Rashid, Ummar Hamid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, Animated } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { LinearGradient } from 'expo-linear-gradient';

import MainMediumText from "../../components/text/MainMediumText";
import HeroText from "../../components/text/HeroText";
import { Gradients, Colors } from "../../themes/colors";
import { FontSize, SpacingH } from "../../themes/sizes";
import { User } from "../../api";
import fonts from "../../themes/fonts";

export default ({ navigation }) => {
    const dispatch = useDispatch();
    const institution = useSelector(state => state.user.profile.institution);

    React.useEffect(() => {
        setTimeout(() => {
            dispatch(User.getMatchedCoaches()).then(() => {
                navigation.navigate("MatchList");
            });
        }, 5000);
    }, []);

    const textAnim = React.useRef(new Animated.Value(0)).current;

    const textColorAnim = textAnim.interpolate({
        inputRange: [0, 0.5, 1],
        outputRange: ["#000000", "#00000070", "#000000"],
    })

    Animated.loop(
        Animated.timing(textAnim, {
            toValue: 1,
            duration: 2500,
            useNativeDriver: false,
        })
    ).start();

    return (
        <LinearGradient
            style={styles.gradient}
            start={[1, 0]}
            end={[0, 1]}
            colors={[Gradients.GREEN_BLUE.Start, Gradients.GREEN_BLUE.End]}>

            {/* {institution !== null
                ? <>
                    <HeroText style={{ fontSize: FontSize.BIG, marginBottom: SpacingH.s4, }}>Lucky you!</HeroText>
                    <MainMediumText style={{ fontSize: FontSize.NORMAL }}>{institution.shortname} is providing you</MainMediumText>
                    <MainMediumText style={{ fontSize: FontSize.NORMAL }}>with a personal coach.</MainMediumText>
                    <Animated.Text style={{ fontFamily: fonts.MainMedium , fontSize: FontSize.NORMAL, color: textColorAnim, marginTop: SpacingH.s2, textAlign: "center" }} >Take a breath while {"\n"} we match you.</Animated.Text>
                </>
                : <>
                    <HeroText style={{ fontSize: FontSize.BIG, marginBottom: SpacingH.s4, }}>The perfect fit!</HeroText>
                    <MainMediumText style={{ fontSize: FontSize.NORMAL, marginTop: SpacingH.s2, }}>Wait a moment while we find</MainMediumText>
                    <MainMediumText style={{ fontSize: FontSize.NORMAL, }}> your coaching matches.</MainMediumText>
                </>
            } */}

            <HeroText style={{ fontSize: FontSize.MEDIUM, marginBottom: SpacingH.s3, textAlign: "center" }}>Finding your{"\n"}perfect coach</HeroText>
            <Animated.Text style={{ fontFamily: fonts.MainMedium, fontSize: FontSize.NORMAL, color: textColorAnim, marginTop: SpacingH.s2, textAlign: "center" }} >Take a breath while{"\n"}we match you.</Animated.Text>

        </LinearGradient>
    );
}

const styles = StyleSheet.create({
    gradient: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
    },
});
