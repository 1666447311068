/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiThunkHandler } from "./utils";

const getCurrentGoals = createAsyncThunk(
    "goals/getCurrentGoals",
    async ({ page }, thunkAPI) => apiThunkHandler(axios.get("/goals/current?page=" + page), thunkAPI)
);

const refreshCurrentGoals = createAsyncThunk(
    "goals/refreshCurrentGoals",
    async (_, thunkAPI) => apiThunkHandler(axios.get("/goals/current"), thunkAPI)
);

const getCompletedGoals = createAsyncThunk(
    "goals/getCompletedGoals",
    async ({ page }, thunkAPI) => apiThunkHandler(axios.get("/goals/completed?page=" + page), thunkAPI)
);

const refreshCompletedGoals = createAsyncThunk(
    "goals/refreshCompletedGoals",
    async (_, thunkAPI) => apiThunkHandler(axios.get("/goals/completed"), thunkAPI)
);

const createGoal = createAsyncThunk(
    "goals/createGoal",
    async ({ goal }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/goals",
            {
                ...goal
            }
        ),
        thunkAPI
    )
);

const updateGoal = createAsyncThunk(
    "goals/updateGoal",
    async ({ goal }, thunkAPI) => apiThunkHandler(
        axios.put(
            "/goals/" + goal.uuid,
            {
                ...goal
            }
        ),
        thunkAPI
    )
);

const deleteGoal = createAsyncThunk(
    "goals/deleteGoal",
    async ({ goal }, thunkAPI) => apiThunkHandler(axios.delete("/goals/" + goal.uuid), thunkAPI)
);

const completeGoal = createAsyncThunk(
    "goals/completeGoal",
    async ({ goal }, thunkAPI) => apiThunkHandler(axios.post("/goals/" + goal.uuid + "/complete"), thunkAPI)
);

const getClientGoals = createAsyncThunk(
    "goals/getClientGoals",
    async ({ client, page }, thunkAPI) => apiThunkHandler(axios.get("/goals/of-client/" + client.uuid + "?page=" + page), thunkAPI)
);

const refreshClientGoals = createAsyncThunk(
    "goals/refreshClientGoals",
    async ({ client }, thunkAPI) => apiThunkHandler(axios.get("/goals/of-client/" + client.uuid), thunkAPI)
);

export default {
    getCurrentGoals,
    refreshCurrentGoals,

    getCompletedGoals,
    refreshCompletedGoals,

    createGoal,
    updateGoal,
    deleteGoal,
    completeGoal,

    getClientGoals,
    refreshClientGoals,
};
