/**
 * LifeRamp
 *
 * @author      Ummar Hamid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { FontSize, SpacingH, SpacingW } from "../../themes/sizes";
import { MaterialCommunityIcons, Ionicons } from "@expo/vector-icons";
import { GlobalStyles } from "../../themes/styles";

import { Colors } from "../../themes/colors";
import MainMediumText from "../text/MainMediumText";
import MainText from "../text/MainText";

export default ({ style, onPress, action, createdAt, isCompleted, completedAt, onPressEdit, type }) => (
    <TouchableOpacity onPress={onPress} style={[styles.card, style]}>
        <View style={styles.container}>
            <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <MainText style={styles.titleText}>I will</MainText>
                {isCompleted ?
                    <Ionicons name="checkmark-circle-outline" size={24} color={Colors.LIGHT_GREY} />
                    :
                    <TouchableOpacity onPress={onPressEdit}>
                        <MaterialCommunityIcons name="circle-edit-outline" size={24} color={Colors.DARK_GREY} />
                    </TouchableOpacity>
                }
            </View>
            <MainMediumText numberOfLines={2} style={[GlobalStyles.text, style, styles.subtitleText]}>{action}</MainMediumText>
            <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "baseline" }}>
                <MainMediumText style={{ fontSize: FontSize.VERY_SMALL, color: Colors.NAVY, marginTop: SpacingH.s0 }}>{type.toUpperCase()}</MainMediumText>
                <MainText style={{ fontSize: FontSize.SMALLER, color: Colors.TEXT_BLACK, marginTop: SpacingH.s0 }}>
                    {
                        isCompleted ?
                            "Completed on " + new Date(Date.parse(completedAt)).toDateString()
                            :
                            "Created on " + new Date(Date.parse(createdAt)).toDateString()
                    }
                </MainText>
            </View>
        </View>
    </TouchableOpacity>
);

const styles = StyleSheet.create({
    card: {
        borderRadius: 2,
        backgroundColor: Colors.SOFT_GREY,
        marginVertical: SpacingH.s1,
        paddingVertical: SpacingH.s2,
    },
    container: {
        flex: 1,
        justifyContent: "center",
        paddingHorizontal: SpacingH.s3,
    },
    titleText: {
        color: Colors.BLACK,
        fontSize: FontSize.NORMAL,
    },
    subtitleText: {
        color: Colors.BLACK,
        fontSize: FontSize.NORMAL,
        marginVertical: SpacingH.s2,
    },
});
