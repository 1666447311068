/**
 * LifeRamp
 *
 * @author      Chetanya Gupta
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, TouchableOpacity, View, Image } from "react-native";
import datetime from "date-and-time";

import { SpacingH, Size, SpacingW, FontSize } from "../../themes/sizes";
import { Colors, Gradients } from "../../themes/colors";

import MainMediumText from "../text/MainMediumText";
import HeroText from "../text/HeroText";

import { LinearGradient } from 'expo-linear-gradient';

export default ({ name, image_profile, pronouns, about, profileHandler, chatHandler }) => (
    <View style={styles.mainContainer} >
        <View style={styles.topContainer}>
            <TouchableOpacity style={styles.innerContainer} onPress={profileHandler} >
                <Image source={{ uri: image_profile }} style={{ marginBottom: SpacingH.s2, width: 84, height: 84, borderRadius: 42, borderColor: Colors.BLUE_GREY, borderWidth: 1 }} />
                <HeroText style={styles.nameStyle}>{name}</HeroText>
            </TouchableOpacity>
            {(pronouns || about) &&
                <View style={styles.sessionContainer}>
                    <MainMediumText style={{ fontSize: FontSize.SMALLER }}>{pronouns}</MainMediumText>
                    {about &&
                        <MainMediumText style={{ fontSize: FontSize.SMALLER, marginTop: SpacingH.s1, color: Colors.LIGHT_GREY, textAlign: "center" }}>{about}</MainMediumText>
                    }
                </View>
            }
        </View>
        <TouchableOpacity onPress={chatHandler}>
            <LinearGradient
                style={styles.chatBar}
                start={[1, 0]}
                end={[0, 1]}
                colors={[Gradients.GREEN_BLUE.Start, Gradients.GREEN_BLUE.End]}>
                <MainMediumText>Chat</MainMediumText>
            </LinearGradient>
        </TouchableOpacity>
    </View>
);

const styles = StyleSheet.create({
    mainContainer: {
        marginBottom: SpacingH.s4,
    },
    topContainer: {
        paddingTop: SpacingH.s1,
        backgroundColor: Colors.SOFT_GREY,
    },
    innerContainer: {
        justifyContent: "center",
        alignItems: 'center',
        paddingTop: SpacingH.s2,
        paddingBottom: SpacingH.s2,
    },
    sessionContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: SpacingW.s4,
        marginTop: -SpacingH.s1,
        marginBottom: SpacingH.s2,
    },
    nameStyle: {
        fontSize: FontSize.NORMED,
        textAlign: "center",
        paddingHorizontal: SpacingW.s1
    },
    chatBar: {
        justifyContent: "center",
        alignItems: "center",
        height: Size.h5,
    }
});
