/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { ImageBackground, StyleSheet, TouchableOpacity, View } from "react-native";
import { FontSize, SpacingW, SpacingH, Size } from "../../../themes/sizes";

import { Colors } from "../../../themes/colors";
import MainMediumText from "../../text/MainMediumText";
import FavoriteIcon from "../../icons/FavoriteIcon";


export default ({ style, onPress, image, title, subtitle, isFavorite, onPressFavorite }) => (
    <TouchableOpacity onPress={onPress} style={[styles.card, style]} activeOpacity={0.8}>
        <ImageBackground source={{ uri: image }} style={styles.image} >
            <View style={{ flex: 7 }} />
            <View style={styles.bottomOverlay}>
                <MainMediumText style={styles.titleText} numberOfLines={2} >{title}</MainMediumText>
                <FavoriteIcon hitSlop={50} onPress={onPressFavorite} isLiked={isFavorite} />
            </View>
        </ImageBackground>
    </TouchableOpacity>
);

const styles = StyleSheet.create({
    card: {
        borderWidth: 1,
        borderColor: Colors.SOFT_GREY,
        width: Size.w50,
        height: Size.w50,
        marginRight: SpacingH.s2,
    },
    image: {
        flex: 1,
    },
    titleText: {
        color: Colors.WHITE,
        fontSize: FontSize.VERY_SMALL,
        textAlign: "left",
        paddingRight: SpacingW.s3,
        flex: 1
    },
    subtitleText: {
        color: Colors.WHITE,
        fontSize: FontSize.SMALLER,
        textAlign: "left",
    },
    bottomOverlay: {
        flex: 2,
        flexDirection: "row",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        justifyContent: "space-between",
        alignItems: "center",
        paddingHorizontal: SpacingW.s3,
    }
});
