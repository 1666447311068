/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { View } from "react-native";
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { sV, FontSize, SpacingH } from "../../themes/sizes";
import { Colors } from "../../themes/colors";
import { GlobalStyles, materialTopTabs } from "../../themes/styles";

import ContainerView from "../../components/main/ContainerView";

import HeroText from "../../components/text/HeroText";
import MainText from "../../components/text/MainText";

import CurrentGoals from "./goals/Current"
import CompletedGoals from "./goals/Completed"

const GoalTabs = createMaterialTopTabNavigator();

export default ({ navigation }) => (
    <ContainerView >
        <View style={[GlobalStyles.containerMarginTop, GlobalStyles.containerMarginH]}>
            <HeroText style={{ fontSize: FontSize.BIG, marginBottom: SpacingH.s2 }}>My Goals</HeroText>
        </View>
        <GoalTabs.Navigator tabBarOptions={materialTopTabs}>
            <GoalTabs.Screen name="CurrentGoals" component={CurrentGoals} options={{ title: 'Current Goals' }} />
            <GoalTabs.Screen name="CompletedGoals" component={CompletedGoals} options={{ title: 'Current Goals' }} />
        </GoalTabs.Navigator>
    </ContainerView>
);
