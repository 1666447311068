/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import Constants from "expo-constants";
import { timezone } from "expo-localization";

export default {
    apiBase: Constants.manifest.extra.apiBase,
    deviceName: Constants.deviceName,
    timezone,
    firebaseConfig: {
        apiKey: "AIzaSyC7uJ-JH9Kj10d7UvgV06DhemnFVFVvJCU",
        authDomain: "liferamp-app.firebaseapp.com",
        databaseURL: "https://liferamp-app-default-rtdb.firebaseio.com",
        projectId: "liferamp-app",
        storageBucket: "liferamp-app.appspot.com",
        messagingSenderId: "333562444380",
        appId: "1:333562444380:web:33b9c0a25c71d5d209c499",
        measurementId: "G-FRHJ5YJL76",
    },
};
