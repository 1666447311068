/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActivityIndicator, Image, ScrollView, TouchableOpacity, View, Alert, Pressable } from "react-native";

import * as Analytics from "expo-firebase-analytics";
import * as Notifications from "expo-notifications";

import { Feather } from '@expo/vector-icons';

import { FontSize, SpacingW, SpacingH, wp } from "../../themes/sizes";
import { Colors } from "../../themes/colors";

import ContainerView from "../../components/main/ContainerView";

import HeroText from "../../components/text/HeroText";
import HeroSemiText from "../../components/text/HeroSemiText";
import MainText from "../../components/text/MainText";
import MainMediumText from "../../components/text/MainMediumText";

import PrimaryButton from "../../components/buttons/PrimaryButton";
import ActionButton from "../../components/buttons/ActionButton";

import BaseCard from "../../components/cards/BaseCard";
import CircleCard from "../../components/cards/CircleCard";

import CoachSessionModal from "../../components/utils/CoachSessionModal";
import ClientSessionModal from "../../components/utils/ClientSessionModal";

import { GlobalStyles } from "../../themes/styles";

import { setCircle } from "../../state/slices/circleSlice";
import { setIsSpinning } from "../../state/slices/spinnerSlice";

import { createOrGetConversation, setupNewMessageListener, unreadCount } from "../../chat";
import { User } from "../../api";
import { setCoachUnreadCount } from "../../state/slices/chatSlice";

let autoLoadInterval = null;

export default ({ navigation }) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.user);
    const user = useSelector(state => state.user.profile);
    const circle = useSelector(state => state.circle.data);

    const subscriptionExpired = !user.subscriptionEnd || (new Date()).getTime() > (new Date(user.subscriptionEnd)).getTime();

    const coachUnreadCount = useSelector(state => state.chat.coachUnreadCount);
    const coach = user.coaches && user.coaches.length && user.coaches[0];

    const matchCoach = () => {
        dispatch(setIsSpinning(true));
        dispatch(User.getMatchedCoaches()).then(() => {
            navigation.navigate("MatchList", { showBack: true });
            dispatch(setIsSpinning(false));
        });
    };

    React.useEffect(() => {
        dispatch(User.getMyProfile()).then(_ => {
            let goingToIntro = false;
            if (!user.isCoach) {
                if (!user.profileComplete) {
                    navigation.navigate("IntroProfileScreen");
                    goingToIntro = true;
                } else if (!user.coaches || !user.coaches.length) {
                    matchCoach();
                    goingToIntro = true;
                }
            }

            dispatch(setCircle({ circle: user.circle }));
            !goingToIntro && subscriptionExpired && navigation.navigate("Pay");
        });
    }, [subscriptionExpired]);

    React.useEffect(() => {
        autoLoadInterval = setInterval(() => { dispatch(User.getMyProfile()); }, 1000 * 45 * 1);
        user.isCoach && dispatch(User.getCoachDashboardData());

        return () => { autoLoadInterval && clearInterval(autoLoadInterval); };
    }, []);

    /** -- Message Coach -- **/
    const [coachThreadId, setCoachThreadId] = React.useState(null);
    const setupCoachMessage = async () => {
        if (!coach || coachThreadId) return;

        const threadId = await createOrGetConversation(user, coach);
        setCoachThreadId(threadId);

        const uC = await unreadCount(threadId, user);
        dispatch(setCoachUnreadCount(uC));

        if (threadId) return;

        setTimeout(() => setupCoachMessage(), 3000);
    };

    const updateCoachUnreadCount = async () => {
        const uC = await unreadCount(coachThreadId, user);
        dispatch(setCoachUnreadCount(uC));
    };

    React.useEffect(() => {
        if (!coachThreadId) {
            setupCoachMessage();
        } else {
            const ref = setupNewMessageListener(coachThreadId, updateCoachUnreadCount);
            return () => { ref.off() };
        }
    }, [coachThreadId]);

    const [openChat, setOpenChat] = React.useState(false);
    const messageCoach = () => {
        Analytics.logEvent("tap_messageCoach", { screen: "Connect", coach: coach.name });
        setOpenChat(true);
    };

    React.useEffect(() => {
        if (!openChat) return;

        if (!coachThreadId) {
            dispatch(setIsSpinning(true));
            setupCoachMessage();
        } else {
            navigation.navigate("ChatStackScreen", { screen: "Chat", params: { threadId: coachThreadId, user: coach, isCoach: true } });
            dispatch(setIsSpinning(false));
            setOpenChat(false);
        }
    }, [openChat, coachThreadId]);
    /** -- Message Coach -- */

    const schedule1on1 = () => {
        Analytics.logEvent("tap_schedule1on1", { screen: "Connect", coach: coach.name });

        if (!user.canCreateSession) {
            return Alert.alert("Limit reached", "Sorry, you've exhausted your session limit.");
        }

        const calendlyUrlWithUser = coach ? coach.calendly_url + "?name=" + encodeURIComponent(user.name) + "&email=" + encodeURIComponent(user.email) : "";
        navigation.navigate("WebView", { url: calendlyUrlWithUser, schedule: true });
    };

    const [showSessionModal, setShowSessionModal] = React.useState(true);
    const [incompleteSessionIndex, setIncompleteSessionIndex] = React.useState(0);
    const [incompleteSession, setIncompleteSession] = React.useState(null);
    const nextIncompleteSession = () => {
        if (user.incompleteSessions.length > incompleteSessionIndex + 1) {
            setIncompleteSessionIndex(incompleteSessionIndex + 1);
            setIncompleteSession(user.incompleteSessions[incompleteSessionIndex + 1]);
        } else {
            setIncompleteSessionIndex(null);
            setShowSessionModal(false);
        }
    };
    React.useEffect(() => {
        if (user.incompleteSessions.length > 0) {
            setIncompleteSession(user.incompleteSessions[incompleteSessionIndex]);
        }
    }, []);

    return (
        <ContainerView hasHeader>
            <ScrollView style={[GlobalStyles.containerPaddingH, { paddingTop: GlobalStyles.container.paddingTop }]}>
                <View style={GlobalStyles.containerMarginTop} />
                <HeroText style={{ fontSize: FontSize.BIG }}>Talk it out.</HeroText>
                <HeroText style={{ fontSize: FontSize.BIG }}>Gain confidence.</HeroText>
                <HeroText style={{ fontSize: FontSize.BIG }}>Find clarity.</HeroText>

                {!user.isCoach &&
                    (user.coaches && user.coaches.length > 0 ?
                        <>
                            <MainMediumText style={{ fontSize: FontSize.NORMAL, marginTop: SpacingH.s3 }}>Connect with your coach</MainMediumText>
                            <BaseCard style={{ marginTop: SpacingH.s2 }}>
                                <TouchableOpacity onPress={() => { navigation.navigate("CoachProfile", { session: true }) }}>
                                    <View
                                        style={{ flexDirection: "row", alignItems: "center", marginBottom: SpacingH.s2 }}>
                                        <Image
                                            source={{ uri: coach.image_profile }}
                                            style={{ width: 84, height: 84, borderRadius: 42, marginRight: SpacingW.s3, borderColor: Colors.BLUE_GREY, borderWidth: 1 }} />
                                        <View style={{ flexDirection: "column" }}>
                                            <MainMediumText style={{ fontSize: FontSize.NORMAL, marginBottom: SpacingH.s0 }}>Coach {coach.firstname}</MainMediumText>
                                            {coachUnreadCount > 0 &&
                                                <Pressable onPress={() => messageCoach(coach)}>
                                                    <MainMediumText style={{ fontSize: FontSize.SMALLER, color: Colors.DEEP_TEAL }}>{coachUnreadCount} unread</MainMediumText>
                                                </Pressable>
                                            }
                                        </View>
                                    </View>
                                </TouchableOpacity>
                                <View style={{ flexDirection: "row", alignItems: "flex-start", marginBottom: SpacingH.s2 }}>
                                    <MainText style={{ flex: 1, fontSize: FontSize.SMALL }}>
                                        Welcome to LifeRamp. Spend some time exploring your feed and resources.
                                    </MainText>
                                </View>
                                <View style={{ flexDirection: "row" }}>
                                    <PrimaryButton buttonSize={'xs'} btnStyle={{ minWidth: 0, marginRight: SpacingW.s3 }} textStyle={{ fontSize: FontSize.SMALL }} onPress={schedule1on1}>
                                        Schedule a 1 on 1
                                    </PrimaryButton>
                                    <ActionButton buttonStyle={'navy'} buttonSize={"sm"} style={{ height: 44 }} activeOpacity={0.8} textStyle={{ fontSize: FontSize.SMALL }} onPress={() => messageCoach(coach)}>
                                        Message
                                    </ActionButton>
                                </View>
                            </BaseCard>
                        </>
                        :
                        <>
                            <MainMediumText style={{ fontSize: FontSize.NORMAL, marginTop: SpacingH.s3 }}>Connect with your coach</MainMediumText>
                            <BaseCard style={{ marginTop: SpacingH.s2, paddingVertical: 0, paddingHorizontal: 0, backgroundColor: Colors.SOFT_WHITE, paddingBottom: SpacingH.s0 }}>
                                <Image
                                    source={require("../../assets/illustrations/my_clients.png")}
                                    style={{ width: wp(90), height: wp(90) * 354 / 800, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
                                />
                                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", marginVertical: SpacingH.s2 }}>
                                    <HeroSemiText style={{ fontSize: FontSize.NORMED, color: Colors.DARK_GREY, marginBottom: SpacingH.s3 }}>
                                        You haven't selected a coach yet!
                                    </HeroSemiText>
                                    <PrimaryButton buttonSize={'xs'} textStyle={{ fontSize: FontSize.SMALL }} onPress={() => { matchCoach(); }}>
                                        Choose a coach
                                    </PrimaryButton>
                                </View>
                            </BaseCard>
                        </>
                    )
                }

                {user.isCoach &&
                    <>
                        <MainMediumText style={{ fontSize: FontSize.NORMAL, marginTop: SpacingH.s3 }}>Connect with your clients</MainMediumText>
                        <BaseCard style={{ marginTop: SpacingH.s2, paddingVertical: 0, paddingHorizontal: 0, backgroundColor: Colors.SOFT_WHITE }}>
                            <TouchableOpacity onPress={() => { navigation.navigate("MyClients") }} activeOpacity={0.8}>
                                <Image
                                    source={require("../../assets/illustrations/my_clients.png")}
                                    style={{ width: "100%", height: wp(90) * 354 / 800, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
                                />
                                <View style={{ flex: 1, alignItems: "center", justifyContent: "center", marginVertical: SpacingH.s0 }}>
                                    <HeroSemiText style={{ fontSize: FontSize.NORMED, color: Colors.DARK_GREY }}>
                                        {state.clientCount ? state.clientCount : 0} client{state.clientCount && state.clientCount > 1 ? 's' : ''} connected
                                    </HeroSemiText>
                                </View>
                                <View style={{
                                    backgroundColor: Colors.NAVY,
                                    flexDirection: "row",
                                    marginTop: SpacingH.s1,
                                    justifyContent: "space-between",
                                    paddingHorizontal: SpacingW.s2,
                                    paddingVertical: SpacingH.s2,
                                    borderBottomLeftRadius: 5,
                                    borderBottomRightRadius: 5,
                                }}>
                                    <MainMediumText style={{ fontSize: FontSize.SMALLER, color: Colors.SOFT_WHITE }}>{state.sessionCompleteCount ? state.sessionCompleteCount : 0} completed sessions</MainMediumText>
                                    <MainMediumText style={{ fontSize: FontSize.SMALLER, color: Colors.SOFT_WHITE }}>{state.sessionTotalCount ? state.sessionTotalCount : 0} total sessions</MainMediumText>
                                </View>
                            </TouchableOpacity>
                        </BaseCard>
                    </>
                }

                {user.circle &&
                    <>
                        <MainMediumText style={{ fontSize: FontSize.NORMAL, marginTop: SpacingH.s3 }}>Connect with your circle</MainMediumText>
                        <CircleCard style={{ marginBottom: SpacingH.s1 }} {...circle} onPress={() => { navigation.navigate("CircleStackScreen") }} />
                    </>
                }

                {user.isSuperAdmin &&
                    <View style={{ alignItems: "flex-end", paddingHorizontal: SpacingH.s0, marginTop: SpacingH.s1, marginBottom: SpacingH.s1 }}>
                        <TouchableOpacity onPress={() => { navigation.navigate("CoachCircleList") }}>
                            <MainMediumText style={{ fontSize: FontSize.SMALL, color: Colors.DEEP_TEAL }}>View all circles<Feather name="chevron-right" size={FontSize.NORMAL} color={Colors.DEEP_TEAL} /></MainMediumText>
                        </TouchableOpacity>
                    </View>
                }

                <View style={{ height: 50 }} />

            </ScrollView>

            {incompleteSession !== null ?
                user.isCoach ?
                    <CoachSessionModal
                        isModalVisible={showSessionModal}
                        setModalVisible={setShowSessionModal}
                        session={incompleteSession}
                        nextSession={nextIncompleteSession}
                    />
                    :
                    <ClientSessionModal
                        isModalVisible={showSessionModal}
                        setModalVisible={setShowSessionModal}
                        session={incompleteSession}
                        nextSession={nextIncompleteSession}
                    />
                : <></>
            }
        </ContainerView>
    );
};
