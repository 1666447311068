/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { FontSize, SpacingW, SpacingH, Size, hp } from "../../../themes/sizes";

import { Colors } from "../../../themes/colors";
import MainMediumText from "../../text/MainMediumText";
import MainText from "../../text/MainText";
import FavoriteIcon from "../../icons/FavoriteIcon";

export default ({ style, onPress, title, subtitle, byline, isFavorite, onPressFavorite }) => (
    <TouchableOpacity onPress={onPress} style={[styles.card, style]} activeOpacity={0.8}>
        <View style={styles.topContainer}>
            <MainMediumText style={styles.titleText} numberOfLines={2}>{title}</MainMediumText>
            <MainText style={styles.subtitleText} numberOfLines={3}>{subtitle}</MainText>
        </View>
        <View style={styles.bottomContainer}>
            <View>
                <MainText style={{ fontSize: FontSize.VERY_SMALL, color: Colors.WHITE_GREY, }}>Created by</MainText>
                <MainText style={{ fontSize: FontSize.VERY_SMALL, color: Colors.WHITE_GREY }}>{byline}</MainText>
            </View>
            <FavoriteIcon hitSlop={50} onPress={onPressFavorite} isLiked={isFavorite} />
        </View>
    </TouchableOpacity>
);

const styles = StyleSheet.create({
    card: {
        height: hp(25),
        marginRight: SpacingH.s2,
        backgroundColor: Colors.NAVY,
    },
    topContainer: {
        flex: 5,
        justifyContent: "center",
        paddingLeft: SpacingH.s3,
        paddingRight: SpacingH.s5,
    },
    bottomContainer: {
        flex: 2,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingHorizontal: SpacingW.s5,
        backgroundColor: Colors.BLACK,
        color: Colors.LIGHT_GREY,
    },
    titleText: {
        color: Colors.DEEP_TEAL,
        fontSize: FontSize.NORMAL,
        marginBottom: SpacingH.s1
    },
    subtitleText: {
        color: Colors.WHITE_GREY,
        fontSize: FontSize.VERY_SMALL,
    },
});
