/**
 * LifeRamp
 *
 * @author      Anees Muzzafer Shah
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, View, TouchableOpacity, Image, ActivityIndicator, ImageBackground, Animated } from "react-native";
import { Audio, Video } from 'expo-av';
import MultiTap from "react-native-multitap";
import Slider from '@react-native-community/slider';
import Modal from '@m4ttheweric/modal-enhanced-react-native-web';
import datetime from "date-and-time";
import * as WebBrowser from 'expo-web-browser';


import { EvilIcons, Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { Colors } from "../../themes/colors";
import { FontSize, Size, SpacingH, SpacingW } from "../../themes/sizes";
import { wp, hp } from "../../themes/sizes";
import { MessageTypes } from "../../chat";

import MainText from "../text/MainText";
import MainMediumText from "../text/MainMediumText";
import ChatLikeIcon from "../icons/ChatLikeIcon";
import fonts from "../../themes/fonts";

const imageW = wp("60%");
const imageH = hp("40%");

const toleranceMillisAfter = 1000;
const toleranceMillisBefore = 1000;

const Direction = {
    SEND: "send",
    RECEIVE: "receive",
};

export default ({ children, direction, type, withUser, isLiked, onPress, onLongPress, onUserPress, sentAt, imageSource, onPressImage, videoSource, audioSource, documentSource, info }) => {

    const [hasReceived, setHasReceived] = React.useState(false);
    const [imageSize, setImageSize] = React.useState({ width: 0, height: 0, r: 1 });
    const [sliderValue, setSliderValue] = React.useState(0);
    const [sound, setSound] = React.useState();
    const [durationPlayed, setDurationPlayed] = React.useState("");
    const [isAudioPlaying, setAudioPlaying] = React.useState(false);
    const [isSeeking, setIsSeeking] = React.useState(false);
    const [showLike, setShowLike] = React.useState(isLiked ? true : false);
    const [audioErrorState, setAudioErrorState] = React.useState(false);
    const [isModalVisible, setModalVisible] = React.useState(false);

    const [bubbleWidth, setBubbleWidth] = React.useState(0);
    const [bubbleHeight, setBubbleHeight] = React.useState(0);

    const video = React.useRef(null);
    const likeNumber = React.useRef(0);

    const audioDuration = React.useRef(1);
    const audioDurationInFormat = React.useRef("0:00");
    const playPosition = React.useRef(0);

    const sentAtReadable = datetime.format(new Date(sentAt), "h:mm A");
    const likeRef = React.useRef();

    const deleteAnim = React.useRef(new Animated.Value(0)).current;
    const scaleAnim = React.useRef(new Animated.Value(1)).current;
    const textAnim = React.useRef(new Animated.Value(0)).current;


    React.useEffect(() => { info && type === "image" && setImageSize({ width: info.width, height: info.height, r: Math.max(0.3, Math.min(3.33, info.width / info.height)) }); }, [imageSource]);
    React.useEffect(() => { isLiked && likeRef.current.doScale(); }, []);

    React.useEffect(() => {
        if (typeof isLiked === "undefined" && likeNumber.current === 1) {
            likeRef.current.oneZero();
        } else if (isLiked === 1 && typeof likeNumber.current === "undefined") {
            setShowLike(() => true);
            setTimeout(() => { likeRef.current.doScale(); }, 0);
        } else if (isLiked === 1 && likeNumber.current === 2) {
            likeRef.current.twoOne();
        } else if (isLiked === 2 && likeNumber.current === 1) {
            likeRef.current.oneTwo();
        }

        likeNumber.current = isLiked;
    }, [isLiked]);

    const humanReadableSize = (documentSize) => {
        var i = -1;
        var byteUnits = [" kB", " MB", " GB"];
        var fileSizeInBytes = documentSize;
        do {
            fileSizeInBytes = fileSizeInBytes / 1024;
            i++;
        } while (fileSizeInBytes > 1024);

        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    };

    const getImageWidth = () => imageSize.width >= imageSize.height ? imageW : imageH * imageSize.r;
    const getImageHeight = () => imageSize.width < imageSize.height ? imageH : imageW / imageSize.r;

    React.useEffect(() => {
        if (type === "audio") {
            (async () => {
                try {
                    const sound = new Audio.Sound();
                    if (audioSource) {
                        await sound.loadAsync({ uri: audioSource });
                        sound.setProgressUpdateIntervalAsync(100);
                        sound.setOnPlaybackStatusUpdate(doOnStatusUpdate);
                        setSound(sound);
                    }
                } catch (err) {
                    console.log("AudioPlaybackError:", err);
                    setAudioErrorState(true);
                }
            })();
        }
    }, [audioSource]);

    const doOnStatusUpdate = async playbackStatus => {
        if (playbackStatus.isPlaying) {
            const millis = playbackStatus.positionMillis;
            setSliderValue(millis / audioDuration.current);
            setDurationPlayed(millisToMinutesAndSeconds(millis));
        }

        if (playbackStatus.didJustFinish) {
            playPosition.current = 0;
            setSliderValue(0);
            setAudioPlaying(false);
        }
    };

    const playSound = async () => {
        if (!sound) return;

        await sound.playFromPositionAsync(playPosition.current, { toleranceMillisBefore, toleranceMillisAfter });
        setAudioPlaying(true);
    };

    const pauseSound = async () => {
        setAudioPlaying(false);
        sound.pauseAsync();
        playPosition.current = (await sound.getStatusAsync()).positionMillis;
        setAudioPlaying(false);
    };

    const onSeekValueChange = async (value) => {
        if (sound && !isSeeking) {
            setIsSeeking(true);
            await sound.pauseAsync();
        }
    };

    const onSeekingComplete = async (value) => {
        if (sound) {
            setIsSeeking(false);
            playPosition.current = value * audioDuration.current;
            await sound.playFromPositionAsync(playPosition.current, { toleranceMillisBefore, toleranceMillisAfter });
            setAudioPlaying(true);
        }
    };

    React.useEffect(() => {
        return sound ? () => { sound.unloadAsync(); } : undefined;
    }, [sound]);

    const millisToMinutesAndSeconds = (millis) => {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return `${minutes}:${(seconds < 10 ? "0" : "")}${seconds}`;
    };

    // ------------ Audio logic end --------------

    const showVideoInFullscreen = async () => {
        window.open(videoSource, '_blank');
        return

        await video.current.presentFullscreenPlayer();
        await video.current.playAsync();
    };
    const stopVideoPlayback = async () => {
        await video.current.stopAsync()
    };
    const onFullscreenUpdate = ({ fullscreenUpdate }) => fullscreenUpdate === Video.FULLSCREEN_UPDATE_PLAYER_DID_DISMISS && stopVideoPlayback();

    const documentIcon = (type) => {
        switch (type) {
            case "pdf":
                return <MaterialCommunityIcons name="file-pdf" size={22} color={Colors.HEART_RED} />
            case "doc":
            case "docx":
                return <MaterialCommunityIcons name="microsoft-word" size={22} color="dodgerblue" />
            case "xls":
            case "xlsx":
                return <MaterialCommunityIcons name="microsoft-excel" size={22} color="green" />
            case "ppt":
            case "pptx":
                return <MaterialCommunityIcons name="microsoft-powerpoint" size={22} color="green" />
            case "other":
            default:
                return <MaterialCommunityIcons name="file-document" size={22} color={Colors.LIGHT_GREY} />
        }
    };

    const handleDocumentDownload = async () => await WebBrowser.openBrowserAsync(documentSource);

    const getBubbleDimensions = (e) => {
        setBubbleWidth(e.nativeEvent.layout.width);
        setBubbleHeight(e.nativeEvent.layout.height);

    };

    const handleDelete = () => {
        setModalVisible(false);
        scaleDownAnim();
    };

    const deleteAnimation = () => {
        setModalVisible(true);
        Animated.timing(deleteAnim, {
            toValue: 1,
            duration: 300,
            useNativeDriver: true,
        }).start();
    };

    const cancelDelete = () => {
        Animated.timing(deleteAnim, {
            toValue: 0,
            duration: 300,
            useNativeDriver: true,
        }).start();
    }

    const scaleDownAnim = () => {
        Animated.timing(scaleAnim, {
            toValue: 0,
            duration: 300,
            useNativeDriver: true,
        }).start(() => { setTimeout(onLongPress, 5); });
    };

    const rotateAnim = deleteAnim.interpolate({
        inputRange: [0, 1],
        outputRange: ["0deg", "15.deg"],
    });

    Animated.loop(
        Animated.timing(textAnim, {
            toValue: 1,
            duration: 3000,
            useNativeDriver: false,
        })
    ).start();

    const textColorAnim = textAnim.interpolate({
        inputRange: [0, 0.5, 1],
        outputRange: [Colors.WHITE, Colors.DEEP_TEAL, Colors.WHITE],
    })

    const renderModal = () => {
        return (
            <Modal
                style={{ margin: 0, position: "absolute", left: 0, top: 0, width: Size.w100 }}
                isVisible={isModalVisible}
                swipeDirection="right"
                onSwipeComplete={() => { setModalVisible(false); cancelDelete() }}
                onBackdropPress={() => { setModalVisible(false); cancelDelete() }}
                onBackButtonPress={() => { setModalVisible(false); cancelDelete() }}
                animationIn="slideInRight"
                animationOut="fadeOut"
                backdropOpacity={0}
                backdropTransitionOutTiming={0}
                onModalWillHide={cancelDelete}
            >
                <View style={{ height: hp("12%"), width: wp("100%"), backgroundColor: Colors.NAVY, justifyContent: "center", alignItems: "center" }}>
                    <TouchableOpacity onPress={handleDelete} style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", borderBottomWidth: 0, borderColor: Colors.WHITE, paddingRight: 2 }}>
                        <MaterialIcons name="delete" size={22} color={Colors.WHITE} />
                        <Animated.Text style={{ fontFamily: fonts.MainMedium, fontSize: FontSize.NORMAL, color: textColorAnim }} >Tap to delete</Animated.Text>
                    </TouchableOpacity>
                </View>
            </Modal>
        );
    }

    if (direction == Direction.SEND) {
        // SEND
        switch (type) {
            case MessageTypes.TEXT:
                return (
                    <MultiTap onDoubleTap={onPress} onLongPress={deleteAnimation} style={{ backgroundColor: isModalVisible ? "#e2f4f2" : Colors.WHITE }}>
                        <Animated.View style={{ flexDirection: "row", alignSelf: "flex-end", justifyContent: "flex-end", marginTop: 20, marginBottom: 10, transform: [{ rotateY: rotateAnim }, { rotateX: rotateAnim }, { scale: scaleAnim }] }}>
                            <View style={[styles.sendChatBubbleContainer, { width: children.length > 28 ? "74%" : "auto" }]} onLayout={(e) => getBubbleDimensions(e)} >
                                {
                                    showLike &&
                                    <ChatLikeIcon
                                        ref={likeRef}
                                        width={bubbleWidth / 2}
                                        height={bubbleHeight / 2}
                                        likes={isLiked}
                                        onAnimComplete={() => { setShowLike(false) }}
                                    />
                                }
                                <MainText style={[styles.textColor]}>{children}</MainText>
                                <MainMediumText style={styles.sendTime}>{sentAtReadable}</MainMediumText>
                            </View>
                            <View style={styles.sendCornerOuter}>
                                <View style={[styles.sendCornerInner, { backgroundColor: isModalVisible ? "#e2f4f2" : Colors.WHITE }]}></View>
                            </View>
                        </Animated.View>
                        {renderModal()}
                    </MultiTap>
                );

            case MessageTypes.VIDEO:
                return (
                    <TouchableOpacity style={[styles.videoBubbleContainer, { backgroundColor: Colors.LIGHT_TEAL }]} onPress={showVideoInFullscreen} onLongPress={onLongPress}>
                        {typeof videoSource === "undefined" ?
                            <ActivityIndicator animating={true} size="small" color={Colors.NAVY} /> :
                            <Ionicons name="play-circle-outline" size={24} color={Colors.NAVY}></Ionicons>
                        }
                        <MainMediumText style={[styles.videoText, { color: Colors.NAVY, flexWrap: "wrap", flex: 1 }]}>Tap to view a video message you sent to {withUser.name}</MainMediumText>
                        {/* <Video
                            ref={video}
                            style={[styles.video]}
                            source={{ uri: videoSource }}
                            useNativeControls
                            resizeMode="contain"
                            onFullscreenUpdate={onFullscreenUpdate}
                            isLooping
                        /> */}
                    </TouchableOpacity>
                );

            case MessageTypes.AUDIO:
                audioDuration.current = info.duration;
                audioDurationInFormat.current = millisToMinutesAndSeconds(info.duration);
                return (
                    <MultiTap onDoubleTap={onPress} onLongPress={deleteAnimation} style={{ backgroundColor: isModalVisible ? "#e2f4f2" : Colors.WHITE }}>
                        <Animated.View style={{ flexDirection: "row", justifyContent: "flex-end", width: "100%", marginTop: 20, marginBottom: 10, transform: [{ rotateY: rotateAnim }, { rotateX: rotateAnim }, { scale: scaleAnim }] }}>
                            <View style={styles.sendAudioBubbleContainer} onLayout={(e) => getBubbleDimensions(e)}>
                                {
                                    showLike &&
                                    <ChatLikeIcon
                                        ref={likeRef}
                                        width={bubbleWidth / 2}
                                        height={bubbleHeight / 2}
                                        likes={isLiked}
                                        onAnimComplete={() => { setShowLike(false) }}
                                    />
                                }
                                <View style={{ flexDirection: "row" }}>
                                    {typeof audioSource === "undefined" || !sound ?
                                        <ActivityIndicator animating={true} size="small" color={Colors.NAVY} /> :
                                        <TouchableOpacity style={{ marginLeft: SpacingW.s1 }} onPress={isAudioPlaying ? pauseSound : playSound}>
                                            {!isAudioPlaying ?
                                                <Ionicons name="play" size={24} color={Colors.NAVY}></Ionicons> :
                                                <Ionicons name="pause" size={24} color={Colors.NAVY}></Ionicons>}
                                        </TouchableOpacity>}
                                    <View style={{ flex: 1, alignItems: 'stretch', justifyContent: 'center' }}>
                                        <Slider
                                            style={{ width: "100%", height: 25 }}
                                            minimumValue={0}
                                            maximumValue={1}
                                            minimumTrackTintColor={Colors.NAVY}
                                            maximumTrackTintColor={Colors.NAVY}
                                            thumbTintColor={Colors.NAVY}
                                            value={sliderValue}
                                            disabled={typeof audioSource === "undefined" || !sound ? true : false}
                                            onValueChange={onSeekValueChange}
                                            onSlidingComplete={onSeekingComplete}
                                        />
                                    </View>
                                </View>
                                <View style={styles.receiveAudioBottomWrapper} >
                                    <MainMediumText style={styles.sendDuration}>{isAudioPlaying ? durationPlayed : audioDurationInFormat.current}</MainMediumText>
                                    <MainMediumText style={styles.sendTime}>{sentAtReadable}</MainMediumText>
                                </View>
                            </View>
                            <View style={styles.sendCornerOuter}>
                                <View style={[styles.sendCornerInner, { backgroundColor: isModalVisible ? "#e2f4f2" : Colors.WHITE }]}></View>
                            </View>
                        </Animated.View>
                        {renderModal()}
                    </MultiTap>
                );

            case MessageTypes.IMAGE:
                return (
                    <View style={{ backgroundColor: isModalVisible ? "#e2f4f2" : Colors.WHITE }}>
                        <Animated.View style={{ flexDirection: "row", alignSelf: "flex-end", marginTop: 20, marginBottom: 10, transform: [{ rotateY: rotateAnim }, { rotateX: rotateAnim }, { scale: scaleAnim }] }}>
                            <View style={styles.sendImageBubbleContainer} onLayout={(e) => getBubbleDimensions(e)}>
                                <MultiTap onSingleTap={() => { onPressImage(imageSource, imageSize) }} onDoubleTap={onPress} onLongPress={deleteAnimation}>
                                    {!hasReceived &&
                                        <View style={{
                                            width: getImageWidth(),
                                            height: getImageHeight(),
                                            borderRadius: 10,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            backgroundColor: Colors.WHITE,
                                        }} >
                                            <ActivityIndicator animating={true} size="small" color={Colors.NAVY} />
                                        </View>}
                                    {typeof imageSource !== "undefined" &&
                                        <ImageBackground source={{ uri: imageSource }}
                                            style={{
                                                width: hasReceived ? getImageWidth() : 1,
                                                height: hasReceived ? getImageHeight() : 1,
                                                justifyContent: "flex-end",
                                                alignItems: "flex-end",
                                            }}
                                            imageStyle={{ borderRadius: 10 }}
                                            onLoad={(e) => { setHasReceived(() => true); }}
                                            resizeMode="cover">
                                            {hasReceived && <View style={{ height: SpacingH.s3, paddingHorizontal: SpacingH.s1, backgroundColor: "rgba(255, 255, 255, 0.5)", justifyContent: "center", alignItems: "center", marginBottom: SpacingH.s0, marginRight: SpacingH.s0, borderRadius: SpacingH.s3 / 2 }}>
                                                <MainMediumText style={styles.sendTimeImage}>{sentAtReadable}</MainMediumText>
                                            </View>}
                                        </ImageBackground>}
                                </MultiTap>
                                {
                                    showLike &&
                                    <ChatLikeIcon
                                        ref={likeRef}
                                        width={bubbleWidth / 2}
                                        height={bubbleHeight / 2}
                                        likes={isLiked}
                                        onAnimComplete={() => { setShowLike(false) }}
                                    />
                                }
                            </View>
                            <View style={styles.sendCornerOuter}>
                                <View style={[styles.sendCornerInner, { backgroundColor: isModalVisible ? "#e2f4f2" : Colors.WHITE }]}></View>
                            </View>
                            {renderModal()}
                        </Animated.View>
                    </View>

                );

            case MessageTypes.DOCUMENT:
                return (
                    <View style={{ backgroundColor: isModalVisible ? "#e2f4f2" : Colors.WHITE }}>
                        <Animated.View style={{ flexDirection: "row", justifyContent: "flex-end", marginTop: 20, marginBottom: 10, transform: [{ translateX: 0 }, { rotateY: rotateAnim }, { rotateX: rotateAnim }, { scale: scaleAnim }] }} >
                            <View style={[styles.sendDocumentBubbleContainer, { width: "74%" }]} onLayout={(e) => getBubbleDimensions(e)}>
                                <MultiTap onDoubleTap={onPress} onLongPress={deleteAnimation}>
                                    {
                                        showLike &&
                                        <ChatLikeIcon
                                            ref={likeRef}
                                            width={bubbleWidth / 2}
                                            height={bubbleHeight / 2}
                                            likes={isLiked}
                                            onAnimComplete={() => { setShowLike(false) }}
                                        />
                                    }
                                    <View style={[styles.documentInternalWrapper, { backgroundColor: Colors.WHITE_GREY }]}>
                                        {documentIcon(info.type)}
                                        <View style={{ flex: 1, marginLeft: SpacingW.s1 }}>
                                            <MainText numberOfLines={1} ellipsizeMode="tail" >
                                                {info.name}
                                            </MainText>
                                        </View>
                                        {typeof documentSource === "undefined" ?
                                            <ActivityIndicator animating={true} size="small" color={Colors.NAVY} /> :
                                            <TouchableOpacity onPress={handleDocumentDownload}>
                                                <EvilIcons name="arrow-down" size={30} color={Colors.NAVY} />
                                            </TouchableOpacity>
                                        }
                                    </View>
                                    <View style={{ flexDirection: "row", justifyContent: "space-between", marginHorizontal: SpacingW.s3 }}>
                                        <MainMediumText style={styles.sendTime}>{humanReadableSize(info.size)}</MainMediumText>
                                        <MainMediumText style={styles.sendTime}>{sentAtReadable}</MainMediumText>
                                    </View>
                                </MultiTap>
                            </View>
                            <View style={styles.sendCornerOuter}>
                                <View style={[styles.sendCornerInner, { backgroundColor: isModalVisible ? "#e2f4f2" : Colors.WHITE }]}></View>
                            </View>
                            {renderModal()}
                        </Animated.View>
                    </View>
                );
        }
    } else {
        // RECEIVE
        switch (type) {
            case MessageTypes.TEXT:
                return (
                    <MultiTap onDoubleTap={onPress} onLongPress={onLongPress}>
                        <View style={{ marginVertical: 10 }}>
                            <MainText style={styles.senderName}>{withUser.name}</MainText>
                            <View style={{ flexDirection: "row", alignSelf: "flex-start", }}>
                                <View style={styles.receiveCornerOuter}>
                                    <View style={styles.receiveCornerMiddle}>
                                        <View style={styles.receiveCornerInner}>
                                            <TouchableOpacity onPress={onUserPress}>
                                                <Image
                                                    source={{ uri: withUser.image_profile }}
                                                    style={{ width: 30, height: 30, marginRight: 15, borderColor: Colors.BLUE_GREY, }}
                                                    resizeMode="cover" />
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                </View>

                                <View style={[styles.receiveChatBubbleContainer, { width: children.length > 16 ? "74%" : "auto" }]} onLayout={(e) => getBubbleDimensions(e)} >
                                    {
                                        showLike &&
                                        <ChatLikeIcon
                                            ref={likeRef}
                                            isReceive
                                            width={-(bubbleWidth / 2)}
                                            height={bubbleHeight / 2}
                                            likes={isLiked}
                                            onAnimComplete={() => { setShowLike(false) }}
                                        />
                                    }
                                    <MainText>{children}</MainText>
                                    <MainMediumText style={styles.receiveTime}>{sentAtReadable}</MainMediumText>
                                </View>
                            </View>
                        </View>
                    </MultiTap>
                );

            case MessageTypes.VIDEO:
                return (
                    <TouchableOpacity style={styles.videoBubbleContainer} onPress={showVideoInFullscreen} onLongPress={onLongPress}>
                        <Ionicons name="play-circle-outline" size={24} color={Colors.NAVY}></Ionicons>
                        <MainMediumText style={{ ...styles.videoText, flexWrap: "wrap", flex: 1 }}>Tap to view a video message from {withUser.name}</MainMediumText>
                        {/* <Video
                            ref={video}
                            style={[styles.video]}
                            source={{ uri: videoSource }}
                            useNativeControls
                            resizeMode="contain"
                            onFullscreenUpdate={onFullscreenUpdate}
                            isLooping
                        /> */}
                    </TouchableOpacity>
                );

            case MessageTypes.AUDIO:
                audioDuration.current = info.duration;
                audioDurationInFormat.current = millisToMinutesAndSeconds(info.duration);
                return (
                    <View style={{ marginVertical: 10 }}>
                        <MainText style={styles.senderName}>{withUser.name}</MainText>
                        <View style={{ flexDirection: "row", alignSelf: "flex-start", width: "100%" }}>
                            <View style={styles.receiveCornerOuter}>
                                <View style={styles.receiveCornerMiddle}>
                                    <View style={styles.receiveCornerInner}>
                                        <TouchableOpacity onPress={onUserPress}>
                                            <Image
                                                source={{ uri: withUser.image_profile }}
                                                style={{ width: 30, height: 30, marginRight: 15, borderColor: Colors.BLUE_GREY, }}
                                                resizeMode="cover" />
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            </View>
                            <MultiTap onDoubleTap={onPress} style={styles.receiveAudioBubbleContainer} onLayout={(e) => getBubbleDimensions(e)}>
                                {
                                    showLike &&
                                    <ChatLikeIcon
                                        ref={likeRef}
                                        isReceive
                                        width={-(bubbleWidth / 2)}
                                        height={bubbleHeight / 2}
                                        likes={isLiked}
                                        onAnimComplete={() => { setShowLike(false) }}
                                    />
                                }
                                <View style={{ flexDirection: "row" }}>
                                    {typeof audioSource === "undefined" || !sound ?
                                        <ActivityIndicator animating={true} size="small" color={Colors.LIGHT_GREY} /> :
                                        <TouchableOpacity style={{ marginLeft: SpacingW.s1 }} onPress={isAudioPlaying ? pauseSound : playSound}>
                                            {!isAudioPlaying ?
                                                <Ionicons name="play" size={24} color={Colors.LIGHT_GREY}></Ionicons> :
                                                <Ionicons name="pause" size={24} color={Colors.LIGHT_GREY}></Ionicons>}
                                        </TouchableOpacity>}
                                    <View style={{ flex: 1, alignItems: 'stretch', justifyContent: 'center' }}>
                                        <Slider
                                            style={{ width: "100%", height: 25 }}
                                            minimumValue={0}
                                            maximumValue={1}
                                            minimumTrackTintColor={Colors.LIGHT_GREY}
                                            maximumTrackTintColor={Colors.LIGHT_GREY}
                                            thumbTintColor={Colors.LIGHT_GREY}
                                            value={sliderValue}
                                            disabled={typeof audioSource === "undefined" || !sound ? true : false}
                                            onValueChange={onSeekValueChange}
                                            onSlidingComplete={onSeekingComplete}
                                        />
                                    </View>
                                </View>
                                {audioErrorState ?
                                    <View style={{ marginLeft: SpacingW.s2 }}>
                                        <MainMediumText style={{ fontSize: FontSize.VERY_SMALL, color: Colors.HEART_RED }}>Something went wrong!</MainMediumText>
                                        <MainMediumText style={{ fontSize: FontSize.VERY_SMALL, marginBottom: SpacingH.s1, color: Colors.HEART_RED }}>Please ask the sender to resend the audio.</MainMediumText>
                                    </View> :
                                    <View style={styles.receiveAudioBottomWrapper} >
                                        <MainMediumText style={styles.receiveDuration}>{isAudioPlaying ? durationPlayed : audioDurationInFormat.current}</MainMediumText>
                                        <MainMediumText style={styles.receiveTime}>{sentAtReadable}</MainMediumText>
                                    </View>
                                }
                            </MultiTap>
                        </View>
                    </View>
                );

            case MessageTypes.IMAGE:
                return (
                    <View style={{ marginVertical: 10 }}>
                        <MainText style={styles.senderName}>{withUser.name}</MainText>
                        <View style={{ flexDirection: "row", alignSelf: "flex-start" }}>
                            <View style={styles.receiveCornerOuter}>
                                <View style={styles.receiveCornerMiddle}>
                                    <View style={styles.receiveCornerInner}>
                                        <TouchableOpacity onPress={onUserPress}>
                                            <Image
                                                source={{ uri: withUser.image_profile }}
                                                style={{ width: 30, height: 30, marginRight: 15, borderColor: Colors.BLUE_GREY, }}
                                                resizeMode="cover" />
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            </View>

                            <View style={styles.receiveImageBubbleContainer} onLayout={(e) => getBubbleDimensions(e)}>
                                <MultiTap onSingleTap={() => { onPressImage(imageSource, imageSize) }} onDoubleTap={onPress} >
                                    {!hasReceived &&
                                        <View style={{
                                            width: getImageWidth(),
                                            height: getImageHeight(),
                                            borderRadius: 10,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            backgroundColor: Colors.WHITE,
                                        }} >
                                            <ActivityIndicator animating={true} size="small" color={Colors.SOFT_GREY} />
                                        </View>}
                                    {typeof imageSource !== "undefined" &&
                                        <ImageBackground source={{ uri: imageSource }}
                                            style={{
                                                width: hasReceived ? getImageWidth() : 1,
                                                height: hasReceived ? getImageHeight() : 1,
                                                justifyContent: "flex-end",
                                                alignItems: "flex-end",
                                            }}
                                            imageStyle={{ borderRadius: 10 }}
                                            onLoad={(e) => { setHasReceived(() => true); }}
                                            resizeMode="cover">
                                            {hasReceived && <View style={{ height: SpacingH.s3, paddingHorizontal: SpacingH.s1, backgroundColor: "rgba(255, 255, 255, 0.5)", justifyContent: "center", alignItems: "center", marginBottom: SpacingH.s0, marginRight: SpacingH.s0, borderRadius: SpacingH.s3 / 2 }}>
                                                <MainMediumText style={styles.sendTimeImage}>{sentAtReadable}</MainMediumText>
                                            </View>}
                                        </ImageBackground>}
                                </MultiTap>
                                {
                                    showLike &&
                                    <ChatLikeIcon
                                        ref={likeRef}
                                        isReceive
                                        width={-(bubbleWidth / 2)}
                                        height={bubbleHeight / 2}
                                        likes={isLiked}
                                        onAnimComplete={() => { setShowLike(false) }}
                                    />
                                }
                            </View>
                        </View>
                    </View>
                );

            case MessageTypes.DOCUMENT:
                return (
                    <View style={{ marginVertical: 10 }}>
                        <MainText style={styles.senderName}>{withUser.name}</MainText>
                        <View style={{ flexDirection: "row", alignSelf: "flex-start" }}>
                            <View style={styles.receiveCornerOuter}>
                                <View style={styles.receiveCornerMiddle}>
                                    <View style={styles.receiveCornerInner}>
                                        <TouchableOpacity onPress={onUserPress}>
                                            <Image
                                                source={{ uri: withUser.image_profile }}
                                                style={{ width: 30, height: 30, marginRight: 15, borderColor: Colors.BLUE_GREY, }}
                                                resizeMode="cover" />
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            </View>

                            <View style={[styles.receiveDocumentBubbleContainer, { width: "74%" }]} onLayout={(e) => getBubbleDimensions(e)}>
                                {
                                    showLike &&
                                    <ChatLikeIcon
                                        ref={likeRef}
                                        isReceive
                                        width={-(bubbleWidth / 2)}
                                        height={bubbleHeight / 2}
                                        likes={isLiked}
                                        onAnimComplete={() => { setShowLike(false) }}
                                    />
                                }
                                <View style={styles.documentInternalWrapper}>
                                    {documentIcon(info.type)}
                                    <View style={{ flex: 1, marginLeft: SpacingW.s1 }}>
                                        <MainText numberOfLines={1} ellipsizeMode="tail" >
                                            {info.name}
                                        </MainText>
                                    </View>
                                    {typeof documentSource === "undefined" ?
                                        <ActivityIndicator animating={true} size="small" color={Colors.NAVY} /> :
                                        <TouchableOpacity onPress={handleDocumentDownload}>
                                            <EvilIcons name="arrow-down" size={30} color={Colors.NAVY} />
                                        </TouchableOpacity>
                                    }
                                </View>
                                <View style={{ flexDirection: "row", justifyContent: "space-between", marginHorizontal: SpacingW.s3 }}>
                                    <MainMediumText style={styles.receiveTime}>{sentAtReadable}</MainMediumText>
                                    <MainMediumText style={styles.receiveTime}>{humanReadableSize(info.size)}</MainMediumText>
                                </View>
                            </View>
                        </View>
                    </View>
                );
        }
    }
};

const styles = StyleSheet.create({
    senderName: {
        marginLeft: 52,
        marginBottom: 5,
        fontSize: FontSize.SMALLER,
        color: Colors.BLUE_GREY
    },
    receiveChatBubbleContainer: {
        backgroundColor: Colors.SOFT_GREY,
        paddingHorizontal: 15,
        paddingTop: 15,
        borderRadius: 12,
        borderBottomLeftRadius: 0
    },
    receiveLikeIconContainer: {
        backgroundColor: Colors.SOFT_GREY,
        width: 30,
        height: 30,
        position: "absolute",
        right: -15,
        top: -15,
        borderRadius: 15,
        borderWidth: 3,
        borderColor: Colors.WHITE,
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 2,
        zIndex: 2

    },
    receiveCornerOuter: {
        alignSelf: "flex-end",
        flexDirection: "row",
        backgroundColor: Colors.SOFT_GREY,
        width: 40,
        height: 40,
    },
    receiveCornerMiddle: {
        alignSelf: "flex-start",
        justifyContent: "flex-end",
        width: 40,
        height: 40,
        borderBottomRightRadius: 20,
        backgroundColor: Colors.WHITE,
    },
    receiveCornerInner: {
        alignSelf: "flex-start",
        width: 30,
        height: 30,
        borderRadius: 15,
        overflow: "hidden"
    },
    receiveTime: {
        color: Colors.LIGHT_GREY,
        fontSize: FontSize.VERY_SMALL,
        marginVertical: SpacingH.s0,
    },
    receiveDuration: {
        color: Colors.LIGHT_GREY,
        fontSize: FontSize.VERY_SMALL,
        marginVertical: SpacingH.s0,
        marginLeft: Size.w10 + SpacingW.s2
    },
    sendChatBubbleContainer: {
        backgroundColor: Colors.LIGHT_TEAL,
        paddingHorizontal: 15,
        paddingTop: 15,
        borderRadius: 12,
        borderBottomRightRadius: 0,
    },
    sendCornerOuter: {
        alignSelf: "flex-end",
        backgroundColor: Colors.LIGHT_TEAL,
        width: 20,
        height: 20
    },
    sendCornerInner: {
        width: 20,
        height: 20,
        borderBottomLeftRadius: 20,
        backgroundColor: Colors.WHITE,
    },
    sendLikeIconContainer: {
        backgroundColor: Colors.SOFT_GREY,
        width: 30,
        height: 30,
        position: "absolute",
        left: -15,
        top: -15,
        borderRadius: 15,
        borderWidth: 3,
        borderColor: Colors.WHITE,
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 2,
        zIndex: 2,
    },
    sendTime: {
        color: Colors.NAVY,
        fontSize: FontSize.VERY_SMALL,
        alignSelf: "flex-end",
        marginVertical: SpacingH.s0,

    },
    sendTimeWrapper: {
        height: SpacingH.s3,
        paddingHorizontal: SpacingH.s1,
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: SpacingH.s0,
        marginRight: SpacingH.s0,
        borderRadius: SpacingH.s3 / 2
    },
    sendTimeImage: {
        color: Colors.NAVY,
        fontSize: FontSize.VERY_SMALL,
        marginVertical: SpacingH.s0,
    },
    sendDuration: {
        color: Colors.NAVY,
        fontSize: FontSize.VERY_SMALL,
        marginVertical: SpacingH.s0,
        marginLeft: Size.w10 + SpacingW.s2
    },
    videoBubbleContainer: {
        flexDirection: "row",
        backgroundColor: Colors.SOFT_GREY,
        borderRadius: 24,
        paddingVertical: SpacingH.s1,
        marginVertical: SpacingH.s1,
        alignItems: "center",
        marginHorizontal: SpacingW.s2,
        paddingHorizontal: SpacingW.s3,
    },
    videoText: {
        color: Colors.NAVY,
        fontSize: FontSize.SMALLER,
        marginLeft: SpacingW.s1
    },
    receiveImageBubbleContainer: {
        backgroundColor: Colors.SOFT_GREY,
        paddingHorizontal: 4,
        paddingVertical: 4,
        borderRadius: 12,
        borderBottomLeftRadius: 0,
    },
    sendImageBubbleContainer: {
        backgroundColor: Colors.LIGHT_TEAL,
        paddingHorizontal: 4,
        paddingVertical: 4,
        borderRadius: 12,
        borderBottomRightRadius: 0,
    },
    sendAudioBubbleContainer: {
        width: "74%",
        backgroundColor: Colors.LIGHT_TEAL,
        paddingHorizontal: 15,
        paddingTop: 15,
        borderRadius: 12,
        borderBottomRightRadius: 0,
    },
    receiveAudioBubbleContainer: {
        width: "74%",
        backgroundColor: Colors.SOFT_GREY,
        paddingHorizontal: 15,
        paddingTop: 15,
        borderRadius: 12,
        borderBottomLeftRadius: 0
    },
    receiveAudioBottomWrapper: {
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        paddingRight: SpacingW.s4,
    },
    receiveDocumentBubbleContainer: {
        backgroundColor: Colors.SOFT_GREY,
        paddingHorizontal: SpacingW.s1,
        paddingTop: SpacingW.s1,
        borderRadius: 12,
        borderBottomLeftRadius: 0
    },
    documentInternalWrapper: {
        flexDirection: "row",
        alignItems: "center",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        height: Size.h5,
        padding: SpacingW.s1,
        backgroundColor: Colors.WHITE_GREY,
    },
    sendDocumentBubbleContainer: {
        backgroundColor: Colors.LIGHT_TEAL,
        paddingHorizontal: SpacingW.s1,
        paddingTop: SpacingW.s1,
        borderRadius: 12,
        borderBottomRightRadius: 0,
    },
    textColor: {
        color: Colors.NAVY,
    }
});
