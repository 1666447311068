/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiThunkHandler } from "./utils";

const Stage = {
    START: "start",
    CANCELLATION_REASON: "cancellation-reason",
    ADDITIONAL_COMMENTS: "additional-comments",
};

const Status = {
    COMPLETED: "completed",
    CANCELLED: "cancelled",
};

const CoachCancellationReasons = [
    {
        id: "1",
        label: "On client request",
        value: "coach:client-request"
    }, {
        id: "2",
        label: "Client did not attend",
        value: "coach:client-did-not-attend"
    }, {
        id: "3",
        label: "Personal",
        value: "coach:personal"
    }, {
        id: "4",
        label: "Technical issue",
        value: "coach:technical"
    }, {
        id: "5",
        label: "Any other reason",
        value: "coach:other"
    }
];

const ClientCancellationReasons = [
    {
        id: "1",
        label: "On coach request",
        value: "client:coach-request"
    }, {
        id: "2",
        label: "Coach did not attend",
        value: "client:coach-did-not-attend"
    }, {
        id: "3",
        label: "Personal",
        value: "client:personal"
    }, {
        id: "4",
        label: "Technical issue",
        value: "client:technical"
    }, {
        id: "5",
        label: "Any other reason",
        value: "client:other"
    }
];

const getJourneyData = createAsyncThunk(
    "coachingSessions/getJourneyData",
    async (_, thunkAPI) => apiThunkHandler(axios.get("/coaching-sessions/journey"), thunkAPI)
);

const getUpcomingSessions = createAsyncThunk(
    "coachingSessions/getUpcomingSessions",
    async ({ page }, thunkAPI) => apiThunkHandler(axios.get("/coaching-sessions/upcoming?page=" + page), thunkAPI)
);

const refreshUpcomingSessions = createAsyncThunk(
    "coachingSessions/refreshUpcomingSessions",
    async (_, thunkAPI) => apiThunkHandler(axios.get("/coaching-sessions/upcoming"), thunkAPI)
);

const getPastSessions = createAsyncThunk(
    "coachingSessions/getPastSessions",
    async ({ page }, thunkAPI) => apiThunkHandler(axios.get("/coaching-sessions/past?page=" + page), thunkAPI)
);

const refreshPastSessions = createAsyncThunk(
    "coachingSessions/refreshPastSessions",
    async (_, thunkAPI) => apiThunkHandler(axios.get("/coaching-sessions/past"), thunkAPI)
);

const getCompletedSessions = createAsyncThunk(
    "coachingSessions/getCompletedSessions",
    async ({ page }, thunkAPI) => apiThunkHandler(axios.get("/coaching-sessions/completed?page=" + page), thunkAPI)
);

const refreshCompletedSessions = createAsyncThunk(
    "coachingSessions/refreshCompletedSessions",
    async (_, thunkAPI) => apiThunkHandler(axios.get("/coaching-sessions/completed"), thunkAPI)
);

const coachSessionUpdate = createAsyncThunk(
    "coachingSessions/coachSessionUpdate",
    async ({ session, status, cancellation_reason, coach_rating, coach_comments }, thunkAPI) => apiThunkHandler(
        axios.put(
            "/coaching-sessions/" + session.uuid + "/coach",
            {
                status,
                cancellation_reason,
                coach_rating,
                coach_comments,
            }
        ),
        thunkAPI
    )
);

const clientSessionUpdate = createAsyncThunk(
    "coachingSessions/clientSessionUpdate",
    async ({ session, status, cancellation_reason, client_rating, client_comments }, thunkAPI) => apiThunkHandler(
        axios.put(
            "/coaching-sessions/" + session.uuid + "/client",
            {
                status,
                cancellation_reason,
                client_rating,
                client_comments,
            }
        ),
        thunkAPI
    )
);

const createInstantSession = createAsyncThunk(
    "coachingSessions/createInstantSession",
    async ({ user }, thunkAPI) => apiThunkHandler(axios.post("/me/instant-session/" + user.uuid), thunkAPI)
);

export default {
    createInstantSession,
    getJourneyData,

    getUpcomingSessions,
    refreshUpcomingSessions,

    getPastSessions,
    refreshPastSessions,

    getCompletedSessions,
    refreshCompletedSessions,

    coachSessionUpdate,
    clientSessionUpdate,

    Stage,
    Status,
    CoachCancellationReasons,
    ClientCancellationReasons,
};
