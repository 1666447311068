/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { FlatList, ScrollView, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { GlobalStyles } from "../../../themes/styles";
import { FontSize, Size, SpacingH, SpacingW, wp } from "../../../themes/sizes";
import { Colors } from "../../../themes/colors";

import MainMediumText from "../../../components/text/MainMediumText";
import ResourceImageCard from "../../../components/cards/resources/ResourceImageCard";
import ResourceCard from "../../../components/cards/resources/ResourceCard";
import PlaceHolder from "../../../components/utils/PlaceHolder";

import { Resources } from "../../../api";
import { markAsFavorite, unmarkAsFavorite } from "../../../state/slices/resourceSlice";

export default ({ navigation }) => {
    const dispatch = useDispatch();

    const resource = useSelector(state => state.resource);
    const categories = useSelector(state => state.resource.categories);
    const resources = useSelector(state => state.resource.resources);

    React.useEffect(() => {
        dispatch(Resources.refreshResources());
    }, []);

    const loadMore = (category) => {
        if (resource.loading) return;
        resource.categoryPage[category] <= resource.categoryMaxPage[category] && dispatch(Resources.getResources({ category, page: resource.categoryPage[category] }));
    };

    const handleFavoritePress = (item) => {
        if (item.isFavorite) {
            dispatch(unmarkAsFavorite({ uuid: item.uuid }))
            dispatch(Resources.unmarkAsFavorite({ uuid: item.uuid }))
        } else {
            dispatch(markAsFavorite({ uuid: item.uuid }))
            dispatch(Resources.markAsFavorite({ uuid: item.uuid }))
        }
    };

    return (
        <View style={{ flex: 1, backgroundColor: Colors.WHITE }}>
            <ScrollView style={{ paddingVertical: SpacingH.s2 }}>
                {!resources.length &&
                    <PlaceHolder style={GlobalStyles.containerMarginH} status={resource.loading} type={"regularCard"} />
                }

                {
                    categories.filter(c => c.is_featured).map(category => (
                        (resources.filter(r => r.category.name == category.name).length > 0) &&
                        <View key={category.name}>
                            <View style={[GlobalStyles.containerPaddingH, { flexDirection: "row", justifyContent: "space-between", alignItems: "baseline" }]}>
                                <MainMediumText style={{ fontSize: FontSize.NORMAL }}>{category.name}</MainMediumText>
                                <MainMediumText style={{ fontSize: FontSize.SMALL, color: Colors.NAVY }}>{category.count} Resources</MainMediumText>
                            </View>
                            <View style={[GlobalStyles.containerMarginH, { paddingVertical: SpacingH.s1, marginBottom: SpacingH.s2 }]}>
                                <FlatList
                                    ListFooterComponent={<View style={{ width: SpacingW.s5 }} />}
                                    horizontal={true}
                                    style={{ paddingBottom: SpacingH.s3, paddingLeft: GlobalStyles.containerPaddingH.paddingHorizontal }}
                                    data={resources.filter(r => r.category.name === category.name)}
                                    onRefresh={() => dispatch(Resources.refreshResources())}
                                    refreshing={resource.categoryRefreshing[category.name] || false}
                                    onEndReached={() => { loadMore(category.name) }}
                                    renderItem={({ item }) =>
                                        category.type === "card" ?
                                            <ResourceCard
                                                style={{ width: Size.w80 }}
                                                onPressFavorite={() => { handleFavoritePress(item) }}
                                                onPress={() => navigation.navigate("WebView", { url: item.link, title: item.title })}
                                                {...item} />
                                            :
                                            <ResourceImageCard
                                                onPressFavorite={() => { handleFavoritePress(item) }}
                                                onPress={() => navigation.navigate("WebView", { url: item.link, title: item.title })}
                                                {...item} />
                                    }
                                    keyExtractor={item => item.uuid}
                                />
                            </View>
                        </View>
                    ))
                }

                {
                    categories.filter(c => !c.is_featured).map(category => (
                        (resources.filter(r => r.category.name == category.name).length > 0) &&
                        <View key={category.name}>
                            <View style={[GlobalStyles.containerPaddingH, { flexDirection: "row", justifyContent: "space-between", alignItems: "baseline" }]}>
                                <MainMediumText style={{ fontSize: FontSize.NORMAL }}>{category.name}</MainMediumText>
                                <MainMediumText style={{ fontSize: FontSize.SMALL, color: Colors.NAVY }}>{category.count} Resources</MainMediumText>
                            </View>
                            <View style={[GlobalStyles.containerMarginH, { paddingVertical: SpacingH.s1, marginBottom: SpacingH.s2 }]}>
                                <FlatList
                                    horizontal={true}
                                    style={{ paddingBottom: SpacingH.s3, paddingHorizontal: GlobalStyles.containerPaddingH.paddingHorizontal }}
                                    data={resources.filter(r => r.category.name == category.name)}
                                    onRefresh={() => dispatch(Resources.refreshResources())}
                                    refreshing={resource.categoryRefreshing[category.name] || false}
                                    onEndReached={() => { loadMore(category.name) }}
                                    renderItem={({ item }) =>
                                        category.type === "image-card" ?
                                            <ResourceImageCard
                                                onPressFavorite={() => { handleFavoritePress(item) }}
                                                onPress={() => navigation.navigate("WebView", { url: item.link, title: item.title })}
                                                {...item} />
                                            :
                                            <ResourceCard
                                                style={{ width: Size.w80 }}
                                                onPressFavorite={() => { handleFavoritePress(item) }}
                                                onPress={() => navigation.navigate("WebView", { url: item.link, title: item.title })}
                                                {...item} />
                                    }
                                    keyExtractor={item => item.uuid}
                                />
                            </View>
                        </View>
                    ))
                }
            </ScrollView>
        </View>
    );
}
