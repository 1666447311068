/**
 * LifeRamp
 *
 * @author      Waris Anwar
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from 'react';
import { View, FlatList, TouchableOpacity } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { EvilIcons } from '@expo/vector-icons';

import { GlobalStyles } from '../../themes/styles';
import { FontSize, SpacingH } from "../../themes/sizes";
import CircleCard from '../../components/cards/CircleCard';
import SearchInput from "../../components/input/SearchInput";
import ContainerView from "../../components/main/ContainerView";
import HeroSemiText from '../../components/text/HeroSemiText';
import PlaceHolder from '../../components/utils/PlaceHolder';

import { Circle } from "../../api";
import { setCircle } from '../../state/slices/circleSlice';
import { setIsSpinning } from '../../state/slices/spinnerSlice';
import { Colors } from '../../themes/colors';

export default ({ navigation }) => {
    const dispatch = useDispatch();

    const state = useSelector(state => state.circle);
    const circles = useSelector(state => state.circle.circles);
    const [search, setSearch] = React.useState("");

    React.useEffect(() => {
        dispatch(Circle.refreshCircles({ search }));
    }, [search]);

    const viewCircle = (circle) => {
        dispatch(setIsSpinning(true));
        dispatch(setCircle({ circle }));
        navigation.navigate("CircleStackScreen");
        dispatch(setIsSpinning(false));
    }

    const loadMore = () => {
        if (state.loading) return;
        state.circlePage <= state.circleMaxPage && dispatch(Circle.getCircles({ page: state.circlePage, search }));
    };

    return (
        <ContainerView >
            <View style={GlobalStyles.containerPaddingH}>
                <TouchableOpacity onPress={() => { navigation.goBack(); }} style={{ position: "absolute", top: 7, left: 10, zIndex: 10 }}>
                    <EvilIcons name="chevron-left" size={42} color={Colors.BLUE_GREY} />
                </TouchableOpacity>
                <View style={{ marginBottom: SpacingH.s1 }}>
                    <HeroSemiText style={{ fontSize: FontSize.BIG, textAlign: "center" }}>Circles</HeroSemiText>
                </View>
                <View style={{ marginTop: SpacingH.s0 }}>
                    <SearchInput
                        placeholder={"Search circles"}
                        value={search}
                        onChangeText={s => setSearch(s)}
                        isShaded
                    />
                </View>
            </View>
            <FlatList
                style={GlobalStyles.containerPaddingH}
                data={circles}
                onRefresh={() => dispatch(Circle.refreshCircles({ search }))}
                refreshing={state.circlesRefreshing || state.loading}
                onEndReached={loadMore}
                renderItem={({ item }) => <CircleCard {...item} onPress={() => { viewCircle(item) }} />}
                ListEmptyComponent={<PlaceHolder status={(state.circlesRefreshing || state.loading)} type={"regularCard"} />}
                keyExtractor={item => item.uuid}
            />
        </ContainerView>
    )
}
