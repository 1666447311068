/**
 * LifeRamp
 *
 * @author      Ummar Hamid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { FlatList, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { GlobalStyles } from "../../../themes/styles";
import { SpacingH } from "../../../themes/sizes";
import { Colors } from "../../../themes/colors";
import PlaceHolder from "../../../components/utils/PlaceHolder";
import SessionCard from "../../../components/cards/SessionCard";

import { CoachingSessions } from "../../../api";

export default ({ navigation, route }) => {
    const dispatch = useDispatch();

    const state = useSelector(state => state.coachingSession);
    const pastSessions = useSelector(state => state.coachingSession.pastSessions);

    const client = route.params?.client || false;

    React.useEffect(() => {
        dispatch(CoachingSessions.refreshPastSessions());
    }, []);

    const loadMore = () => {
        if (state.loading) return;
        state.pastPage <= state.pastMaxPage && dispatch(CoachingSessions.getPastSessions({ page: state.pastPage }));
    };

    let filteredPastSessions = client !== false ? pastSessions.filter(u => u.user.uuid === client.uuid) : pastSessions;
    filteredPastSessions = filteredPastSessions.filter(s => s.status !== "rescheduled");

    return (
        <View style={{ flex: 1, backgroundColor: Colors.WHITE, paddingVertical: SpacingH.s2 }}>
            <FlatList
                data={filteredPastSessions}
                onRefresh={() => dispatch(CoachingSessions.refreshPastSessions())}
                refreshing={(state.pastRefreshing || state.loading) && !state.autoLoading}
                onEndReached={loadMore}
                renderItem={({ item }) => <SessionCard {...item} style={GlobalStyles.containerMarginH} />}
                ListEmptyComponent={<PlaceHolder style={GlobalStyles.containerMarginH} status={(state.pastRefreshing || state.loading)} repeat={4} type={"regularCard"} />}
                keyExtractor={item => item.uuid}
            />
        </View>
    );
};
