/**
 * LifeRamp
 *
 * @author      Ummar Hamid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { Platform, TouchableOpacity, View, Image } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import * as ImagePicker from "expo-image-picker";

import { FontSize, SpacingH } from "../../themes/sizes";
import { Colors } from "../../themes/colors";
import { AntDesign } from "@expo/vector-icons";
import randomString from "@afaanbilal/random-string";

import mime from "mime";

import firebase from "firebase/app";
import "firebase/storage";

import NonSafeContainerView from "../../components/main/NonSafeContainerView";

import HeroText from "../../components/text/HeroText";
import MainMediumText from "../../components/text/MainMediumText";
import ProgressBar from "../../components/bars/ProgressBar";

import CircleButton from "../../components/buttons/CircleButton";
import LinkButton from "../../components/buttons/LinkButton";
import PrimaryButton from "../../components/buttons/PrimaryButton";

import { User } from "../../api";
import { setIsSpinning } from "../../state/slices/spinnerSlice";
import MainText from "../../components/text/MainText";

export default ({ navigation }) => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.user.loading);
    const userProfile = useSelector(state => state.user.profile);
    const [userImageError, setUserImageError] = React.useState(false);
    const [isImagePicked, setIsImagePicked] = React.useState(false);
    const [profile, setProfile] = React.useState({ ...userProfile, image_profile: userProfile.image_profile, });

    const pickImage = async () => {
        setUserImageError(false);

        const gotPermission = await (async () => {
            if (Platform.OS !== "web") {
                const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
                if (status !== "granted") {
                    alert("The camera roll permission is needed to add an image!");
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        })();
        if (!gotPermission) {
            return;
        }

        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            quality: 0.8,
            aspect: [1, 1],
        });

        if (!result.cancelled) {
            setProfile({ ...profile, image_profile: result.uri });
            setIsImagePicked(true);
        };
    };

    const saveProfile = async () => {
        if (loading) return;
        if (profile.image_profile && profile.image_profile.startsWith("file")) {
            dispatch(setIsSpinning(true));

            const uri = profile.image_profile;
            const mimeType = mime.getType(uri);
            const ref = firebase.storage().ref("user-images/" + userProfile.uuid + "/profile." + mime.getExtension(mimeType));
            const response = await fetch(uri);
            const blob = await response.blob();

            if (blob.size <= (5 * 1024 * 1024)) {
                await ref.put(blob, { contentType: mimeType });
                const url = await ref.getDownloadURL();
                setProfile({ ...profile, image_profile: url });
                dispatch(User.updateProfile({ profile: { ...profile, image_profile: url } })).then(() => { navigation.navigate("LuckyYou"); });
                dispatch(setIsSpinning(false));
            } else {
                dispatch(setIsSpinning(false));
                setUserImageError(true);
            }
        }
    };

    return (
        <NonSafeContainerView style={{ paddingBottom: SpacingH.s4 }}>
            <ProgressBar
                currentProgress={1}
                totalProgress={1}
                animDuration={350}
            ></ProgressBar>
            <View style={{ marginHorizontal: SpacingH.s3, marginVertical: SpacingH.s6 }}>
                <HeroText style={{ fontSize: FontSize.BIG, color: Colors.WHITE }}>Add a selfie so your coach can put a face to the name.</HeroText>
            </View>

            <View style={{ alignItems: "center", marginVertical: SpacingH.s0 }}>
                <MainMediumText style={{ color: Colors.HEART_RED, fontSize: FontSize.SMALL }}>{userImageError ? "*Please choose an image less than 5MB" : ""}</MainMediumText>

                {isImagePicked ?
                    <>
                        <TouchableOpacity activeOpacity={0.8} onPress={pickImage}>
                            <Image
                                style={{
                                    width: 150,
                                    height: 150,
                                    borderRadius: 75
                                }}
                                source={{ uri: profile.image_profile }}
                                resizeMode="cover"
                            />
                            <MainText style={{ color: Colors.WHITE, textAlign: "center", textDecorationLine: "underline", marginTop: SpacingH.s0 }}>Change</MainText>
                        </TouchableOpacity>
                        <PrimaryButton buttonSize={"sm"} style={{ marginTop: SpacingH.s5 }} onPress={saveProfile}>Let's go!</PrimaryButton>
                    </>
                    :
                    <>
                        <HeroText style={{ textAlign: "center", marginBottom: SpacingH.s2, fontSize: FontSize.NORMED, color: Colors.WHITE }}>Upload a pic.</HeroText>
                        <CircleButton onPress={pickImage}>
                            <AntDesign name="plus" size={46} color={Colors.NAVY} />
                        </CircleButton>
                    </>
                }

                <LinkButton onPress={() => navigation.navigate("LuckyYou")} textStyle={{ color: Colors.WHITE, fontSize: FontSize.NORMAL, marginTop: SpacingH.s3 }}>No thanks. Maybe later.</LinkButton>
            </View>
        </NonSafeContainerView>
    );
};
