/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

export const apiThunkHandler = async (asyncFn, { rejectWithValue }) => {
    try {
        return (await asyncFn).data;
    } catch (err) {
        console.log("ApiThunkHandlerError: ", err, err.response.data);
        return rejectWithValue(err.response.data);
    }
};
