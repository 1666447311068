/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, TextInput } from "react-native";

import { Colors } from "../../themes/colors";
import Fonts from "../../themes/fonts";
import { FontSize } from "../../themes/sizes";

export default (props) => (
    <TextInput
        maxLength={5000}
        {...props}
        style={[styles.lineInput, props.style]}
        placeholderTextColor={Colors.WHITE} />
);

const styles = StyleSheet.create({
    lineInput: {
        height: 40,
        color: Colors.WHITE,
        fontSize: FontSize.NORMAL,
        borderBottomColor: Colors.WHITE,
        borderBottomWidth: 1,
        fontFamily: Fonts.Main,
    },
});
