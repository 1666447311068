/**
 * LifeRamp
 *
 * @author      Chetanya Gupta
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { Keyboard, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View } from "react-native";
import { useDispatch } from "react-redux";
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons } from '@expo/vector-icons';
import RadioGroup from 'react-native-radio-buttons-group';
import Modal from '@m4ttheweric/modal-enhanced-react-native-web';
import datetime from "date-and-time";

import { Colors, Gradients } from "../../themes/colors";
import { FontSize, hp, SpacingH } from "../../themes/sizes";
import BoxInput from "../../components/input/BoxInput"
import MainMediumText from "../text/MainMediumText";
import MainText from "../text/MainText";
import Rating from "./Rating";

import { CoachingSessions, User } from "../../api";
import { setIsSpinning } from "../../state/slices/spinnerSlice";

export default ({ isModalVisible, setModalVisible, session, nextSession }) => {
    const dispatch = useDispatch();

    const [stage, setStage] = React.useState(CoachingSessions.Stage.START);

    const [status, setStatus] = React.useState(null);
    const [rating, setRating] = React.useState(null);
    const [selectedOption, setSelectedOption] = React.useState(null);
    const [comments, setComments] = React.useState(null);

    const onPressRadioButton = (radioButtonsArray) => {
        setSelectedOption(radioButtonsArray.filter(r => r.selected === true));
    };

    const handleNext = () => {
        if (!rating) {
            alert("Please choose a rating");
        }

        setStatus(CoachingSessions.Status.COMPLETED);
        setStage(CoachingSessions.Stage.ADDITIONAL_COMMENTS);
    };

    const handleSkip = () => {
        dispatch(setIsSpinning(true));
        dispatch(CoachingSessions.clientSessionUpdate({ session, status: CoachingSessions.Status.COMPLETED, cancellation_reason: null, client_rating: null, client_comments: null }))
            .then(() => {
                dispatch(User.getMyProfile());
                setStage(CoachingSessions.Stage.START);
                setStatus(null);
                setSelectedOption(null);
                setComments(null);
                nextSession();
                dispatch(setIsSpinning(false));
            });
    };

    const handleCancel = () => {
        setStatus(CoachingSessions.Status.CANCELLED);
        setStage(CoachingSessions.Stage.CANCELLATION_REASON);
    };

    const handleBackOnCancel = () => {
        setStage(CoachingSessions.Stage.START);
    };

    const handleNextOnCancel = () => {
        setStage(CoachingSessions.Stage.ADDITIONAL_COMMENTS);
    };

    const handleBackOnComments = () => {
        setStage(status == CoachingSessions.Status.COMPLETED ? CoachingSessions.Stage.START : CoachingSessions.Stage.CANCELLATION_REASON);
    };

    const handleDoneOnComments = () => {
        dispatch(setIsSpinning(true));
        dispatch(CoachingSessions.clientSessionUpdate({ session, status, cancellation_reason: selectedOption && selectedOption.length ? selectedOption[0].value : null, client_rating: rating, client_comments: comments }))
            .then(() => {
                dispatch(User.getMyProfile());
                setStage(CoachingSessions.Stage.START);
                setStatus(null);
                setSelectedOption(null);
                setComments(null);
                nextSession();
                dispatch(setIsSpinning(false));
            });
    };

    return (
        <Modal
            style={{ width: "85%", alignSelf: "center" }}
            isVisible={isModalVisible}
            coverScreen={true}
            backdropColor="black"
            backdropOpacity={0.8}
            onBackButtonPress={() => setModalVisible(false)}
            animationOut="fadeOutDown"
            backdropTransitionOutTiming={0}
        >
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                <View style={styles.modalWrapper}>
                    <View style={styles.modalTop}>
                        {stage === CoachingSessions.Stage.START && <MainText style={{ color: Colors.WHITE }}>Rate Your Session</MainText>}
                        {stage === CoachingSessions.Stage.CANCELLATION_REASON && <MainText style={{ color: Colors.WHITE }}>Cancellation Reason</MainText>}
                        {stage === CoachingSessions.Stage.ADDITIONAL_COMMENTS && <MainText style={{ color: Colors.WHITE }}>Additional Comments</MainText>}
                    </View>
                    <View style={styles.modalMid}>
                        {stage === CoachingSessions.Stage.START &&
                            <>
                                <View style={{ backgroundColor: Colors.SOFT_GREY }}>
                                    <View style={{ alignItems: "center", paddingBottom: SpacingH.s3 }}>
                                        <MainText style={{ paddingTop: SpacingH.s2 }}>{session.name} with</MainText>
                                        <MainMediumText style={{ fontSize: FontSize.MEDIUM }}>{session.coach.name}</MainMediumText>
                                        <Rating style={{ marginTop: SpacingH.s2 }} onStarRatingPress={setRating} />
                                    </View>
                                    <View style={{ flexDirection: "row", justifyContent: "space-between", paddingHorizontal: SpacingH.s2, paddingVertical: SpacingH.s1, backgroundColor: Colors.LIGHT_GREY }}>
                                        <MainText style={{ color: Colors.WHITE }}>{datetime.format(new Date(session.start_at), "ddd, MMM D")}</MainText>
                                        <MainText style={{ color: Colors.WHITE }}>{datetime.format(new Date(session.start_at), "h:mm A")}</MainText>
                                    </View>
                                </View>
                                <TouchableOpacity>
                                    <MainText style={{ fontSize: FontSize.SMALL, textDecorationLine: 'underline', textAlign: "right", marginTop: SpacingH.s1 }}
                                        onPress={handleCancel}>
                                        Couldn't connect with the coach?
                                    </MainText>
                                </TouchableOpacity>
                            </>
                        }

                        {stage === CoachingSessions.Stage.CANCELLATION_REASON &&
                            <View style={{ alignItems: "flex-start", }}>
                                <RadioGroup radioButtons={CoachingSessions.ClientCancellationReasons} onPress={onPressRadioButton} />
                            </View>
                        }

                        {stage === CoachingSessions.Stage.ADDITIONAL_COMMENTS &&
                            <View style={{ paddingHorizontal: SpacingH.s1, alignSelf: "center" }}>
                                <BoxInput
                                    onChangeText={(value) => setComments(value)}
                                    placeholder={"Write your comments here"}
                                    multiline={true}
                                    numberOfLines={10}
                                    style={{ minHeight: 150, textAlignVertical: "top", paddingVertical: SpacingH.s1, width: hp("35%"), backgroundColor: Colors.SOFT_GREY }}
                                />
                            </View>
                        }
                    </View>
                    {stage === CoachingSessions.Stage.START &&
                        <View style={styles.modalBottom}>
                            <TouchableOpacity onPress={handleNext} style={{ flex: 2 }}>
                                <View style={{ borderBottomLeftRadius: 20, overflow: "hidden" }}>
                                    <LinearGradient
                                        style={{ paddingHorizontal: SpacingH.s3, paddingVertical: SpacingH.s2 }}
                                        start={[0, 1]}
                                        end={[1, 0]}
                                        colors={[Gradients.GREEN_BLUE.Start, Gradients.GREEN_BLUE.End]}>
                                        <MainMediumText style={styles.submitStyle}>Next</MainMediumText>
                                    </LinearGradient>
                                </View>
                            </TouchableOpacity>

                            <TouchableOpacity onPress={handleSkip} style={{ flex: 1 }}>
                                <MainText style={{ ...styles.submitStyle, paddingHorizontal: SpacingH.s3, paddingVertical: SpacingH.s2, borderBottomRightRadius: 20 }}>Skip</MainText>
                            </TouchableOpacity>
                        </View>
                    }
                    {stage === CoachingSessions.Stage.CANCELLATION_REASON &&
                        <View style={styles.modalBottom}>
                            <TouchableOpacity onPress={handleBackOnCancel} style={{ flex: 1, paddingLeft: SpacingH.s3, paddingTop: SpacingH.s1, borderBottomLeftRadius: 20, backgroundColor: Colors.WHITE }} >
                                <Ionicons name="arrow-back" size={30} color="black" />
                            </TouchableOpacity>

                            <TouchableOpacity onPress={handleNextOnCancel} style={{ flex: 3, alignItems: "center", paddingHorizontal: SpacingH.s2, paddingVertical: SpacingH.s2, borderBottomRightRadius: 20 }} >
                                <MainText >Next</MainText>
                            </TouchableOpacity>
                        </View>}
                    {stage === CoachingSessions.Stage.ADDITIONAL_COMMENTS &&
                        <View style={styles.modalBottom}>
                            <TouchableOpacity onPress={handleBackOnComments} style={{ flex: 1, paddingLeft: SpacingH.s3, paddingTop: SpacingH.s1, borderBottomLeftRadius: 20, backgroundColor: Colors.WHITE }} >
                                <Ionicons name="arrow-back" size={30} color="black" />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={handleDoneOnComments} style={{ flex: 3, alignItems: "center", paddingHorizontal: SpacingH.s3, paddingVertical: SpacingH.s2, borderBottomRightRadius: 20 }} >
                                <MainText >Done</MainText>
                            </TouchableOpacity>
                        </View>
                    }
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    )
};

const styles = StyleSheet.create({
    modalWrapper: {
        minHeight: hp("18%"),
        backgroundColor: Colors.WHITE,
        borderRadius: 24,
        justifyContent: "flex-start",
    },
    modalTop: {
        backgroundColor: Colors.BLACK,
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
        paddingVertical: SpacingH.s2,
        alignItems: "center",
        justifyContent: "center",
    },
    modalMid: {
        marginHorizontal: SpacingH.s2,
        marginVertical: SpacingH.s3,
    },
    modalBottom: {
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        flexDirection: "row",
        backgroundColor: Colors.SOFT_GREY,
        borderTopWidth: 1,
        borderColor: Colors.SOFT_GREY
    },
    submitStyle: {
        textAlign: "center"
    }
});
