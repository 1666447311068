/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import config from "./config";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiThunkHandler } from "./utils";

const signUp = createAsyncThunk(
    "user/signUp",
    async ({ firstname, lastname, email, password, expoPushToken }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/auth/signup",
            {
                firstname,
                lastname,
                email,
                password,
                device_name: config.deviceName,
                timezone: config.timezone,
                expo_push_token: expoPushToken,
            }
        ),
        thunkAPI
    )
);

const signIn = createAsyncThunk(
    "user/signIn",
    async ({ email, password, expoPushToken }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/auth/signin",
            {
                email,
                password,
                device_name: config.deviceName,
                timezone: config.timezone,
                expo_push_token: expoPushToken,
            }
        ),
        thunkAPI
    )
);

const signOut = createAsyncThunk(
    "user/signOut",
    async ({ expo_push_token }, thunkAPI) => apiThunkHandler(axios.post("/auth/signout", { expo_push_token }), thunkAPI)
);

const resetStages = {
    REQUEST: "REQUEST",
    VERIFY: "VERIFY",
    RESET: "RESET",
    DONE: "DONE",
};

const requestResetPassword = createAsyncThunk(
    "user/requestResetPassword",
    async ({ email }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/auth/request-reset-password",
            {
                email,
            }
        ),
        thunkAPI
    )
);

const verifyCode = createAsyncThunk(
    "user/verifyCode",
    async ({ email, uuid, code }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/auth/verify-code",
            {
                email,
                uuid,
                code,
            }
        ),
        thunkAPI
    )
);

const resetPassword = createAsyncThunk(
    "user/resetPassword",
    async ({ email, uuid, token, password }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/auth/reset-password",
            {
                email,
                uuid,
                token,
                password,
            }
        ),
        thunkAPI
    )
);

const getProfileQuestions = createAsyncThunk(
    "user/getProfileQuestions",
    async ({ all = false }, thunkAPI) => apiThunkHandler(axios.get("/profile-questions" + (all ? "/all" : "")), thunkAPI)
);

const setProfileAnswer = createAsyncThunk(
    "user/setProfileAnswer",
    async ({ uuid, answer }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/profile-data/" + uuid,
            {
                answer: JSON.stringify(answer)
            }
        ),
        thunkAPI
    )
);

const getMatchedCoaches = createAsyncThunk(
    "user/getMatchedCoaches",
    async (_, thunkAPI) => apiThunkHandler(axios.get("/me/matched-coaches"), thunkAPI)
);

const addCoach = createAsyncThunk(
    "user/addCoach",
    async ({ uuid }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/me/coach",
            {
                uuid
            }
        ),
        thunkAPI
    )
);

const changeCoach = createAsyncThunk(
    "user/changeCoach",
    async ({ from_uuid, to_uuid }, thunkAPI) => apiThunkHandler(
        axios.put(
            "/me/coach",
            {
                from_uuid,
                to_uuid
            }
        ),
        thunkAPI
    )
);

const removeCoach = createAsyncThunk(
    "user/removeCoach",
    async ({ uuid }, thunkAPI) => apiThunkHandler(
        axios.delete(
            "/me/coach",
            {
                uuid
            }
        ),
        thunkAPI
    )
);

const updateProfile = createAsyncThunk(
    "user/updateProfile",
    async ({ profile }, thunkAPI) => apiThunkHandler(
        axios.put(
            "/me",
            {
                ...profile
            }
        ),
        thunkAPI
    )
);

const getMyProfile = createAsyncThunk(
    "user/getMyProfile",
    async (_, thunkAPI) => apiThunkHandler(axios.get("/me"), thunkAPI)
);

const getMyPosts = createAsyncThunk(
    "user/getMyPosts",
    async ({ page }, thunkAPI) => apiThunkHandler(axios.get("/me/posts?page=" + page), thunkAPI)
);

const refreshMyPosts = createAsyncThunk(
    "user/refreshMyPosts",
    async (_, thunkAPI) => apiThunkHandler(axios.get("/me/posts"), thunkAPI)
);

const getProfile = createAsyncThunk(
    "user/getProfile",
    async ({ uuid }, thunkAPI) => apiThunkHandler(axios.get("/users/" + uuid), thunkAPI)
);

const getPosts = createAsyncThunk(
    "user/getPosts",
    async ({ uuid, page }, thunkAPI) => apiThunkHandler(axios.get("/users/" + uuid + "/posts?page=" + page), thunkAPI)
);

const refreshPosts = createAsyncThunk(
    "user/refreshPosts",
    async ({ uuid }, thunkAPI) => apiThunkHandler(axios.get("/users/" + uuid + "/posts"), thunkAPI)
);

const getMyClients = createAsyncThunk(
    "user/getMyClients",
    async ({ page, showActive, search }, thunkAPI) => apiThunkHandler(axios.get("/me/my-clients?page=" + page + (showActive ? "&active" : "") + (search !== "" ? "&search=" + search : "")), thunkAPI)
);

const refreshMyClients = createAsyncThunk(
    "user/refreshMyClients",
    async ({ showActive, search }, thunkAPI) => apiThunkHandler(axios.get("/me/my-clients" + (showActive ? "?active" : "") + (search !== "" ? "&search=" + search : "")), thunkAPI)
);

const getCoachDashboardData = createAsyncThunk(
    "user/getCoachDashboardData",
    async (_, thunkAPI) => apiThunkHandler(axios.get("/me/coach-dashboard"), thunkAPI)
);

const chatNotify = createAsyncThunk(
    "user/chatNotify",
    async ({ type = "chat", uuid, threadId, message }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/me/chat/notify",
            {
                type,
                uuid,
                threadId,
                message,
            }
        ),
        thunkAPI
    )
);

const contactSupport = createAsyncThunk(
    "user/contactSupport",
    async ({ subject, message }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/me/contact",
            {
                "subject": subject,
                "message": message,
            }
        ),
        thunkAPI
    )
);

const markNotificationsRead = createAsyncThunk(
    "user/markNotificationsRead",
    async (_, thunkAPI) => apiThunkHandler(axios.get("/me/mark-notifications-read"), thunkAPI)
);

export default {
    signUp,
    signIn,
    signOut,

    resetStages,
    requestResetPassword,
    verifyCode,
    resetPassword,

    getProfileQuestions,
    setProfileAnswer,

    getMatchedCoaches,
    addCoach,
    changeCoach,
    removeCoach,

    updateProfile,
    getMyProfile,
    getMyPosts,
    refreshMyPosts,

    getProfile,
    getPosts,
    refreshPosts,

    getMyClients,
    refreshMyClients,

    getCoachDashboardData,

    chatNotify,

    contactSupport,
    markNotificationsRead,
};
