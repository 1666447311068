/**
 * LifeRamp
 *
 * @author      Waris Anwar
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { Image, ImageBackground, StyleSheet, TouchableOpacity, View, } from "react-native";
import { FontSize, Size, wp, SpacingH, SpacingW } from "../../themes/sizes";

import { Colors } from "../../themes/colors";
import MainMediumText from "../text/MainMediumText";

export default ({ style, onPress, image, institution, peopleCount, postCount }) => (
    <TouchableOpacity activeOpacity={0.6} onPress={onPress} style={[styles.card, style]}>
        <ImageBackground source={{ uri: image }} style={{ width: "100%", height: Size.h25, borderRadius: 2 }}>
            <View style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: Colors.NAVY, opacity: 0.6 }} />
        </ImageBackground>
        <View style={{ flexDirection: "row", paddingHorizontal: SpacingW.s3, alignItems: "center", marginTop: SpacingH.s1, justifyContent: "space-between" }}>
            <View style={{ flex: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
                <View style={{ flexDirection: "row", alignItems: "center", flexBasis: wp("30%"), marginRight: Size.w05 }}>
                    <Image
                        source={{ uri: institution ? institution.image : null }}
                        style={{ width: 42, height: 42, borderRadius: 21, marginRight: SpacingW.s2 }} />
                    <View style={{ flexDirection: "column", maxWidth: "100%" }}>
                        <MainMediumText style={{ fontSize: FontSize.VERY_SMALL }}>Guided by</MainMediumText>
                        <MainMediumText style={{ fontSize: FontSize.VERY_SMALL }}>{institution ? institution.name : "Loading..."}</MainMediumText>
                    </View>
                </View>
                <View style={{ marginHorizontal: SpacingW.s2, marginTop: SpacingH.s0 }}>
                    <MainMediumText style={styles.cardInfo}>
                        Members
                    </MainMediumText>
                    <MainMediumText style={[styles.cardInfo, { textAlign: "right" }]}>
                        {peopleCount}
                    </MainMediumText>
                </View>
                <View style={{ marginTop: SpacingH.s0 }}>
                    <MainMediumText style={styles.cardInfo}>
                        Conversations
                    </MainMediumText>
                    <MainMediumText style={[styles.cardInfo, { textAlign: "right" }]}>
                        {postCount}
                    </MainMediumText>
                </View>
            </View>
        </View>
    </TouchableOpacity>
);

const styles = StyleSheet.create({
    card: {
        marginVertical: SpacingH.s2,
        paddingTop: 0,
        paddingHorizontal: 0,
        paddingBottom: SpacingH.s1,
        backgroundColor: Colors.SOFT_GREY,
    },
    cardInfo: {
        fontSize: FontSize.VERY_SMALL,
        color: Colors.LIGHT_GREY
    }
});
