/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { createSlice } from "@reduxjs/toolkit";
import { CoachingSessions } from "../../api";
import { setIsLoading, setError } from "../utils";

const initialState = {
    loading: false,
    error: "",

    refreshing: false,
    autoLoading: false,

    uuid: null,
    data: {},

    countCompleted: 0,
    sessionNow: null,

    upcomingSessions: [],
    upcomingPage: 2,
    upcomingMaxPage: 2,

    pastRefreshing: false,
    pastSessions: [],
    pastPage: 2,
    pastMaxPage: 2,

    instantZoomURL: null,
};

export const coachingSessionSlice = createSlice({
    name: "session",
    initialState,
    reducers: {
        setSession(state, { payload: { session } }) {
            state.uuid = session.uuid;
            state.data = session;
        },
        resetInstantZoomURL(state) {
            state.instantZoomURL = null;
        },
        setAutoLoading(state, { payload }) {
            state.autoLoading = payload;
        },
    },
    extraReducers: {
        [CoachingSessions.createInstantSession.pending]: setIsLoading(true),
        [CoachingSessions.getJourneyData.pending]: setIsLoading(true),

        [CoachingSessions.refreshUpcomingSessions.pending]: (state) => { state.refreshing = true; },
        [CoachingSessions.getUpcomingSessions.pending]: setIsLoading(true),

        [CoachingSessions.refreshPastSessions.pending]: (state) => { state.pastRefreshing = true; },
        [CoachingSessions.getPastSessions.pending]: setIsLoading(true),

        [CoachingSessions.createInstantSession.fulfilled]: (state, action) => {
            state.instantZoomURL = action.payload.join_url;
            setIsLoading(false)(state);
        },
        [CoachingSessions.getJourneyData.fulfilled]: (state, action) => {
            state.countCompleted = action.payload.countCompleted;
            state.sessionNow = action.payload.sessionNow;
            setIsLoading(false)(state);
        },

        [CoachingSessions.refreshUpcomingSessions.fulfilled]: (state, action) => {
            state.upcomingSessions = action.payload.coachingSessions;
            state.upcomingPage = 2;
            state.upcomingMaxPage = action.payload.maxPage;
            state.refreshing = false;
        },
        [CoachingSessions.getUpcomingSessions.fulfilled]: (state, action) => {
            state.upcomingPage++;
            state.upcomingSessions.push(...action.payload.coachingSessions);
            setIsLoading(false)(state);
        },

        [CoachingSessions.refreshPastSessions.fulfilled]: (state, action) => {
            state.pastSessions = action.payload.coachingSessions;
            state.pastPage = 2;
            state.pastMaxPage = action.payload.maxPage;
            state.pastRefreshing = false;
        },
        [CoachingSessions.getPastSessions.fulfilled]: (state, action) => {
            state.pastPage++;
            state.pastSessions.push(...action.payload.coachingSessions);
            setIsLoading(false)(state);
        },

        [CoachingSessions.createInstantSession.rejected]: setError,
        [CoachingSessions.getJourneyData.rejected]: setError,
        [CoachingSessions.refreshUpcomingSessions.rejected]: setError,
        [CoachingSessions.getUpcomingSessions.rejected]: setError,
        [CoachingSessions.refreshPastSessions.rejected]: setError,
        [CoachingSessions.getPastSessions.rejected]: setError,
    }
});

export const { setSession, resetInstantZoomURL, setAutoLoading } = coachingSessionSlice.actions;

export default coachingSessionSlice.reducer;
