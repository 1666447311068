/**
 * LifeRamp
 *
 * @author      Anees Muzzafer
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, Pressable, View } from "react-native";
import { EvilIcons } from '@expo/vector-icons';

import { FontSize, SpacingH, SpacingW } from "../../themes/sizes";
import { Colors } from "../../themes/colors";


import MainText from "../text/MainText";

export default ({ children, buttonSize, style, textStyle, onPress }) => (
    <Pressable onPress={onPress}>
        <View style={[
            styles.tagButton,
            style,
        ]}>
            <MainText style={[styles.buttonText, textStyle]}>{children}</MainText>
            <EvilIcons name="close" size={10} color={Colors.TEXT_BLACK} />
        </View>
    </Pressable>
);

const styles = StyleSheet.create({
    tagButton: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: SpacingH.s0,
        borderRadius: 16,
        flexDirection: "row",
        paddingLeft: SpacingW.s3,
        paddingRight: SpacingW.s1,
        marginRight: SpacingW.s1,
        marginBottom: SpacingH.s1,
        backgroundColor: Colors.WHITE_GREY
    },
    buttonText: {
        fontSize: FontSize.SMALLER,
        marginRight: SpacingW.s1,
    },
});
