/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, View, TouchableOpacity, Image } from "react-native";
import { EvilIcons } from "@expo/vector-icons";
import { FontSize, SpacingH, SpacingW, Size, isWeb } from "../../themes/sizes";
import { Colors } from "../../themes/colors";

import MainMediumText from "../text/MainMediumText";

export default ({ children, style, navigation }) => (
    <View style={styles.header}>
        <TouchableOpacity onPress={() => { navigation.goBack(); }}>
            <EvilIcons name="chevron-left" size={42} color={Colors.BLUE_GREY} />
        </TouchableOpacity>
        <View style={styles.circleContainer}>
            <MainMediumText style={{ fontSize: FontSize.NORMED }}>Conversations</MainMediumText>
        </View>
    </View>
);

const styles = StyleSheet.create({
    header: {
        flexDirection: "row",
        marginTop: Size.adjustedStatusBarHeight,
        alignItems: "center",
        backgroundColor: Colors.WHITE,
        paddingVertical: SpacingH.s2,
        paddingLeft: SpacingH.s1,
        paddingRight: SpacingW.s3,
        borderBottomColor: Colors.SOFT_GREY,
        borderBottomWidth: 1,
    },
    circleContainer: {
        flex: 1,
        alignItems: "flex-start",
        paddingLeft: SpacingW.s5,
    },
    circleImage: {
        height: 42,
        width: 42,
        borderRadius: 21,
        marginVertical: SpacingH.s1,
    },
});
