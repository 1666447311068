/**
 * LifeRamp
 *
 * @author      Waris Anwar, Burhan Rashid, Ummar Hamid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { TouchableOpacity, ActivityIndicator, StyleSheet, View, BackHandler } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { EvilIcons } from "@expo/vector-icons";

import { Colors } from "../../themes/colors";
import { FontSize, SpacingH, Size, SpacingW, wp } from "../../themes/sizes";
import { GlobalStyles } from "../../themes/styles";

import LinkButton from "../../components/buttons/LinkButton";
import PrimaryButton from "../../components/buttons/PrimaryButton";

import LineInput from "../../components/input/LineInput";
import CustomBoxInput from "../../components/input/CustomBoxInput";

import NonSafeContainerView from "../../components/main/NonSafeContainerView";

import MainText from "../../components/text/MainText";
import HeroText from "../../components/text/HeroText";

import { User } from "../../api";
import { setResetStage, clearError } from "../../state/slices/userSlice";
import MainRegularText from "../../components/text/MainRegularText";

export default ({ navigation, route }) => {
    const dispatch = useDispatch();

    const title = route.params?.title || "Reset Password";

    const loading = useSelector(state => state.user.loading);
    const error = useSelector(state => state.user.error);
    const resetData = useSelector(state => state.user.resetData);
    const stage = resetData.stage;

    const [email, setEmail] = React.useState(null);

    const [otpArray, setOtpArray] = React.useState(["", "", "", "", "", ""]);
    const [inputBoxRefs, _] = React.useState([
        React.useRef(null),
        React.useRef(null),
        React.useRef(null),
        React.useRef(null),
        React.useRef(null),
        React.useRef(null),
    ]);

    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");

    React.useEffect(() => {
        if (stage == User.resetStages.DONE) {
            dispatch(setResetStage({ stage: User.resetStages.REQUEST }));
            setEmail("");
            setPassword("");
            setConfirmPassword("");
            setOtpArray(["", "", "", "", "", ""]);
            alert("Password reset successful. Please sign in using your new password.");
            navigation.goBack();
        }
    }, [stage]);


    React.useEffect(() => {
        dispatch(setResetStage({ stage: User.resetStages.REQUEST }));

        const backHandler = BackHandler.addEventListener("hardwareBackPress", () => {
            dispatch(clearError());
            setOtpArray(["", "", "", "", "", ""]);
            dispatch(setResetStage({ stage: User.resetStages.REQUEST }));
            return stage === User.resetStages.VERIFY;
        });

        return () => { backHandler.remove(); };
    }, []);

    const refCallback = textInputRef => node => {
        textInputRef.current = node;
    };

    const submitEmail = () => {
        if (email === "" || email === null) {
            return alert("Please enter your email address.");
        }

        dispatch(User.requestResetPassword({ email }));
    };

    const submitCode = () => {
        dispatch(clearError());

        let code = otpArray.join("");

        if (code === null || code === "" || code.length != 6) {
            return alert("Please enter verification code.");
        }

        dispatch(User.verifyCode({ email, code, uuid: resetData.uuid }));
    };

    const resetPassword = () => {
        if (password === "" || confirmPassword === "") {
            return alert("Please fill both the fields.");
        }

        if (password !== confirmPassword) {
            return alert("Passwords don't match.");
        }

        dispatch(User.resetPassword({ email, password, uuid: resetData.uuid, token: resetData.token }));
    };

    const onOtpChange = (index) => {
        return (value) => {
            if (isNaN(Number(value))) {
                return;
            }

            setOtpArray([
                ...otpArray.slice(0, index),
                value,
                ...otpArray.slice(index + 1)
            ]);

            if (value !== "" && index < 5) {
                inputBoxRefs[index + 1].current.focus();
            }
        };
    };

    const onOtpKeyPress = (index) => {
        return ({ nativeEvent: { key: value } }) => {
            if (value === "Backspace" && otpArray[index] === "") {
                if (index >= 1) {
                    inputBoxRefs[index - 1].current.focus();
                }
            }
        };
    };

    const backToSignIn = () => {
        dispatch(clearError());
        dispatch(setResetStage({ stage: User.resetStages.REQUEST }));
        navigation.goBack();
    };

    return (
        <NonSafeContainerView style={GlobalStyles.containerPaddingH}>
        <TouchableOpacity onPress={backToSignIn} style={{ position: "absolute", top: 40, left: 10, zIndex: 10 }}>
            <EvilIcons name="chevron-left" size={42} color={Colors.BLUE_GREY} />
        </TouchableOpacity>
            <HeroText style={{ fontSize: FontSize.BIG, marginVertical: SpacingH.s2, color: Colors.WHITE, textAlign: "center" }}>{title}</HeroText>

            <ActivityIndicator size="large" color={Colors.DEEP_TEAL} animating={loading} />

            {error != "" && <MainText style={{ color: Colors.HEART_RED, alignSelf: "center", marginBottom: SpacingH.s2 }}>{error}</MainText>}

            {stage === User.resetStages.REQUEST &&
                <View style={styles.view}>
                    <LineInput
                        placeholder="Email"
                        textContentType="emailAddress"
                        style={{ marginVertical: SpacingH.s3 }}
                        autoCompleteType={"email"}
                        autoCapitalize={"none"}
                        keyboardType={"email-address"}
                        value={email}
                        onChangeText={(t) => { setEmail(t) }} />
                    <PrimaryButton style={styles.button} buttonSize={'sm'} onPress={submitEmail}>Submit</PrimaryButton>
                </View>
            }

            {stage === User.resetStages.VERIFY &&
                <View style={styles.view}>
                    <MainRegularText style={{ marginBottom: Size.h5, color: Colors.WHITE, alignSelf: "center", textAlign: "center", fontSize: FontSize.SMALLER, maxWidth: wp(80) }}>A verification code has been emailed to you.</MainRegularText>
                    <View style={styles.boxInputView}>
                        {
                            inputBoxRefs.map((textInputRef, index) => (
                                <CustomBoxInput
                                    key={index}
                                    maxLength={1}
                                    keyboardType={"number-pad"}
                                    style={{ backgroundColor: Colors.TRANSPARENT, borderColor: Colors.WHITE, color: Colors.WHITE }}
                                    value={otpArray[index]}
                                    autoFocus={index === 0 ? true : undefined}
                                    onKeyPress={onOtpKeyPress(index)}
                                    onChangeText={onOtpChange(index)}
                                    refCallback={refCallback(textInputRef)}
                                />
                            ))
                        }
                    </View>
                    <PrimaryButton style={[styles.button, { alignSelf: "center", marginTop: Size.h10 }]} buttonSize={'sm'} onPress={submitCode}>Submit Code</PrimaryButton>
                </View>
            }

            {stage === User.resetStages.RESET &&
                <View style={styles.view}>
                    <LineInput
                        placeholder="New Password"
                        textContentType="password"
                        style={{ marginTop: SpacingH.s3 }}
                        autoCompleteType={"password"}
                        autoCapitalize={"none"}
                        secureTextEntry={true}
                        value={password}
                        onChangeText={(t) => { setPassword(t) }} />
                    <LineInput
                        placeholder="Confirm New Password"
                        textContentType="password"
                        style={{ marginTop: SpacingH.s3 }}
                        autoCompleteType={"password"}
                        autoCapitalize={"none"}
                        secureTextEntry={true}
                        value={confirmPassword}
                        onChangeText={(t) => { setConfirmPassword(t) }} />
                    <PrimaryButton style={[styles.button, { marginTop: SpacingH.s3 }]} buttonSize={'sm'} onPress={resetPassword}>Submit</PrimaryButton>
                </View>
            }

            <View style={{ justifyContent: "center", flexDirection: "row" }}>
                <LinkButton style={{ marginTop: SpacingH.s5, textAlign: "center" }} onPress={backToSignIn}>Back to Sign In</LinkButton>
            </View>
        </NonSafeContainerView>
    );
}

const styles = StyleSheet.create({
    button: {
        alignSelf: "flex-end"
    },
    linkButton: {
        marginVertical: SpacingH.s3
    },
    view: {
        marginTop: Size.h5,
    },
    boxInputView: {
        flexDirection: "row",
        marginBottom: Size.h5,
        marginHorizontal: SpacingH.s5,
        justifyContent: "space-between"
    }
});
