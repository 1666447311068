/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import Conversations from "../screens/chat/Conversations";
import Chat from "../screens/chat/Chat";

import ConversationsHeader from "../components/navigation/ConversationsHeader";
import ChatHeader from "../components/navigation/ChatHeader";

const ChatStack = createStackNavigator();
const ChatStackScreen = () => (
    <ChatStack.Navigator
        headerMode="screen">
        <ChatStack.Screen
            name="Conversations"
            component={Conversations}
            options={({ navigation }) => ({ header: ({ navigation }) => (<ConversationsHeader navigation={navigation} />), title: 'Conversations' })}
        />
        <ChatStack.Screen
            name="Chat"
            component={Chat}
            options={({ navigation }) => ({ header: ({ navigation }) => (<ChatHeader navigation={navigation} />), title: 'Chat' })}
        />
    </ChatStack.Navigator>
);

export default ChatStackScreen;
