/**
 * LifeRamp
 *
 * @author      Waris Anwar, Burhan Rashid, Ummar hamid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { TouchableOpacity, View, StyleSheet } from "react-native";
import { EvilIcons } from "@expo/vector-icons";
// import { WebView } from "react-native-webview";
import { WebView } from "react-native-web-webview";
import { Colors } from "../../themes/colors";
import { FontSize, Size, SpacingH, SpacingW } from "../../themes/sizes";
import ContainerView from "../../components/main/ContainerView";
import { GlobalStyles } from "../../themes/styles";
import HeroSemiText from "../text/HeroSemiText";

export default ({ navigation, route }) => {
    const title = route.params?.title || null;
    const schedule = route.params?.schedule || false;

    return (
        <ContainerView style={{ flex: 1, paddingBottom: SpacingH.s1, paddingHorizontal: 0 }} hasHeader>
            {title !== null &&
                <View style={{ paddingVertical: SpacingH.s2, borderBottomColor: Colors.BLUE_GREY, borderBottomWidth: 0.5 }}>
                    <HeroSemiText numberOfLines={1} style={{ marginLeft: SpacingW.s4, width: Size.w80, fontSize: FontSize.NORMED }}>{title}</HeroSemiText>
                </View>
            }
            <TouchableOpacity onPress={() => { navigation.goBack(); }} style={[GlobalStyles.closeButton, title !== null && { backgroundColor: Colors.TRANSPARENT, borderWidth: 0, top: 10 }, schedule && styles.calendlyClose]}>
                <EvilIcons name="close" size={28} color={schedule ? "#006bff" : Colors.BLUE_GREY} />
            </TouchableOpacity>
            <WebView source={{ uri: route.params.url }} />
        </ContainerView>
    )
};

const styles = StyleSheet.create({
    calendlyClose: {
        backgroundColor: Colors.TRANSPARENT,
        borderColor: "#1a1a1a20",
        width: 40,
        height: 40,
        borderRadius: 20,
        top: 18,
    }
})