/**
 * LifeRamp
 *
 * @author      Afaan Bilal | Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { View, StyleSheet, Animated, TouchableOpacity } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { LinearGradient } from "expo-linear-gradient";
import { EvilIcons } from "@expo/vector-icons";
import PaginationDots from "react-native-pagination-dots";

import { Colors, Gradients } from "../../themes/colors";
import { Size, SpacingH, SpacingW } from "../../themes/sizes";

import CoachProfileComponent from "../../components/reusableScreens/CoachProfileComponent";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import ActionButton from "../../components/buttons/ActionButton";

import { User } from "../../api";
import { setIsSpinning } from "../../state/slices/spinnerSlice";

export default ({ navigation, route }) => {
    const dispatch = useDispatch();
    const [index, setIndex] = React.useState(route.params?.index);
    const changeCoach = route.params?.from_uuid !== null;
    const matchedCoaches = useSelector(state => state.user.matchedCoaches);
    const matchedCoach = matchedCoaches[index];

    const screenAnim = React.useRef(new Animated.Value(1)).current;

    const screenAnimStart = () => {
        Animated.timing(screenAnim, {
            toValue: 0.2,
            duration: 350,
            useNativeDriver: false,
        }).start(() => {
            next();
            screenAnimStop();
        });
    };

    const screenAnimStop = () => {
        Animated.timing(screenAnim, {
            toValue: 1,
            duration: 350,
            useNativeDriver: false,
        }).start();
    };

    const user = useSelector(state => state.user.profile);
    const subscriptionExpired = !user.subscriptionEnd || (new Date()).getTime() > (new Date(user.subscriptionEnd)).getTime();

    const chooseCoach = () => {
        dispatch(setIsSpinning(true));
        if (changeCoach) {
            dispatch(User.changeCoach({ from_uuid: route.params?.from_uuid, to_uuid: matchedCoach.uuid }))
                .then(() => dispatch(User.getMyProfile()))
                .then(() => {
                    navigation.popToTop();
                    dispatch(setIsSpinning(false));
                });
        } else {
            if (subscriptionExpired) {
                navigation.navigate("Pay", { chosenCoach: matchedCoach.uuid });
            } else {
                dispatch(User.addCoach({ uuid: matchedCoach.uuid }))
                    .then(() => dispatch(User.getMyProfile()))
                    .then(() => {
                        navigation.popToTop();
                        dispatch(setIsSpinning(false));
                    });
            }
        }
    };

    const next = () => {
        index < matchedCoaches.length - 1 ? setIndex(index + 1) : setIndex(0);
    };

    return (
        <View style={{ flex: 1, backgroundColor: Colors.WHITE }}>
            <View style={styles.paginationContainer}>
                <TouchableOpacity onPress={() => { navigation.goBack(); }} style={{ alignSelf: "flex-start" }}>
                    <EvilIcons name="chevron-left" size={42} color={Colors.WHITE} />
                </TouchableOpacity>
                <View style={styles.paginationDotContainer}>
                    <PaginationDots length={matchedCoaches.length} activeIndex={index} />
                </View>
                <View style={{ width: 43 }} />
            </View>

            <Animated.View style={{ opacity: screenAnim, flex: 1 }}>
                <CoachProfileComponent {...matchedCoach} />
            </Animated.View>
            <LinearGradient
                colors={[Gradients.TRANSPARENT.Start, Gradients.TRANSPARENT.End]}
                style={styles.bottomBar}>
                <PrimaryButton style={{ marginRight: 5 }} onPress={chooseCoach}>{subscriptionExpired ? "Subscribe" : "Choose " + matchedCoach.firstname}</PrimaryButton>
                <ActionButton activeOpacity={0.6} buttonSize={'sm'} buttonStyle={'navy'} onPress={screenAnimStart}>Next</ActionButton>
            </LinearGradient>
        </View >
    );
}

const styles = StyleSheet.create({
    bottomBar: {
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        paddingVertical: SpacingH.s2,
        paddingTop: Size.h10,
        backgroundColor: Colors.TRANSPARENT
    },
    paginationContainer: {
        position: "absolute",
        top: Size.adjustedStatusBarHeight > 0 ? Size.adjustedStatusBarHeight + 2 : 30,
        left: 0,
        zIndex: 10,
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingHorizontal: SpacingW.s2,
    },
    paginationDotContainer: {
        backgroundColor: "rgba(0,0,0,0.1)",
        paddingVertical: 8,
        paddingHorizontal: 12,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        borderRadius: 15,
        alignSelf: "center"
    },
});
