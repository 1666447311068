/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { View, StyleSheet, ScrollView, Image } from "react-native";

import { GlobalStyles } from "../../themes/styles";
import { Colors } from "../../themes/colors";
import { FontSize, SpacingH, hp, } from "../../themes/sizes";
import HeroText from "../../components/text/HeroText";
import HeroSemiText from "../../components/text/HeroSemiText";
import MainMediumText from "../../components/text/MainMediumText";

export default ({ image_cover, name, pronouns, role, headline, location, education, bio }) => {
    const parsedLocation = JSON.parse(location);
    const readableLocation = parsedLocation ? parsedLocation.city + ", " + parsedLocation.country : false;

    return (
        <View style={{ flex: 1, backgroundColor: Colors.WHITE }}>
            <ScrollView contentContainerStyle={{ paddingBottom: SpacingH.s2 }}>
                <Image
                    source={{ uri: image_cover }}
                    style={{ width: "100%", height: hp(35) }}
                    resizeMode="cover" />
                <View style={[GlobalStyles.container, GlobalStyles.containerPaddingH, { flex: 1, paddingBottom: SpacingH.s6 }]}>
                    <HeroText style={styles.coachName}>{name}</HeroText>
                    <MainMediumText style={styles.coachTitle}>Certified LifeRamp Life Coach  •  {pronouns}</MainMediumText>
                    <MainMediumText style={styles.coachTitle}>{role}</MainMediumText>
                    <HeroSemiText style={styles.coachInfo}>{headline}</HeroSemiText>
                    {readableLocation !== false &&
                        <>
                            <MainMediumText style={styles.coachItemTitle}>Location</MainMediumText>
                            <MainMediumText style={styles.coachItemDetail}>{readableLocation}</MainMediumText>
                        </>
                    }
                    <MainMediumText style={styles.coachItemTitle}>General Education</MainMediumText>
                    <MainMediumText style={styles.coachItemDetail}>{education}</MainMediumText>
                    <MainMediumText style={styles.coachItemTitle}>Bio</MainMediumText>
                    <MainMediumText style={styles.coachItemDetail}>{bio}</MainMediumText>
                </View>
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    coachName: {
        marginTop: SpacingH.s1,
        marginBottom: SpacingH.s0,
        fontSize: FontSize.BIG,
    },
    coachTitle: {
        color: Colors.BLUE_GREY,
        marginBottom: SpacingH.s2,
        fontSize: FontSize.SMALLER,
    },
    coachInfo: {
        color: Colors.DARK_GREY,
        fontSize: FontSize.NORMED,
        lineHeight: FontSize.BIG,
        marginBottom: SpacingH.s3,
    },
    coachItemTitle: {
        color: Colors.BLUE_GREY,
        marginBottom: SpacingH.s1,
        fontSize: FontSize.SMALL,
    },
    coachItemDetail: {
        color: Colors.DARK_GREY,
        marginBottom: SpacingH.s4,
        fontSize: FontSize.SMALL,
        lineHeight: 26,
        textAlign: "justify",
    },
});
