/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { View, TouchableOpacity } from "react-native";
import { useSelector } from "react-redux";

import Carousel from "react-native-snap-carousel";
import { EvilIcons } from "@expo/vector-icons";

import { FontSize, SpacingH, Size, isIOS } from "../../themes/sizes";
import { GlobalStyles } from "../../themes/styles";
import { Colors } from "../../themes/colors";

import NonSafeContainerView from "../../components/main/NonSafeContainerView";

import CoachMatchCard from "../../components/cards/CoachMatchCard";
import HeroText from "../../components/text/HeroText";
import MainText from "../../components/text/MainText";
import MainMediumText from "../../components/text/MainMediumText";

export default ({ navigation, route }) => {
    const matchedCoaches = useSelector(state => state.user.matchedCoaches);
    const changeCoach = route.params && route.params.from_uuid;
    const showBack = route.params?.showBack || false;

    return (
        <NonSafeContainerView>
            <View style={[GlobalStyles.containerPaddingH]}>
                {showBack &&
                    <TouchableOpacity onPress={() => { navigation.goBack(); }} style={{ position: "absolute", top: isIOS ? -20 : -45, left: 10, zIndex: 10 }}>
                        <EvilIcons name="chevron-left" size={42} color={Colors.WHITE} />
                    </TouchableOpacity>
                }
                <HeroText style={{ fontSize: FontSize.BIGGER, marginVertical: SpacingH.s3, color: Colors.WHITE }}>{changeCoach ? "Change" : "Choose"} your LifeRamp coach</HeroText>
                <MainText style={{ fontSize: FontSize.SMALL, marginBottom: SpacingH.s6, color: Colors.WHITE }}>
                    Meet your matches and choose your coach. {"\n"}
                    Don't worry, you can change coaches at anytime.
                </MainText>

                <MainMediumText style={{ fontSize: FontSize.SMALL, marginBottom: SpacingH.s1, color: Colors.WHITE }}>{matchedCoaches.length} Results</MainMediumText>
            </View>
            <View style={{ flex: 1, flexDirection: "row", justifyContent: "center" }}>
                <Carousel
                    layout={"default"}
                    inactiveSlideScale={0.95}
                    data={matchedCoaches}
                    sliderWidth={Size.w100}
                    itemWidth={Size.w90}
                    // onSnapToItem={index => setState({ ...state, activeIndex: index })}
                    renderItem={({ item, index }) => (
                        <CoachMatchCard
                            {...item}
                            onPress={() => { navigation.navigate("MatchProfile", { index, from_uuid: changeCoach ? route.params.from_uuid : null }); }}
                            style={{ marginVertical: 10 }}
                        />
                    )} />
            </View>
        </NonSafeContainerView>
    );
}
