/**
 * LifeRamp
 *
 * @author      Afaan Bilal | Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import Constants from 'expo-constants';
import { widthPercentageToDP, heightPercentageToDP } from 'react-native-responsive-screen';

import { PixelRatio, Platform } from "react-native";

export const sV = (pt) => {
    return PixelRatio.roundToNearestPixel(pt * PixelRatio.get());
};

// export const wp = widthPercentageToDP;
// export const hp = heightPercentageToDP;

export const wp = (w) => {
    if (widthPercentageToDP(100) < 600) {
        return widthPercentageToDP(w)
    }
    return parseFloat(w) * 6;
};

export const hp = (h) => {
    if (widthPercentageToDP(100) < 600) {
        return heightPercentageToDP(h)
    }
    return parseFloat(h) * 10;
};

export const isTabPortrait = wp("100%") > 760 && wp("100%") < 960;
const divFactorFS = isTabPortrait ? 1 : 1;

const fontScale = widthPercentageToDP(100) < 600 ? 1.5 : 1.1;

export const FontSize = {
    VERY_BIG:   wp(7.2) * fontScale,
    BIGGER:     wp(5.7) * fontScale,
    BIG:        wp(4.7) * fontScale,
    MEDIUM:     wp(3.7) * fontScale,
    NORMED:     wp(3.2) * fontScale,
    NORMAL:     wp(2.6) * fontScale,
    SMALL:      wp(2.4) * fontScale,
    SMALLER:    wp(2.2) * fontScale,
    VERY_SMALL: wp(2.0) * fontScale,
};

const hMulFactor = isTabPortrait ? 1 : 1;
const wMulFactor = isTabPortrait ? .5 : 1;

export const SpacingW = {
    s0: wp("0.5%") * wMulFactor,
    s1: wp("1%")   * wMulFactor,
    s2: wp("2%")   * wMulFactor,
    s3: wp("3%")   * wMulFactor,
    s4: wp("4%")   * wMulFactor,
    s5: wp("5%")   * wMulFactor,
    s6: wp("6%")   * wMulFactor,
};

export const SpacingH = {
    s0: hp("0.5%") * hMulFactor,
    s1: hp("1%")   * hMulFactor,
    s2: hp("2%")   * hMulFactor,
    s3: hp("3%")   * hMulFactor,
    s4: hp("4%")   * hMulFactor,
    s5: hp("5%")   * hMulFactor,
    s6: hp("6%")   * hMulFactor,
};

export const Size = {
    w100:   wp("100%") *  wMulFactor,
    w90:    wp("90%")  *  wMulFactor,
    w80:    wp("80%")  *  wMulFactor,
    w75:    wp("75%")  *  wMulFactor,
    w70:    wp("70%")  *  wMulFactor,
    w60:    wp("60%")  *  wMulFactor,
    w50:    wp("50%")  *  wMulFactor,
    w40:    wp("40%")  *  wMulFactor,
    w30:    wp("30%")  *  wMulFactor,
    w25:    wp("25%")  *  wMulFactor,
    w20:    wp("20%")  *  wMulFactor,
    w15:    wp("15%")  *  wMulFactor,
    w10:    wp("10%")  *  wMulFactor,
    w5:     wp("5%")   *  wMulFactor,

    h100:   hp("100%") *  hMulFactor,
    h90:    hp("90%")  *  hMulFactor,
    h80:    hp("80%")  *  hMulFactor,
    h75:    hp("75%")  *  hMulFactor,
    h70:    hp("70%")  *  hMulFactor,
    h60:    hp("60%")  *  hMulFactor,
    h50:    hp("50%")  *  hMulFactor,
    h40:    hp("40%")  *  hMulFactor,
    h30:    hp("30%")  *  hMulFactor,
    h25:    hp("25%")  *  hMulFactor,
    h20:    hp("20%")  *  hMulFactor,
    h15:    hp("15%")  *  hMulFactor,
    h10:    hp("10%")  *  hMulFactor,
    h5:     hp("5%")   *  hMulFactor,

    adjustedStatusBarHeight: Platform.OS == "ios" ? Constants.statusBarHeight : 0,
};

export const Utils = {
    keyboardAvoidOffset: Size.adjustedStatusBarHeight + (Platform.OS == "ios" ? hp("12%") : .1 * Size.adjustedStatusBarHeight)
};

export const isWeb = Platform.OS == "web";
export const isIOS = Platform.OS == "ios";
