/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { View } from "react-native";
import { Colors } from "../../themes/colors";
import MainText from "../../components/text/MainText";
import { SpacingH } from "../../themes/sizes";

export default ({ navigation }) => (
    <View style={{ flex: 1, backgroundColor: Colors.WHITE, paddingVertical: SpacingH.s2 }}>
        <MainText>Settings</MainText>
    </View>
);
