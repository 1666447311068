/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { Text } from "react-native";

import ReactTimeAgo from "react-time-ago";

import { Colors } from "../../themes/colors";
import { FontSize } from "../../themes/sizes";

function Time({ children, style }) {
    return <Text style={[{ fontSize: FontSize.VERY_SMALL, color: Colors.BLUE_GREY }, style]}>{children}</Text>
};

export default ({ date, style }) => {
    return <ReactTimeAgo style={style} date={Date.parse(date)} component={Time} />
};
