/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiThunkHandler } from "./utils";

const getCircles = createAsyncThunk(
    "circle/getCircles",
    async ({ page, search }, thunkAPI) => apiThunkHandler(axios.get("/circles?page=" + page + (search !== "" ? '&search=' + search : "")), thunkAPI)
);

const refreshCircles = createAsyncThunk(
    "circle/refreshCircles",
    async ({ search }, thunkAPI) => apiThunkHandler(axios.get("/circles" + (search !== "" ? '?search=' + search : "")), thunkAPI)
);

const getCircle = createAsyncThunk(
    "circle/getCircle",
    async ({ uuid }, thunkAPI) => apiThunkHandler(axios.get("/circles/" + uuid), thunkAPI)
);

const getPosts = createAsyncThunk(
    "circle/getPosts",
    async ({ uuid, page }, thunkAPI) => apiThunkHandler(axios.get("/circles/" + uuid + "/posts?page=" + page), thunkAPI)
);

const refreshPosts = createAsyncThunk(
    "circle/refreshPosts",
    async ({ uuid }, thunkAPI) => apiThunkHandler(axios.get("/circles/" + uuid + "/posts"), thunkAPI)
);

const getNotices = createAsyncThunk(
    "circle/getNotices",
    async ({ uuid, page }, thunkAPI) => apiThunkHandler(axios.get("/circles/" + uuid + "/notices?page=" + page), thunkAPI)
);

const refreshNotices = createAsyncThunk(
    "circle/refreshNotices",
    async ({ uuid }, thunkAPI) => apiThunkHandler(axios.get("/circles/" + uuid + "/notices"), thunkAPI)
);
const createPost = createAsyncThunk(
    "circle/createPost",
    async ({ uuid, post }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/circles/" + uuid + "/posts",
            {
                ...post,
                tags: JSON.stringify(post.tags),
            },
        ),
        thunkAPI
    )
);

const getPeople = createAsyncThunk(
    "circle/getPeople",
    async ({ uuid, page, search }, thunkAPI) => apiThunkHandler(axios.get("/circles/" + uuid + "/people?page=" + page + (search !== "" ? '&search=' + search : "")), thunkAPI)
);

const refreshPeople = createAsyncThunk(
    "circle/refreshPeople",
    async ({ uuid, search }, thunkAPI) => apiThunkHandler(axios.get("/circles/" + uuid + "/people" + (search !== "" ? '?search=' + search : "")), thunkAPI)
);

export default {
    getCircles,
    refreshCircles,

    getCircle,

    getPosts,
    refreshPosts,

    getNotices,
    refreshNotices,

    getPeople,
    refreshPeople,

    createPost,
};
