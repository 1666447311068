/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { Text, StyleSheet } from "react-native";
import Fonts from "../../themes/fonts";
import { FontSize } from "../../themes/sizes";
import { GlobalStyles } from "../../themes/styles";

export default ({ children, style, numberOfLines = 0 }) => (
    <Text style={[GlobalStyles.text, styles.textStyle, style]} numberOfLines={numberOfLines} >{children}</Text>
);

const styles = StyleSheet.create({
    textStyle: {
        fontFamily: Fonts.Title,
        fontSize: FontSize.BIG,
        fontWeight: "600",
    },
});
