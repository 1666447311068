/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { ScrollView, View, Image, StyleSheet, ActivityIndicator, TouchableOpacity } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { EvilIcons } from '@expo/vector-icons';

import { SpacingH, Size, wp } from "../../../themes/sizes";
import { Colors } from "../../../themes/colors";
import { FontSize } from "../../../themes/sizes";

import BoxInput from "../../../components/input/BoxInput";
import DateInput from "../../../components/input/DateInput";
import ContainerView from "../../../components/main/ContainerView";
import HeroText from "../../../components/text/HeroText";
import MainMediumText from "../../../components/text/MainMediumText";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import LinkButton from "../../../components/buttons/LinkButton";
import fonts from "../../../themes/fonts";

import MainText from "../../../components/text/MainText";
import { clearError } from "../../../state/slices/goalSlice";
import { GlobalStyles } from "../../../themes/styles";

import { Goals } from "../../../api";
import { setIsSpinning } from "../../../state/slices/spinnerSlice";

const blankGoal = { type: "", action: "", frequency: "", outcome: "", target_at: "", shared_with_coach: false };

export default ({ navigation, route }) => {
    const dispatch = useDispatch();

    const loading = useSelector(state => state.goal.loading);
    const error = useSelector(state => state.goal.error);
    const goalData = useSelector(state => state.goal.data);
    const isCoach = useSelector(state => state.user.profile.isCoach);

    const isEdit = route.params?.edit || false;
    const [goal, setGoal] = React.useState(isEdit ? goalData : blankGoal);

    const [goalColor, setGoalColor] = React.useState(null);
    const [frequencyColor, setFrequencyColor] = React.useState(null);
    const [outcomeColor, setOutcomeColor] = React.useState(null);
    const [dateColor, setDateColor] = React.useState(null);

    React.useEffect(() => {
        return () => {
            dispatch(clearError());
        };
    }, []);

    const createGoal = () => {
        validateGoal() &&
            dispatch(setIsSpinning(true)) &&
            dispatch(Goals.createGoal({ goal: goal }))
                .then(() => {
                    dispatch(setIsSpinning(false));
                    navigation.goBack();
                });
    };

    const validateGoal = () => {
        if (goal.type === "" || goal.action === "" || goal.frequency === "" || goal.outcome === "" || goal.target_at === "") {
            alert("Please fill all the fields!");
            return false;
        };
        return true;
    };

    const updateGoal = () => {
        dispatch(clearError());
        validateGoal() &&
            dispatch(setIsSpinning(true)) &&
            dispatch(Goals.updateGoal({ goal: goal }))
                .then(() => {
                    dispatch(setIsSpinning(false));
                    navigation.goBack();
                });
    };

    return (
        <ContainerView hasHeader>
            <ScrollView>
                <View>
                    <TouchableOpacity onPress={() => { navigation.goBack(); }} style={{ position: "absolute", top: 20, left: 10, zIndex: 10 }}>
                        <EvilIcons name="chevron-left" size={42} color={Colors.DARK_GREY} />
                    </TouchableOpacity>
                    <Image
                        source={require("../../../assets/illustrations/set_goal.png")}
                        style={{ width: wp(100), height: wp(100) * 0.645 }}
                        resizeMode="cover"
                    />
                </View>
                <View style={GlobalStyles.containerPaddingH}>
                    <View style={{ marginBottom: SpacingH.s2 }}>
                        {isEdit ?
                            <View>
                                <HeroText>Edit goal</HeroText>
                                <MainMediumText style={{ marginTop: SpacingH.s0, marginBottom: SpacingH.s2, fontSize: FontSize.SMALLER }}>Created on {new Date(Date.parse(goal.createdAt)).toDateString()}</MainMediumText>
                            </View>
                            :
                            <View>
                                <HeroText>Set a goal</HeroText>
                                <MainMediumText style={{ marginTop: SpacingH.s0, marginBottom: SpacingH.s2, color: Colors.LIGHT_GREY, fontSize: FontSize.SMALLER }}>All fields are required</MainMediumText>
                            </View>
                        }
                        {error != "" && <MainText style={{ color: Colors.HEART_RED }}>{error}</MainText>}
                        <View style={[GlobalStyles.containerMarginH, {marginRight: 0, flexDirection: "row", justifyContent: "center", marginVertical: SpacingH.s1}]}>
                            <View style={styles.checkboxContainer}>
                                <BouncyCheckbox
                                    size={22}
                                    fillColor={Colors.TEXT_BLACK}
                                    unfillColor="#FFFFFF"
                                    text="Professional"
                                    textStyle={styles.checkboxItem}
                                    iconStyle={{ borderColor: Colors.TEXT_BLACK, borderRadius: 3, marginRight: "-9%" }}
                                    disableBuiltInState
                                    isChecked={goal.type === "professional"}
                                    onPress={() => { setGoal({ ...goal, type: "professional" }) }}
                                />
                            </View>
                            <View style={styles.checkboxContainer}>
                                <BouncyCheckbox
                                    size={22}
                                    fillColor={Colors.TEXT_BLACK}
                                    unfillColor="#FFFFFF"
                                    text="Educational"
                                    textStyle={styles.checkboxItem}
                                    iconStyle={{ borderColor: Colors.TEXT_BLACK, borderRadius: 3, marginRight: "-9%" }}
                                    disableBuiltInState
                                    isChecked={goal.type === "educational"}
                                    onPress={() => { setGoal({ ...goal, type: "educational" }) }}
                                />
                            </View>
                            <View style={styles.checkboxContainer}>
                                <BouncyCheckbox
                                    size={22}
                                    fillColor={Colors.TEXT_BLACK}
                                    unfillColor="#FFFFFF"
                                    text="Personal"
                                    textStyle={styles.checkboxItem}
                                    iconStyle={{ borderColor: Colors.TEXT_BLACK, borderRadius: 3, marginRight: "-9%" }}
                                    disableBuiltInState
                                    isChecked={goal.type === "personal"}
                                    onPress={() => { setGoal({ ...goal, type: "personal" }) }}
                                />
                            </View>
                        </View>
                        {isEdit && !goal.completed &&
                            <MainMediumText style={{ marginTop: SpacingH.s2, marginBottom: SpacingH.s0, color: Colors.LIGHT_GREY }}>Tap fields to edit</MainMediumText>
                        }
                        <MainMediumText style={{ marginBottom: SpacingH.s0, marginTop: SpacingH.s2, fontSize: FontSize.SMALLER }}>I will...</MainMediumText>
                        <BoxInput
                            style={{ ...styles.titleBox, borderColor: goalColor }}
                            placeholder="What specific action will you take?"
                            value={goal.action}
                            editable={!goal.completed}
                            onChangeText={(text) => { setGoal({ ...goal, action: text }) }}
                            onFocus={() => { setGoalColor(Colors.ELECTRIC_TEAL); }}
                            onBlur={() => { setGoalColor(Colors.LIGHT_GREY); }}
                            isSelfAdjusting
                        />
                        <MainMediumText style={{ marginBottom: SpacingH.s0, fontSize: FontSize.SMALLER }}>Every...</MainMediumText>
                        <BoxInput
                            style={{ ...styles.titleBox, borderColor: frequencyColor }}
                            placeholder="When and how often will you do it?"
                            value={goal.frequency}
                            editable={!goal.completed}
                            onChangeText={(text) => { setGoal({ ...goal, frequency: text }) }}
                            onFocus={() => { setFrequencyColor(Colors.ELECTRIC_TEAL); }}
                            onBlur={() => { setFrequencyColor(Colors.LIGHT_GREY); }}
                            isSelfAdjusting
                        />
                        <MainMediumText style={{ marginBottom: SpacingH.s0, fontSize: FontSize.SMALLER }}>In order to...</MainMediumText>
                        <BoxInput
                            style={{ ...styles.titleBox, borderColor: outcomeColor }}
                            placeholder="What growth or outcome will result?"
                            value={goal.outcome}
                            editable={!goal.completed}
                            onChangeText={(text) => { setGoal({ ...goal, outcome: text }) }}
                            onFocus={() => { setOutcomeColor(Colors.ELECTRIC_TEAL); }}
                            onBlur={() => { setOutcomeColor(Colors.LIGHT_GREY); }}
                            isSelfAdjusting
                        />
                        <MainMediumText style={{ marginBottom: SpacingH.s0, fontSize: FontSize.SMALLER }}>I will accomplish this by...</MainMediumText>
                        <DateInput
                            style={{ ...styles.titleBox, borderColor: dateColor }}
                            placeholder="Select a date"
                            value={goal.target_at == "" ? "" : new Date(Date.parse(goal.target_at)).toDateString()}
                            editable={!goal.completed}
                            onDateSet={(date) => { setGoal({ ...goal, target_at: date }) }}
                            minimumDate={Date.now()}
                            onFocus={() => { setDateColor(Colors.ELECTRIC_TEAL); }}
                            onBlur={() => { setDateColor(Colors.LIGHT_GREY); }} />
                        {!isCoach &&
                            <View style={{ marginVertical: SpacingH.s1 }}>
                                <BouncyCheckbox
                                    size={22}
                                    fillColor={Colors.TEXT_BLACK}
                                    unfillColor="#FFFFFF"
                                    text="Share with coach"
                                    textStyle={styles.checkboxItem}
                                    iconStyle={{ borderColor: Colors.TEXT_BLACK, borderRadius: 3 }}
                                    disableBuiltInState
                                    isChecked={goal.shared_with_coach === true}
                                    onPress={() => { setGoal({ ...goal, shared_with_coach: !goal.shared_with_coach }) }}
                                />
                            </View>
                        }
                    </View>
                    {isEdit ?
                        <View style={[styles.buttonContainer, { marginBottom: SpacingH.s2 }]}>
                            <PrimaryButton style={styles.postButton} buttonSize={'lg'} onPress={updateGoal}>Save</PrimaryButton>
                        </View>
                        :
                        <View style={styles.buttonContainer}>
                            <PrimaryButton style={styles.postButton} buttonSize={'lg'} onPress={createGoal}>Set it</PrimaryButton>
                        </View>
                    }
                </View>
            </ScrollView>
        </ContainerView>
    );
};

const styles = StyleSheet.create({
    checkboxContainer: {
        flexDirection: "row",
        alignItems: "center",
        width: Size.w30,
    },
    checkboxItem: {
        fontSize: FontSize.SMALLER,
        fontFamily: fonts.MainMedium,
        // fontWeight: "bold",
        color: Colors.TEXT_BLACK,
        textDecorationLine: "none",
    },
    titleBox: {
        marginBottom: SpacingH.s2,
        borderColor: Colors.ELECTRIC_TEAL,
    },
    buttonContainer: {
        alignItems: "center",
    },
    postButton: {
        marginBottom: SpacingH.s2
    },
});
