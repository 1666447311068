/**
 * LifeRamp
 *
 * @author      Anees Muzzafer
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

// import Modal from 'react-native-modal';
// import Modal from 'modal-react-native-web';
import Modal from '@m4ttheweric/modal-enhanced-react-native-web';

import { Colors } from "../../themes/colors";
import { hp, Size, SpacingH, SpacingW, wp } from "../../themes/sizes";
import MainMediumText from "../text/MainMediumText";
import { GlobalStyles } from "../../themes/styles";

export default ({ isModalVisible, setModalVisible, options }) => (
    <Modal
        style={{ margin: "auto", width: wp(100), maxWidth: wp(100), justifyContent: "flex-end" }}
        isVisible={isModalVisible}
        ariaHideApp={false}
        transparent={true}
        animationType="slide"
        backdropOpacity={0.3}
        onRequestClose={() => setModalVisible(false)}
        swipeDirection="down"
        onSwipeComplete={() => setModalVisible(false)}
        onBackdropPress={() => setModalVisible(false)}
        onBackButtonPress={() => setModalVisible(false)}>
        <View style={styles.modalWrapper}>
            <View style={styles.modalBar}></View>
            {
                options.map((option, i) => (
                    option.show === true &&
                    <TouchableOpacity key={i} onPress={() => {option.onPress(); setModalVisible(false); }} style={styles.option} activeOpacity={option.activeOpacity}>
                        <MainMediumText style={{ color: option.textColor || Colors.DARK_GREY }}>{option.text}</MainMediumText>
                        <View>{option.icon}</View>
                    </TouchableOpacity>
                ))
            }
        </View>
    </Modal>
);

const styles = StyleSheet.create({
    modalWrapper: {
        minHeight: hp("18%"),
        backgroundColor: Colors.WHITE,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        paddingBottom: SpacingH.s2
    },
    modalBar: {
        width: Size.w10,
        height: 5,
        backgroundColor: Colors.BLUE_GREY,
        alignSelf: "center",
        marginTop: SpacingH.s1,
        marginBottom: SpacingH.s1,
        borderRadius: 3
    },
    option: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingHorizontal: SpacingW.s5,
        paddingVertical: SpacingH.s2,
    },
});
