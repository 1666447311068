/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import config from "./config";
import user from "./user";
import circle from "./circle";
import posts from "./posts";
import resources from "./resources";
import goals from "./goals";
import coachingSessions from "./coachingSessions";

axios.defaults.baseURL = config.apiBase;

export const setAuthToken = (token) => { axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; }

export const User = user;
export const Circle = circle;
export const Posts = posts;
export const Resources = resources;
export const Goals = goals;
export const CoachingSessions = coachingSessions;
