/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { Alert, FlatList, StyleSheet, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { AntDesign, MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";

import { GlobalStyles } from "../../themes/styles";
import { Colors, Gradients } from "../../themes/colors";
import { Size, SpacingH, SpacingW } from "../../themes/sizes";
import NoticeCard from "../../components/cards/NoticeCard";
import CircleButton from "../../components/buttons/CircleButton";
import PlaceHolder from "../../components/utils/PlaceHolder";

import LikeIcon from '../../components/icons/LikeIcon';


import { Circle, Posts, User } from "../../api";
import { clearOtherUser } from "../../state/slices/userSlice";
import { setPost } from "../../state/slices/circleSlice";
import { addPostReaction, removePostReaction } from "../../state/slices/circleSlice";
import { setIsSpinning } from "../../state/slices/spinnerSlice";

import SlideUpModal from "../../components/utils/SlideUpModal";

export default ({ navigation }) => {
    const dispatch = useDispatch();
    const circle = useSelector(state => state.circle);
    const user = useSelector(state => state.user.profile);

    const [isModalVisible, setModalVisible] = React.useState(false);
    const [selectedPostUuid, setSelectedPostUuid] = React.useState(null);
    const selectedPost = circle.notices.length && circle.notices.find(p => p.uuid === selectedPostUuid);
    const hasLiked = selectedPost && selectedPost.reactions.find(r => r.user.uuid == user.uuid && r.type == "like");

    React.useEffect(() => {
        dispatch(Circle.refreshNotices({ uuid: circle.uuid }));
    }, []);

    const loadMore = () => {
        if (circle.loading) return;
        circle.noticePage <= circle.noticeMaxPage && dispatch(Circle.getNotices({ uuid: circle.uuid, page: circle.noticePage }));
    };

    const onPostPress = (uuid) => {
        dispatch(setPost({ uuid, type: "notice" }));
        setTimeout(() => navigation.navigate("CircleViewNotice"), 0);
    };

    const onUserPress = (uuid) => {
        dispatch(clearOtherUser());
        dispatch(User.getProfile({ uuid }));
        dispatch(User.refreshPosts({ uuid }))
        navigation.navigate("ViewProfile", { uuid });
    };

    const deletePost = () => {
        dispatch(setIsSpinning(true));
        dispatch(Posts.deletePost({ post: selectedPost }))
            .then(() => {
                setModalVisible(false);
                dispatch(setIsSpinning(false));
            });
    };

    const handleLikePress = () => {
        const reactionType = "like";

        if (hasLiked) {
            dispatch(removePostReaction({ uuid: selectedPost.uuid, type: reactionType, user, postType: "notice" }))
            dispatch(Posts.removeReaction({ uuid: selectedPost.uuid }));
        } else {
            dispatch(addPostReaction({ uuid: selectedPost.uuid, type: reactionType, user, postType: "notice" }))
            dispatch(Posts.reactToPost({ uuid: selectedPost.uuid, type: reactionType }));
        }
    };

    return (
        <View style={{ flex: 1, backgroundColor: Colors.WHITE }}>
            <FlatList
                style={[GlobalStyles.optionalPaddingH, { marginBottom: SpacingH.s1, flex: 1 }]}
                data={circle.notices}
                onRefresh={() => dispatch(Circle.refreshNotices({ uuid: circle.uuid }))}
                refreshing={circle.noticesRefreshing || circle.loading}
                onEndReached={loadMore}
                renderItem={({ item }) => <NoticeCard  {...item} circleShortName={circle.data.institution.shortname} key={item.uuid} style={{ marginVertical: SpacingH.s1, flex: 1 }} onUserPress={onUserPress} onPostPress={onPostPress} onKebabPress={() => {
                    setModalVisible(true);
                    setSelectedPostUuid(item.uuid);
                }} />}
                ListFooterComponent={<View style={{ height: Size.h15 }} />}
                ListEmptyComponent={() => (<PlaceHolder status={(circle.noticesRefreshing || circle.loading)} type={"postCard"} />)}
                keyExtractor={item => item.uuid}
            />
            {user.canManageInstitution &&
                <LinearGradient start={[0.5, 0]} end={[0.5, 0.9]} colors={[Gradients.TRANSPARENT.Start, Gradients.TRANSPARENT.End]} style={{ position: "absolute", bottom: 0, left: 0, width: "100%", paddingTop: SpacingH.s0 }}>
                    <CircleButton onPress={() => { navigation.navigate("CircleAddEditPost", { postType: "notice" }) }} style={GlobalStyles.circleAddButton}>
                        <AntDesign name="plus" size={36} color={Colors.NAVY} />
                    </CircleButton>
                </LinearGradient>
            }

            <SlideUpModal
                isModalVisible={isModalVisible}
                setModalVisible={setModalVisible}
                options={[
                    {
                        show: false,
                        text: hasLiked ? "Unlike" : "Like",
                        onPress: handleLikePress,
                        icon: <LikeIcon width={18} height={15} onPress={handleLikePress} isLiked={hasLiked} />,
                    },
                    {
                        show: true,
                        text: "View",
                        onPress: () => {
                            setModalVisible(false);
                            onPostPress(selectedPost.uuid);
                        },
                        icon: <MaterialCommunityIcons name="view-array-outline" size={20} color={Colors.DARK_GREY} />,
                    },
                    {
                        show: selectedPost && selectedPost.user.uuid === user.uuid,
                        text: "Edit",
                        onPress: () => {
                            const post = selectedPost;
                            navigation.navigate("CircleAddEditPost", { post: { uuid: post.uuid, title: post.title, content: post.content, image: post.image, tags: post.tags.map(t => t.name)}, postType: "notice" })
                        },
                        icon: <MaterialIcons name="edit" size={20} color={Colors.DARK_GREY} />,
                    },
                    {
                        show: selectedPost && selectedPost.user.uuid === user.uuid,
                        text: "Delete",
                        textColor: Colors.HEART_RED,
                        onPress: () => {
                            Alert.alert(
                                "Delete Post?",
                                "This action is irreversible.",
                                [
                                    {
                                        text: "Delete",
                                        onPress: deletePost,
                                        style: "destructive"
                                    },
                                    {
                                        text: "Cancel",
                                        onPress: () => console.log("Cancel Pressed"),
                                        style: "cancel"
                                    },
                                ]
                            );
                        },
                        icon: <MaterialIcons name="delete" size={20} color={Colors.HEART_RED} />,
                    },
                    {
                        show: false,
                        text: "Report",
                        onPress: () => { setModalVisible(false); navigation.navigate("Report", { postUUID: selectedPost.uuid }); },
                        icon: <MaterialIcons name="report" size={20} color={Colors.DARK_GREY} />,
                    },
                ]}
            />


        </View>
    );
};

const styles = StyleSheet.create({
    modalWrapper: {
        height: Size.h40,
        backgroundColor: Colors.WHITE,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
    },
    modalBar: {
        width: Size.w10,
        height: 5,
        backgroundColor: Colors.BLUE_GREY,
        alignSelf: "center",
        marginTop: SpacingH.s1,
        marginBottom: SpacingH.s5,
        borderRadius: 3
    },
    option: {
        paddingHorizontal: SpacingW.s5,
        paddingVertical: SpacingH.s2,
    }
});
