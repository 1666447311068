/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { Platform, StyleSheet, TextInput } from "react-native";

import { Colors } from "../../themes/colors";
import { FontSize } from "../../themes/sizes";
import Fonts from "../../themes/fonts";

export default (props) => {
    const [currentHeight, setCurrentHeight] = React.useState(30);

    return (
        <TextInput
            maxLength={5000}
            {...props}
            textBreakStrategy="highQuality"
            style={[styles.boxInput, props.style, {
                height: props.isSelfAdjusting ? Math.max(44, currentHeight + 10) : 44, paddingVertical: currentHeight > 130 ? 4 : 0, paddingTop: Platform.OS == "ios" && props.isSelfAdjusting ? 10 : 0,
            }]}
            multiline={props.isSelfAdjusting ? true : false}
            onContentSizeChange={(event) => {
                if (event.nativeEvent.contentSize.height < 140) {
                    setCurrentHeight(event.nativeEvent.contentSize.height);
                } else {
                    setCurrentHeight(140);
                }
            }}
        />
    );
};

const styles = StyleSheet.create({
    boxInput: {
        paddingHorizontal: 12,
        color: Colors.TEXT_PRIMARY,
        fontSize: FontSize.NORMAL,
        borderColor: Colors.TEXT_BLACK,
        borderRadius: 8,
        borderWidth: 1,
        fontFamily: Fonts.Main,
    },
});
