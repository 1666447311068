/**
 * LifeRamp
 *
 * @author      Chetanya Gupta
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import datetime from "date-and-time";

import { FontSize, SpacingH, Size, SpacingW } from "../../themes/sizes";
import { Colors } from "../../themes/colors";
import MainMediumText from "../text/MainMediumText";
import MainText from "../text/MainText";
import ProgressBar from "../bars/ProgressBar";

const todayTS = (new Date()).getTime();

export default ({ style, onPress, action, type, createdAt, target_at }) => {
    const creationTS = (new Date(createdAt)).getTime();
    const targetTS = (new Date(target_at)).getTime();
    const progress = (todayTS - creationTS) / ((targetTS - creationTS) || 1);

    return (
        <TouchableOpacity onPress={onPress} style={[styles.card, style]}>
            <View style={styles.container}>
                <MainMediumText style={styles.subtitleText}>{type}</MainMediumText>
                <MainMediumText style={styles.titleText} numberOfLines={2}>{action}</MainMediumText>

                <View style={styles.progressBarContainer}>
                    <ProgressBar
                        currentProgress={todayTS - creationTS}
                        totalProgress={targetTS - creationTS}
                        animDuration={3000}
                        style={styles.progressBar}
                        color={Colors.DEEP_TEAL} />
                </View>

                <View style={{ flexDirection: "row" }}>
                    <MainText style={styles.leftDate}>Set on</MainText>
                    <MainText style={styles.rightDate}>To be completed on</MainText>
                </View>

                <View style={{ flexDirection: "row" }}>
                    <MainText style={styles.leftDate}>{datetime.format(new Date(createdAt), "ddd, MMM D")}</MainText>
                    <MainText style={styles.rightDate}>{datetime.format(new Date(target_at), "ddd, MMM D")}</MainText>
                </View>
            </View>
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    card: {
        borderRadius: 2,
        backgroundColor: Colors.SOFT_GREY,
        marginVertical: SpacingH.s1,
        paddingVertical: SpacingH.s2,
    },
    container: {
        justifyContent: "center",
        marginHorizontal: SpacingW.s4,
        paddingHorizontal: SpacingW.s5,
    },
    progressBarContainer: {
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: Colors.WHITE,
        color: Colors.LIGHT_GREY,
        height: SpacingW.s2,
        borderRadius: SpacingW.s2 / 4,
        marginBottom: SpacingH.s2,
    },
    progressBar: {
        height: SpacingW.s2,
        borderRadius: SpacingW.s2 / 4,
    },
    titleText: {
        color: Colors.BLACK,
        fontSize: FontSize.NORMAL,
        marginBottom: SpacingH.s2,
    },
    subtitleText: {
        color: Colors.BLACK,
        fontSize: FontSize.SMALLER,
        textTransform: "uppercase",
        marginBottom: SpacingH.s2,
    },
    leftDate: {
        color: Colors.BLACK,
        fontSize: FontSize.SMALLER,
    },
    rightDate: {
        flex: 1,
        color: Colors.BLACK,
        textAlign: "right",
        fontSize: FontSize.SMALLER,
    }
});
