/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { KeyboardAvoidingView, Platform, Keyboard, FlatList, BackHandler, Alert } from "react-native";
import { useSelector, useDispatch } from "react-redux";

import "firebase/database";
import "firebase/storage";

import { SpacingH, SpacingW, Utils } from "../../themes/sizes";

import ContainerView from "../../components/main/ContainerView";
import PlaceHolder from "../../components/utils/PlaceHolder";
import ChatInput from "../../components/input/ChatInput";
import ChatBubble from "../../components/utils/ChatBubble";

import {
    sendTextMessage,
    sendImageMessage,
    sendAudioMessage,
    sendVideoMessage,
    sendDocumentMessage,
    messagesRef,
    markAsRead,
} from "../../chat";

import { setChatWithUser, setMessages, newMessage, updateMessage, delMessage, setCoachUnreadCount, resetMessages, setCurrentThreadId } from "../../state/slices/chatSlice";
import { User } from "../../api";
import { markNotificationsRead } from "../../notifications";

export default ({ navigation, route }) => {
    markNotificationsRead();

    const dispatch = useDispatch();
    let chatFlatList = React.useRef();

    const [loading, setLoading] = React.useState(true);
    const [refreshing, setRefreshing] = React.useState(false);
    const [didJustRefresh, setDidJustRefresh] = React.useState(false);
    const [firstMessageTs, setFirstMessageTs] = React.useState(null);

    const scrollToEnd = () => { !didJustRefresh && chatFlatList.current && chatFlatList.current.scrollToEnd({ animated: true }); };
    const scrollToTop = () => { chatFlatList.current && chatFlatList.current.scrollToOffset({ animated: true, offset: 0 }); };
    React.useEffect(() => {
        scrollToEnd();

        let keyboardOpenListener = Keyboard.addListener("keyboardDidShow", () => scrollToEnd());
        return () => { keyboardOpenListener.remove() };
    }, [chatFlatList]);

    const state = useSelector(state => state.chat);
    const user = useSelector(state => state.user.profile);
    const chatList = useSelector(state => state.chat.messages);

    React.useEffect(() => {
        dispatch(resetMessages());
        dispatch(setChatWithUser({ user: route.params?.user }));
    }, []);

    const threadId = route.params?.threadId;
    const isCoach = route.params?.isCoach || false;

    const resetCurrentThreadId = () => { dispatch(setCurrentThreadId(null)); return false; };
    React.useEffect(() => {
        dispatch(setCurrentThreadId(threadId));
        const backHandler = BackHandler.addEventListener("hardwareBackPress", resetCurrentThreadId);

        return () => {
            backHandler.remove();
            resetCurrentThreadId();
        };
    }, [threadId]);

    React.useEffect(() => {
        messagesRef(threadId).orderByChild("sentAt").limitToLast(15).once("value", recentSnapshot => {
            let messages = [];
            let isFirst = true;
            recentSnapshot.forEach(mV => {
                const m = mV.val();
                messages.push(m);

                if (isFirst) {
                    setFirstMessageTs(m.sentAt);
                }

                // Mark as read
                if (m.sender != user.uuid) {
                    markAsRead(threadId, m);
                }
            });

            dispatch(setMessages({ messages }));
            setLoading(false);

            messagesRef(threadId).orderByChild("sentAt").startAt(Date.now()).on("child_added", m => dispatch(newMessage({ message: m.val() })));

            messagesRef(threadId).on("child_changed", m => dispatch(updateMessage({ message: m.val() })));
            messagesRef(threadId).on("child_removed", m => dispatch(delMessage({ message: m.val() })));

            isCoach && dispatch(setCoachUnreadCount(0));
        });

        return () => { messagesRef(threadId).off(); };
    }, []);

    const loadPreviousMessages = () => {
        setRefreshing(true);
        messagesRef(threadId).orderByChild("sentAt").endAt(firstMessageTs).limitToLast(15).once("value", recentSnapshot => {
            let messages = [];
            let isFirst = true;
            recentSnapshot.forEach(mV => {
                const m = mV.val();

                if (!chatList.find(o => o.id === m.id)) {
                    messages.push(m);
                }

                if (isFirst) {
                    setFirstMessageTs(m.sentAt);
                    isFirst = false;
                }

                // Mark as read
                if (m.sender != user.uuid) {
                    markAsRead(threadId, m);
                }
            });

            dispatch(setMessages({ messages: [...messages, ...chatList] }));
            setRefreshing(false);
            setDidJustRefresh(true);
            setTimeout(() => { scrollToTop(); }, 500);
        });
    };

    const likeMessage = (m) => {
        if (!m.likedBy) {
            m.sender !== user.uuid && dispatch(User.chatNotify({ type: "like", threadId, uuid: m.sender }));
            return messagesRef(threadId).child(m.id).set({ ...m, likedBy: [user.uuid] });
        }

        if (m.likedBy.includes(user.uuid)) {
            return messagesRef(threadId).child(m.id).set({ ...m, likedBy: m.likedBy.filter(uuid => uuid !== user.uuid) });
        } else {
            m.sender !== user.uuid && dispatch(User.chatNotify({ type: "like", threadId, uuid: m.sender }));
            return messagesRef(threadId).child(m.id).set({ ...m, likedBy: [...m.likedBy, user.uuid] });
        }
    };

    const deleteMessage = (m) => {
        if (m.sender != user.uuid) return;
        messagesRef(threadId).child(m.id).remove();
    };

    const newMessageHandler = text => { sendTextMessage(threadId, text, user, dispatch, route.params?.user); };
    const newAudioHandler = audio => { sendAudioMessage(threadId, audio.uri, user, { duration: audio.duration }, dispatch, route.params?.user); };
    const newImageCaptureHandler = image => { sendImageMessage(threadId, image.uri, user, { width: image.width, height: image.height }, dispatch, route.params?.user); };
    const newVideoCaptureHandler = uri => { sendVideoMessage(threadId, uri, user, dispatch, route.params?.user); };
    const newDocumentHandler = doc => { sendDocumentMessage(threadId, doc.uri, user, { type: doc.type, name: doc.name, size: doc.size }, dispatch, route.params?.user); };

    const onPressImage = (uri, imageSize) => { navigation.navigate("FullscreenImage", { uri, imageSize }); };

    return (
        <ContainerView style={{ flex: 1, paddingBottom: SpacingH.s1, paddingHorizontal: 0, position: "relative" }} hasHeader>
            <KeyboardAvoidingView keyboardVerticalOffset={Utils.keyboardAvoidOffset} style={{ flex: 1 }} behavior={Platform.OS == "ios" ? "padding" : null}>
                <FlatList
                    onContentSizeChange={() => scrollToEnd()}
                    style={{ flex: 1, paddingHorizontal: SpacingW.s1 }}
                    data={chatList}
                    onRefresh={() => { loadPreviousMessages() }}
                    refreshing={refreshing}
                    ref={chatFlatList}
                    onEndReached={() => setDidJustRefresh(false)}
                    renderItem={({ item }) =>
                        <ChatBubble
                            withUser={state.chatWithUser}
                            direction={item.sender == user.uuid ? "send" : "receive"}
                            type={item.type}
                            isLiked={item.likedBy && item.likedBy.length}
                            likedBy={item.likedBy}
                            sentAt={item.sentAt}
                            onPressImage={onPressImage}
                            imageSource={item.imageSource}
                            videoSource={item.videoSource}
                            audioSource={item.audioSource}
                            documentSource={item.documentSource}
                            info={item.info}
                            onPress={() => { likeMessage(item) }}
                            onLongPress={()  => { deleteMessage(item) }}>
                            {item.text}
                        </ChatBubble>
                    }
                    ListEmptyComponent={() => (<PlaceHolder status={loading} type={"peopleCard"} emptyMessage={"Go ahead and say hi!"} />)}
                    keyExtractor={item => item.id}
                />
                <ChatInput
                    placeholder="Write a message..."
                    style={{ marginHorizontal: SpacingW.s2, width: "96%" }}
                    newMessage={newMessageHandler}
                    newAudio={newAudioHandler}
                    newImage={newImageCaptureHandler}
                    newVideo={newVideoCaptureHandler}
                    newDocument={newDocumentHandler}
                />
            </KeyboardAvoidingView>
        </ContainerView>
    );
}
