/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

export default {
    Title: 'Fraunces',
    TitleSemiBold: 'Fraunces-SemiBold',
    Main: 'Montserrat',
    MainRegular: 'Montserrat-Regular',
    MainMedium: 'Montserrat-Medium',
    MainBold: 'Montserrat-SemiBold',
};
