/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import Constants from "expo-constants";
import * as Notifications from "expo-notifications";

import { store } from "../state/store";

Notifications.setNotificationHandler({
    handleNotification: async (n) => ({
        shouldShowAlert: store.getState().user.notificationsEnabled && (n.request.content.data.type !== "chat" || store.getState().chat.currentThreadId !== n.request.content.data.threadId),
        shouldPlaySound: false,
        shouldSetBadge: false,
    }),
});

export const registerForPushNotificationsAsync = async () => {
    let token;

    if (Constants.isDevice && Platform.OS !== "web") {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();

        let finalStatus = existingStatus;
        if (existingStatus !== "granted") {
            const { status } = await Notifications.requestPermissionsAsync({
                ios: {
                    allowAlert: true,
                    allowBadge: true,
                    allowSound: true,
                    allowAnnouncements: true,
                },
            });
            finalStatus = status;
        }

        if (finalStatus !== "granted") {
            alert("Please allow push notifications to get message and activity notifications!");
            return;
        }

        token = (await Notifications.getExpoPushTokenAsync()).data;
    } else {
        console.log("Must use physical device for Push Notifications");
    }

    if (Platform.OS === "android") {
        Notifications.setNotificationChannelAsync("default", {
            name: "default",
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
            lightColor: "#FF231F7C",
        });
    }

    // console.log("Token: ", token);
    return token;
};

export const NotificationTypes = {
    CHAT: "chat",

    POST_LIKED: "post-liked",

    COMMENT: "comment",
    COMMENT_LIKED: "comment-liked",
};

export const notificationReceivedHandler = async (notification) => {
    // console.log("Notification received.", notification);
};

export const markNotificationsRead = () => {
    Notifications.setBadgeCountAsync(0);
    store.dispatch(User.markNotificationsRead());
};

import { navigate } from "../navigation/navigator";
import { setPost } from "../state/slices/circleSlice";
import { Platform } from "react-native";
import { User, Posts } from "../api";

export const notificationResponseHandler = (response) => {
    // console.log("Response", response);
    markNotificationsRead();

    const notificationData = response.notification.request.content.data;
    switch (notificationData.type) {
        case NotificationTypes.CHAT:
            navigate("ChatStackScreen", { screen: "Chat", params: { threadId: notificationData.threadId, user: notificationData.user } });
            break;
        case NotificationTypes.POST_LIKED:
        case NotificationTypes.COMMENT:
        case NotificationTypes.COMMENT_LIKED:
            if (!store.getState().circle.posts.find(p => p.uuid == notificationData.postUuid)) {
                store.dispatch(Posts.getPost({ uuid: notificationData.postUuid })).then(() => {
                    store.dispatch(setPost({ uuid: notificationData.postUuid }));
                    navigate("CircleStackScreen", { screen: "CircleViewPost" });
                });
            } else {
                store.dispatch(setPost({ uuid: notificationData.postUuid }));
                navigate("CircleStackScreen", { screen: "CircleViewPost" });
            }
            break;
        default:
            console.warn("Not handling: ", notificationData);
            break;
    }
};

export const registerNotificationHandlers = () => {
    Notifications.addNotificationReceivedListener(notificationReceivedHandler);
    Notifications.addNotificationResponseReceivedListener(notificationResponseHandler);
};
