/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, View } from "react-native";
import { SpacingW, SpacingH} from "../../themes/sizes";

import { Colors } from "../../themes/colors";

export default ({ style, children }) => (
    <View style={[styles.card, style]}>{children}</View>
);

const styles = StyleSheet.create({
    card: {
        backgroundColor: Colors.SOFT_GREY,
        paddingHorizontal: SpacingW.s5,
        paddingVertical: SpacingH.s3,
        borderRadius: 5,
    },
});
