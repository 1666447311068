/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { Text, StyleSheet } from "react-native";
import Fonts from "../../themes/fonts";
import { FontSize } from "../../themes/sizes";
import { GlobalStyles } from "../../themes/styles";

export default (props) => (
    <Text {...props} style={[GlobalStyles.text, styles.textStyle, props.style]}>{props.children}</Text>
);

const styles = StyleSheet.create({
    textStyle: {
        fontFamily: Fonts.Main,
        fontSize: FontSize.NORMAL,
    },
});
