/**
 * LifeRamp
 *
 * @author      Ummar Hamid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, StyleSheet, KeyboardAvoidingView, ScrollView, TouchableOpacity, Platform, ImageBackground, Image, Alert } from "react-native";
import { MaterialCommunityIcons, Feather, EvilIcons } from "@expo/vector-icons";
import * as ImagePicker from "expo-image-picker";
import mime from "mime";

import firebase from "firebase/app";

import { FontSize, SpacingH } from "../../../themes/sizes";
import { Colors } from "../../../themes/colors";
import ContainerView from "../../../components/main/ContainerView";

import BoxInput from "../../../components/input/BoxInput";

import HeroSemiText from "../../../components/text/HeroSemiText";
import MainMediumText from "../../../components/text/MainMediumText";
import LinkButton from "../../../components/buttons/LinkButton"

import { User } from "../../../api";
import { setIsSpinning } from "../../../state/slices/spinnerSlice";

export default ({ navigation }) => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.user.loading);
    const [shouldUpdate, setShouldUpdate] = React.useState(false);
    const userProfile = useSelector(state => state.user.profile);
    const [userFirstNameError, setUserFirstNameError] = React.useState(false);
    const [userLastnameError, setUserLastnameError] = React.useState(false);
    const [userImageError, setUserImageError] = React.useState(false);
    const [coverImageError, setCoverImageError] = React.useState(false);

    const [profile, setProfile] = React.useState({
        firstname: userProfile.firstname,
        lastname: userProfile.lastname,
        role: userProfile.role,
        pronouns: userProfile.pronouns,
        about: userProfile.about,
        image_profile: userProfile.image_profile,
        phone: userProfile.phone,
        education: userProfile.education,
        email: userProfile.email,
        headline: userProfile.headline,
        bio: userProfile.bio,
        image_cover: userProfile.image_cover,
    });
    const pickImage = async (flag) => {
        const gotPermission = await (async () => {
            if (Platform.OS !== "web") {
                const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
                if (status !== "granted") {
                    alert("The camera roll permission is needed to add an image!");
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        })();

        if (!gotPermission) {
            return;
        }

        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            quality: 0.8,
            aspect: flag ? [1, 1] : [136, 100],
        });

        if (!result.cancelled) {
            if (flag) {
                setProfile({ ...profile, image_profile: result.uri });
            } else {
                setProfile({ ...profile, image_cover: result.uri });
            }
        };
    };

    const saveProfile = async () => {
        if (loading) return;
        if (!userFirstNameError && !userLastnameError) {
            dispatch(setIsSpinning(true));

            if (profile.image_cover && profile.image_cover.startsWith("file")) {
                const uri = profile.image_cover;
                const mimeType = mime.getType(uri);
                const ref = firebase.storage().ref("user-images/" + userProfile.uuid + "/cover." + mime.getExtension(mimeType));

                const response = await fetch(uri);
                const blob = await response.blob();
                if (blob.size <= (5 * 1024 * 1024)) {
                    await ref.put(blob, { contentType: mimeType });
                    const url = await ref.getDownloadURL();

                    setProfile({ ...profile, image_cover: url });
                } else {
                    setCoverImageError(true);
                    return;
                }
            }

            if (profile.image_profile && profile.image_profile.startsWith("file")) {
                const uri = profile.image_profile;
                const mimeType = mime.getType(uri);
                const ref = firebase.storage().ref("user-images/" + userProfile.uuid + "/profile." + mime.getExtension(mimeType));

                const response = await fetch(uri);
                const blob = await response.blob();
                if (blob.size <= (5 * 1024 * 1024)) {
                    await ref.put(blob, { contentType: mime.getType(uri) });
                    const url = await ref.getDownloadURL();

                    setProfile({ ...profile, image_profile: url });
                    setShouldUpdate(true);
                } else {
                    setUserImageError(true);
                    return;
                }
            } else {
                setShouldUpdate(true);
            }
        }
    };

    const firstNameHandler = (value) => {
        setProfile({ ...profile, firstname: value });
        setUserFirstNameError(!value.length);
    }

    const lastNameHandler = (value) => {
        setProfile({ ...profile, lastname: value });
        setUserLastnameError(!value.length);
    }

    const showAlert = (message, type = "Error") =>
        Alert.alert(
            type,
            message,
            [{ text: "Ok", style: "cancel", }]
        );

    React.useEffect(() => {
        if (shouldUpdate) {
            dispatch(User.updateProfile({ profile: profile })).then((response) => {

                if (!response.payload?.errors) {
                    navigation.goBack();
                } else {
                    for (let k in response.payload.errors) {
                        if (response.payload.errors.hasOwnProperty(k)) {
                            showAlert(response.payload.errors[k][0], "Error");
                            break;
                        }
                    }
                }

                dispatch(setIsSpinning(false));
            });

            setShouldUpdate(false);
        }
    }, [shouldUpdate]);

    return (
        <ContainerView>
            <View style={styles.header}>
                <TouchableOpacity onPress={() => { navigation.goBack(); }}>
                    <EvilIcons name="chevron-left" size={42} color={Colors.BLUE_GREY} />
                </TouchableOpacity>
                <HeroSemiText style={{ textAlign: "center", fontSize: FontSize.BIG }}>Edit Profile</HeroSemiText>
                <TouchableOpacity onPress={saveProfile}>
                    <Feather name="check" size={36} color={Colors.DEEP_TEAL} />
                </TouchableOpacity>
            </View>
            <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS == "ios" ? "padding" : null}>
                <ScrollView>
                    <View style={styles.profileImage}>
                        <MainMediumText style={styles.errorMsg}>{userImageError ? "*Please choose an image less than 5MB" : ""}</MainMediumText>
                        <TouchableOpacity onPress={() => pickImage(true)}>
                            <ImageBackground source={{ uri: profile.image_profile }} style={{ width: 150, height: 150, alignItems: "flex-end" }} imageStyle={{ borderRadius: 80, marginBottom: SpacingH.s2 }}>
                                <MaterialCommunityIcons name="image-edit-outline" size={24} color={Colors.BLACK} />
                            </ImageBackground>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.inputArea}>
                        <View style={styles.nameInput}>
                            <View style={{ flex: 1, paddingRight: SpacingH.s1 }}>
                                <MainMediumText style={styles.errorMsg}>{userFirstNameError ? "*Please fill in your first name" : ""}</MainMediumText>
                                <MainMediumText style={styles.inputLabel}>First name</MainMediumText>
                                <BoxInput
                                    maxLength={500}
                                    value={profile.firstname}
                                    onChangeText={firstNameHandler}
                                />
                            </View>
                            <View style={{ flex: 1, paddingLeft: SpacingH.s1 }} >
                                <MainMediumText style={styles.errorMsg}>{userLastnameError ? "*Please fill in your last name" : ""}</MainMediumText>
                                <MainMediumText style={styles.inputLabel}>Last name</MainMediumText>
                                <BoxInput
                                    maxLength={500}
                                    value={profile.lastname}
                                    onChangeText={lastNameHandler}
                                />
                            </View>
                        </View>
                        <View style={styles.otherInputs}>
                            <MainMediumText style={styles.inputLabel}>Role</MainMediumText>
                            <BoxInput
                                maxLength={500}
                                value={profile.role}
                                onChangeText={(value) => setProfile({ ...profile, role: value })}
                            />
                        </View>
                        <View style={styles.nameInput}>
                            <View style={{ flex: 1, paddingRight: SpacingH.s1 }}>
                                <MainMediumText style={styles.inputLabel}>Pronouns</MainMediumText>
                                <BoxInput
                                    maxLength={500}
                                    value={profile.pronouns}
                                    maxLength={20}
                                    onChangeText={(value) => setProfile({ ...profile, pronouns: value })}
                                />
                            </View>
                            <View style={{ flex: 1, paddingLeft: SpacingH.s1 }} >
                                <MainMediumText style={styles.inputLabel}>Phone number</MainMediumText>
                                <BoxInput
                                    maxLength={500}
                                    value={profile.phone}
                                    onChangeText={(value) => setProfile({ ...profile, phone: value })}
                                    maxLength={10}
                                    keyboardType={"phone-pad"}
                                />
                            </View>
                        </View>
                        <View style={styles.otherInputs}>
                            <MainMediumText style={styles.inputLabel}>Education</MainMediumText>
                            <BoxInput
                                maxLength={500}
                                value={profile.education}
                                onChangeText={(value) => setProfile({ ...profile, education: value })}
                                isSelfAdjusting
                            />
                        </View>
                        <View style={styles.otherInputs}>
                            <MainMediumText style={styles.inputLabel}>About me</MainMediumText>
                            <BoxInput
                                maxLength={500}
                                value={profile.about}
                                onChangeText={(value) => setProfile({ ...profile, about: value })}
                                multiline={true}
                                isSelfAdjusting
                            />
                        </View>
                        <View style={styles.otherInputs}>
                            <MainMediumText style={styles.inputLabel}>Email</MainMediumText>
                            <BoxInput
                                maxLength={500}
                                style={{ backgroundColor: Colors.SOFT_GREY }}
                                value={profile.email}
                                editable={false}
                            />
                        </View>
                        {userProfile.isCoach && <>
                            <View style={styles.otherInputs}>
                                <MainMediumText style={styles.inputLabel}>Headline</MainMediumText>
                                <BoxInput
                                    maxLength={500}
                                    value={profile.headline}
                                    onChangeText={(value) => setProfile({ ...profile, headline: value })}
                                    isSelfAdjusting
                                />
                            </View>
                            <View style={styles.otherInputs}>
                                <MainMediumText style={styles.inputLabel}>Bio</MainMediumText>
                                <BoxInput
                                    maxLength={2000}
                                    value={profile.bio}
                                    onChangeText={(value) => setProfile({ ...profile, bio: value })}
                                    isSelfAdjusting
                                />
                            </View>
                            <View style={styles.otherInputs}>
                                <MainMediumText style={styles.errorMsg}>{coverImageError ? "*Please choose an image less than 5MB" : ""}</MainMediumText>
                                <MainMediumText style={styles.inputLabel}>Cover image</MainMediumText>
                                <Image source={{ uri: profile.image_cover }} style={{ height: 150, borderRadius: 5 }} />
                                <View style={{ alignItems: "center", marginVertical: SpacingH.s0 }}>
                                    <LinkButton onPress={() => pickImage(false)} textStyle={{ alignContent: "center", color: Colors.BLUE_GREY }}>Change</LinkButton>
                                </View>
                            </View>
                        </>}
                    </View>
                </ScrollView>
            </KeyboardAvoidingView>
        </ContainerView >
    );
}

const styles = StyleSheet.create({
    header: {
        flexDirection: "row",
        padding: SpacingH.s1,
        alignItems: "center",
        justifyContent: "space-between",
        marginHorizontal: SpacingH.s1,
    },
    profileImage: {
        flexDirection: "column",
        alignItems: "center"
    },
    nameInput: {
        flexDirection: "row",
        marginBottom: SpacingH.s1,
    },
    inputArea: {
        marginHorizontal: SpacingH.s2,
        marginTop: SpacingH.s2,
    },
    otherInputs: {
        marginVertical: SpacingH.s1,
    },
    errorMsg: {
        color: Colors.HEART_RED,
        fontSize: FontSize.VERY_SMALL
    },
    inputLabel: {
        color: Colors.BLUE_GREY,
        paddingBottom: SpacingH.s0
    }
});
