/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import CircleHeader from "../components/navigation/CircleHeader";
import Circle from "../screens/circle/Circle";
import CircleViewPost from "../screens/circle/posts/ViewPost"
import CircleViewNotice from "../screens/circle/posts/ViewNotice"
import CircleAddEditPost from "../screens/circle/posts/AddEditPost";

const CircleStack = createStackNavigator();
const CircleStackScreen = () => (
    <CircleStack.Navigator
        headerMode="screen">
        <CircleStack.Screen
            name="Circle"
            component={Circle}
            options={({ navigation }) => ({ header: ({ navigation }) => (<CircleHeader navigation={navigation} />) })}
        />
        <CircleStack.Screen
            name="CircleViewPost"
            component={CircleViewPost}
            options={({ navigation }) => ({ header: ({ navigation }) => (<CircleHeader navigation={navigation} />), title: 'Circle Post' })}
        />
        <CircleStack.Screen
            name="CircleViewNotice"
            component={CircleViewNotice}
            options={({ navigation }) => ({ header: ({ navigation }) => (<CircleHeader navigation={navigation} />), title: 'Circle Message' })}
        />
        <CircleStack.Screen
            name="CircleAddEditPost"
            component={CircleAddEditPost}
            options={({ navigation }) => ({ header: ({ navigation }) => (<CircleHeader navigation={navigation} />), title: 'Circle Members' })}
        />
    </CircleStack.Navigator>
);

export default CircleStackScreen;
