/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */


import React from "react";
import { Text, StyleSheet, View, Image, ScrollView, Pressable, Keyboard, KeyboardAvoidingView, Platform, Alert } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { Entypo, MaterialIcons } from "@expo/vector-icons";
import ParsedText from "react-native-parsed-text";
import * as WebBrowser from "expo-web-browser";

import MainMediumText from "../../../components/text/MainMediumText";
import TimeAgo from "../../../components/utils/TimeAgo";
import CommentInput from "../../../components/input/CommentInput";
import CommentItem from "../../../components/utils/CommentItem";
import MainRegularText from "../../../components/text/MainRegularText";
import ReactionText from "../../../components/utils/ReactionText";
import ContainerView from "../../../components/main/ContainerView";
import HeroText from "../../../components/text/HeroText";
import SlideUpModal from "../../../components/utils/SlideUpModal";
import LikeIcon from "../../../components/icons/LikeIcon";
import { Colors } from "../../../themes/colors";
import { FontSize, SpacingH, SpacingW, Size, Utils } from "../../../themes/sizes";
import { GlobalStyles } from "../../../themes/styles";

import { Posts, User } from "../../../api";
import { clearOtherUser } from "../../../state/slices/userSlice";
import { addPostReaction, removePostReaction } from "../../../state/slices/circleSlice";
import { setIsSpinning } from "../../../state/slices/spinnerSlice";
import { markNotificationsRead } from "../../../notifications";

export default ({ navigation, route }) => {
    markNotificationsRead();

    const dispatch = useDispatch();

    const [aspectRatio, setAspectRatio] = React.useState(1);
    const [shouldAutoScroll, setShouldAutoScroll] = React.useState(false);
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [isCommentModalVisible, setCommentModalVisible] = React.useState(false);
    const [selectedComment, setSelectedComment] = React.useState(null);

    const loading = useSelector(state => state.circle.loading);
    const postType = "post";
    const post = useSelector(state => state.circle[postType + "s"].find(p => p.uuid == state.circle.currentPostUUID));
    const user = useSelector(state => state.user.profile);

    let hasLiked = post.reactions.find(r => r.user.uuid == user.uuid && r.type == "like");
    let postScrollView = React.useRef();

    const scrollToEnd = () => {
        postScrollView.current && postScrollView.current.scrollToEnd({ animated: true });
        setShouldAutoScroll(false);
    };

    React.useEffect(() => {
        let keyboardOpenListener = Keyboard.addListener("keyboardDidShow", () => {
            scrollToEnd();
        });

        return () => { keyboardOpenListener.remove() };
    }, [postScrollView]);

    React.useEffect(() => {
        if (post.image) {
            Image.getSize(post.image, (width, height) => {
                let r = Math.max(0.3, Math.min(3.33, width / height));
                setAspectRatio(r);
            });
        }
    }, []);

    const onUserPress = (uuid) => {
        dispatch(clearOtherUser());
        dispatch(User.getProfile({ uuid }));
        dispatch(User.refreshPosts({ uuid }))
        navigation.navigate("ViewProfile", { uuid });
    };

    const deletePost = () => {
        dispatch(setIsSpinning(true));
        setCommentModalVisible(false);
        navigation.goBack();
        dispatch(Posts.deletePost({ post }))
            .then(() => {
                dispatch(setIsSpinning(false));
            });
    };

    const deleteComment = () => {
        dispatch(setIsSpinning(true));
        setCommentModalVisible(false);
        dispatch(Posts.removeComment({ comment: selectedComment }))
            .then(() => {
                dispatch(setIsSpinning(false));
            });
    };

    const handlePostLikePress = () => {
        const reactionType = "like";

        if (hasLiked) {
            dispatch(removePostReaction({ uuid: post.uuid, type: reactionType, user, postType }))
            dispatch(Posts.removeReaction({ uuid: post.uuid }));
        } else {
            dispatch(addPostReaction({ uuid: post.uuid, type: reactionType, user, postType }))
            dispatch(Posts.reactToPost({ uuid: post.uuid, type: reactionType }));
        }
    };

    const handleNewCommentHandler = (content) => {
        dispatch(Posts.addComment({ uuid: post.uuid, content }));
        setShouldAutoScroll(true);
    };

    const handleUrlPress = (url) => {
        WebBrowser.openBrowserAsync(url);
    };

    const renderText = (matchingURL) => {
        if (!matchingURL) return;

        return matchingURL.replace(/(^\w+:|^)\/\//, "");
    };

    return (
        <ContainerView hasHeader style={{ flex: 1, paddingBottom: SpacingH.s1 }}>
            <ScrollView style={[GlobalStyles.optionalPaddingH, styles.scrollView]} ref={postScrollView} onContentSizeChange={() => shouldAutoScroll && scrollToEnd()}>
                <View style={styles.ownerWrapper}>
                    <View style={styles.ownerInnerWrapper}>
                        <Pressable style={styles.cardTitleOwner} onPress={() => onUserPress(post.user.uuid)}>
                            <Image source={{ uri: post.user.image_profile }} style={styles.ownerImage} />
                            <View style={{ paddingTop: SpacingH.s1 }}>
                                <MainMediumText style={{ fontSize: FontSize.SMALL }}>{post.user.name}</MainMediumText>
                                <TimeAgo date={post.postedAt} />
                            </View>
                        </Pressable>
                        <Pressable style={[styles.cardTitleTag, { paddingTop: SpacingH.s1 }]}>
                            {post.tags && post.tags.map(t => <Text style={[styles.cardTitleTagItem]} key={t.uuid}>{t.name}</Text>)}
                        </Pressable>
                        <Pressable style={styles.kebabWrapper} hitSlop={24} onPress={() => setModalVisible(true)} >
                            <Entypo name="dots-three-vertical" size={16} color={Colors.DARK_GREY} />
                        </Pressable>
                    </View>
                </View>
                <View style={{ ...styles.postContentWrapper, paddingHorizontal: SpacingW.s4 }}>
                    <View style={styles.postContentTitle}>
                        <HeroText style={{ fontSize: FontSize.NORMED, marginBottom: SpacingH.s2, }}>{post.title}</HeroText>
                    </View>
                    {post.image &&
                        <View style={styles.postImageWrapper}>
                            <Image
                                source={{ uri: post.image }}
                                style={{
                                    width: "100%",
                                    height: (Size.w100 - 2 * SpacingW.s4) / aspectRatio,
                                    borderRadius: SpacingH.s1
                                }}
                                resizeMode="cover"
                            />
                        </View>
                    }

                    <View style={styles.postContent}>
                        <MainRegularText>
                            <ParsedText
                                style={{ fontSize: FontSize.SMALL, textAlign: "justify" }}
                                parse={
                                    [
                                        { type: 'url', style: styles.url, onPress: handleUrlPress, renderText: renderText },
                                    ]
                                }
                                childrenProps={{ allowFontScaling: false }}>
                                {post.content}
                            </ParsedText>
                        </MainRegularText>
                    </View>
                    <View style={styles.postReaction}>
                        <ReactionText reactions={post.reactions} user={user} />
                        <View style={{ flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                            <LikeIcon onPress={handlePostLikePress} isLiked={post.reactions.find(o => o.user.uuid == user.uuid)} />
                        </View>
                    </View>
                </View>
                {postType == "post" &&
                    post.comments.map((comment) => (
                        <CommentItem
                            {...comment}
                            currentUser={user}
                            key={comment.uuid}
                            onUserPress={onUserPress}
                            onLongPress={() => {
                                setSelectedComment(comment);
                                setCommentModalVisible(true);
                            }}
                        />
                    ))
                }
            </ScrollView>

            {/* Post Options */}
            <SlideUpModal
                isModalVisible={isModalVisible}
                setModalVisible={setModalVisible}
                options={[
                    {
                        show: post.user.uuid === user.uuid,
                        text: "Edit",
                        onPress: () => { navigation.navigate("CircleAddEditPost", { post: { uuid: post.uuid, title: post.title, content: post.content, image: post.image, tags: post.tags.map(t => t.name) }, postType }) },
                        icon: <MaterialIcons name="edit" size={20} color={Colors.DARK_GREY} />,
                    },
                    {
                        show: post.user.uuid === user.uuid,
                        text: "Delete",
                        textColor: Colors.HEART_RED,
                        onPress: () => {
                            Alert.alert(
                                "Delete Post?",
                                "This action is irreversible.",
                                [
                                    {
                                        text: "Delete",
                                        onPress: deletePost,
                                        style: "destructive"
                                    },
                                    {
                                        text: "Cancel",
                                        onPress: () => console.log("Cancel Pressed"),
                                        style: "cancel"
                                    },
                                ]
                            );
                        },
                        icon: <MaterialIcons name="delete" size={20} color={Colors.HEART_RED} />,
                    },
                    {
                        show: post.user.uuid !== user.uuid,
                        text: "Report",
                        onPress: () => { setModalVisible(false); navigation.navigate("Report", { postUUID: post.uuid }); },
                        icon: <MaterialIcons name="report" size={20} color={Colors.DARK_GREY} />,
                    },

                ]}
            />

            {/* Comment Options */}
            <SlideUpModal
                isModalVisible={isCommentModalVisible}
                setModalVisible={setCommentModalVisible}
                options={[
                    {
                        show: selectedComment?.user.uuid === user.uuid,
                        text: "Delete",
                        textColor: Colors.HEART_RED,
                        onPress: () => {
                            Alert.alert(
                                "Delete Comment?",
                                "This action is irreversible.",
                                [
                                    {
                                        text: "Delete",
                                        onPress: deleteComment,
                                        style: "destructive"
                                    },
                                    {
                                        text: "Cancel",
                                        onPress: () => console.log("Cancel Pressed"),
                                        style: "cancel"
                                    },
                                ]
                            );
                        },
                        icon: <MaterialIcons name="delete" size={20} color={Colors.HEART_RED} />,
                    },
                    {
                        show: true,
                        text: "Report",
                        onPress: () => { setCommentModalVisible(false); navigation.navigate("Report", { commentUUID: selectedComment.uuid }); },
                        icon: <MaterialIcons name="report" size={20} color={Colors.DARK_GREY} />,
                    },
                ]}
            />
            {postType == "post" &&
                <KeyboardAvoidingView keyboardVerticalOffset={Utils.keyboardAvoidOffset} behavior={Platform.OS == "ios" ? "padding" : null}>
                    <CommentInput
                        style={{ width: "100%" }}
                        placeholder="Write a comment"
                        onPostPress={handleNewCommentHandler}
                        imageSource={user.image_profile}
                        loading={loading}
                    />
                </KeyboardAvoidingView>
            }
        </ContainerView>
    );
};

const styles = StyleSheet.create({
    scrollView: {
        paddingVertical: SpacingH.s2,
        backgroundColor: Colors.WHITE,
    },
    spacing: {
        paddingVertical: SpacingH.s2,
        paddingHorizontal: SpacingW.s4,
    },
    ownerWrapper: {
        flexDirection: "column",
        backgroundColor: Colors.WHITE,
        overflow: "hidden",
        paddingBottom: SpacingH.s2,
        paddingHorizontal: SpacingW.s4,
    },
    ownerInnerWrapper: {
        flexDirection: "row",
    },
    cardTitleOwner: {
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        marginRight: SpacingW.s4,
    },
    ownerImage: {
        height: 48,
        width: 48,
        borderRadius: 24,
        marginRight: SpacingW.s2,
    },
    cardTitleTag: {
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
    },
    cardTitleTagItem: {
        fontSize: FontSize.SMALL,
        color: Colors.DEEP_TEAL,
        marginRight: SpacingW.s1,
        marginBottom: SpacingH.s1,
    },
    kebabWrapper: {
        width: Size.w10,
        height: Size.h5,
        justifyContent: "center",
        alignItems: "flex-end",
    },

    // Content Row
    postContentWrapper: {
        flexDirection: "column",
        overflow: "hidden",
        paddingBottom: SpacingH.s2,
        borderBottomWidth: 1,
        borderBottomColor: Colors.SOFT_GREY,
    },
    postContentTitle: {

    },
    postImageWrapper: {
        marginBottom: SpacingH.s1,
    },

    postReaction: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: SpacingH.s2,
    },
    postContent: {
        marginVertical: SpacingH.s2,
    },
    url: {
        color: Colors.DEEP_TEAL
    },
});
