/**
 * LifeRamp
 *
 * @author      Burhan Rashid, Chetanya Gupta
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { ScrollView, View, StyleSheet, FlatList } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesome5 } from "@expo/vector-icons";
import datetime from "date-and-time";

import { FontSize, SpacingH } from "../../../themes/sizes";
import { Colors } from "../../../themes/colors";
import { GlobalStyles } from "../../../themes/styles";
import MainText from "../../../components/text/MainText";
import SessionCard from "../../../components/cards/SessionCard";
import HeroText from "../../../components/text/MainMediumText";
import MainMediumText from "../../../components/text/MainMediumText";
import GoalProgressCard from "../../../components/cards/GoalProgressCard";
import CircleButton from "../../../components/buttons/CircleButton";
import PlaceHolder from "../../../components/utils/PlaceHolder";

import { CoachingSessions, Goals, User } from "../../../api";
import { setGoal } from "../../../state/slices/goalSlice";
import { setAutoLoading } from "../../../state/slices/coachingSessionSlice";

let autoLoadInterval = null;

export default ({ navigation }) => {
    const dispatch = useDispatch();

    const state = useSelector(state => state.coachingSession);
    const user = useSelector(state => state.user.profile);
    const goal = useSelector(state => state.goal);
    const currentGoals = useSelector(state => state.goal.currentGoals);

    React.useEffect(() => {
        dispatch(CoachingSessions.getJourneyData());
        dispatch(Goals.refreshCurrentGoals());
        dispatch(CoachingSessions.refreshUpcomingSessions());
        dispatch(CoachingSessions.refreshPastSessions());

        if (autoLoadInterval) {
            clearInterval(autoLoadInterval);
        }

        autoLoadInterval = setInterval(() => {
            dispatch(setAutoLoading(true));
            dispatch(CoachingSessions.refreshUpcomingSessions());
            dispatch(CoachingSessions.refreshPastSessions());
            dispatch(User.getMyProfile());
            dispatch(CoachingSessions.getJourneyData()).then(() => dispatch(setAutoLoading(false)));
        }, 1000 * 60 * 1);

        return () => {
            if (autoLoadInterval) {
                clearInterval(autoLoadInterval);
            }
        };
    }, []);

    const viewGoal = (goal) => {
        dispatch(setGoal({ goal }));
        navigation.navigate("ViewGoal");
    };

    const refreshData = () => {
        dispatch(CoachingSessions.refreshUpcomingSessions());
        dispatch(CoachingSessions.refreshPastSessions());
        dispatch(CoachingSessions.getJourneyData());
        dispatch(Goals.refreshCurrentGoals())
    };

    const hasAnyCompleted = state.countCompleted > 0;
    const firstSessionNumber = hasAnyCompleted ? state.countCompleted : 1;

    let activeUpcomingSessions = state.upcomingSessions.filter(s => s.status !== "cancelled");
    activeUpcomingSessions.sort((a, b) => (new Date(a.start_at).getTime() > new Date(b.start_at).getTime()));
    if (state.sessionNow !== null) {
        activeUpcomingSessions.shift();
    }
    const nextSession = activeUpcomingSessions.length ? activeUpcomingSessions[0] : null;
    const completedSessions = state.pastSessions.filter(s => s.completed_at !== null);
    const lastCompletedSession = completedSessions.length ? completedSessions[0] : null;

    return (
        <FlatList
            style={[{ paddingTop: SpacingH.s2, backgroundColor: Colors.WHITE }]}
            ListHeaderComponent={
                <View style={GlobalStyles.containerPaddingH}>
                    {state.sessionNow !== null &&
                        <>
                            <MainMediumText style={{ fontSize: FontSize.NORMAL }}>Happening Now</MainMediumText>
                            <SessionCard {...state.sessionNow} navigation={navigation} />
                        </>
                    }

                    {user.isCoach ?
                        <>
                            <MainMediumText style={{ fontSize: FontSize.NORMAL }}>Next session</MainMediumText>

                            {nextSession !== null ?
                                <SessionCard {...nextSession} navigation={navigation} />
                                :
                                <MainText style={{ textAlign: "center", marginTop: SpacingH.s3, color: Colors.BLUE_GREY }}>No upcoming sessions!</MainText>
                            }
                        </>
                        :
                        <>
                            <MainMediumText style={{ fontSize: FontSize.NORMAL, marginTop: state.sessionNow !== null ? SpacingH.s3 : 0 }}>Coaching Sessions</MainMediumText>
                            <View style={styles.sessionButtonContainer}>
                                <CircleButton style={styles.sessionButton}>
                                    {hasAnyCompleted ? <FontAwesome5 name="check" style={styles.sessionButtonIcon} color={Colors.WHITE} /> : <HeroText style={styles.sessionButtonText}>{firstSessionNumber}</HeroText>}
                                </CircleButton>
                                <CircleButton style={styles.sessionButton}>
                                    <View style={[styles.sessionButton, styles.sessionButtonOutline]}>
                                        <HeroText style={styles.sessionButtonText}>{firstSessionNumber + 1}</HeroText>
                                    </View>
                                </CircleButton>
                                <CircleButton style={styles.sessionButton}>
                                    <View style={[styles.sessionButton, styles.sessionButtonOutline]}>
                                        <HeroText style={styles.sessionButtonText}>{firstSessionNumber + 2}</HeroText>
                                    </View>
                                </CircleButton>
                            </View>
                            <View style={styles.sessionButtonContainer}>
                                {lastCompletedSession !== null &&
                                    <View style={{ flex: 1 }}>
                                        <MainMediumText style={{ fontSize: FontSize.SMALLER, color: Colors.BLUE_GREY, textAlign: "center" }}>Completed on {"\n"}
                                            {datetime.format(new Date(lastCompletedSession.completed_at), "MMMM D, YYYY")}</MainMediumText>
                                    </View>
                                }
                                {nextSession !== null ?
                                    <View style={{ flex: 1 }}>
                                        <MainMediumText style={{ fontSize: FontSize.SMALLER, color: Colors.BLUE_GREY, textAlign: "center" }}>Scheduled on {"\n"}
                                            {datetime.format(new Date(nextSession.start_at), "MMMM D, YYYY")}</MainMediumText>
                                    </View>
                                    :
                                    <View style={{ flex: 1 }} />
                                }
                                <View style={{ flex: lastCompletedSession !== null ? 1 : 2 }} />
                            </View>
                        </>
                    }
                    <MainMediumText style={{ fontSize: FontSize.NORMAL, marginTop: SpacingH.s3 }}>My goals</MainMediumText>
                </View>
            }
            ListFooterComponent={<View style={{ height: SpacingH.s3 }} />}
            data={currentGoals}
            onRefresh={() => refreshData()}
            refreshing={(goal.refreshing || goal.loading) && !state.autoLoading}
            renderItem={({ item }) => <GoalProgressCard {...item} style={GlobalStyles.containerMarginH} onPress={() => { viewGoal(item) }} />}
            ListEmptyComponent={<PlaceHolder style={GlobalStyles.containerMarginH} status={(goal.refreshing || goal.loading)} type={"regularCard"} emptyMessage={"Go ahead and add your goals"}/>}
            keyExtractor={item => item.uuid}
            nestedScrollEnabled
        />
    );
};

const styles = StyleSheet.create({
    sessionButton: {
        width: 85,
        height: 85,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 85 / 2,
    },
    sessionButtonContainer: {
        flexDirection: "row",
        justifyContent: "space-around",
        paddingTop: SpacingH.s2,
    },
    sessionButtonIcon: {
        fontSize: 26,
        alignSelf: "center"
    },
    sessionButtonText: {
        textAlign: "center",
        fontSize: FontSize.NORMED,
    },
    sessionButtonOutline: {
        width: 83,
        height: 83,
        borderRadius: 83 / 2,
        backgroundColor: Colors.WHITE,
    },
});
