/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

export const setIsLoading = (flag) => (state) => { state.loading = flag; };

export const setError = (state, action) => {
    console.log("Action:", action);

    if (action.type === "user/getMyProfile/rejected") {
        state.token = null;
    }

    if (action.payload.errors) {
        for (let k in action.payload.errors) {
            if (action.payload.errors.hasOwnProperty(k)) {
                state.error = action.payload.errors[k][0];
                break;
            }
        }
    } else {
        state.error = action.payload.message || "Something went wrong!";
    }

    state.loading = false;

    if (state.refreshing) {
        state.refreshing = false;
    }
};
