/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { createSlice } from "@reduxjs/toolkit";
import { Goals } from "../../api";
import { setIsLoading, setError } from "../utils";

const initialState = {
    loading: false,
    error: "",

    refreshing: false,

    uuid: null,
    data: {},

    currentGoals: [],
    currentPage: 2,
    currentMaxPage: 2,

    completedRefreshing: false,
    completedGoals: [],
    completedPage: 2,
    completedMaxPage: 2,

    clientRefreshing: false,
    clientGoals: [],
    clientPage: 2,
    clientMaxPage: 2,
};

export const goalSlice = createSlice({
    name: "goal",
    initialState,
    reducers: {
        setGoal(state, { payload: { goal } }) {
            state.uuid = goal.uuid;
            state.data = goal;
        },
        clearError(state) {
            state.error = "";
        },
    },
    extraReducers: {
        [Goals.refreshCurrentGoals.pending]: (state) => { state.refreshing = true; },
        [Goals.getCurrentGoals.pending]: setIsLoading(true),

        [Goals.refreshCompletedGoals.pending]: (state) => { state.completedRefreshing = true; },
        [Goals.getCompletedGoals.pending]: setIsLoading(true),

        [Goals.createGoal.pending]: setIsLoading(true),
        [Goals.updateGoal.pending]: setIsLoading(true),
        [Goals.deleteGoal.pending]: setIsLoading(true),
        [Goals.completeGoal.pending]: setIsLoading(true),

        [Goals.refreshClientGoals.pending]: (state) => { state.clientRefreshing = true; },
        [Goals.getClientGoals.pending]: setIsLoading(true),

        [Goals.refreshCurrentGoals.fulfilled]: (state, action) => {
            state.currentGoals = action.payload.goals;
            state.currentPage = 2;
            state.currentMaxPage = action.payload.maxPage;
            state.refreshing = false;
        },
        [Goals.getCurrentGoals.fulfilled]: (state, action) => {
            state.currentPage++;
            state.currentGoals.push(...action.payload.goals);
            setIsLoading(false)(state);
        },

        [Goals.refreshCompletedGoals.fulfilled]: (state, action) => {
            state.completedGoals = action.payload.goals;
            state.completedPage = 2;
            state.completedMaxPage = action.payload.maxPage;
            state.completedRefreshing = false;
        },
        [Goals.getCompletedGoals.fulfilled]: (state, action) => {
            state.completedPage++;
            state.completedGoals.push(...action.payload.goals);
            setIsLoading(false)(state);
        },

        [Goals.createGoal.fulfilled]: (state, action) => {
            const goal = action.payload.goal;
            state.currentGoals.unshift(goal)
            setIsLoading(false)(state);
        },
        [Goals.updateGoal.fulfilled]: (state, action) => {
            const goal = action.payload.goal;
            if (goal.isCompleted) {
                state.completedGoals.splice(state.completedGoals.indexOf(state.completedGoals.find(g => g.uuid == goal.uuid)), 1, goal);
            } else {
                state.currentGoals.splice(state.currentGoals.indexOf(state.currentGoals.find(g => g.uuid == goal.uuid)), 1, goal);
            }
            setIsLoading(false)(state);
        },
        [Goals.deleteGoal.fulfilled]: (state, action) => {
            const goal = action.meta.arg.goal;
            if (goal.isCompleted) {
                state.completedGoals.splice(state.completedGoals.indexOf(state.completedGoals.find(g => g.uuid == goal.uuid)), 1);
            } else {
                state.currentGoals.splice(state.currentGoals.indexOf(state.currentGoals.find(g => g.uuid == goal.uuid)), 1);
            }
            setIsLoading(false)(state);
        },
        [Goals.completeGoal.fulfilled]: (state, action) => {
            const goal = action.meta.arg.goal;
            state.currentGoals.splice(state.currentGoals.indexOf(state.currentGoals.find(g => g.uuid == goal.uuid)), 1);
            state.completedGoals.unshift({ ...goal, isCompleted: true, completedAt: new Date().toISOString() });
            setIsLoading(false)(state);
        },

        [Goals.refreshClientGoals.fulfilled]: (state, action) => {
            state.clientGoals = action.payload.goals;
            state.clientPage = 2;
            state.clientMaxPage = action.payload.maxPage;
            state.clientRefreshing = false;
        },
        [Goals.getClientGoals.fulfilled]: (state, action) => {
            state.clientPage++;
            state.clientGoals.push(...action.payload.goals);
            setIsLoading(false)(state);
        },

        [Goals.refreshCurrentGoals.rejected]: setError,
        [Goals.getCurrentGoals.rejected]: setError,
        [Goals.refreshCompletedGoals.rejected]: setError,
        [Goals.getCompletedGoals.rejected]: setError,
        [Goals.createGoal.rejected]: setError,
        [Goals.updateGoal.rejected]: setError,
        [Goals.deleteGoal.rejected]: setError,
        [Goals.completeGoal.rejected]: setError,
        [Goals.refreshClientGoals.rejected]: setError,
        [Goals.getClientGoals.rejected]: setError,
    }
});

export const { setGoal, clearError } = goalSlice.actions;

export default goalSlice.reducer;
