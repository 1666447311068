/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StatusBar, SafeAreaView, View } from "react-native";
import { GlobalStyles } from "../../themes/styles";
import { Colors } from "../../themes/colors";

export default ({ style, children, hasHeader }) => (
    <SafeAreaView style={[GlobalStyles.safeArea]}>
        <View style={[GlobalStyles.container, { paddingTop: hasHeader ? 0 : GlobalStyles.container.paddingTop }, style]}>
            <StatusBar backgroundColor={Colors.WHITE} barStyle={"dark-content"} />
            {children}
        </View>
    </SafeAreaView>
);
