/**
 * LifeRamp
 *
 * @author      Burhan Rashid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { ScrollView, View, Image, StyleSheet, TouchableOpacity, Text } from "react-native";
import { useSelector } from "react-redux";
import { Ionicons, AntDesign, Feather, EvilIcons } from '@expo/vector-icons';

import { SpacingH, Size, wp, SpacingW } from "../../../themes/sizes";
import { Colors } from "../../../themes/colors";
import { FontSize } from "../../../themes/sizes";
import { GlobalStyles } from "../../../themes/styles";

import ContainerView from "../../../components/main/ContainerView";
import HeroText from "../../../components/text/HeroText";
import MainMediumText from "../../../components/text/MainMediumText";
import MainText from "../../../components/text/MainText";
import fonts from "../../../themes/fonts";

export default ({ navigation, route }) => {
    const goal = useSelector(state => state.goal.data);
    const client = route.params?.client || false;

    return (
        <ContainerView hasHeader>
            <ScrollView>
                <View>
                    <TouchableOpacity onPress={() => { navigation.goBack(); }} style={{ position: "absolute", top: 20, left: 10, zIndex: 10 }}>
                        <EvilIcons name="chevron-left" size={42} color={Colors.SOFT_WHITE} />
                    </TouchableOpacity>
                    <Image
                        source={require("../../../assets/illustrations/view_goal.png")}
                        style={{ width: wp(100), height: wp(100) * 0.645 }}
                        resizeMode="cover"
                    />
                </View>
                <View style={GlobalStyles.containerPaddingH}>
                    <View style={{ marginBottom: SpacingH.s2 }}>
                        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            {client &&
                                <HeroText>{client.firstname}'s goal</HeroText>
                            }
                            {goal.isCompleted &&
                                <Ionicons name="checkmark-circle-outline" size={32} color={Colors.DARK_GREY} />
                            }
                        </View>
                        {goal.isCompleted ?
                            <MainMediumText style={{ marginTop: SpacingH.s0, marginBottom: SpacingH.s2, fontSize: FontSize.SMALLER }}>Completed on {new Date(Date.parse(goal.completedAt)).toDateString()}</MainMediumText>
                            :
                            <MainMediumText style={{ marginTop: SpacingH.s0, marginBottom: SpacingH.s2, fontSize: FontSize.SMALLER }}>Created on {new Date(Date.parse(goal.createdAt)).toDateString()}</MainMediumText>
                        }
                        <View style={{ flexDirection: "row", justifyContent: "center", flex: 1 }}>
                            <View style={styles.checkboxContainer}>
                                {
                                    goal.type === "professional" ?
                                        <AntDesign name="checksquare" size={24} color={Colors.DARK_GREY} />
                                        :
                                        <Feather name="square" size={24} color={Colors.DARK_GREY} />
                                }
                                <Text style={styles.checkboxItem}>Professional</Text>
                            </View>
                            <View style={styles.checkboxContainer}>
                                {
                                    goal.type === "educational" ?
                                        <AntDesign name="checksquare" size={24} color={Colors.DARK_GREY} />
                                        :
                                        <Feather name="square" size={24} color={Colors.DARK_GREY} />
                                }
                                <Text style={styles.checkboxItem}>Educational</Text>
                            </View>
                            <View style={styles.checkboxContainer}>
                                {
                                    goal.type === "personal" ?
                                        <AntDesign name="checksquare" size={24} color={Colors.DARK_GREY} />
                                        :
                                        <Feather name="square" size={24} color={Colors.DARK_GREY} />
                                }
                                <Text style={styles.checkboxItem}>Personal</Text>
                            </View>
                        </View>

                        <MainMediumText style={{ marginBottom: SpacingH.s0, marginTop: SpacingH.s2, fontSize: FontSize.SMALLER }}>I will...</MainMediumText>
                        <View style={styles.titleBox}>
                            <MainText>{goal.action}</MainText>
                        </View>
                        <MainMediumText style={{ marginBottom: SpacingH.s0, fontSize: FontSize.SMALLER }}>Every...</MainMediumText>
                        <View style={styles.titleBox}>
                            <MainText>{goal.frequency}</MainText>
                        </View>
                        <MainMediumText style={{ marginBottom: SpacingH.s0, fontSize: FontSize.SMALLER }}>In order to...</MainMediumText>
                        <View style={styles.titleBox}>
                            <MainText>{goal.outcome}</MainText>
                        </View>
                        <MainMediumText style={{ marginBottom: SpacingH.s0, fontSize: FontSize.SMALLER }}>And I will accomplish this by...</MainMediumText>
                        <View style={styles.titleBox}>
                            <MainText>{goal.target_at == "" ? "" : new Date(Date.parse(goal.target_at)).toDateString()}</MainText>
                        </View>
                    </View>
                </View>
            </ScrollView>
        </ContainerView>
    );
};

const styles = StyleSheet.create({
    checkboxContainer: {
        flexDirection: "row",
        alignItems: "center",
        width: Size.w30,
    },
    checkboxItem: {
        fontSize: FontSize.SMALLER,
        fontFamily: fonts.MainMedium,
        color: Colors.TEXT_BLACK,
        marginLeft: SpacingW.s1,
    },
    titleBox: {
        borderRadius: 5,
        marginBottom: SpacingH.s2,
        borderColor: Colors.ELECTRIC_TEAL,
        backgroundColor: Colors.WHITE_GREY,
        paddingVertical: SpacingH.s2,
        paddingHorizontal: SpacingW.s3,
    },
    buttonContainer: {
        alignItems: "center",
    },
    postButton: {
        marginBottom: SpacingH.s2
    },
});
