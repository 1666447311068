/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { FlatList, View, StyleSheet } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { GlobalStyles } from "../../themes/styles";
import { SpacingH, SpacingW } from "../../themes/sizes";
import { Colors } from "../../themes/colors";
import SearchInput from "../../components/input/SearchInput";
import PeopleListItem from "../../components/cards/PeopleListItem";
import PlaceHolder from "../../components/utils/PlaceHolder";

import { Circle, User } from "../../api";
import { clearOtherUser } from "../../state/slices/userSlice";

export default ({ navigation }) => {
    const dispatch = useDispatch();
    const circle = useSelector(state => state.circle);
    const [search, setSearch] = React.useState("");

    React.useEffect(() => {
        dispatch(Circle.refreshPeople({ uuid: circle.uuid, search }));
    }, [search]);

    const loadMore = () => {
        if (circle.loading) return;
        circle.peoplePage <= circle.peopleMaxPage && dispatch(Circle.getPeople({ uuid: circle.uuid, page: circle.peoplePage, search }));
    };

    const viewProfile = (uuid) => {
        dispatch(clearOtherUser());
        dispatch(User.getProfile({ uuid }));
        dispatch(User.refreshPosts({ uuid }))
        navigation.navigate("ViewProfile", { uuid });
    };

    return (
        <View style={{ flex: 1, paddingTop: SpacingH.s0, backgroundColor: Colors.WHITE }}>
            <View style={{ paddingHorizontal: SpacingW.s3, marginTop: SpacingH.s0 }}>
                <SearchInput
                    placeholder={"Search"}
                    value={search}
                    onChangeText={s => setSearch(s)}
                />
            </View>
            <FlatList
                contentContainerStyle={{ paddingBottom: SpacingH.s2 }}
                style={[GlobalStyles.optionalPaddingH, { flex: 1, paddingTop: SpacingH.s2 }]}
                data={circle.people}
                onRefresh={() => dispatch(Circle.refreshPeople({ uuid: circle.uuid, search }))}
                refreshing={circle.peopleRefreshing || circle.loading}
                onEndReached={loadMore}
                renderItem={({ item }) => <PeopleListItem {...item} onPress={() => { viewProfile(item.uuid); }} />}
                ListEmptyComponent={() => (<PlaceHolder status={(circle.peopleRefreshing || circle.loading)} type={"peopleCard"} />)}
                keyExtractor={item => item.uuid}
            />
        </View>
    );
};
