/**
 * LifeRamp
 *
 * @author      Anees Muzzafer
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, TextInput, View, TouchableOpacity, Platform, Pressable, Animated } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Ionicons, MaterialIcons, Entypo, } from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";

import * as ImagePicker from "expo-image-picker";
import * as DocumentPicker from "expo-document-picker";

import Modal from '@m4ttheweric/modal-enhanced-react-native-web';

import Fonts from "../../themes/fonts";
import { Gradients } from "../../themes/colors";
import { Colors } from "../../themes/colors";
import { FontSize, hp, Size, SpacingH, SpacingW, wp, isTabPortrait } from "../../themes/sizes";
import { Audio } from "expo-av";
import MainMediumText from "../text/MainMediumText";

export default (props) => {
    const [chatText, setChatText] = React.useState("");
    const [currentHeight, setCurrentHeight] = React.useState(0);
    const [recording, setRecording] = React.useState();
    const [durationRecorded, setDurationRecorded] = React.useState(0);
    const [animation, setAnimation] = React.useState(false);
    const [isCameraModalVisible, setCameraModalVisible] = React.useState(false);
    const [isAttachModalVisible, setAttachModalVisible] = React.useState(false);

    const slideAnim = React.useRef(new Animated.Value(0)).current;
    const rotateAnim = React.useRef(new Animated.Value(0)).current;

    const ref_inputChat = React.useRef();

    const audioRecordStartAnim = () => {
        Animated.timing(slideAnim, {
            toValue: 1,
            duration: 300,
            useNativeDriver: false,
        }).start();
    };

    const audioRecordStopAnim = () => {
        Animated.timing(slideAnim, {
            toValue: 0,
            duration: 300,
            useNativeDriver: false,
        }).start(() => { setAnimation(false) });
    };

    const slideAnimInterpolated = slideAnim.interpolate({
        inputRange: [0, 1],
        outputRange: ["20%", "100%"]
    });

    const deleteButtonAnim = () => {
        Animated.spring(rotateAnim, {
            toValue: 1,
            friction: 2.5,
            useNativeDriver: false,
        }).start();
    }
    const rotateAnimInterpolated = rotateAnim.interpolate({
        inputRange: [0, 0.5, 1],
        outputRange: ["0deg", "60deg", "0deg"]
    });


    const textChangeHandler = (text) => {
            setChatText(text);
    };

    const millisToMinutesAndSeconds = (millis) => {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return `${minutes}:${(seconds < 10 ? "0" : "")}${seconds}`;
    }

    const startRecordingAudio = async () => {
        try {
            if (Platform.OS === "ios") {
                setAnimation(true);
                audioRecordStartAnim();
                deleteButtonAnim();
            }

            const gotPermission = await (async () => {
                if (Platform.OS !== "web") {
                    const { status } = await Audio.requestPermissionsAsync();
                    if (status !== "granted") {
                        alert("The microphone permission is needed to send an audio message!");
                        return false;
                    }

                    return true;
                }
            })();

            if (Platform.OS === "web") {
                alert('Sorry, this feature is only available on LifeRamp app!');
                return;
            }

            if (!gotPermission) return;

            await Audio.setAudioModeAsync({
                allowsRecordingIOS: true,
                playsInSilentModeIOS: true,
            });

            const recording = new Audio.Recording();
            await recording.prepareToRecordAsync({
                android: {
                    extension: ".m4a",
                    outputFormat: Audio.RECORDING_OPTION_ANDROID_OUTPUT_FORMAT_MPEG_4,
                    audioEncoder: Audio.RECORDING_OPTION_ANDROID_AUDIO_ENCODER_AAC,
                    sampleRate: 44100,
                    numberOfChannels: 2,
                    bitRate: 128000,
                },
                ios: {
                    extension: ".m4a",
                    outputFormat: Audio.RECORDING_OPTION_IOS_OUTPUT_FORMAT_MPEG4AAC,
                    audioQuality: Audio.RECORDING_OPTION_IOS_AUDIO_QUALITY_MIN,
                    sampleRate: 44100,
                    numberOfChannels: 2,
                    bitRate: 128000,
                    linearPCMBitDepth: 16,
                    linearPCMIsBigEndian: false,
                    linearPCMIsFloat: false,
                }
            });

            recording.setProgressUpdateInterval(500);
            recording.setOnRecordingStatusUpdate((status) => {
                const millis = status.durationMillis;
                setDurationRecorded(millisToMinutesAndSeconds(millis));
            })

            await recording.startAsync();

            if (Platform.OS !== "ios") {
                setAnimation(true);
                audioRecordStartAnim();
                deleteButtonAnim();
            }

            setRecording(recording);
        } catch (err) {
            console.error("AudioRecordError: ", err);
        }
    };
    const stopRecordingAudio = async () => {
        setRecording(undefined);
        const recordedStatus = await recording.stopAndUnloadAsync();
        props.newAudio({
            uri: recording.getURI(),
            duration: recordedStatus.durationMillis,
        });
        rotateAnim.setValue(0);
        audioRecordStopAnim();
    };

    const cancelRecordingAudio = async () => {
        setRecording(undefined);
        await recording.stopAndUnloadAsync();
        rotateAnim.setValue(0);
        audioRecordStopAnim();
    };

    const onCameraPress = async () => {
        const gotPermission = await (async () => {
            if (Platform.OS !== "web") {
                const { status } = await ImagePicker.requestCameraPermissionsAsync();
                if (status !== "granted") {
                    alert("The camera roll permission is needed to capture an Image!");
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        })();

        if (!gotPermission) return;

        let result = await ImagePicker.launchCameraAsync({
            allowsEditing: true,
            quality: 0.8,
        });

        setTimeout(() => { setCameraModalVisible(false); }, 0);
        if (result.cancelled) return;

        props.newImage({
            uri: result.uri,
            width: result.width,
            height: result.height,
        });
    };

    const onCamcorderPress = async () => {
        const gotPermission = await (async () => {
            if (Platform.OS !== "web") {
                const { status } = await ImagePicker.requestCameraPermissionsAsync();
                if (status !== "granted") {
                    alert("The camera roll permission is needed to capture a Video!");
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        })();

        if (!gotPermission) return;

        let result = await ImagePicker.launchCameraAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Videos,
            videoMaxDuration: 30,
            allowsEditing: true,
            quality: 0.8,
            videoQuality: 1,
        });

        setTimeout(() => { setCameraModalVisible(false); }, 0);

        if (result.cancelled) return;

        props.newVideo(result.uri);
    };

    const onAttachPress = type => async () => {
        let doc = await DocumentPicker.getDocumentAsync({
            copyToCacheDirectory: false,
            type: type == "audio" ? "audio/*" : "*/*"
        });

        if (doc.type === "success") {
            if (doc.size > 10 * 1024 * 1024) {
                alert("The file is too big. Max 10MB.");
                return;
            }

            const allowedTypes = ["txt", "pdf", "xls", "xlsx", "doc", "docx", "ppt", "pptx", "mp3"];
            const documentType = doc.name.split(".").pop();

            if (type === "document" && !allowedTypes.includes(documentType)) {
                alert("Unsupported document type.");
                setTimeout(() => setAttachModalVisible(false), 0);
                return;
            }

            props.newDocument({
                name: doc.name,
                uri: doc.uri,
                type: type === "document" ? documentType : "audio",
                size: doc.size,
            });
        }

        setAttachModalVisible(false);
    };

    const onAttachMedia = type => async () => {
        const gotPermission = await (async () => {
            if (Platform.OS !== "web") {
                const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
                if (status !== "granted") {
                    alert("The permission is needed to send media!");
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        })();

        if (!gotPermission) return;

        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: type == "image" ? ImagePicker.MediaTypeOptions.Images : ImagePicker.MediaTypeOptions.Videos,
            allowsEditing: true,
            quality: 0.1,
        });

        setAttachModalVisible(false);
        if (result.cancelled) return;

        type == "image" ? props.newImage({
            uri: result.uri,
            width: result.width,
            height: result.height,
        }) : props.newVideo(result.uri);
    };

    const sendTextMessage = () => {
        let sendText = chatText.trimStart().trimEnd();
        if (sendText === "") {
            return;
        }
        props.newMessage(sendText);
    }

    let content = null;

    if (chatText.length) {
        content = (
            <TouchableOpacity onPress={() => { sendTextMessage(); setChatText("");  setCurrentHeight(0); ref_inputChat.current.focus(); }}>
                <LinearGradient
                    style={[styles.recordSendButton, { paddingLeft: 1 }]}
                    start={[1, 0]}
                    end={[0, 1]}
                    colors={[Gradients.GREEN_BLUE.Start, Gradients.GREEN_BLUE.End]}>
                    <Ionicons name="arrow-up-outline" size={30} color={Colors.SOFT_WHITE} />
                </LinearGradient>
            </TouchableOpacity>
        );
    } else {
        content = (
            <View style={styles.iconContainer}>
                <TouchableOpacity onPress={() => { setAttachModalVisible(true); }}>
                    <View style={styles.iconButton} >
                        <Entypo name="attachment" size={20} color={Colors.LIGHT_GREY} />
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={startRecordingAudio}>
                    <LinearGradient
                        style={styles.recordSendButton}
                        start={[1, 0]}
                        end={[0, 1]}
                        colors={[Gradients.GREEN_BLUE.Start, Gradients.GREEN_BLUE.End]}>
                        <Ionicons name="mic-sharp" size={22} color={Colors.SOFT_WHITE} />
                    </LinearGradient>
                </TouchableOpacity>
            </View>
        );
    }
    return (
        <View style={[styles.chatInputContainer, props.style]}>
            <TouchableOpacity onPress={() => { setCameraModalVisible(true); }}>
                <View style={styles.CameraIconButton} >
                    <AntDesign name="pluscircle" size={26} color={Colors.BLUE_GREY} />
                </View>
            </TouchableOpacity>
            <Modal
                style={{ margin: "auto", width: wp(100), maxWidth: wp(100), justifyContent: "flex-end" }}
                isVisible={isCameraModalVisible}
                onBackdropPress={() => setCameraModalVisible(false)}
                onBackButtonPress={() => setCameraModalVisible(false)}
                animationIn="fadeInUpBig"
                animationInTiming={300}
                backdropOpacity={0.3}
                animationOut="fadeOutDown"
                backdropTransitionOutTiming={0}
            >
                <View style={styles.cameraModelWrapper}>
                    <TouchableOpacity onPress={onCameraPress}>
                        <View style={ styles.cameraCaptureButton }>
                            <Ionicons name="camera" size={26} color={Colors.SOFT_WHITE} />
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={onCamcorderPress}>
                        <View style={ styles.videoRecordButton }>
                            <Ionicons name="videocam" size={26} color={Colors.SOFT_WHITE} />
                        </View>
                    </TouchableOpacity>
                </View>
            </Modal>

            <Modal
                style={{ margin: "auto", width: wp(100), maxWidth: wp(100), justifyContent: "flex-end" }}
                isVisible={isAttachModalVisible}
                onBackdropPress={() => setAttachModalVisible(false)}
                onBackButtonPress={() => setAttachModalVisible(false)}
                animationIn="fadeInRightBig"
                animationOut="fadeOutLeftBig"
                backdropOpacity={0.3}
                backdropTransitionOutTiming={0} >
                <View style={styles.attachModelWrapper}>
                    <TouchableOpacity onPress={onAttachPress("document")}>
                        <View style={styles.attachButton}>
                            <Ionicons name="document" size={26} color={Colors.SOFT_WHITE} />
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={onAttachMedia("image")}>
                        <View style={styles.attachButton}>
                            <Ionicons name="image" size={26} color={Colors.SOFT_WHITE} />
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={onAttachMedia("video")}>
                        <View style={styles.attachButton}>
                            <Entypo name="video" size={26} color={Colors.SOFT_WHITE} />
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={onAttachPress("audio")}>
                        <View style={styles.attachButton}>
                            <MaterialIcons name="audiotrack" size={26} color={Colors.SOFT_WHITE} />
                        </View>
                    </TouchableOpacity>
                </View>
            </Modal>


            <TextInput
                {...props}
                style={[styles.chatInput, props.style, { height: Math.max(60, currentHeight), paddingVertical: 18 }]}
                multiline={true}
                ref={ref_inputChat}
                onChangeText={textChangeHandler}
                blurOnSubmit={true}
                onKeyPress={(event) => {
                    if (event.nativeEvent.key == "Enter") {
                        sendTextMessage();
                        setChatText("");
                        setCurrentHeight(0);
                        ref_inputChat.current.focus();
                    }
                }}
                onContentSizeChange={(event) => {
                    if (event.nativeEvent.contentSize.height < 140) {
                        setCurrentHeight(event.nativeEvent.contentSize.height);
                    }
                }}
                value={chatText}
            />
            {content}
            {animation &&
                <View style={{ width: "100%", zIndex: 2, position: "absolute" }}>
                    <Animated.View style={{ width: slideAnimInterpolated, alignSelf: "flex-end" }}>
                        <LinearGradient
                            style={styles.recordInputContainer}
                            start={[1, 0]}
                            end={[0, 1]}
                            colors={[Gradients.GREEN_BLUE.Start, Gradients.GREEN_BLUE.End]}>
                            <Animated.View style={{ opacity: slideAnim, transform: [{ rotate: rotateAnimInterpolated }] }}>
                                <TouchableOpacity onPress={cancelRecordingAudio}>
                                    <View style={styles.deleteAudioButton}>
                                        <MaterialIcons name="delete" size={20} color={Colors.LIGHT_GREY} />
                                    </View>
                                </TouchableOpacity>
                            </Animated.View>
                            <Animated.View style={{ ...styles.recordDuration, opacity: slideAnim }}>
                                <MainMediumText style={{ fontSize: FontSize.SMALL, color: Colors.SOFT_WHITE }}>
                                    {durationRecorded}
                                </MainMediumText>
                            </Animated.View>
                            <Animated.View style={{ opacity: slideAnim, }}>
                                <Pressable style={styles.recordSendButton} onPress={stopRecordingAudio}>
                                    <AntDesign name="arrowup" size={22} color={Colors.DEEP_TEAL} />
                                </Pressable>
                            </Animated.View>
                        </LinearGradient>
                    </Animated.View>
                </View>}
        </View>
    );
};

const styles = StyleSheet.create({
    chatInputContainer: {
        alignSelf: "flex-end",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: Colors.WHITE_GREY,
        borderRadius: 26,
    },
    chatInput: {
        flex: 1,
        paddingHorizontal: 4,
        color: Colors.TEXT_PRIMARY,
        fontSize: FontSize.NORMAL,
        fontFamily: Fonts.Main,
        alignSelf: "center",
        paddingTop: Platform.OS == "ios" ? 15 : 0,
    },
    iconContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    CameraIconButton: {
        marginHorizontal: 8
    },
    iconButton: {
        marginHorizontal: SpacingW.s4
    },
    recordSendButton: {
        width: 38,
        height: 38,
        borderRadius: 19,
        backgroundColor: Colors.SOFT_GREY,
        marginHorizontal: 5,
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: 2,
    },
    recordDuration: {

    },
    recordInputContainer: {
        alignSelf: "flex-end",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: Colors.DEEP_TEAL,
        borderRadius: 23,
        height: 46,
        width: "100%"
    },
    deleteAudioButton: {
        width: 38,
        height: 38,
        borderRadius: 19,
        backgroundColor: Colors.SOFT_GREY,
        marginHorizontal: 5,
        alignItems: "center",
        justifyContent: "center"
    },
    cameraModelWrapper: {
        width: isTabPortrait ? Size.h10 : Size.w20,
        height: isTabPortrait ? Size.h20 : Size.w40,
        backgroundColor: Colors.WHITE,
        bottom: Size.h10,
        left: SpacingW.s3,
        borderRadius: isTabPortrait ? Size.h5 : Size.w10,
        justifyContent: "space-between",
        alignItems: "center"
    },
    cameraCaptureButton: {
        width: isTabPortrait ? hp("8%") : wp("16%"),
        height: isTabPortrait ? hp("8%") : wp("16%"),
        marginTop: SpacingH.s1,
        borderRadius: isTabPortrait ? hp("4%") : wp("8%"),
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.BLUE_GREY,
    },
    videoRecordButton: {
        width: isTabPortrait ? hp("8%") : wp("16%"),
        height: isTabPortrait ? hp("8%") : wp("16%"),
        marginBottom: SpacingH.s1,
        borderRadius: isTabPortrait ? hp("4%") : wp("8%"),
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.BLUE_GREY,
    },
    attachModelWrapper: {
        height: Size.h10,
        backgroundColor: Colors.WHITE_GREY,
        bottom: Size.h10,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginHorizontal: Size.w5,
        paddingHorizontal: SpacingW.s2,
        borderRadius: hp("7.5%"),
    },
    attachButton: {
        width:  isTabPortrait ? hp("8%") : wp("16%"),
        height: isTabPortrait ? hp("8%") : wp("16%"),
        borderRadius: isTabPortrait ? hp("4%") : wp("8%"),
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.BLUE_GREY,
    },
});
