/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { Colors } from "../../themes/colors";
import { FontSize } from "../../themes/sizes";
import MainText from "../text/MainText";

export default ({ children, style, onPress, textStyle }) => (
    <View style={[{ jusifyContent: "center", alignItems: "flex-start" }, style]}>
        <TouchableOpacity onPress={onPress} >
            <MainText style={[styles.linkButton, textStyle]}>{children}</MainText>
        </TouchableOpacity>
    </View>
);

const styles = StyleSheet.create({
    linkButton: {
        fontSize: FontSize.NORMAL,
        color: Colors.DEEP_TEAL,
        textDecorationLine: "underline",
    },
});
