/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { createSlice } from "@reduxjs/toolkit";
import { Circle, Posts } from "../../api";
import { setIsLoading, setError } from "../utils";

const initialState = {
    loading: false,
    error: "",

    refreshing: false,
    autoLoading: false,

    uuid: null,
    data: {},

    posts: [],
    page: 2,
    maxPage: 2,

    noticesRefreshing: false,
    notices: [],
    noticePage: 2,
    noticeMaxPage: 2,

    peopleRefreshing: false,
    people: [],
    peoplePage: 2,
    peopleMaxPage: 2,

    circlesRefreshing: false,
    circles: [],
    circlePage: 2,
    circleMaxPage: 2,

    currentPostUUID: null,
    currentPostType: null,
    comment: null,
};

export const circleSlice = createSlice({
    name: "circle",
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            state.loading = payload;
        },
        clearError(state) {
            state.error = "";
        },
        setCircleUUID: (state, { payload: { uuid } }) => {
            state.uuid = uuid;
        },
        setCircle: (state, { payload: { circle } }) => {
            if (circle) {
                state.uuid = circle.uuid;
                state.data = circle;
                state.posts = [];
                state.page = 2;
                state.maxPage = 2;
            }
        },
        setCircleFromUUID: (state, { payload: { uuid } }) => {
            if (uuid === state.uuid) return;

            const circle = state.circles.find(c => c.uuid === uuid);
            if (!circle) return;

            state.data = circle;
            state.uuid = circle?.uuid;
            state.posts = [];
            state.page = 2;
            state.maxPage = 2;
        },
        addPostReaction: (state, { payload: { uuid, type, user, postType = "post" } }) => {
            let post = state[postType + "s"].find(p => p.uuid === uuid);
            post.reactions.push({ uuid: "my-reaction", type, user });
            post.reactionCounts[type]++;
        },
        removePostReaction: (state, { payload: { uuid, type, user, postType = "post" } }) => {
            let post = state[postType + "s"].find(p => p.uuid === uuid);
            post.reactions = post.reactions.filter(r => r.user.uuid !== user.uuid);
            post.reactionCounts[type]--;
        },
        setPost: (state, { payload: { uuid, type } }) => {
            state.currentPostUUID = uuid;
            state.currentPostType = type;
        },
        addCommentReaction: (state, { payload: { uuid, type, user } }) => {
            const comment = state.posts.find(p => p.uuid === state.currentPostUUID).comments.find(c => c.uuid === uuid);
            comment.reactions.push({ uuid: "my-reaction", type, user });
            comment.reactionCounts[type]++;
        },
        removeCommentReaction: (state, { payload: { uuid, type, user } }) => {
            const comment = state.posts.find(p => p.uuid === state.currentPostUUID).comments.find(c => c.uuid === uuid);
            comment.reactions = comment.reactions.filter(r => r.user.uuid !== user.uuid);
            comment.reactionCounts[type]--;
        },
        addPost: (state, { payload: { post } }) => {
            const postType = post.type;
            if (!state[postType + "s"].find(p => p.uuid == post.uuid)) {
                state[postType + "s"].push(post)
            }
        },
        setCircleAutoLoading: (state, { payload }) => {
            state.autoLoading = payload;
        },
    },
    extraReducers: {
        [Circle.refreshCircles.pending]: (state) => { state.circlesRefreshing = true },
        [Circle.getCircles.pending]: setIsLoading(true),

        [Circle.getCircle.pending]: setIsLoading(true),

        [Circle.refreshPosts.pending]: (state) => { state.refreshing = true },
        [Circle.getPosts.pending]: setIsLoading(true),

        [Circle.refreshNotices.pending]: (state) => { state.noticesRefreshing = true },
        [Circle.getNotices.pending]: setIsLoading(true),

        [Circle.createPost.pending]: setIsLoading(true),
        [Posts.getPost.pending]: setIsLoading(true),
        [Posts.deletePost.pending]: setIsLoading(true),
        [Posts.updatePost.pending]: setIsLoading(true),

        [Circle.refreshPeople.pending]: (state) => { state.peopleRefreshing = true },
        [Circle.getPeople.pending]: setIsLoading(true),

        [Posts.getComments.pending]: setIsLoading(true),
        [Posts.addComment.pending]: setIsLoading(true),
        [Posts.removeComment.pending]: setIsLoading(true),
        [Posts.reactToComment.pending]: setIsLoading(true),
        [Posts.removeReactionFromComment.pending]: setIsLoading(true),

        [Posts.createReport.pending]: setIsLoading(true),

        [Circle.refreshCircles.fulfilled]: (state, action) => {
            state.circles = action.payload.circles;
            state.circlePage = 2;
            state.circleMaxPage = action.payload.maxPage;
            state.circlesRefreshing = false;
        },
        [Circle.getCircles.fulfilled]: (state, action) => {
            if (state.circlePage < state.circleMaxPage) state.circlePage++;
            state.circleMaxPage = action.payload.maxPage;
            state.circles.push(...action.payload.circles);
            setIsLoading(false)(state);
        },

        [Circle.getCircle.fulfilled]: (state, action) => {
            state.uuid = action.payload.circle.uuid;
            state.data = action.payload.circle;
            setIsLoading(false)(state);
        },

        [Circle.refreshPosts.fulfilled]: (state, action) => {
            state.posts = action.payload.posts;
            state.page = 2;
            state.maxPage = action.payload.maxPage;
            state.refreshing = false;
        },
        [Circle.getPosts.fulfilled]: (state, action) => {
            state.page++;
            state.maxPage = action.payload.maxPage;
            state.posts.push(...action.payload.posts);
            setIsLoading(false)(state);
        },

        [Circle.refreshNotices.fulfilled]: (state, action) => {
            state.notices = action.payload.posts;
            state.noticePage = 2;
            state.noticeMaxPage = action.payload.maxPage;
            state.noticesRefreshing = false;
        },
        [Circle.getNotices.fulfilled]: (state, action) => {
            state.noticePage++;
            state.noticePage = action.payload.maxPage;
            state.notices.push(...action.payload.posts);
            setIsLoading(false)(state);
        },

        [Circle.refreshPeople.fulfilled]: (state, action) => {
            state.people = action.payload.people;
            state.peoplePage = 2;
            state.peopleMaxPage = action.payload.maxPage;
            state.peopleRefreshing = false;
        },
        [Circle.getPeople.fulfilled]: (state, action) => {
            state.peoplePage++;
            state.peopleMaxPage = action.payload.maxPage;
            state.people.push(...action.payload.people);
            setIsLoading(false)(state);
        },

        [Posts.reactToPost.fulfilled]: (state, action) => {
            const postType = action.payload.post.type;
            const post = state[postType + "s"].find(p => p.uuid === action.payload.post.uuid);
            state[postType + "s"][state[postType + "s"].indexOf(post)] = action.payload.post;
            setIsLoading(false)(state);
        },
        [Posts.removeReaction.fulfilled]: (state, action) => {
            const postType = action.payload.post.type;
            const post = state[postType + "s"].find(p => p.uuid === action.payload.post.uuid);
            state[postType + "s"][state[postType + "s"].indexOf(post)] = action.payload.post;
            setIsLoading(false)(state);
        },
        [Circle.createPost.fulfilled]: (state, action) => {
            const postType = action.payload.post.type;
            state[postType + "s"].unshift(action.payload.post);
            setIsLoading(false)(state);
        },
        [Posts.getPost.fulfilled]: (state, action) => {
            const post = action.payload.post;
            if (!state.posts.find(p => p.uuid == post.uuid)) {
                state.posts.push(post)
            }
            setIsLoading(false)(state);
        },
        [Posts.deletePost.fulfilled]: (state, action) => {
            const post = action.meta.arg.post;
            const postType = post.type;
            state[postType + "s"].splice(state[postType + "s"].indexOf(state[postType + "s"].find(p => p.uuid == post.uuid)), 1);
            setIsLoading(false)(state);
        },
        [Posts.updatePost.fulfilled]: (state, action) => {
            const postType = action.payload.post.type;
            state[postType + "s"].splice(
                state[postType + "s"].indexOf(
                    state[postType + "s"].find(p => p.uuid === action.payload.post.uuid)
                ), 1, action.payload.post
            );
            setIsLoading(false)(state);
        },
        [Posts.getComments.fulfilled]: (state, action) => {
            state.posts.find(p => p.uuid == state.currentPostUUID).comments = action.payload.comments;
            setIsLoading(false)(state);
        },
        [Posts.addComment.fulfilled]: (state, action) => {
            state.posts.find(p => p.uuid == state.currentPostUUID).comments.push(action.payload.comment);
            setIsLoading(false)(state);
        },
        [Posts.removeComment.fulfilled]: (state, action) => {
            const comment = action.meta.arg.comment;
            state.posts.find(p => p.uuid == state.currentPostUUID).comments.splice(
                state.posts.find(p => p.uuid == state.currentPostUUID).comments.indexOf(comment), 1
            );
            setIsLoading(false)(state);
        },
        [Posts.reactToComment.fulfilled]: (state, action) => {
            const post = state.posts.find(p => p.uuid === state.currentPostUUID)
            const comment = post.comments.find(c => c.uuid == action.payload.comment.uuid);
            post.comments[post.comments.indexOf(comment)] = action.payload.comment;
            setIsLoading(false)(state);
        },
        [Posts.removeReactionFromComment.fulfilled]: (state, action) => {
            const post = state.posts.find(p => p.uuid === state.currentPostUUID)
            const comment = post.comments.find(c => c.uuid == action.payload.comment.uuid);
            post.comments[post.comments.indexOf(comment)] = action.payload.comment;
            setIsLoading(false)(state);
        },

        [Posts.createReport.fulfilled]: (state, action) => {
            setIsLoading(false)(state);
        },

        [Circle.refreshCircles.rejected]: setError,
        [Circle.getCircles.rejected]: setError,

        [Circle.getCircle.rejected]: setError,

        [Circle.refreshPosts.rejected]: setError,
        [Circle.getPosts.rejected]: setError,

        [Circle.refreshNotices.rejected]: setError,
        [Circle.getNotices.rejected]: setError,

        [Circle.refreshPeople.rejected]: setError,
        [Circle.getPeople.rejected]: setError,

        [Posts.reactToPost.rejected]: setError,
        [Posts.removeReaction.rejected]: setError,
        [Circle.createPost.rejected]: setError,
        [Posts.getPost.rejected]: setError,
        [Posts.deletePost.rejected]: setError,
        [Posts.updatePost.rejected]: setError,
        [Posts.getComments.rejected]: setError,
        [Posts.addComment.rejected]: setError,
        [Posts.removeComment.rejected]: setError,
        [Posts.reactToComment.rejected]: setError,
        [Posts.removeReactionFromComment.rejected]: setError,

        [Posts.createReport.rejected]: setError,
    }
});

export const {
    setLoading,
    clearError,

    setCircleUUID,
    setCircle,
    setCircleFromUUID,

    addPostReaction,
    removePostReaction,

    setPost,

    addCommentReaction,
    removeCommentReaction,

    addPost,

    setCircleAutoLoading,
} = circleSlice.actions;

export default circleSlice.reducer;
