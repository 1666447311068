/**
 * LifeRamp
 *
 * @author      Chetanya Gupta
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { View, StyleSheet, ScrollView, TextInput, Alert, Image, ImageBackground, TouchableOpacity } from "react-native";
import { useDispatch } from "react-redux";
import * as ImagePicker from "expo-image-picker";
import mime from "mime";
import firebase from "firebase/app";
import randomString from "@afaanbilal/random-string";
import { EvilIcons } from "@expo/vector-icons";

import { SpacingH, FontSize, Size, SpacingW, wp } from "../../themes/sizes";
import { Colors } from "../../themes/colors";
import ContainerView from "../../components/main/ContainerView";

import BoxInput from "../../components/input/BoxInput";
import MainMediumText from "../../components/text/MainMediumText";
import HeroSemiText from "../../components/text/HeroSemiText";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { GlobalStyles } from "../../themes/styles";

import { setIsSpinning } from "../../state/slices/spinnerSlice";
import { Posts } from "../../api";

export default ({ navigation, route }) => {
    const dispatch = useDispatch();

    const isContact = route.params?.type === "contact";
    const commentUUID = route.params?.commentUUID || null;
    const postUUID = route.params?.postUUID || null;

    const [message, setMessage] = React.useState("");
    const [subject, setSubject] = React.useState("");
    const [image, setImage] = React.useState(null);
    const [shouldReport, setShouldReport] = React.useState(false);

    const pickImage = async () => {
        const gotPermission = await (async () => {
            if (Platform.OS !== "web") {
                const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
                if (status !== "granted") {
                    alert("The camera roll permission is needed to add an image to the post!");
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        })();

        if (!gotPermission) {
            return;
        }

        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            quality: 1,
        });

        if (!result.cancelled) {
            setImage(result.uri);
        }
    };

    const showAlert = (message, type = "Error") =>
        Alert.alert(
            type,
            message,
            [{ text: "Ok", style: "cancel", }]
        );

    const createReport = async () => {
        if (!subject.replace(/ /g,"").length) {
            showAlert("Report subject cannot be empty");
            return;
        }
        if (!message.replace(/ /g,"").length) {
            showAlert("Please fill in the report message");
            return;
        }
        if (image && image.startsWith("file")) {
            dispatch(setIsSpinning(true));

            const uri = image;
            let refPath = "report-images/" + randomString() + "/" + uri.split("/").pop();
            const ref = firebase.storage().ref(refPath);
            const response = await fetch(uri);
            const blob = await response.blob();
            if (blob.size <= (5 * 1024 * 1024)) {
                await ref.put(blob, { contentType: mime.getType(uri) });
                const url = await ref.getDownloadURL();
                setImage(url);
                setShouldReport(true);
            } else {
                dispatch(setIsSpinning(false));
                showAlert("Please choose an image less than 5MB");
                return;
            }

        } else {
            setShouldReport(true);
        }
    };

    React.useEffect(() => {
        if (shouldReport) {
            dispatch(setIsSpinning(true));
            dispatch(Posts.createReport({ message, subject, image, postUUID, commentUUID }))
                .then((response) => {
                    if (!response?.error) {
                        navigation.goBack();
                        showAlert("Report sent!", "Success");
                    } else {
                        showAlert("Something went wrong!", "Error!");
                    }

                    dispatch(setIsSpinning(false));
                    setShouldReport(false);
                });
        }
    }, [shouldReport]);

    return (
        <ContainerView hasHeader>
            <View style={GlobalStyles.containerPaddingH}>
                <TouchableOpacity onPress={() => { navigation.goBack(); }} style={{ position: "absolute", top: 23, left: 10, zIndex: 10 }}>
                    <EvilIcons name="chevron-left" size={42} color={Colors.BLUE_GREY} />
                </TouchableOpacity>
                <View style={{ marginVertical: SpacingH.s2 }}>
                    <HeroSemiText style={{ fontSize: FontSize.MEDIUM, textAlign: "center" }}>{!isContact ? "Report" : "Contact"}</HeroSemiText>
                </View>
            </View>
            <ScrollView style={[GlobalStyles.containerPaddingH, { flex: 1 }]}>
                <TouchableOpacity onPress={pickImage} activeOpacity={0.8}>
                    {image === null &&
                        <ImageBackground
                            source={require("../../assets/illustrations/add_post_image.png")}
                            resizeMode="contain"
                            imageStyle={[styles.postDefaultImageContainer, { transform: [{ rotateY: "180deg" }] }]}
                            style={[styles.postDefaultImage, { justifyContent: "flex-end", paddingBottom: SpacingH.s1, opacity: 0.95 }]}>
                            <View style={{ justifyContent: "center", alignItems: "center", borderWidth: 0, paddingBottom: SpacingH.s1 }}>
                                <EvilIcons name="plus" size={35} style={{ borderWidth: 0 }} color={Colors.BLUE_GREY} />
                                <MainMediumText style={{ color: Colors.BLUE_GREY, fontSize: FontSize.SMALL }}>Add an image</MainMediumText>
                            </View>
                        </ImageBackground>
                    }
                    {image !== null && <Image source={{ uri: image }} resizeMode="contain" style={styles.postDefaultImage} />}
                </TouchableOpacity>
                <BoxInput
                    value={subject}
                    onChangeText={(text) => setSubject(text)}
                    style={styles.subject}
                    placeholder={"Subject"} />
                <TextInput
                    maxLength={5000}
                    multiline={true}
                    placeholder="Message"
                    value={message}
                    onChangeText={(text) => setMessage(text)}
                    style={styles.commentInput} />
                <View style={{ flex: 1, alignItems: "center", marginTop: SpacingH.s2, marginBottom: SpacingH.s3 }}>
                    <PrimaryButton style={{ alignSelf: "center", }} onPress={createReport}>Send</PrimaryButton>
                </View>
            </ScrollView>
        </ContainerView>
    );
}

const styles = StyleSheet.create({
    subject: {
        backgroundColor: Colors.SOFT_GREY,
        borderWidth: 1,
        borderColor: Colors.SOFT_GREY,
        marginVertical: SpacingH.s2,
    },
    commentInput: {
        textAlignVertical: "top",
        borderRadius: 5,
        height: Size.h40,
        width: Size.w90,
        paddingHorizontal: SpacingW.s3,
        color: Colors.TEXT_PRIMARY,
        fontSize: FontSize.NORMAL,
        alignSelf: "center",
        paddingTop: SpacingH.s3,
        backgroundColor: Colors.SOFT_GREY,
    },
    postDefaultImageContainer: {
        width: "100%",
        height: wp(90) * 581 / 800,
        borderRadius: 8,
    },
    postDefaultImage: {
        borderRadius: 8,
        width: "100%",
        height: wp(90) * 581 / 800,
    },
});
