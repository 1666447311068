/**
 * LifeRamp
 *
 * @author      Waris Anwar
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { FlatList, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { GlobalStyles } from "../../../themes/styles";
import { isTabPortrait, Size, SpacingH, wp } from "../../../themes/sizes";
import { Colors } from "../../../themes/colors";
import ResourceImageCard from "../../../components/cards/resources/ResourceImageCard";
import ResourceCard from "../../../components/cards/resources/ResourceCard";
import PlaceHolder from "../../../components/utils/PlaceHolder";

import { Resources } from "../../../api";
import { markAsFavorite, unmarkAsFavorite } from "../../../state/slices/resourceSlice";

export default ({ navigation }) => {
    const dispatch = useDispatch();

    const resource = useSelector(state => state.resource);
    const favorites = useSelector(state => state.resource.favorites);

    React.useEffect(() => {
        dispatch(Resources.refreshFavoriteResources());
    }, []);

    const loadMore = () => {
        if (resource.loading) return;
        resource.favoritePage <= resource.favoriteMaxPage && dispatch(Resources.getFavoriteResources({ page: resource.favoritePage }));
    };

    const handleFavoritePress = (item) => {
        if (item.isFavorite) {
            dispatch(unmarkAsFavorite({ uuid: item.uuid }))
            dispatch(Resources.unmarkAsFavorite({ uuid: item.uuid }))
        } else {
            dispatch(markAsFavorite({ uuid: item.uuid }))
            dispatch(Resources.markAsFavorite({ uuid: item.uuid }))
        }
    };

    return (
        <View style={{ flex: 1, backgroundColor: Colors.WHITE }}>
            <FlatList
                style={[{ paddingTop: SpacingH.s2 }]}
                data={favorites}
                onRefresh={() => dispatch(Resources.refreshFavoriteResources())}
                refreshing={resource.refreshing}
                onEndReached={() => { loadMore() }}
                renderItem={({ item }) =>
                    item.image ?
                        <ResourceImageCard
                            style={{
                                alignSelf: "center",
                                width: !isTabPortrait ? Size.w80 : wp(70),
                                height: !isTabPortrait ? Size.w70 : wp(70),
                                marginRight: 0,
                                marginBottom: SpacingH.s2,
                                ...GlobalStyles.containerMarginH,
                            }}
                            onPressFavorite={() => { handleFavoritePress(item) }}
                            onPress={() => navigation.navigate("WebView", { url: item.link, title: item.title })}
                            {...item} />
                        :
                        <ResourceCard
                            style={{ ...GlobalStyles.containerMarginH, width: "100%", marginBottom: SpacingH.s2 }}
                            onPressFavorite={() => { handleFavoritePress(item) }}
                            onPress={() => navigation.navigate("WebView", { url: item.link, title: item.title })}
                            {...item} />
                }
                ListEmptyComponent={<PlaceHolder style={GlobalStyles.containerMarginH} status={(resource.refreshing && !favorites.length)} type={"regularCard"} />}
                keyExtractor={item => item.uuid}
            />
        </View>
    );
}
