/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { View, StyleSheet, TouchableOpacity, TextInput, Pressable, ScrollView, Image, BackHandler } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { EvilIcons, MaterialIcons } from '@expo/vector-icons';
import { WebView } from "react-native-webview";
import { LinearGradient } from "expo-linear-gradient";
import ConfettiCannon from "react-native-confetti-cannon";

import { Colors, Gradients } from "../../themes/colors";
import { SpacingH, FontSize, Size, SpacingW } from "../../themes/sizes";
import { GlobalStyles } from "../../themes/styles";
import ContainerView from "../../components/main/ContainerView";
import config from "../../api/config";
import BoxInput from "../../components/input/BoxInput";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import HeroSemiText from "../../components/text/HeroSemiText";
import MainMediumText from "../../components/text/MainMediumText";
import MainText from "../../components/text/MainText";
import fonts from "../../themes/fonts";
import { setIsSpinning } from "../../state/slices/spinnerSlice";
import { User } from "../../api";

const Stage = {
    START: "Start",
    PAY: "Pay",
    THANKS: "Thanks",
};

export default ({ navigation, route }) => {
    const dispatch = useDispatch();
    const authToken = useSelector(state => state.user.token);
    const subscriptionEnd = useSelector(state => state.user.profile.subscriptionEnd);

    const isExpired = subscriptionEnd && (new Date()).getTime() > (new Date(subscriptionEnd)).getTime();
    const chosenCoach = route.params?.chosenCoach || false;

    React.useEffect(() => {
        dispatch(setIsSpinning(false));
        if (isExpired) {
            const backHandler = BackHandler.addEventListener("hardwareBackPress", () => true);
            return () => backHandler.remove();
        }
    }, []);

    const [stage, setStage] = React.useState(Stage.START);

    const [selectedBox, setSelectedBox] = React.useState("3");
    const [showCustomInput, setShowCustomInput] = React.useState(false);
    const [showCouponInput, setShowCouponInput] = React.useState(false);
    const [months, setMonths] = React.useState("3");
    const [coupon, setCoupon] = React.useState("");

    const confettiRef = React.useRef();

    const back = () => { stage == Stage.START ? navigation.goBack() : setStage(Stage.START); };

    const onPressSelectBox = (m) => {
        setSelectedBox(m);
        setShowCustomInput(false);
        setMonths(m);
    };

    const next = () => {
        dispatch(setIsSpinning(true));
        setStage(Stage.PAY);
    };

    const selectBox = (m) => (m === selectedBox) ? (
        <LinearGradient
            style={styles.selectedBoxStyle}
            start={[1, 0]}
            end={[0, 1]}
            colors={[Gradients.GREEN_BLUE.Start, Gradients.GREEN_BLUE.End]}>
            <View style={styles.selectedBoxInnerStyle}>
                <MainMediumText style={{ color: Colors.WHITE }}>{m}</MainMediumText>
                <MainMediumText style={{ color: Colors.WHITE }}>{m === "1" ? "Month" : "Months"}</MainMediumText>
            </View>
        </LinearGradient>
    ) : (
        <Pressable onPress={() => onPressSelectBox(m)}>
            <View style={styles.unselectedBoxStyle}>
                <MainMediumText>{m}</MainMediumText>
                <MainMediumText>{m === "1" ? "Month" : "Months"}</MainMediumText>
            </View>
        </Pressable>
    );

    return (
        <ContainerView hasHeader>
            <View style={[GlobalStyles.containerPaddingH, { justifyContent: "center", alignItems: "center", marginTop: SpacingH.s1 }]}>
                {!isExpired || stage !== Stage.START &&
                    <TouchableOpacity onPress={back} style={{ position: "absolute", left: 0 }}>
                        <EvilIcons name="chevron-left" size={42} color={Colors.BLUE_GREY} />
                    </TouchableOpacity>
                }
                <View style={{ alignSelf: "center" }}>
                    {isExpired && stage === Stage.START ?
                        <HeroSemiText style={{ fontSize: FontSize.MEDIUM, textAlign: "center", paddingBottom: 5, color: Colors.HEART_RED }}>Subscription expired</HeroSemiText>
                        :
                        <HeroSemiText style={{ fontSize: FontSize.MEDIUM, textAlign: "center", paddingBottom: 5 }}>
                            {stage === Stage.START && "Choose your subscription"}
                            {stage === Stage.PAY && "Payment information"}
                            {stage === Stage.THANKS && ""}
                        </HeroSemiText>
                    }
                </View>
                <TouchableOpacity onPress={() => navigation.navigate("Logout")} style={{ position: "absolute", right: 10 }}>
                    <MaterialIcons name="logout" size={25} color={Colors.DARK_GREY} />
                </TouchableOpacity>
            </View>

            <View style={{ justifyContent: "center", alignItems: "center", marginTop: SpacingH.s3 }}>
                <Image source={require("../../assets/LifeRamp.png")} style={{ width: Size.w50, height: Size.w50 * 0.195, marginBottom: SpacingH.s2 }} resizeMode="contain" />
            </View>

            {stage === Stage.START &&
                <View style={{ flex: 1 }}>
                    <ScrollView>

                        {isExpired &&
                            <View style={[GlobalStyles.containerPaddingH, { maxWidth: Size.w90, alignSelf: "center", marginTop: SpacingH.s1, marginBottom: SpacingH.s1 }]}>
                                <MainMediumText style={{ fontSize: FontSize.SMALL, textAlign: "center" }}>{"\n"}Choose a plan to resume your coaching.</MainMediumText>
                            </View>
                        }

                        <View style={styles.selectBoxContainer}>
                            <View style={{ flexDirection: "row", justifyContent: "center" }}>
                                {selectBox("1")}
                                {selectBox("3")}
                            </View>
                            <View style={{ flexDirection: "row", justifyContent: "center" }}>
                                {selectBox("6")}
                                {selectBox("12")}
                            </View>
                        </View>
                        <MainMediumText style={{ alignSelf: "center", marginTop: SpacingH.s3, marginBottom: SpacingH.s3 }}>OR</MainMediumText>
                        {showCustomInput ?
                            <View style={{ justifyContent: "center", alignItems: "center", marginBottom: SpacingH.s3 }}>
                                <LinearGradient
                                    style={styles.selectedCustomBox}
                                    start={[1, 0]}
                                    end={[0, 1]}
                                    colors={[Gradients.GREEN_BLUE.Start, Gradients.GREEN_BLUE.End]}>
                                    <View style={styles.selectedCustomInnerStyle}>
                                        <TextInput
                                            onChangeText={(value) => setMonths(value)}
                                            maxLength={2}
                                            autoFocus
                                            placeholderTextColor={Colors.SOFT_GREY}
                                            onFocus={() => setSelectedBox(0)}
                                            textAlign="center"
                                            keyboardType="number-pad"
                                            value={months}
                                            style={{ color: Colors.WHITE, fontFamily: fonts.MainMedium, borderBottomWidth: 0.5, borderBottomColor: Colors.WHITE, width: 30 }} />
                                        <MainMediumText style={{ color: Colors.WHITE, marginLeft: SpacingW.s1, }}>Months</MainMediumText>
                                    </View>
                                </LinearGradient>
                            </View> :
                            <View style={{ justifyContent: "center", alignItems: "center", marginBottom: SpacingH.s3 }}>
                                <Pressable onPress={() => setShowCustomInput(true)} >
                                    <View style={styles.unselectedCustomBox}>
                                        <MainMediumText >Enter a custom period</MainMediumText>
                                    </View>
                                </Pressable>
                            </View>
                        }
                        <View style={{ minHeight: 60 }}>
                            {showCouponInput ?
                                <View style={[GlobalStyles.containerPaddingH, { marginTop: SpacingH.s2, borderColor: 1, width: 240 + 2 * SpacingW.s2, alignSelf: "center", backgroundColor: Colors.SOFT_GREY, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }]}>
                                    <BoxInput
                                        onChangeText={(value) => setCoupon(value)}
                                        maxLength={20}
                                        placeholder="Discount coupon"
                                        value={coupon}
                                        style={{ paddingVertical: SpacingH.s1, borderWidth: 0, flex: 1 }}
                                    />
                                    <Pressable onPress={() => { setCoupon(""); setShowCouponInput(false); }}>
                                        <EvilIcons name="close" size={28} color={Colors.BLUE_GREY} />
                                    </Pressable>
                                </View>
                                :
                                <Pressable onPress={() => setShowCouponInput(true)}>
                                    <MainText style={{ marginBottom: SpacingH.s0, marginTop: SpacingH.s3, alignSelf: "center", fontSize: FontSize.SMALL, textDecorationLine: "underline", color: Colors.DEEP_TEAL }}>Have a discount coupon?</MainText>
                                </Pressable>
                            }
                        </View>
                        <View style={{ alignItems: "center", marginTop: SpacingH.s6, marginBottom: SpacingH.s4 }}>
                            <PrimaryButton textStyle={{ fontSize: FontSize.NORMAL, fontFamily: fonts.MainBold, color: Colors.WHITE }} onPress={next}>
                                Next
                            </PrimaryButton>
                        </View>

                    </ScrollView>

                </View>
            }

            {
                stage === Stage.PAY &&
                <WebView
                    onLoad={() => dispatch(setIsSpinning(false))}
                    source={{
                        uri: config.apiBase + "/me/payment/setup?months=" + months + "&discount_code=" + coupon,
                        headers: { Authorization: 'Bearer ' + authToken },
                    }}
                    onMessage={(event) => {
                        if (event.nativeEvent.data == "done") {
                            setStage(Stage.THANKS);

                            dispatch(setIsSpinning(true));

                            if (chosenCoach) {
                                dispatch(User.addCoach({ uuid: chosenCoach }))
                                    .then(() => dispatch(User.getMyProfile()))
                                    .then(() => { dispatch(setIsSpinning(false)); confettiRef.current.start() });
                            } else {
                                dispatch(User.getMyProfile()).then(() => { dispatch(setIsSpinning(false)); confettiRef.current.start() });
                            }
                        }
                    }}
                />
            }

            {
                stage === Stage.THANKS &&
                <View style={[GlobalStyles.containerPaddingH, { flex: 1, paddingTop: Size.h10, alignItems: "center" }]}>
                    <ConfettiCannon autoStart={false} ref={confettiRef} count={250} origin={{ x: 0.5, y: 0.6 }} explosionSpeed={500} fadeOut={false} />
                    <Image source={require("../../assets/liferamp-icon-r.png")} style={{ width: Size.w40, height: Size.w40, marginBottom: SpacingH.s2 }} resizeMode="contain" />
                    <HeroSemiText style={{ color: Colors.DEEP_TEAL, marginVertical: SpacingH.s2 }}>Thank you!</HeroSemiText>
                    <MainMediumText style={{ textAlign: "center", marginVertical: SpacingH.s3 }}>{months} month subscription activated.</MainMediumText>
                    <PrimaryButton style={{ marginTop: SpacingH.s2 }} textStyle={{ fontFamily: fonts.MainBold, color: Colors.WHITE }} onPress={() => { navigation.popToTop(); }}>Continue</PrimaryButton>
                </View>
            }
        </ContainerView>
    );
};

const styles = StyleSheet.create({
    selectBoxContainer: {
        marginTop: SpacingH.s2,
    },
    selectedBoxStyle: {
        width: 120,
        height: 120,
        justifyContent: "center",
        alignItems: "center",
        margin: SpacingW.s2,
    },
    selectedBoxInnerStyle: {
        width: 110,
        height: 110,
        backgroundColor: Colors.TRANSPARENT,
        borderColor: Colors.WHITE,
        borderWidth: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    unselectedBoxStyle: {
        width: 120,
        height: 120,
        justifyContent: "center",
        alignItems: "center",
        margin: SpacingW.s2,
        borderWidth: 0.5
    },
    monthInput: {
        alignSelf: "center",
        borderWidth: 0.5,
        marginTop: SpacingH.s1,
        alignItems: "center",
        justifyContent: "center"
    },
    unselectedCustomBox: {
        width: 240 + 2 * SpacingW.s2,
        height: 50,
        justifyContent: "center",
        alignItems: "center",
        margin: SpacingW.s2,
        borderWidth: 0.5
    },
    selectedCustomBox: {
        width: 240 + 2 * SpacingW.s2,
        height: 50,
        justifyContent: "center",
        alignItems: "center",
        margin: SpacingW.s2,
    },
    selectedCustomInnerStyle: {
        width: 230 + 2 * SpacingW.s2,
        height: 40,
        backgroundColor: Colors.TRANSPARENT,
        borderColor: Colors.WHITE,
        borderWidth: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row"
    },
});
