/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    spinning: false,
    message: "",
};

export const spinnerSlice = createSlice({
    name: "spinner",
    initialState,
    reducers: {
        setIsSpinning(state, { payload }) {
            state.spinning = payload;
        },
        setMessage(state, { payload: { message }}) {
            state.message = message;
        },
    },
});

export const { setIsSpinning, setMessage } = spinnerSlice.actions;

export default spinnerSlice.reducer;
