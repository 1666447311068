/**
 * LifeRamp
 *
 * @author      Anees Muzzafer
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, TextInput, View, Platform, Pressable, Image } from "react-native";

import Fonts from "../../themes/fonts";
import { Colors } from "../../themes/colors";
import { FontSize, SpacingW } from "../../themes/sizes";
import MainMediumText from "../text/MainMediumText";


export default (props) => {
    const [commentText, setCommentText] = React.useState("");
    const [currentHeight, setCurrentHeight] = React.useState(0);

    const textChangeHandler = (text) => {
        if(text.length <= 250) {
            setCommentText(text);
        }
    };

    const onPressPost = () => {
        if (props.loading) {
            return;
        }

        props.onPostPress(commentText);
        setCommentText("");
    };

    return (
        <View style={[styles.commentInputContainer, props.style]}>
            <Image source={{ uri: props.imageSource }} style={styles.commentImage} />
            <TextInput
                maxLength={1000}
                {...props}
                style={[styles.commentInput, props.style, { height: Math.max(70, currentHeight), paddingVertical: 10, paddingTop: 25 }]}
                multiline={true}
                onChangeText={textChangeHandler}
                blurOnSubmit={true}
                onKeyPress={(event) => {
                    if (event.nativeEvent.key == "Enter") {
                        onPressPost();
                    }
                }}
                onContentS
                onContentSizeChange={(event) => {
                    if (event.nativeEvent.contentSize.height < 140) {
                        setCurrentHeight(event.nativeEvent.contentSize.height);
                    }
                }}
                value={commentText}
            />
            <View style={{}}>
                <Pressable onPress={onPressPost}>
                    <MainMediumText style={{ marginRight: SpacingW.s5, color: commentText ? Colors.DEEP_TEAL : Colors.BLUE_GREY }}>Post</MainMediumText>
                </Pressable>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    commentInputContainer: {
        alignSelf: "flex-end",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: Colors.WHITE,
        borderTopColor: Colors.WHITE_GREY,
        borderTopWidth: 1,
    },
    commentInput: {
        flex: 1,
        paddingHorizontal: SpacingW.s3,
        color: Colors.TEXT_PRIMARY,
        fontSize: FontSize.NORMAL,
        fontFamily: Fonts.Main,
        alignSelf: "center",
        paddingTop: Platform.OS == "ios" ? 15 : 0,
    },
    commentImage: {
        width: 30,
        height: 30,
        borderRadius: 15,
        marginLeft: SpacingW.s4
    },
});
