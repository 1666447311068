/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import Journey from "../screens/dashboard/Journey";
import Goals from "../screens/dashboard/Goals";
import Connect from "../screens/dashboard/Connect";
import Discover from "../screens/dashboard/Discover";
import Profile from "../screens/dashboard/Profile";

import BottomBarIcon from "../components/icons/BottomBarIcon";
import { Colors } from "../themes/colors";
import fonts from "../themes/fonts";
import { Size, SpacingH } from "../themes/sizes";

const DashboardTabs = createBottomTabNavigator();
const DashboardTabsScreen = () => (
    <DashboardTabs.Navigator
        initialRouteName="Connect"
        headerMode="none"
        tabBarOptions={{
            activeTintColor: Colors.DEEP_TEAL,
            labelStyle: {
                fontFamily: fonts.MainBold,
                fontSize: 10,
            },
            style: { marginBottom: Size.h05, paddingBottom: SpacingH.s0  }
        }}>
        <DashboardTabs.Screen
            name="Connect" component={Connect}
            options={{ title: "Connect", tabBarIcon: props => <BottomBarIcon name="home" active={props.focused} /> }} />
        <DashboardTabs.Screen
            name="Journey" component={Journey}
            options={{ title: "Journey", tabBarIcon: props => <BottomBarIcon name="journey" active={props.focused} /> }} />
        <DashboardTabs.Screen
            name="Goals" component={Goals}
            options={{ title: "Goals", tabBarIcon: props => <BottomBarIcon name="goals" active={props.focused} /> }} />
        <DashboardTabs.Screen
            name="Discover" component={Discover}
            options={{ title: "Discover", tabBarIcon: props => <BottomBarIcon name="resources" active={props.focused} /> }} />
        <DashboardTabs.Screen
            name="Profile" component={Profile}
            options={{ title: "Profile", tabBarIcon: props => <BottomBarIcon name="profile" active={props.focused} /> }} />
    </DashboardTabs.Navigator>
);

export default DashboardTabsScreen;
