/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, View, Pressable, Image } from "react-native";
import datetime from "date-and-time";

import { Colors } from "../../themes/colors";
import { FontSize, SpacingH, SpacingW, hp, Size, wp } from "../../themes/sizes";

import MainMediumText from "../text/MainMediumText";
import MainBoldText from "../text/MainBoldText";

export default ({ uuid, user, title, content, image, postedAt, circleShortName, onKebabPress, onUserPress = () => { }, onPostPress }) => {

    const parseContent = (content) => {
        if (!content) return;

        let pattern = /(((https?:\/\/))[^\s]+)/g;
        return content.replace(pattern, (url) => {
            return url.replace(/(^\w+:|^)\/\//, "");
        });
    };

    return (
        <Pressable onPress={() => onPostPress(uuid)} onLongPress={onKebabPress} style={[styles.cardWrapper, styles.boxShadow]}>
            {image &&
                <View style={styles.cardImageWrapper}>
                    <Image source={{ uri: image }} style={styles.cardImage} resizeMode="cover" />
                </View>
            }
            <View style={{ marginHorizontal: SpacingW.s4 }}>
                <View style={styles.cardContent}>
                    <MainBoldText style={{ fontSize: FontSize.NORMAL, marginBottom: SpacingH.s1 }} numberOfLines={1}>{title}</MainBoldText>
                    <MainMediumText style={{ fontSize: FontSize.SMALLER }} numberOfLines={image ? 3 : 4}>{parseContent(content)}</MainMediumText>
                </View>
                <View style={styles.cardDetails}>
                    <Pressable style={styles.cardDetailsOwner} onLongPress={onKebabPress} onPress={() => onUserPress(user.uuid)}>
                        <MainMediumText style={{ fontSize: FontSize.SMALLER }}>{user.name}</MainMediumText>
                        <MainMediumText style={styles.cardInfo}>{circleShortName} Admin</MainMediumText>
                    </Pressable>
                    <View style={[styles.cardDetailsOwner, { alignItems: "flex-end" }]}>
                        <MainMediumText style={{ fontSize: FontSize.SMALLER }}>{datetime.format(new Date(postedAt), "ddd, MMM D YYYY")}</MainMediumText>
                        <MainMediumText style={styles.cardInfo}>{datetime.format(new Date(postedAt), "hh:ss A")}</MainMediumText>
                    </View>
                </View>
            </View>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    cardWrapper: {
        flexDirection: "column",
        backgroundColor: "#FCFCFC",
        marginHorizontal: wp(6),
        marginTop: SpacingH.s3,
        paddingBottom: SpacingH.s2,
        borderRadius: hp(0.8),
    },
    boxShadow: {
        shadowColor: Colors.BLACK,
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        elevation: 4,
    },
    cardDetails: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    cardDetailsOwner: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
    },
    ownerImage: {
        height: 30,
        width: 30,
        borderRadius: 15,
        marginRight: SpacingW.s2,
    },
    kebabWrapper: {
        width: Size.w10,
        height: Size.h5,
        justifyContent: "center",
        alignItems: "flex-end",
    },
    cardContent: {
        marginTop: SpacingH.s2,
        marginBottom: SpacingH.s2,
    },
    cardImageWrapper: {
        // marginBottom: SpacingH.s0,
    },
    cardImage: {
        height: hp("23%"),
        width: "100%",
        borderTopLeftRadius: hp(0.8),
        borderTopRightRadius: hp(0.8),
        margin: "auto",
    },
    cardInfo: {
        fontSize: FontSize.VERY_SMALL,
        color: Colors.BLUE_GREY
    },
});
