/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import Welcome from "../screens/auth/Welcome";
import SignIn from "../screens/auth/SignIn";
import SignUp from "../screens/auth/SignUp";

import MatchList from "../screens/matching/MatchList";
import CoachProfile from "../screens/matching/CoachProfile";
import ResetPassword from "../screens/auth/ResetPassword";

const AuthStack = createStackNavigator();
const AuthStackScreen = () => (
    <AuthStack.Navigator
        headerMode="none">
        {/* <AuthStack.Screen name="MatchList" component={MatchList} /> */}
        {/* <AuthStack.Screen name="CoachProfile" component={CoachProfile} /> */}
        <AuthStack.Screen name="Welcome" component={Welcome} options={{ title: 'LifeRamp' }} />
        <AuthStack.Screen name="SignIn" component={SignIn} options={{ title: 'Sign In' }} />
        <AuthStack.Screen name="SignUp" component={SignUp} options={{ title: 'Sign Up' }} />
        <AuthStack.Screen name="ResetPassword" component={ResetPassword} options={{ title: 'Reset Password' }} />
    </AuthStack.Navigator>
);

export default AuthStackScreen;
