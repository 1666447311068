/**
 * LifeRamp
 *
 * @author      Chetanya gupta
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import About from "../screens/others/About";
import OpenSourceSoftware from "../screens/others/OpenSourceSoftware";

const AboutStack = createStackNavigator();
const AboutStackScreen = () => (
    <AboutStack.Navigator headerMode="none">
        <AboutStack.Screen name="About" component={About} options={{ title: 'About' }}/>
        <AboutStack.Screen name="OpenSourceSoftware" component={OpenSourceSoftware} options={{ title: 'About' }}/>
    </AboutStack.Navigator>
);

export default AboutStackScreen;
