/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { View, StyleSheet, TouchableOpacity, Animated } from "react-native";

import { FontSize, Size, wp } from "../../themes/sizes";
import { Colors } from "../../themes/colors";

import MainMediumText from "../text/MainMediumText";
import fonts from "../../themes/fonts";

export default ({ children, buttonStyle, buttonSize, style, textStyle, onPress, animated, activeOpacity = 1 }) => {
    const [animation, setAnimation] = React.useState(false);
    const slideAnim = React.useRef(new Animated.Value(0)).current;

    React.useEffect(() => {
        if (animated) {
            if (buttonStyle === "") {
                setAnimation(true);
                PressedAnimation();
            } else if (buttonStyle === "transparent") {
                UnpressedAnimation();
            }
        }
    }, [buttonStyle]);

    const onButtonPress = () => {
        if (animated) {
            PressedAnimation();
        }
        onPress();
    };

    const PressedAnimation = () => {
        Animated.timing(slideAnim, {
            toValue: 1,
            duration: 300,
            useNativeDriver: false,
        }).start();
    };

    const UnpressedAnimation = () => {
        Animated.timing(slideAnim, {
            toValue: 0,
            duration: 300,
            useNativeDriver: false,
        }).start(() => { setAnimation(false) });
    };

    const slideAnimInterpolated = slideAnim.interpolate({
        inputRange: [0, 1],
        outputRange: ["0%", "100%"]
    });

    const textColorAnim = slideAnim.interpolate({
        inputRange: [0, 1],
        outputRange: [Colors.WHITE, Colors.TEXT_PRIMARY]
    });

    return (
        <TouchableOpacity onPress={onButtonPress} activeOpacity={activeOpacity}>
            <View
                style={[
                    styles.Button,
                    buttonSize === "sm" && styles.smButton,
                    buttonSize === "lg" && styles.lgButton,
                    buttonSize === "xl" && styles.xlButton,
                    buttonStyle === "navy" && styles.navyButton,
                    buttonStyle === "transparent" && styles.transparentButton,
                    style,
                ]}>
                {animation &&
                    <View style={{ width: "100%", height: 64, zIndex: 2, position: "absolute", justifyContent: "center", alignItems: "center" }}>
                        <Animated.View style={[styles.anim, { width: slideAnimInterpolated, height: slideAnimInterpolated, opacity: slideAnim }]} ></Animated.View>
                    </View>}
                <Animated.Text style={[styles.buttonText, textStyle, { color: animated ? textColorAnim : Colors.WHITE }]}>
                    {children}
                </Animated.Text>
            </View>
        </TouchableOpacity>
    );

}

const styles = StyleSheet.create({
    Button: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderColor: Colors.WHITE,
        width: wp("40%"),
        height: 64,
        borderRadius: 32,
    },
    navyButton: {
        borderWidth: 0,
        borderColor: Colors.SOFT_WHITE,
        backgroundColor: Colors.NAVY,
    },
    transparentButton: {
        borderWidth: 1,
        borderColor: Colors.SOFT_WHITE,
        backgroundColor: Colors.TRANSPARENT,
    },
    smButton: {
        width: 108,
        height: 64,
    },
    lgButton: {
        width: wp("81%"),
        height: 64,
    },
    xlButton: {
        width: 390,
        height: 52,
    },
    buttonText: {
        fontFamily: fonts.MainMedium,
        fontSize: FontSize.NORMAL,
        color: Colors.TEXT_PRIMARY,
        zIndex: 3
    },
    anim: {
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.WHITE,
        borderRadius: 32,
        width: "100%",
    }
});
