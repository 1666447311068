/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScrollView, View, StyleSheet, Image, Platform, ImageBackground, TouchableOpacity, TextInput, KeyboardAvoidingView } from "react-native";
import * as ImagePicker from "expo-image-picker";
import mime from "mime";
import randomString from "@afaanbilal/random-string";

import firebase from "firebase/app";

import Fonts from "../../../themes/fonts";
import { FontSize, Size, SpacingH, SpacingW, Utils, wp } from "../../../themes/sizes";
import { Colors } from "../../../themes/colors";

import ContainerView from "../../../components/main/ContainerView";

import MainMediumText from "../../../components/text/MainMediumText";
import MainText from "../../../components/text/MainText";
import PrimaryButton from "../../../components/buttons/PrimaryButton.js"
import LinkButton from "../../../components/buttons/LinkButton"
import TagButton from "../../../components/buttons/TagButton";
import { EvilIcons } from '@expo/vector-icons';

import { Circle, Posts } from "../../../api";
import { setIsSpinning } from "../../../state/slices/spinnerSlice";

const blankPost = { title: "", content: "", image: null, tags: [] };

export default ({ navigation, route }) => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.circle.loading);
    const user = useSelector(state => state.user.profile);
    const circle = useSelector(state => state.circle);
    const postType = route.params?.postType || "post";
    const IsPost = postType === "post";
    const isEdit = route.params && route.params.post;
    const [postValid, setPostValid] = React.useState(true);
    const [error, setError] = React.useState(null);

    const aspectRatio = React.useRef(1);

    const pickImage = async () => {
        const gotPermission = await (async () => {
            if (Platform.OS !== "web") {
                const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
                if (status !== "granted") {
                    alert("The camera roll permission is needed to add an image to the post!");
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        })();

        if (!gotPermission) {
            return;
        }

        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            quality: 0.8,
        });

        aspectRatio.current = Math.max(0.3, Math.min(3.33, result.width / result.height));

        if (!result.cancelled) {
            setPostValid(true);
            setPost({ ...post, image: result.uri });
        }
    };

    const [tagText, setTagText] = React.useState("");
    const [currentHeight, setCurrentHeight] = React.useState(60);
    const [titleCurrentHeight, setTitleCurrentHeight] = React.useState(20);

    const [post, setPost] = React.useState((route.params && route.params.post) ? { ...route.params.post } : blankPost);
    const [shouldPost, setShouldPost] = React.useState(false);

    React.useEffect(() => {
        setPost({ ...post, type: postType });
    }, []);

    const addTag = (tag) => {
        if (!tag.startsWith("#")) {
            tag = "#" + tag;
        }

        if (!post.tags.includes(tag)) {
            setPost({ ...post, tags: [...post.tags, tag] });
        }
    };

    const tagTextChanged = (text) => {
        let tag = null;

        if (text.endsWith(",")) {
            tag = text.replace(",", "");
        }

        if (text.endsWith(" ")) {
            tag = text.replace(" ", "");
        }

        if (tag) {
            addTag(tag);
            setTagText("");
            return tag;
        }

        setTagText(text);
    };

    const doPost = async () => {
        if (loading) return;
        if (tagText) {
            addTag(tagText);
            setTagText("");
        }
        if (post.title === "") {
            setError("Please fill in the post title");
            return setPostValid(false);
        }
        if (post.content === "") {
            setError("Post content cannot be empty");
            return setPostValid(false);
        }
        if (post.image && post.image.startsWith("file")) {
            dispatch(setIsSpinning(true));

            const uri = post.image;
            let refPath = "post-images/" + randomString() + "/" + uri.split("/").pop();

            if (isEdit && route.params.post.image) {
                const imagePathParts = route.params.post.image.split("/");
                const finalPart = imagePathParts.pop();
                const randomId = imagePathParts.pop();

                refPath = "post-images/" + randomId + "/" + finalPart;
            }

            const ref = firebase.storage().ref(refPath);
            const response = await fetch(uri);
            const blob = await response.blob();
            if (blob.size <= (5 * 1024 * 1024)) {
                await ref.put(blob, { contentType: mime.getType(uri) });
                const url = await ref.getDownloadURL();
                setPost({ ...post, image: url });
                setShouldPost(true);
            } else {
                dispatch(setIsSpinning(false));
                setPostValid(false);
                setError("Please choose an image less than 5MB");
            }

        } else {
            setShouldPost(true);
        }
    };

    React.useEffect(() => {
        if (shouldPost) {
            dispatch(setIsSpinning(true));

            if (isEdit) {
                dispatch(Posts.updatePost({ uuid: circle.uuid, post })).then(() => { dispatch(setIsSpinning(false)); navigation.goBack(); });
            } else {
                dispatch(Circle.createPost({ uuid: circle.uuid, post })).then(() => { dispatch(setIsSpinning(false)); navigation.goBack() });
            }

            setShouldPost(false);
        }
    });

    return (
        <ContainerView style={{ paddingHorizontal: 0 }} hasHeader>
            <KeyboardAvoidingView keyboardVerticalOffset={Utils.keyboardAvoidOffset} style={{ flex: 1 }} behavior={Platform.OS == "ios" ? "padding" : null}>
                <ScrollView style={{ flex: 1, paddingHorizontal: SpacingW.s3, paddingTop: SpacingW.s2, paddingBottom: SpacingH.s4 }}>

                    <View style={styles.addPostWrapper}>
                        {IsPost &&
                            <View style={{ flexDirection: "row", alignItems: "center", marginBottom: SpacingH.s1 }}>
                                <Image source={{ uri: user.image_profile }} style={styles.profileImage} />
                                <MainMediumText style={{ marginLeft: SpacingW.s2 }}>{user.name}</MainMediumText>
                            </View>
                        }
                        {!postValid && <MainText style={{ color: Colors.HEART_RED }}>{error}</MainText>}
                        <TextInput
                            style={[styles.input, styles.titleInput, { height: Math.max(30, titleCurrentHeight) }]}
                            maxLength={500}
                            multiline={true}
                            placeholder={IsPost ? "Write a compelling title..." : "Title goes here"}
                            value={post.title}
                            onChangeText={(text) => {
                                setPost({ ...post, title: text });
                                if (text == "") {
                                    setTitleCurrentHeight(0);
                                }
                            }}
                            onContentSizeChange={(event) => (setTitleCurrentHeight(event.nativeEvent.contentSize.height))}
                        />

                        <TouchableOpacity onPress={pickImage} activeOpacity={0.8}>
                            {post.image === null &&
                                <ImageBackground
                                    source={require("../../../assets/illustrations/add_post_image.png")}
                                    resizeMode="contain"
                                    imageStyle={[styles.postDefaultImageContainer, { transform: [{ rotateY: "180deg" }] }]}
                                    style={[styles.postDefaultImage, { justifyContent: "flex-end", paddingBottom: SpacingH.s1, opacity: 0.95 }]}>
                                    <View style={{ justifyContent: "center", alignItems: "center", borderWidth: 0, paddingBottom: SpacingH.s1 }}>
                                        <EvilIcons name="plus" size={35} style={{ borderWidth: 0 }} color={Colors.BLUE_GREY} />
                                        <MainMediumText style={{ color: Colors.BLUE_GREY, fontSize: FontSize.SMALL }}>Add an image</MainMediumText>
                                    </View>
                                </ImageBackground>
                            }
                            {post.image !== null &&
                                <Image
                                    source={{ uri: post.image }}
                                    style={{
                                        width: "100%",
                                        height: (Size.w100 - 2 * SpacingW.s4) / aspectRatio.current,
                                        borderRadius: SpacingH.s1
                                    }}
                                    resizeMode="cover"
                                />}
                        </TouchableOpacity>
                        <TextInput
                            style={[styles.input, styles.contentInput, { height: Math.max(50, currentHeight) }]}
                            multiline={true}
                            maxLength={5000}
                            placeholder="Put your ideas into words here..."
                            value={post.content}
                            onChangeText={(text) => {
                                setPost({ ...post, content: text });
                                if (text == "") {
                                    setCurrentHeight(0);
                                }
                            }}
                            onContentSizeChange={(event) => (setCurrentHeight(event.nativeEvent.contentSize.height))}
                        />

                        <View style={styles.tagsWrapper}>
                            {post.tags.map((t) => (
                                <TagButton
                                    key={t}
                                    onPress={() => setPost({ ...post, tags: post.tags.filter(tag => tag != t) })}>
                                    {t}
                                </TagButton>))
                            }
                        </View>
                        {IsPost &&
                            <TextInput
                                style={{ ...styles.input, ...styles.tagInput }}
                                placeholder="Add some #tags here"
                                maxLength={50}
                                value={tagText}
                                onChangeText={tagTextChanged}
                            />
                        }
                    </View>

                    <View style={styles.buttonContainer}>
                        <PrimaryButton style={styles.postButton} buttonSize={'lg'} onPress={doPost}>
                            {isEdit ? "Update" : "Post"}
                        </PrimaryButton>
                        <LinkButton textStyle={{ fontSize: FontSize.SMALL }} onPress={() => { navigation.goBack() }}>Never mind, I'll do it later</LinkButton>
                    </View>

                </ScrollView>
            </KeyboardAvoidingView>
        </ContainerView>
    );
};

const styles = StyleSheet.create({
    addPostWrapper: {
        paddingHorizontal: SpacingW.s3,
        paddingVertical: SpacingH.s2,
        borderColor: Colors.WHITE_GREY,
        borderRadius: 10,
        borderWidth: 1,
        backgroundColor: Colors.WHITE,
        marginBottom: SpacingH.s2,
    },
    profileImage: {
        height: 48,
        width: 48,
        borderRadius: 24,
        marginRight: SpacingW.s2,
    },
    input: {
        width: "100%",
        marginVertical: SpacingH.s1,
        paddingVertical: SpacingH.s0,
        paddingHorizontal: SpacingW.s1,
    },
    titleInput: {
        fontFamily: Fonts.TitleSemiBold,
        fontSize: FontSize.MEDIUM,
        fontWeight: "normal",
    },
    contentInput: {
        fontFamily: Fonts.Main,
        fontSize: FontSize.NORMAL,
        textAlign: "justify",
        paddingVertical: SpacingH.s3,
    },
    tagInput: {
        fontFamily: Fonts.Main,
        fontSize: FontSize.SMALL,
    },
    postDefaultImageContainer: {
        width: "100%",
        height: wp(90) * 581 / 800,
        borderRadius: 8,
        marginVertical: SpacingH.s2,
    },
    postDefaultImage: {
        width: "100%",
        height: wp(90) * 581 / 800,
    },
    tagsWrapper: {
        flexDirection: "row",
        flex: 1,
        flexShrink: 1,
        flexWrap: "wrap",
    },
    postButton: {
        marginBottom: SpacingH.s3,
    },
    buttonContainer: {
        alignItems: "center",
        marginBottom: SpacingH.s3,
    },
});
