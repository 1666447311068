/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import * as Sentry from "sentry-expo";

Sentry.init({
    dsn: "https://c8ee33987489424f8dddf4b2140a683f@o931006.ingest.sentry.io/5879770",
    enableInExpoDevelopment: false,
    debug: false,
});

import { LogBox } from "react-native";
import Navigation from "./src/navigation";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

export default function App() {
    React.useEffect(() => {
        try {
            LogBox && LogBox.ignoreLogs(["Setting a timer", "javascript-time-ago"]);
            TimeAgo.addDefaultLocale(en);
        } catch (err) { }
    }, []);

    return <Navigation />;
}
