/**
 * LifeRamp
 *
 * @author      Chetanya Gupta
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */
import React from "react";
import { StyleSheet, View } from "react-native";

import { AirbnbRating } from "react-native-ratings";
import { Colors } from "../../themes/colors";
import { Size, SpacingH } from "../../themes/sizes";

export default ({ onStarRatingPress, style }) => (
    <View style={[styles.container, style]}>
        <AirbnbRating
            starContainerStyle={{ marginVertical: SpacingH.s0, width: Size.w70, justifyContent: "space-evenly" }}
            selectedColor={Colors.DEEP_TEAL}
            showRating={false}
            count={5}
            reviews={["Bad", "OK", "Good", "Very Good", "Amazing"]}
            defaultRating={0}
            size={30}
            onFinishRating={value => onStarRatingPress(value)}
        />
    </View>
);

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
    },
});
