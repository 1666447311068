/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { Text, StyleSheet, View, TouchableOpacity, Image } from "react-native";
import { FontSize, SpacingH, SpacingW, Size } from "../../themes/sizes";
import { Colors } from "../../themes/colors";

import { EvilIcons } from "@expo/vector-icons";
import { useSelector } from "react-redux";

export default ({ children, style, navigation }) => {
    const circle = useSelector(state => state.circle.data);
    return (
        <View style={styles.header}>
            <TouchableOpacity onPress={() => { navigation.goBack(); }}>
                <EvilIcons name="chevron-left" size={42} color={Colors.BLUE_GREY} />
            </TouchableOpacity>
            <View style={styles.circleContainer}>
                <Image source={{ uri: circle.image }} style={styles.circleImage} />
                <Text style={{ fontSize: FontSize.SMALL }}>{circle.name}</Text>
            </View>
            <View style={{ width: 42 }} />
        </View>
    );
};

const styles = StyleSheet.create({
    header: {
        flexDirection: "row",
        marginTop: Size.adjustedStatusBarHeight,
        alignItems: "center",
        backgroundColor: Colors.WHITE,
        paddingVertical: SpacingH.s1,
        paddingLeft: SpacingH.s1,
        paddingRight: SpacingW.s3,
        borderBottomColor: Colors.SOFT_GREY,
        borderBottomWidth: 1,
    },
    circleContainer: {
        flex: 1,
        alignItems: "center",
    },
    circleImage: {
        height: 42,
        width: 42,
        borderRadius: 21,
        marginVertical: SpacingH.s1,
    },
});
