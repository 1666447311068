/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { View } from "react-native";
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { sV, FontSize } from "../../themes/sizes";

import ContainerView from "../../components/main/ContainerView";

import HeroText from "../../components/text/HeroText";

import Resources from "./discover/Resources";
import Favorites from "./discover/Favorites";
import { GlobalStyles, materialTopTabs } from "../../themes/styles";

const DiscoverTabs = createMaterialTopTabNavigator();


export default ({ navigation }) => (
    <ContainerView>
        <View style={[GlobalStyles.containerMarginTop, GlobalStyles.containerMarginH]}>
            <HeroText style={{... GlobalStyles.containerPaddingH, fontSize: FontSize.BIG }}>Learn continuously.</HeroText>
            <HeroText style={{... GlobalStyles.containerPaddingH, fontSize: FontSize.BIG }}>Explore fearlessly.</HeroText>
            <HeroText style={{... GlobalStyles.containerPaddingH, fontSize: FontSize.BIG, marginBottom: sV(5) }}>Grow endlessly.</HeroText>
        </View>
        <DiscoverTabs.Navigator tabBarOptions={materialTopTabs}>
            <DiscoverTabs.Screen name="Browse" component={Resources} />
            <DiscoverTabs.Screen name="Bookmarked" component={Favorites} />
        </DiscoverTabs.Navigator>
    </ContainerView>
);
