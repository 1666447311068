/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from 'react';
import { Pressable, Animated } from "react-native";
import Svg, { Path } from "react-native-svg";
import { Colors } from "../../themes/colors";

export default ({ onPress, style, isLiked, width = 21, height = 18 }) => {

    const [canLike, setCanLike] = React.useState(true);
    const likeAnim = React.useRef(new Animated.Value(1)).current;

    const scaleAnim = () => {
        likeAnim.setValue(0);
        Animated.spring(likeAnim, {
            toValue: 1,
            friction: 3,
            useNativeDriver: true,
        }).start();
    };

    const handlePress = () => {
        if (canLike) {
            setCanLike(false);
            setTimeout(() => { setCanLike(true); }, 2500);
            onPress();
            scaleAnim();
        }
    };

    return (
        <Animated.View style={{ transform: [{ scale: likeAnim }] }}>
            <Pressable hitSlop={24} onPress={handlePress} style={style}>
                <Svg width={width} height={height} viewBox="0 0 23 20">
                    {
                        isLiked ? (
                            <Path id="svg_1" d="m11.03976,18.44722l-8.52586,-8.47685c-1.97069,-1.95462 -1.97069,-5.12315 0,-7.05719c1.9707,-1.93404 5.16531,-1.95461 7.11526,0l1.4106,1.39909l1.41061,-1.39909c1.9707,-1.95461 5.1653,-1.95461 7.11526,0c1.94995,1.95462 1.97069,5.12315 0,7.05719l-8.52587,8.47685z" strokeWidth="1.2" stroke={Colors.HEART_RED} fill={Colors.HEART_RED} />
                        ) : (
                            <Path id="svg_1" d="m11.03976,18.44722l-8.52586,-8.47685c-1.97069,-1.95462 -1.97069,-5.12315 0,-7.05719c1.9707,-1.93404 5.16531,-1.95461 7.11526,0l1.4106,1.39909l1.41061,-1.39909c1.9707,-1.95461 5.1653,-1.95461 7.11526,0c1.94995,1.95462 1.97069,5.12315 0,7.05719l-8.52587,8.47685z" strokeWidth="1.2" stroke={Colors.BLACK} fill={Colors.WHITE} />
                        )
                    }
                </Svg>
            </Pressable>
        </Animated.View>
    );
}
