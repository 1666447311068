/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert, FlatList, Image, TouchableOpacity, View, Switch, Platform, Linking } from "react-native";
import { FontAwesome5, MaterialIcons, Ionicons, FontAwesome, EvilIcons, MaterialCommunityIcons } from '@expo/vector-icons';

import { GlobalStyles } from "../../themes/styles";
import { FontSize, Size, SpacingH, SpacingW } from "../../themes/sizes";
import { Colors } from "../../themes/colors";
import ContainerView from "../../components/main/ContainerView";
import PostCard from "../../components/cards/PostCard";
import SlideUpModal from "../../components/utils/SlideUpModal";
import MainMediumText from "../../components/text/MainMediumText";
import MainText from "../../components/text/MainText";
import HeroText from "../../components/text/HeroText";
import PlaceHolder from "../../components/utils/PlaceHolder";

import { setIsSpinning } from "../../state/slices/spinnerSlice";
import { setPost, addPost, setCircleFromUUID } from "../../state/slices/circleSlice";
import { createOrGetConversation } from "../../chat";
import { User } from "../../api";
import { setNotificationsEnabled } from "../../state/slices/userSlice";
import { reset } from "../../state/store";
import Fonts from "../../themes/fonts";

export default ({ navigation, route }) => {
    const dispatch = useDispatch();
    const [isModalVisible, setModalVisible] = React.useState(false);

    const state = useSelector(state => state.user);
    const user = useSelector(state => state.user.profile);
    const circleUUID = useSelector(state => state.circle.uuid);
    const isCircleInternal = useSelector(state => state.circle.data.isInternal);

    const uuid = route.params?.uuid || false;

    let userProfile = null;
    let posts = null;
    if (uuid) {
        userProfile = useSelector(state => state.user.otherUser);
        posts = useSelector(state => state.user.otherPosts).filter(p => p.circle.uuid === circleUUID);
    } else {
        userProfile = useSelector(state => state.user.profile);
        posts = useSelector(state => state.user.posts);

        if (!user.isSuperAdmin) {
            posts = posts.filter(p => p.circle.uuid === circleUUID)
        }
    }

    posts = posts.filter(p => p.type !== "notice");

    React.useEffect(() => {
        if (!uuid) {
            dispatch(User.getMyProfile());
        }
    }, []);

    React.useEffect(() => {
        if (!uuid) {
            dispatch(User.refreshMyPosts());
        }
    }, [userProfile]);

    const onRefresh = () => {
        if (uuid) {
            dispatch(User.refreshPosts({ uuid }));
        } else {
            dispatch(User.refreshMyPosts());
            dispatch(User.getMyProfile());
        }
    };

    const loadMore = () => {
        if (state.loading) return;

        if (uuid) {
            state.otherPage <= state.otherMaxPage && dispatch(User.getPosts({ uuid: uuid, page: state.otherPage }));
        } else {
            state.page <= state.maxPage && dispatch(User.getMyPosts({ page: state.page }));
        }
    };

    const messageUser = async (otherUser) => {
        dispatch(setIsSpinning(true));
        const threadId = await createOrGetConversation(user, otherUser);
        navigation.navigate("ChatStackScreen", { screen: "Chat", params: { threadId: threadId, user: otherUser } });
        dispatch(setIsSpinning(false));
    };

    const onPostPress = (post) => {
        if (!uuid) {
            dispatch(setCircleFromUUID({ uuid: post.circle?.uuid }));
        }
        dispatch(addPost({ post }))
        dispatch(setPost({ uuid: post.uuid, type: post.type }));
        setTimeout(() => navigation.navigate("CircleStackScreen", { screen: "CircleViewPost" }), 0);
    };

    return (
        <ContainerView hasHeader>
            <FlatList
                ListHeaderComponent={
                    <View style={[GlobalStyles.containerPaddingH, { paddingTop: SpacingH.s2 }]}>
                        {userProfile.name ?
                            <>
                                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                    <TouchableOpacity onPress={() => { navigation.goBack(); }} style={{ position: "absolute", top: 35, left: 0, zIndex: 10, opacity: !uuid ? 0 : 1 }}>
                                        <EvilIcons name="chevron-left" size={42} color={Colors.BLUE_GREY} />
                                    </TouchableOpacity>
                                    <View style={{ justifyContent: "flex-start", alignItems: "center", paddingTop: SpacingH.s0, paddingLeft: SpacingW.s0, opacity: !uuid ? 1 : 0 }}>
                                        <TouchableOpacity onPress={() => { !uuid && navigation.navigate("ChatStackScreen") }}>
                                            <FontAwesome name="envelope-o" size={24} color={Colors.BLUE_GREY} />
                                        </TouchableOpacity>
                                    </View>
                                    <Image source={{ uri: userProfile.image_profile }} style={{ width: 100, height: 100, borderRadius: 50, borderWidth: 1, borderColor: Colors.BLUE_GREY }} />
                                    <View style={{ justifyContent: "flex-start", alignItems: "center", opacity: !uuid ? 1 : 0 }}>
                                        <TouchableOpacity
                                            onPress={() => { !uuid && setModalVisible(true) }} >
                                            <Ionicons name="ios-menu-outline" size={32} color={Colors.BLUE_GREY} />
                                        </TouchableOpacity>
                                    </View>
                                </View>
                                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", paddingTop: SpacingH.s2, paddingBottom: SpacingH.s1 }}>
                                    <HeroText style={{ fontSize: FontSize.BIG }}>
                                        {userProfile.firstname + " " + userProfile.lastname}
                                    </HeroText>
                                </View>
                                <View style={{ alignItems: "center", paddingBottom: SpacingH.s2, borderBottomWidth: 0.5, borderBottomColor: Colors.BLUE_GREY }}>
                                    {userProfile.role && <MainText style={{ paddingBottom: SpacingH.s1, textAlign: "center" }}>{userProfile.role}</MainText>}
                                    {/* {userProfile.pronouns && <MainText style={{ paddingBottom: SpacingH.s1 }}>{userProfile.pronouns}</MainText>} */}
                                    {userProfile.about && <MainMediumText style={{ textAlign: "justify" }}>{userProfile.about} </MainMediumText>}
                                </View>
                            </>
                            :
                            <PlaceHolder type={"userCard"} />
                        }
                    </View>
                }
                style={GlobalStyles.optionalPaddingH}
                data={posts}
                refreshing={state.refreshing || state.loading}
                onRefresh={onRefresh}
                onEndReached={loadMore}
                renderItem={({ item }) => <PostCard {...item} key={item.uuid} onPostPress={() => onPostPress(item)} style={{ marginVertical: SpacingH.s1, flex: 1 }} baseView={"Profile"} />}
                ListEmptyComponent={() => (
                    (isCircleInternal && !(user.isCoach || user.canManageInstitution || user.isSuperAdmin)) ?
                        <View style={{ flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: Size.h10 }}>
                            <Image source={require("../../assets/illustrations/global_earth.png")} style={{ width: Size.w90, height: Size.w70 * 7 / 9 }} />
                            <HeroText style={[{ textAlign: "center", marginTop: Size.h10, color: Colors.DARK_GREY, fontSize: FontSize.NORMED }]}>
                                Connect.  Engage.  Accelerate.
                            </HeroText>
                        </View>
                        :
                        <PlaceHolder status={(state.refreshing || state.loading)} repeat={2} type={"postCard"} />
                )}
                keyExtractor={item => item.uuid}
            />
            <SlideUpModal
                isModalVisible={isModalVisible}
                setModalVisible={setModalVisible}
                options={[
                    {
                        show: true,
                        text: "Edit Profile",
                        onPress: () => { setModalVisible(false); navigation.navigate("EditProfile"); },
                        icon: <FontAwesome5 name="user-edit" size={16} color={Colors.DARK_GREY} />,
                    },
                    {
                        show: true,
                        text: "Notifications",
                        activeOpacity: 1,
                        icon: <View style={{
                            transform: [{ scale: Platform.OS === "ios" ? 0.7 : 1 }],
                            marginRight: -SpacingW.s2,
                            height: 20
                        }}>
                            <Switch
                                trackColor={{ false: Colors.LIGHT_GREY, true: Colors.DEEP_TEAL }}
                                thumbColor={Colors.WHITE_GREY}
                                ios_backgroundColor={Colors.LIGHT_GREY}
                                onValueChange={(v) => dispatch(setNotificationsEnabled(v))}
                                value={state.notificationsEnabled}
                            />
                        </View>
                    },
                    {
                        show: !!(!user.isCoach && user.coaches && user.coaches.length),
                        text: "Change coach",
                        onPress: () => {
                            setModalVisible(false);
                            dispatch(setIsSpinning(true));
                            dispatch(User.getMatchedCoaches()).then(() => {
                                navigation.navigate("MatchList", { from_uuid: user.coaches[0].uuid, showBack: true });
                                dispatch(setIsSpinning(false));
                            });
                        },
                        icon: <MaterialCommunityIcons name="swap-horizontal-circle-outline" size={20} color={Colors.DARK_GREY} />
                    },
                    {
                        show: false,
                        text: "Settings",
                        onPress: () => { navigation.navigate("Settings"); setModalVisible(false); },
                        icon: <MaterialIcons name="settings" size={20} color={Colors.DARK_GREY} />,
                    },
                    {
                        show: true,
                        text: "About",
                        onPress: () => { navigation.navigate("AboutStackScreen", { screen: "About" }); setModalVisible(false) },
                        icon: <MaterialIcons name="info-outline" size={20} color={Colors.DARK_GREY} />,
                    },
                    {
                        show: true,
                        text: "Contact support",
                        onPress: () => { Linking.openURL('mailto:support@liferamp360.com'); setModalVisible(false); },
                        icon: <MaterialIcons name="contact-support" size={20} color={Colors.DARK_GREY} />,
                    },
                    /** -- Handle in-app support -- */
                    {
                        show: false,
                        text: "Contact support",
                        onPress: () => { navigation.navigate("ContactSupport"); setModalVisible(false); },
                        icon: <MaterialIcons name="contact-support" size={20} color={Colors.DARK_GREY} />,
                    },
                    /** -- Handle in-app support -- */
                    {
                        show: true,
                        text: "Logout",
                        onPress: () => { navigation.navigate("Logout"); setModalVisible(false); },
                        icon: <MaterialIcons name="logout" size={20} color={Colors.DARK_GREY} />,
                    },
                    {
                        show: false,
                        text: "Reset",
                        textColor: Colors.HEART_RED,
                        onPress: () => {
                            Alert.alert(
                                "Reset",
                                "Are you sure?",
                                [
                                    {
                                        text: "Reset",
                                        onPress: () => {
                                            setModalVisible(false);
                                            reset();
                                        },
                                        style: "destructive"
                                    },
                                    {
                                        text: "Cancel",
                                        onPress: () => { setModalVisible(false); },
                                        style: "cancel"
                                    },
                                ]
                            );
                        },
                        icon: <Ionicons name="nuclear" size={24} color={Colors.HEART_RED} />,
                    },
                ]}
            />

        </ContainerView>
    );
};
