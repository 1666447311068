/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { LinearGradient } from 'expo-linear-gradient';

import { Gradients } from "../../themes/colors";

export default ({ children, style, onPress }) => (
    <View style={[styles.circleButton, style]}>
        <TouchableOpacity onPress={onPress}>
            <LinearGradient
                style={[styles.circleButton, style, { marginRight: 0 }]}
                start={[1, 0]}
                end={[0, 1]}
                colors={[Gradients.GREEN_BLUE.Start, Gradients.GREEN_BLUE.End]}>
                {children}
            </LinearGradient>
        </TouchableOpacity>
    </View>
);

const styles = StyleSheet.create({
    circleButton: {
        width: 120,
        height: 120,
        borderRadius: 60,
        alignItems: "center",
        justifyContent: "center",
    },
});
