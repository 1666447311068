/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */


import React from "react";
import { Text, StyleSheet, View, Image, ScrollView, Pressable, Keyboard, KeyboardAvoidingView, Platform, Alert } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { Entypo, MaterialIcons } from "@expo/vector-icons";
import ParsedText from "react-native-parsed-text";
import * as WebBrowser from "expo-web-browser";
import datetime from "date-and-time";

import MainMediumText from "../../../components/text/MainMediumText";
import MainRegularText from "../../../components/text/MainRegularText";
import ContainerView from "../../../components/main/ContainerView";
import HeroText from "../../../components/text/HeroText";
import SlideUpModal from "../../../components/utils/SlideUpModal";
import { Colors } from "../../../themes/colors";
import { FontSize, SpacingH, SpacingW, Size, Utils } from "../../../themes/sizes";
import { GlobalStyles } from "../../../themes/styles";

import { Posts, User } from "../../../api";
import { clearOtherUser } from "../../../state/slices/userSlice";
import { addPostReaction, removePostReaction } from "../../../state/slices/circleSlice";
import { setIsSpinning } from "../../../state/slices/spinnerSlice";

export default ({ navigation, route }) => {
    const dispatch = useDispatch();

    const [aspectRatio, setAspectRatio] = React.useState(1);
    const [shouldAutoScroll, setShouldAutoScroll] = React.useState(false);
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [isCommentModalVisible, setCommentModalVisible] = React.useState(false);
    const [selectedComment, setSelectedComment] = React.useState(null);

    const loading = useSelector(state => state.circle.loading);
    const circleShortName = useSelector(state => state.circle.data?.institution.shortname);
    const postType = "notice";
    const post = useSelector(state => state.circle[postType + "s"].find(p => p.uuid == state.circle.currentPostUUID));
    const user = useSelector(state => state.user.profile);

    let hasLiked = post.reactions.find(r => r.user.uuid == user.uuid && r.type == "like");
    let postScrollView = React.useRef();

    const scrollToEnd = () => {
        postScrollView.current && postScrollView.current.scrollToEnd({ animated: true });
        setShouldAutoScroll(false);
    };

    React.useEffect(() => {
        let keyboardOpenListener = Keyboard.addListener("keyboardDidShow", () => {
            scrollToEnd();
        });

        return () => { keyboardOpenListener.remove() };
    }, [postScrollView]);

    React.useEffect(() => {
        if (post.image) {
            Image.getSize(post.image, (width, height) => {
                let r = Math.max(0.3, Math.min(3.33, width / height));
                setAspectRatio(r);
            });
        }
    }, []);

    const onUserPress = (uuid) => {
        dispatch(clearOtherUser());
        dispatch(User.getProfile({ uuid }));
        dispatch(User.refreshPosts({ uuid }))
        navigation.navigate("ViewProfile", { uuid });
    };

    const deletePost = () => {
        dispatch(setIsSpinning(true));
        setCommentModalVisible(false);
        navigation.goBack();
        dispatch(Posts.deletePost({ post }))
            .then(() => {
                dispatch(setIsSpinning(false));
            });
    };

    const deleteComment = () => {
        dispatch(setIsSpinning(true));
        setCommentModalVisible(false);
        dispatch(Posts.removeComment({ comment: selectedComment }))
            .then(() => {
                dispatch(setIsSpinning(false));
            });
    };

    const handlePostLikePress = () => {
        const reactionType = "like";

        if (hasLiked) {
            dispatch(removePostReaction({ uuid: post.uuid, type: reactionType, user, postType }))
            dispatch(Posts.removeReaction({ uuid: post.uuid }));
        } else {
            dispatch(addPostReaction({ uuid: post.uuid, type: reactionType, user, postType }))
            dispatch(Posts.reactToPost({ uuid: post.uuid, type: reactionType }));
        }
    };

    const handleNewCommentHandler = (content) => {
        dispatch(Posts.addComment({ uuid: post.uuid, content }));
        setShouldAutoScroll(true);
    };

    const handleUrlPress = (url) => {
        WebBrowser.openBrowserAsync(url);
    };

    const renderText = (matchingURL) => {
        if (!matchingURL) return;

        return matchingURL.replace(/(^\w+:|^)\/\//, "");
    };

    return (
        <ContainerView hasHeader style={{ flex: 1 }}>
            <ScrollView style={[GlobalStyles.optionalPaddingH, styles.scrollView]} ref={postScrollView} onContentSizeChange={() => shouldAutoScroll && scrollToEnd()}>
                <View style={styles.postWrapper}>
                    {post.image &&
                        <View style={styles.postImageWrapper}>
                            <Image
                                source={{ uri: post.image }}
                                style={{
                                    width: "100%",
                                    height: (Size.w100 - 2 * SpacingW.s4) / aspectRatio,
                                }}
                                resizeMode="cover"
                            />
                        </View>
                    }
                    <View style={styles.postDetails}>
                        <Pressable style={styles.postDetailsOwner} onPress={() => onUserPress(post.user.uuid)}>
                            <MainMediumText style={{ fontSize: FontSize.SMALLER }}>{post.user.name}</MainMediumText>
                            <MainMediumText style={styles.postDetailsLight}>{circleShortName} Admin</MainMediumText>
                        </Pressable>
                        <View style={[styles.postDetailsOwner, { alignItems: "flex-end" }]}>
                            <MainMediumText style={{ fontSize: FontSize.SMALLER }}>{datetime.format(new Date(post.postedAt), "ddd, MMM D YYYY")}</MainMediumText>
                            <MainMediumText style={styles.postDetailsLight}>{datetime.format(new Date(post.postedAt), "hh:ss A")}</MainMediumText>
                        </View>
                    </View>
                    <View style={styles.postContentWrapper}>
                        <View style={styles.postContentTitle}>
                            <HeroText style={{ fontSize: FontSize.NORMED, width: post.user.uuid === user.uuid ? "90%" : "100%" }}>{post.title}</HeroText>
                            {post.user.uuid === user.uuid &&
                                <Pressable style={styles.kebabWrapper} hitSlop={24} onPress={() => setModalVisible(true)} >
                                    <Entypo name="dots-three-vertical" size={16} color={Colors.DARK_GREY} />
                                </Pressable>
                            }
                        </View>
                        <View style={styles.postContent}>
                            <MainRegularText>
                                <ParsedText
                                    style={{ fontSize: FontSize.SMALL, textAlign: "justify" }}
                                    parse={
                                        [
                                            { type: 'url', style: styles.url, onPress: handleUrlPress, renderText: renderText },
                                        ]
                                    }
                                    childrenProps={{ allowFontScaling: false }}>
                                    {post.content}
                                </ParsedText>
                            </MainRegularText>
                        </View>
                    </View>
                </View>
            </ScrollView>

            {/* Post Options */}
            <SlideUpModal
                isModalVisible={isModalVisible}
                setModalVisible={setModalVisible}
                options={[
                    {
                        show: post.user.uuid === user.uuid,
                        text: "Edit",
                        onPress: () => { navigation.navigate("CircleAddEditPost", { post: { uuid: post.uuid, title: post.title, content: post.content, image: post.image, tags: post.tags.map(t => t.name) }, postType }) },
                        icon: <MaterialIcons name="edit" size={20} color={Colors.DARK_GREY} />,
                    },
                    {
                        show: post.user.uuid === user.uuid,
                        text: "Delete",
                        textColor: Colors.HEART_RED,
                        onPress: () => {
                            Alert.alert(
                                "Delete Post?",
                                "This action is irreversible.",
                                [
                                    {
                                        text: "Delete",
                                        onPress: deletePost,
                                        style: "destructive"
                                    },
                                    {
                                        text: "Cancel",
                                        style: "cancel"
                                    },
                                ]
                            );
                        },
                        icon: <MaterialIcons name="delete" size={20} color={Colors.HEART_RED} />,
                    },
                ]}
            />
        </ContainerView>
    );
};

const styles = StyleSheet.create({
    scrollView: {
        paddingTop: SpacingH.s1,
        backgroundColor: Colors.WHITE,
    },
    postWrapper: {
        paddingHorizontal: SpacingW.s4
    },
    postImageWrapper: {
        marginBottom: SpacingH.s1,
    },
    postDetails: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    postDetailsOwner: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
    },
    postDetailsLight: {
        fontSize: FontSize.VERY_SMALL,
        color: Colors.BLUE_GREY
    },
    postContentWrapper: {
        flexDirection: "column",
        overflow: "hidden",
        paddingBottom: SpacingH.s2,
    },
    postContentTitle: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        marginTop: SpacingH.s2,
    },
    postContent: {
        marginVertical: SpacingH.s2,
    },
    url: {
        color: Colors.DEEP_TEAL
    },
    kebabWrapper: {
        width: Size.w5,
        justifyContent: "center",
        alignItems: "flex-end",
        alignSelf: "center",
    },
});
