/**
 * LifeRamp
 *
 * @author      Ummar Hamid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, TouchableOpacity, View, Image } from "react-native";
import { useSelector } from "react-redux";
import { MaterialIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import datetime from "date-and-time";

import * as WebBrowser from "expo-web-browser";

import { SpacingH, SpacingW, FontSize } from "../../themes/sizes";
import { Colors, Gradients } from "../../themes/colors";
import MainMediumText from "../text/MainMediumText";
import MainText from "../text/MainText";

export default ({ navigation, style, onPress, reschedule_url, cancel_url, location, name, coach, user, start_at, end_at, completed_at, status }) => {
    const isCoach = useSelector(state => state.user.profile.isCoach);
    const isHappeningNow = ((new Date()).getTime() > (new Date(start_at)).getTime() && (new Date()).getTime() < (new Date(end_at)).getTime());
    const isUpcoming = ((new Date()).getTime() < (new Date(start_at)).getTime());
    const isCancelled = status === "cancelled" || null;

    const onReschedule = () => { navigation.navigate("WebView", { url: reschedule_url, schedule: true }); };
    const onCancel = () => { navigation.navigate("WebView", { url: cancel_url, schedule: true }); };
    const onJoin = () => { WebBrowser.openBrowserAsync(location.join_url); };

    return (
        <View onPress={onPress} style={{ ...styles.card, ...style, marginBottom: isHappeningNow ? SpacingH.s2 : SpacingH.s1 }}>
            <View style={styles.topContainer}>
                <MainText>{name} {completed_at !== null && <MainText>completed </MainText>}with</MainText>
                <View style={{ flexDirection: "row", alignItems: "center", marginTop: SpacingH.s2 }}>
                    <Image source={{ uri: isCoach ? user.image_profile : coach.image_profile }} style={{ width: 64, height: 64, borderRadius: 32, marginRight: SpacingW.s3, borderColor: Colors.BLUE_GREY, borderWidth: 1 }} />
                    <MainMediumText style={{ fontSize: FontSize.NORMAL }}>{isCoach ? user.name : coach.name}</MainMediumText>
                </View>
            </View>

            {isHappeningNow && !isCancelled
                ?
                <TouchableOpacity onPress={onJoin} style={{ alignItems: "center", justifyContent: "center" }}>
                    <LinearGradient
                        style={{ width: "100%", paddingVertical: SpacingH.s2, justifyContent: "center", alignItems: "center" }}
                        start={[1, 0]}
                        end={[0, 1]}
                        colors={[Gradients.GREEN_BLUE.Start, Gradients.GREEN_BLUE.End]}>
                        <MainMediumText>Join Now</MainMediumText>
                    </LinearGradient>
                </TouchableOpacity>
                :
                <View style={styles.bottomContainer}>
                    <View style={{ flex: 1 }}>
                        <MainMediumText style={{ color: Colors.WHITE }}>{datetime.format(new Date(start_at), "ddd, MMM D at h:mm A")}</MainMediumText>
                        <MainMediumText style={{ color: Colors.WHITE }}>{datetime.subtract(new Date(end_at), new Date(start_at)).toMinutes()} minute session</MainMediumText>
                    </View>
                    {isCancelled ?
                        <MainMediumText style={{ alignSelf: "center", color: Colors.HEART_RED, fontSize: FontSize.SMALL }}>Cancelled</MainMediumText>
                        :
                        <>
                            {isUpcoming &&
                                <View style={styles.midContainer}>
                                    <TouchableOpacity onPress={onReschedule} style={[styles.actionButton, { marginRight: SpacingW.s4 }]}>
                                        <MaterialIcons name="edit" size={24} color={Colors.SOFT_WHITE} />
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={onCancel} style={styles.actionButton}>
                                        <MaterialIcons name="delete" size={24} color={Colors.SOFT_WHITE} />
                                    </TouchableOpacity>
                                </View>
                            }
                        </>
                    }
                </View>
            }
        </View>
    );
};

const styles = StyleSheet.create({
    card: {
        borderRadius: 2,
        backgroundColor: Colors.WHITE_GREY,
        marginVertical: SpacingH.s1,

    },
    topContainer: {
        paddingLeft: SpacingH.s3,
        paddingRight: SpacingH.s5,
        paddingVertical: SpacingH.s3
    },
    midContainer: {
        flexDirection: "row",
    },
    bottomContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: SpacingH.s3,
        paddingRight: SpacingH.s2,
        backgroundColor: Colors.DARK_GREY,
        paddingVertical: SpacingH.s2,
    },
    actionButton: {
        alignItems: "center",
        justifyContent: "center",
    },
});
