/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiThunkHandler } from "./utils";

const getPost = createAsyncThunk(
    "posts/getPost",
    async ({ uuid }, thunkAPI) => apiThunkHandler(axios.get("/posts/" + uuid), thunkAPI)
);

const updatePost = createAsyncThunk(
    "posts/updatePost",
    async ({ post }, thunkAPI) => apiThunkHandler(
        axios.put(
            "/posts/" + post.uuid,
            {
                ...post,
                tags: JSON.stringify(post.tags),
            }
        ),
        thunkAPI
    )
);

const deletePost = createAsyncThunk(
    "posts/deletePost",
    async ({ post }, thunkAPI) => apiThunkHandler(axios.delete("/posts/" + post.uuid), thunkAPI)
);

const reactToPost = createAsyncThunk(
    "posts/reactToPost",
    async ({ uuid, type }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/posts/" + uuid + "/reactions",
            {
                "type": type
            }
        ),
        thunkAPI
    )
);

const removeReaction = createAsyncThunk(
    "posts/removeReaction",
    async ({ uuid }, thunkAPI) => apiThunkHandler(axios.delete("/posts/" + uuid + "/reactions"), thunkAPI)
);

const getComments = createAsyncThunk(
    "posts/getComments",
    async ({ uuid }, thunkAPI) => apiThunkHandler(axios.get("/post/" + uuid + "/comments"), thunkAPI)
);

const addComment = createAsyncThunk(
    "posts/addComment",
    async ({ uuid, content }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/posts/" + uuid + "/comments",
            {
                "content": content
            }
        ),
        thunkAPI
    )
);

const removeComment = createAsyncThunk(
    "posts/removeComment",
    async ({ comment }, thunkAPI) => apiThunkHandler(axios.delete("/comments/" + comment.uuid), thunkAPI)
);

const reactToComment = createAsyncThunk(
    "posts/reactToComment",
    async ({ uuid, type }, thunkAPI) => apiThunkHandler(
        axios.post(
            "/comments/" + uuid + "/reactions",
            {
                "type": type
            }
        ),
        thunkAPI
    )
);

const removeReactionFromComment = createAsyncThunk(
    "posts/removeReactionFromComment",
    async ({ uuid }, thunkAPI) => apiThunkHandler(axios.delete("/comments/" + uuid + "/reactions"), thunkAPI)
);

const createReport = createAsyncThunk(
    "post/createReport",
    async ({ subject, message, image, postUUID, commentUUID }, thunkAPI) => apiThunkHandler(
        axios.post(
            "reports",
            {
                "subject": subject,
                "message": message,
                "image": (image !== "" ? image : ""),
                "post_uuid": (postUUID !== "" ? postUUID : ""),
                "comment_uuid": (commentUUID !== "" ? commentUUID : "")
            }
        ),
        thunkAPI
    )
);

export default {
    getPost,
    updatePost,
    deletePost,

    reactToPost,
    removeReaction,

    getComments,
    addComment,
    removeComment,

    reactToComment,
    removeReactionFromComment,

    createReport
};
