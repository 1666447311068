/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import Feed from "./Feed";
import Notices from "./Notices";
import People from "./People";
import { materialTopTabs } from "../../themes/styles";

const CircleTabs = createMaterialTopTabNavigator();

export default ({ navigation }) => (
    <CircleTabs.Navigator tabBarOptions={materialTopTabs}>
        <CircleTabs.Screen name="Explore" component={Feed} options={{ title: 'Explore' }} />
        <CircleTabs.Screen name="Message Board" component={Notices} options={{ title: 'Message Board' }} />
        <CircleTabs.Screen name="Members" component={People} options={{ title: 'Members' }} />
    </CircleTabs.Navigator>
);
