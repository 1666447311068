/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { createSlice } from "@reduxjs/toolkit";
import { setAuthToken, User } from "../../api";
import { setIsLoading, setError } from "../utils";

import config from "../../api/config";
import firebase from "firebase/app";
import "firebase/auth";

firebase.initializeApp(config.firebaseConfig);
const signInToFirebase = async (firebaseToken) => await firebase.auth().signInWithCustomToken(firebaseToken);
const signOutOfFirebase = async () => await firebase.auth().signOut();

import * as Analytics from "expo-firebase-analytics";

const initialState = {
    loading: false,
    error: "",

    refreshing: false,

    token: null,
    expoPushToken: null,
    notificationsEnabled: true,

    profile: {},
    posts: [],

    page: 2,
    maxPage: 2,

    otherUser: {},
    otherPosts: [],

    otherPage: 2,
    otherMaxPage: 2,

    profileQuestions: [],
    profileAnswers: [],

    matchedCoaches: [],

    clients: [],

    clientPage: 2,
    maxClientPage: 2,

    resetData: {
        stage: User.resetStages.REQUEST,
        token: null,
        uuid: null,
    },

    clientCount: 0,
    sessionTotalCount: 0,
    sessionCompleteCount: 0,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setLoading(state, flag) {
            state.loading = flag;
        },
        clearError(state) {
            state.error = "";
        },
        clearOtherUser(state) {
            state.otherUser = {};
            state.otherPosts = [];
        },
        setExpoPushToken(state, { payload: { token } }) {
            state.expoPushToken = token;
        },
        setProfileSingleAnswer(state, { payload: { uuid, answer } }) {
            if (state.profileAnswers.find(o => o.uuid == uuid)) {
                state.profileAnswers.find(o => o.uuid == uuid).answer[0] = answer;
            } else {
                state.profileAnswers.push({ uuid, answer: [answer] });
            }
        },
        setProfileMultiAnswer(state, { payload: { uuid, answer } }) {
            if (state.profileAnswers.find(o => o.uuid == uuid)) {
                let cAnswer = state.profileAnswers.find(o => o.uuid == uuid).answer;
                if (cAnswer.includes(answer)) {
                    state.profileAnswers.find(o => o.uuid == uuid).answer = cAnswer.filter(o => o !== answer);
                } else {
                    cAnswer.push(answer);
                }
            } else {
                state.profileAnswers.push({ uuid, answer: [answer] });
            }
        },
        setResetStage(state, { payload: { stage } }) {
            state.resetData.stage = stage;
        },
        setNotificationsEnabled(state, { payload }) {
            state.notificationsEnabled = payload;
        },
    },
    extraReducers: {
        [User.signUp.pending]: setIsLoading(true),
        [User.signIn.pending]: setIsLoading(true),
        [User.signOut.pending]: setIsLoading(true),
        [User.requestResetPassword.pending]: setIsLoading(true),
        [User.verifyCode.pending]: setIsLoading(true),
        [User.resetPassword.pending]: setIsLoading(true),

        [User.getProfileQuestions.pending]: setIsLoading(true),
        [User.getMatchedCoaches.pending]: setIsLoading(true),

        [User.updateProfile.pending]: setIsLoading(true),
        [User.getMyProfile.pending]: setIsLoading(true),

        [User.refreshMyPosts.pending]: (state) => { state.refreshing = true },
        [User.getMyPosts.pending]: setIsLoading(true),

        [User.getProfile.pending]: setIsLoading(true),
        [User.refreshPosts.pending]: (state) => { state.refreshing = true },
        [User.getPosts.pending]: setIsLoading(true),

        [User.refreshMyClients.pending]: (state) => { state.refreshing = true },
        [User.getMyClients.pending]: setIsLoading(true),

        [User.getCoachDashboardData.pending]: setIsLoading(true),

        [User.contactSupport.pending]: setIsLoading(true),

        [User.signUp.fulfilled]: (state, { payload: { token, me, firebaseToken } }) => {
            signInToFirebase(firebaseToken);

            state.token = token;
            state.profile = me;

            Analytics.setUserId(me.uuid);
            Analytics.setUserProperties({ name: me.name, email: me.email });
            Analytics.logEvent("sign_up");

            setAuthToken(state.token);
            setIsLoading(false)(state);
        },
        [User.signIn.fulfilled]: (state, { payload: { token, me, firebaseToken } }) => {
            signInToFirebase(firebaseToken);

            state.token = token;
            state.profile = me;

            Analytics.setUserId(me.uuid);
            Analytics.setUserProperties({ name: me.name, email: me.email });
            Analytics.logEvent("login");

            setAuthToken(state.token);
            setIsLoading(false)(state);
        },
        [User.signOut.fulfilled]: (state) => {
            signOutOfFirebase();
            state.token = null;
            state.profile = {};

            Analytics.setUserId(null);
            Analytics.logEvent("logout");

            setAuthToken(null);
            setIsLoading(false)(state);
        },

        [User.requestResetPassword.fulfilled]: (state, { payload: { uuid } }) => {
            state.resetData.uuid = uuid;
            state.resetData.stage = User.resetStages.VERIFY;
            state.error = "";
            setIsLoading(false)(state);
        },
        [User.verifyCode.fulfilled]: (state, { payload: { token } }) => {
            state.resetData.token = token;
            state.resetData.stage = User.resetStages.RESET;
            state.error = "";
            setIsLoading(false)(state);
        },
        [User.resetPassword.fulfilled]: (state) => {
            state.resetData.stage = User.resetStages.DONE;
            state.error = "";
            setIsLoading(false)(state);
        },

        [User.getProfileQuestions.fulfilled]: (state, { payload }) => {
            state.profileQuestions = payload["profile-questions"];
            setIsLoading(false)(state);
        },
        [User.getMatchedCoaches.fulfilled]: (state, { payload }) => {
            state.matchedCoaches = payload["matched-coaches"];
            setIsLoading(false)(state);
        },

        [User.updateProfile.fulfilled]: (state, { payload: { me } }) => {
            state.profile = me;
            setIsLoading(false)(state);
        },
        [User.getMyProfile.fulfilled]: (state, { payload: { me } }) => {
            state.profile = me;
            setIsLoading(false)(state);
        },

        [User.refreshMyPosts.fulfilled]: (state, action) => {
            state.posts = action.payload.posts;
            state.page = 2;
            state.maxPage = action.payload.maxPage;
            state.refreshing = false;
        },
        [User.getMyPosts.fulfilled]: (state, action) => {
            state.page++;
            state.maxPage = action.payload.maxPage;
            state.posts.push(...action.payload.posts);
            setIsLoading(false)(state);
        },

        [User.refreshMyClients.fulfilled]: (state, action) => {
            state.clients = action.payload.clients;
            state.clientPage = 2;
            state.clientMaxPage = action.payload.maxPage;
            state.refreshing = false;
        },
        [User.getMyClients.fulfilled]: (state, action) => {
            state.clientPage++;
            state.clientMaxPage = action.payload.maxPage;
            state.clients.push(...action.payload.clients);
            setIsLoading(false)(state);
        },

        [User.getProfile.fulfilled]: (state, { payload: { user } }) => {
            state.otherUser = user;
            setIsLoading(false)(state);
        },

        [User.refreshPosts.fulfilled]: (state, action) => {
            if (action.meta.arg.uuid != state.otherUser.uuid) return;

            state.otherPosts = action.payload.posts;
            state.otherPage = 2;
            state.otherMaxPage = action.payload.maxPage;
            state.refreshing = false;
        },
        [User.getPosts.fulfilled]: (state, action) => {
            if (action.meta.arg.uuid != state.otherUser.uuid) return;

            state.otherPage++;
            state.otherMaxPage = action.payload.maxPage;
            state.otherPosts.push(...action.payload.posts);
            setIsLoading(false)(state);
        },

        [User.getCoachDashboardData.fulfilled]: (state, action) => {
            state.clientCount = action.payload.clientCount;
            state.sessionTotalCount = action.payload.sessionTotalCount;
            state.sessionCompleteCount = action.payload.sessionCompleteCount;
            setIsLoading(false)(state);
        },

        [User.contactSupport.fulfilled]: (state, action) => {
            setIsLoading(false)(state);
        },

        [User.signUp.rejected]: setError,
        [User.signIn.rejected]: setError,
        [User.signOut.rejected]: setError,
        [User.requestResetPassword.rejected]: setError,
        [User.verifyCode.rejected]: setError,
        [User.resetPassword.rejected]: setError,

        [User.getProfileQuestions.rejected]: setError,
        [User.getMatchedCoaches.rejected]: setError,
        [User.setProfileAnswer.rejected]: setError,

        [User.updateProfile.rejected]: setError,
        [User.getMyProfile.rejected]: setError,

        [User.refreshMyPosts.rejected]: setError,
        [User.getMyPosts.rejected]: setError,

        [User.getProfile.rejected]: setError,
        [User.refreshPosts.rejected]: setError,
        [User.getPosts.rejected]: setError,

        [User.refreshMyClients.rejected]: setError,
        [User.getMyClients.rejected]: setError,

        [User.getCoachDashboardData.rejected]: setError,

        [User.contactSupport.rejected]: setError,
    }
});

export const {
    reset,

    setLoading,
    clearError,
    clearOtherUser,

    setExpoPushToken,

    setProfileSingleAnswer,
    setProfileMultiAnswer,
    setResetStage,

    setNotificationsEnabled,
} = userSlice.actions;

export default userSlice.reducer;
