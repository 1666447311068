/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { FontSize, SpacingW, SpacingH } from "../../themes/sizes";

import { Colors } from "../../themes/colors";
import MainMediumText from "../text/MainMediumText";
import MainText from "../text/MainText";

export default ({ image_profile, name, onPress }) => (
    <TouchableOpacity onPress={onPress}>
        <View style={[styles.card]}>
            <Image source={{ uri: image_profile }} style={[styles.image]} />
            <View style={[styles.textContainer]}>
                <MainMediumText style={[styles.name]}>{name}</MainMediumText>
            </View>
        </View>
    </TouchableOpacity>
);

const styles = StyleSheet.create({
    card: {
        flex: 1,
        flexDirection: "row",
        borderBottomColor: Colors.SOFT_GREY,
        borderBottomWidth: 0,
        marginBottom: 1,
        paddingHorizontal: SpacingW.s2,
        paddingVertical: SpacingH.s1,
    },
    image: {
        width: 48,
        height: 48,
        borderRadius: 24,
        marginHorizontal: SpacingW.s2,
    },
    textContainer: {
        flex: 1,
        justifyContent: "center",
        marginHorizontal: SpacingW.s2,
    },
    name: {
        marginBottom: SpacingH.s0,
    },
    textSubContainer: {
        flexDirection: "row",
    },
    messageText: {
        color: Colors.LIGHT_GREY,
        fontSize: FontSize.SMALLER,
    },
    timeText: {
        marginHorizontal: SpacingW.s1,
        color: Colors.DARK_GREY,
        fontSize: FontSize.SMALLER
    },
});
