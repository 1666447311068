/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { FlatList, View, StyleSheet } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import BouncyCheckbox from "react-native-bouncy-checkbox";

import { GlobalStyles } from "../../../themes/styles";
import { FontSize, Size, SpacingH } from "../../../themes/sizes";
import { Colors } from "../../../themes/colors";
import fonts from "../../../themes/fonts";
import GoalCard from "../../../components/cards/GoalCard";
import PlaceHolder from "../../../components/utils/PlaceHolder";

import { Goals } from "../../../api";
import { setGoal } from "../../../state/slices/goalSlice";

export default ({ navigation }) => {
    const dispatch = useDispatch();

    const goal = useSelector(state => state.goal);
    const completedGoals = useSelector(state => state.goal.completedGoals);

    React.useEffect(() => {
        dispatch(Goals.refreshCompletedGoals());
    }, []);

    const loadMore = () => {
        if (goal.loading) return;
        goal.completedPage <= goal.completedMaxPage && dispatch(Goals.getCompletedGoals({ page: goal.completedPage }));
    };

    const viewGoal = (goal) => {
        dispatch(setGoal({ goal }));
        navigation.navigate("ViewGoal");
    };

    const [filterType, setFilterType] = React.useState(["professional", "educational", "personal"]);

    const toggleFilter = (type) => {
        let categories = filterType.filter(t => t != type);

        if (!filterType.includes(type)) {
            categories = [...categories, type];
        }
        setFilterType(categories);
    };

    const filteredCompletedGoals = completedGoals.filter(g => filterType.includes(g.type));

    return (
        <View style={{ flex: 1, backgroundColor: Colors.WHITE }}>
            <FlatList
                data={filteredCompletedGoals}
                onRefresh={() => dispatch(Goals.refreshCompletedGoals())}
                refreshing={goal.completedRefreshing || goal.loading}
                onEndReached={loadMore}
                renderItem={({ item }) => <GoalCard {...item} style={GlobalStyles.containerMarginH} onPress={() => { viewGoal(item) }} />}
                ListEmptyComponent={<PlaceHolder style={GlobalStyles.containerMarginH} status={(goal.completedRefreshing || goal.loading)} type={"regularCard"} emptyMessage={"Go ahead and add your goals"} />}
                keyExtractor={item => item.uuid}
                ListHeaderComponent={
                    <View style={[GlobalStyles.containerMarginH, {marginRight: 0, flexDirection: "row", justifyContent: "center", marginVertical: SpacingH.s2}]}>
                        <View style={styles.checkboxContainer}>
                            <BouncyCheckbox
                                size={22}
                                fillColor={Colors.TEXT_BLACK}
                                unfillColor="#FFFFFF"
                                text="Professional"
                                textStyle={styles.checkboxItem}
                                iconStyle={{ borderColor: Colors.TEXT_BLACK, borderRadius: 3, marginRight: "-9%" }}
                                disableBuiltInState
                                isChecked={filterType.includes("professional")}
                                onPress={() => { toggleFilter("professional") }}
                            />
                        </View>
                        <View style={styles.checkboxContainer}>
                            <BouncyCheckbox
                                size={22}
                                fillColor={Colors.TEXT_BLACK}
                                unfillColor="#FFFFFF"
                                text="Educational"
                                textStyle={styles.checkboxItem}
                                iconStyle={{ borderColor: Colors.TEXT_BLACK, borderRadius: 3, marginRight: "-9%" }}
                                disableBuiltInState
                                isChecked={filterType.includes("educational")}
                                onPress={() => { toggleFilter("educational") }}
                            />
                        </View>
                        <View style={styles.checkboxContainer}>
                            <BouncyCheckbox
                                size={22}
                                fillColor={Colors.TEXT_BLACK}
                                unfillColor="#FFFFFF"
                                text="Personal"
                                textStyle={styles.checkboxItem}
                                iconStyle={{ borderColor: Colors.TEXT_BLACK, borderRadius: 3, marginRight: "-9%" }}
                                disableBuiltInState
                                isChecked={filterType.includes("personal")}
                                onPress={() => { toggleFilter("personal") }}
                            />
                        </View>
                    </View>
                }
            />
        </View>
    );
};

const styles = StyleSheet.create({
    checkboxContainer: {
        flexDirection: "row",
        alignItems: "center",
        width: Size.w30,
    },
    checkboxItem: {
        fontSize: FontSize.SMALLER,
        fontFamily: fonts.MainMedium,
        color: Colors.TEXT_BLACK,
        textDecorationLine: "none",
    },
});
