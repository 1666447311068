/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { LinearGradient } from "expo-linear-gradient";
import { StatusBar } from "react-native";
import { GlobalStyles } from "../../themes/styles";
import { hp } from "../../themes/sizes";
import { Gradients, Colors } from "../../themes/colors";

export default ({ style, children }) => (
    <LinearGradient
        style={[GlobalStyles.container, { paddingTop: hp("8%") }, style]}
        start={[1, 0]}
        end={[0, 1]}
        colors={[Gradients.DARK.Start, Gradients.DARK.End]}>
        <StatusBar backgroundColor={Colors.NAVY} barStyle={"light-content"} />
        {children}
    </LinearGradient>
);
