/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { Image, View } from "react-native";
import { Placeholder, PlaceholderMedia, PlaceholderLine, ShineOverlay } from "rn-placeholder";

import { FontSize, Size, SpacingH, SpacingW } from "../../themes/sizes";
import { Colors } from "../../themes/colors";

import MainMediumText from "../text/MainMediumText";

export default ({ type, emptyMessage, emptyMessageStyle, repeat, status = true, style }) => (
    <View style={style}>
        {!status ?
            <View style={{ flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: Size.h10 }}>
                <Image source={require("../../assets/illustrations/empty_space.png")} style={{ width: Size.w70, height: Size.w70 * 7 / 9 }} />
                <MainMediumText style={[{ textAlign: "center", marginTop: SpacingH.s5, color: Colors.BLUE_GREY, fontSize: FontSize.SMALLER }, emptyMessageStyle]}>
                    {emptyMessage ? emptyMessage : "There's nothing here but empty space."}
                </MainMediumText>
            </View>
            :
            <>
                <>
                    {type === "peopleCard" &&
                        <Placeholder Animation={ShineOverlay}>
                            {
                                [...Array(repeat ? repeat : 10).keys()].map(x => (
                                    <Placeholder key={x} style={{ marginVertical: SpacingH.s0, marginHorizontal: SpacingW.s5, borderBottomWidth: 1, borderBottomColor: Colors.SOFT_WHITE, paddingBottom: SpacingH.s1 }}>
                                        <PlaceholderLine width={90} height={60} style={{ marginBottom: 0 }} />
                                    </Placeholder>
                                ))
                            }
                        </Placeholder>
                    }
                </>
                <>
                    {type === "regularCard" &&
                        <Placeholder Animation={ShineOverlay}>
                            {
                                [...Array(repeat ? repeat : 3).keys()].map(x => (
                                    <Placeholder key={x} style={{ marginTop: SpacingH.s2 }}>
                                        <PlaceholderMedia style={{ width: "100%", height: Size.h20 }} />
                                    </Placeholder>
                                ))
                            }
                        </Placeholder>
                    }
                </>
                <>
                    {type === "postCard" &&
                        <>
                            {
                                [...Array(repeat ? repeat : 2).keys()].map(x => (
                                    <Placeholder key={x} style={{ marginVertical: SpacingH.s2, marginHorizontal: SpacingW.s5, paddingVertical: SpacingH.s2, borderBottomWidth: 1, borderBottomColor: Colors.SOFT_WHITE }} Animation={ShineOverlay}>
                                        <Placeholder Left={() => <PlaceholderLine height={64} width={16} style={{ marginRight: SpacingW.s2, marginBottom: SpacingH.s2 }} />}>
                                            <PlaceholderLine width={50} height={30} style={{ marginTop: SpacingH.s1 }} />
                                        </Placeholder>
                                        <PlaceholderLine width={90} height={20} />
                                        <PlaceholderLine width={90} height={100} style={{ marginBottom: SpacingH.s2, borderRadius: 6 }} />
                                        <PlaceholderLine width={90} height={15} />
                                        <PlaceholderLine width={90} height={15} />
                                        <PlaceholderLine width={90} height={15} />
                                        <PlaceholderLine width={90} height={15} />
                                    </Placeholder>
                                ))
                            }
                        </>
                    }
                </>
                <>
                    {type === "userCard" &&
                        <>
                            {
                                [...Array(repeat ? repeat : 1).keys()].map(x => (
                                    <Placeholder key={x} style={{ borderBottomWidth: 1, borderBottomColor: Colors.SOFT_GREY, marginLeft: "-3%", paddingLeft: SpacingH.s2 }} Animation={ShineOverlay}>
                                        <View style={{ alignItems: "center", justifyContent: "center" }}>
                                            <PlaceholderMedia size={120} style={{ borderRadius: 60 }} />
                                        </View>
                                        <PlaceholderLine width={100} height={25} style={{ marginTop: SpacingH.s2, marginBottom: SpacingH.s1 }} />
                                        <PlaceholderLine width={100} height={25} style={{ marginVertical: 0 }} />
                                    </Placeholder>
                                ))
                            }
                        </>
                    }
                </>
            </>
        }
    </View>
);
