/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { View, StyleSheet, ScrollView, TextInput, Alert, TouchableOpacity } from "react-native";
import { useDispatch } from "react-redux";
import { EvilIcons } from "@expo/vector-icons";

import { SpacingH, FontSize, Size, SpacingW, wp } from "../../themes/sizes";
import { Colors } from "../../themes/colors";
import ContainerView from "../../components/main/ContainerView";

import BoxInput from "../../components/input/BoxInput";
import HeroSemiText from "../../components/text/HeroSemiText";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { GlobalStyles } from "../../themes/styles";

import { setIsSpinning } from "../../state/slices/spinnerSlice";

import { User } from "../../api";
import Fonts from "../../themes/fonts";

export default ({ navigation, route }) => {
    const dispatch = useDispatch();

    const [message, setMessage] = React.useState("");
    const [subject, setSubject] = React.useState("");
    const [shouldContact, setShouldContact] = React.useState(false);

    const showAlert = (message, type = "Error") =>
        Alert.alert(
            type,
            message,
            [{ text: "Ok", style: "cancel", }]
        );

    const contactSupport = async () => {
        if (!subject.trim().length) {
            showAlert("Subject cannot be empty");
            return;
        }
        if (!message.trim().length) {
            showAlert("Please fill in the message");
            return;
        }
        setShouldContact(true);
    };

    React.useEffect(() => {
        if (shouldContact) {
            dispatch(setIsSpinning(true));
            dispatch(User.contactSupport({ message, subject }))
                .then((response) => {
                    if (!response?.error) {
                        navigation.goBack();
                        showAlert("We will get back to you soon!", "Thank you!");
                    } else {
                        showAlert("Something went wrong!", "Error!");
                    }
                    dispatch(setIsSpinning(false));
                    setShouldContact(false);
                });
        }
    }, [shouldContact]);

    return (
        <ContainerView hasHeader>
            <View style={GlobalStyles.containerPaddingH}>
                <TouchableOpacity onPress={() => { navigation.goBack(); }} style={{ position: "absolute", top: 23, left: 10, zIndex: 10 }}>
                    <EvilIcons name="chevron-left" size={42} color={Colors.BLUE_GREY} />
                </TouchableOpacity>
                <View style={{ marginVertical: SpacingH.s2 }}>
                    <HeroSemiText style={{ fontSize: FontSize.MEDIUM, textAlign: "center" }}>Contact support</HeroSemiText>
                </View>
            </View>
            <ScrollView style={[GlobalStyles.containerPaddingH, { flex: 1 }]}>
                <BoxInput
                    value={subject}
                    onChangeText={(text) => setSubject(text)}
                    style={styles.subject}
                    placeholder={"Subject"} />
                <TextInput
                    maxLength={5000}
                    multiline={true}
                    placeholder="Message"
                    value={message}
                    onChangeText={(text) => setMessage(text)}
                    style={styles.commentInput} />
                <View style={{ flex: 1, alignItems: "center", marginTop: SpacingH.s4, marginBottom: SpacingH.s3 }}>
                    <PrimaryButton style={{ alignSelf: "center", }} onPress={contactSupport}>Send</PrimaryButton>
                </View>
            </ScrollView>
        </ContainerView>
    );
};

const styles = StyleSheet.create({
    subject: {
        backgroundColor: Colors.SOFT_GREY,
        borderWidth: 1,
        borderColor: Colors.SOFT_GREY,
        marginVertical: SpacingH.s2,
    },
    commentInput: {
        textAlignVertical: "top",
        height: Size.h40,
        width: Size.w90,
        paddingHorizontal: SpacingW.s3,
        alignSelf: "center",
        paddingTop: SpacingH.s3,
        backgroundColor: Colors.SOFT_GREY,
        color: Colors.TEXT_PRIMARY,
        fontSize: FontSize.NORMAL,
        borderColor: Colors.SOFT_GREY,
        borderRadius: 8,
        borderWidth: 1,
        fontFamily: Fonts.Main,
    },
    postDefaultImageContainer: {
        width: "100%",
        height: wp(90) * 581 / 800,
        borderRadius: 8,
    },
    postDefaultImage: {
        borderRadius: 8,
        width: "100%",
        height: wp(90) * 581 / 800,
    },
});
