/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { View, StyleSheet, ScrollView, ActivityIndicator, BackHandler, Animated } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import Icon from "react-native-vector-icons/FontAwesome";
import { AntDesign } from '@expo/vector-icons';

import { FontSize, SpacingH, Size } from "../../themes/sizes";
import { GlobalStyles } from "../../themes/styles";
import { Colors } from "../../themes/colors";

import NonSafeContainerView from "../../components/main/NonSafeContainerView";
import ProgressBar from "../../components/bars/ProgressBar";

import HeroText from "../../components/text/HeroText";
import MainText from "../../components/text/MainText";

import PrimaryButton from "../../components/buttons/PrimaryButton";
import ActionButton from "../../components/buttons/ActionButton";

import { User } from "../../api";
import { setProfileSingleAnswer, setProfileMultiAnswer } from "../../state/slices/userSlice";
import BoxInput from "../../components/input/BoxInput";

let buttonsAddedValue = 0;

export default ({ navigation, route }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.profile);
    const loading = useSelector(state => state.user.loading);

    const [year, setYear] = React.useState("");

    React.useEffect(() => {
        const backHandler = BackHandler.addEventListener("hardwareBackPress", () => true);
        return () => backHandler.remove();
    }, []);

    React.useEffect(() => {
        dispatch(User.getProfileQuestions({ all: (route.params && route.params.all) || false }))
    }, []);

    const [error, setError] = React.useState("");
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const currentQuestion = useSelector(state => state.user.profileQuestions[currentIndex] || null);
    const currentAnswer = useSelector(state => state.user.profileAnswers.find(o => currentQuestion ? o.uuid === currentQuestion.uuid : false)) || null;
    const profileQuestions = useSelector(state => state.user.profileQuestions);

    const screenAnim = React.useRef(new Animated.Value(1)).current;

    const screenAnimStart = (action) => {
        Animated.timing(screenAnim, {
            toValue: 0,
            duration: 350,
            useNativeDriver: false,
        }).start(() => {
            if (action === "next") {
                next();
            } else if (action === "prev") {
                prev();
                setError("");
            }
            screenAnimStop();
        });
    };

    const screenAnimStop = () => {
        Animated.timing(screenAnim, {
            toValue: 1,
            duration: 350,
            useNativeDriver: false,
        }).start();
    };


    const setYearValue = (val) => {
        setYear(val.replace(/[^0-9]/g, ''));
        dispatch(setProfileMultiAnswer({ uuid: currentQuestion.uuid, answer: year }));
    };

    const onPressAnswer = (a) => {
        if (currentQuestion.type === "single-select") {
            dispatch(setProfileSingleAnswer({ uuid: currentQuestion.uuid, answer: a }))
        } else {
            dispatch(setProfileMultiAnswer({ uuid: currentQuestion.uuid, answer: a }))
        }
        setError("");
    };


    const checkForErrors = () => {
        if (currentQuestion.type === "number") {
            if (year < 1900) {
                return setError("Please enter a valid year.");
            }

            if (year > (new Date().getFullYear() - 15)) {
                return setError("Sorry, LifeRamp is only available to ages 16+.");
            }
        } else {
            if (!currentAnswer || !currentAnswer.answer.length) {
                return setError("Please select at least one.");
            }
        }
        setError("");
        screenAnimStart("next");
    }

    const next = async () => {
        dispatch(User.setProfileAnswer({ uuid: currentAnswer.uuid, answer: currentAnswer.answer }));
        if (currentIndex < profileQuestions.length) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const getButtonSize = (a, i) => {
        const threshold = 15;

        if (a.length > threshold) {
            buttonsAddedValue += 2;
            return "lg";
        }

        if (currentQuestion.answers.length > i + 1 && buttonsAddedValue % 2 === 0) {
            if (currentQuestion.answers[i + 1].length > threshold) {
                buttonsAddedValue += 2;
                return "lg";
            }
        }

        if (i == currentQuestion.answers.length - 1 && buttonsAddedValue % 2 === 0) {
            buttonsAddedValue += 2;
            return "lg";
        }

        buttonsAddedValue += 1;
        return "";
    };

    const questionsLeft = profileQuestions.length - currentIndex;

    return (
        <NonSafeContainerView style={{ paddingBottom: SpacingH.s4 }}>
            <ProgressBar
                currentProgress={currentIndex}
                totalProgress={profileQuestions.length}
                animDuration={350}
                onAnimComplete={() => { navigation.navigate("ProfileImageUpload", { currentIndex: currentIndex }); }}
            ></ProgressBar>
            <ScrollView style={[GlobalStyles.containerPaddingH, { flex: 1, paddingBottom: Size.h10 }]}>
                <Animated.View style={{ opacity: screenAnim }}>
                    {profileQuestions.length > 0 && questionsLeft > 1 ?
                        <MainText style={{ fontSize: FontSize.NORMAL, marginBottom: SpacingH.s2, color: Colors.WHITE }}>Your new coach is just {questionsLeft} questions away.</MainText> : null
                    }

                    {currentQuestion === null && <ActivityIndicator size="large" color={Colors.DEEP_TEAL} animating={loading} />}
                    {currentQuestion !== null &&
                        <>
                            <HeroText style={{ fontSize: FontSize.BIG, color: Colors.WHITE, marginVertical: SpacingH.s2 }}>{currentQuestion.question}</HeroText>
                            {error !== "" && <MainText style={{ fontSize: FontSize.NORMAL, marginVertical: SpacingH.s0, color: Colors.HEART_RED }}>{error}</MainText>}

                            {currentQuestion.type === "number" ?
                                <View style={styles.dateInputWrapper}>
                                    <BoxInput
                                        value={year}
                                        onChangeText={(value) => setYearValue(value)}
                                        style={{ color: Colors.WHITE, borderColor: Colors.WHITE }}
                                        placeholder={"Year of birth"}
                                        keyboardType="number-pad"
                                        maxLength={4}
                                        placeholderTextColor={Colors.WHITE}
                                    />
                                    <Icon style={[styles.calendar, { color: Colors.WHITE }]} name="calendar" size={24} />
                                </View>
                                :
                                <View style={[styles.inputWrapper]}>
                                    {
                                        currentQuestion.answers.map((a, i) => (
                                            <ActionButton
                                                key={a}
                                                style={styles.ActionButton}
                                                buttonSize={getButtonSize(a, i)}
                                                onPress={() => onPressAnswer(a)}
                                                animated
                                                buttonStyle={currentAnswer && currentAnswer.answer.includes(a) ? "" : "transparent"}>
                                                {a}
                                            </ActionButton>
                                        ))
                                    }
                                </View>
                            }
                        </>
                    }
                </Animated.View>
            </ScrollView>
            <View style={[GlobalStyles.containerMarginH, styles.bottomWrapper]}>
                <ActionButton activeOpacity={0.4} style={[styles.bottomButtons, { opacity: currentIndex == 0 ? 0 : 1 }]} buttonSize={"sm"} onPress={() => { screenAnimStart("prev") }} textStyle={{ width: 35 }} >
                    <AntDesign name="arrowleft" size={35} color={Colors.ELECTRIC_TEAL} style={{ margin: "auto" }} />
                </ActionButton>
                {currentIndex < profileQuestions.length - 1 &&
                    <ActionButton activeOpacity={0.4} style={styles.bottomButtons} buttonSize={"sm"} onPress={checkForErrors} textStyle={{ width: 35 }} >
                        <AntDesign name="arrowright" size={35} color={Colors.ELECTRIC_TEAL} style={{ margin: "auto" }} />
                    </ActionButton>}
                {currentIndex == profileQuestions.length - 1 &&
                    <PrimaryButton buttonSize={"sm"} onPress={checkForErrors}>Let's go!</PrimaryButton>}
            </View>
        </NonSafeContainerView>
    );
};

const styles = StyleSheet.create({
    inputWrapper: {
        paddingTop: Size.h5,
        paddingBottom: Size.h5,
        flex: 0,
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap",
    },
    ActionButton: {
        marginBottom: SpacingH.s2,
    },
    bottomWrapper: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: SpacingH.s3,
        paddingHorizontal: GlobalStyles.containerPaddingH.paddingHorizontal,
    },
    bottomButtons: {
        backgroundColor: Colors.TRANSPARENT,
        width: 70,
        height: 70,
        borderRadius: 35,
        borderColor: Colors.ELECTRIC_TEAL,
        borderWidth: 2
    },
    dateInputWrapper: {
        marginTop: SpacingH.s5,
        marginBottom: Size.h10,
    },
    calendar: {
        position: "absolute",
        right: 15,
        top: 8,
    }
});
