/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { Provider } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { useNetInfo } from "@react-native-community/netinfo";

import AppLoading from "expo-app-loading";
import { useFonts } from "expo-font";

// import Spinner from "react-native-loading-spinner-overlay";

import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator, TransitionPresets } from "@react-navigation/stack";

import { store, persistor } from "../state/store";
import { setAuthToken } from "../api";

import { registerForPushNotificationsAsync, registerNotificationHandlers } from "../notifications";
import { setExpoPushToken } from "../state/slices/userSlice";

import AuthStackScreen from "./auth";
import CircleStackScreen from "./circle";
import ChatStackScreen from "./chat";
import AboutStackScreen from "./about";
import DashboardTabsScreen from "./dashboard";
import Settings from "../screens/others/Settings";
import Logout from "../screens/others/Logout";
import FullscreenImage from "../screens/utils/FullscreenImage";
import OfflineNotice from "../screens/utils/OfflineNotice";

import { setIsSpinning } from "../state/slices/spinnerSlice";

import CoachProfile from "../screens/matching/CoachProfile";
import MatchProfile from "../screens/matching/MatchProfile";
import WebView from "../components/webView/WebView";
import Sessions from "../screens/dashboard/Sessions"
import IntroProfileScreen from "../screens/matching/IntroProfileScreen";
import LuckyYou from "../screens/matching/LuckyYou";
import Profile from "../screens/dashboard/Profile";
import EditProfile from "../screens/dashboard/profile/EditProfile";
import ViewGoal from "../screens/dashboard/goals/ViewGoal";
import GoalAddEdit from "../screens/dashboard/goals/AddEditGoal";
import MatchList from "../screens/matching/MatchList";
import CoachCircleList from "../screens/circle/CoachCircleList";
import MyClients from "../screens/dashboard/coachDashboard/MyClients";
import ViewClient from "../screens/dashboard/coachDashboard/ViewClient";
import ViewClientGoal from "../screens/dashboard/coachDashboard/ViewClientGoal";
import ProfileImageUpload from "../screens/matching/ProfileImageUpload";
import Report from "../screens/others/Report";
import ContactSupport from "../screens/others/ContactSupport";
import Spinner from "../screens/utils/Spinner";
import Pay from "../screens/others/Pay";



const RootStack = createStackNavigator();
const RootStackScreen = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);

    const authToken = useSelector(state => state.user.token);
    const isSignedIn = authToken !== null;

    const netInfo = useNetInfo();
    const [isOffline, setIsOffline] = React.useState(false);

    React.useEffect(() => {
        setIsOffline(!netInfo.isConnected);

        if (netInfo.isConnected) {
            dispatch(setIsSpinning(false));
        }
    }, [netInfo])

    const onOfflineDismiss = () => {
        setIsOffline(false);
        dispatch(setIsSpinning(false));
    };

    if (isSignedIn) {
        setAuthToken(authToken);
    }

    // Notifications
    React.useEffect(() => {
        registerForPushNotificationsAsync().then(token => { dispatch(setExpoPushToken({ token })); });
        registerNotificationHandlers();
    }, []);

    const [fontsLoaded] = useFonts({
        'Montserrat': require('../assets/fonts/Montserrat/Montserrat-Light.ttf'),
        'Montserrat-Regular': require('../assets/fonts/Montserrat/Montserrat-Regular.ttf'),
        'Montserrat-Medium': require('../assets/fonts/Montserrat/Montserrat-Medium.ttf'),
        'Montserrat-SemiBold': require('../assets/fonts/Montserrat/Montserrat-SemiBold.ttf'),
        'Fraunces': require('../assets/fonts/Fraunces/Fraunces.ttf'),
        'Fraunces-SemiBold': require('../assets/fonts/Fraunces/Fraunces-SemiBold.ttf'),
    });

    const luckyYouFadeAnim = {
        animation: 'timing',
        config: {
            duration: 600,
        },
    };

    const spinner = useSelector(state => state.spinner);

    if (isLoading || !fontsLoaded) {
        return <AppLoading />;
    } else {
        return (
            isOffline ?
                <OfflineNotice onDismiss={onOfflineDismiss} />
                : <>
                    <Spinner isVisible={spinner.spinning} />
                    <RootStack.Navigator
                        headerMode="none">
                        {
                            isSignedIn ? (
                                <>
                                    <RootStack.Screen name="DashboardTabsScreen" component={DashboardTabsScreen} />
                                    <RootStack.Screen name="CircleStackScreen" component={CircleStackScreen} />
                                    <RootStack.Screen name="ChatStackScreen" component={ChatStackScreen} />
                                    <RootStack.Screen name="AboutStackScreen" component={AboutStackScreen} />
                                    <RootStack.Screen name="FullscreenImage" component={FullscreenImage} options={{title: "View Image"}} />
                                    <RootStack.Screen
                                        name="LuckyYou"
                                        component={LuckyYou}
                                        options={{
                                            title: "LifeRamp",
                                            transitionSpec: {
                                                open: luckyYouFadeAnim,
                                                close: luckyYouFadeAnim,
                                            },
                                        }}
                                    />
                                    <RootStack.Screen name="CoachProfile" component={CoachProfile} />
                                    <RootStack.Screen
                                        name="MatchList"
                                        component={MatchList}
                                        options={{
                                            title: "LifeRamp Coaches",
                                            transitionSpec: {
                                                open: luckyYouFadeAnim,
                                                close: luckyYouFadeAnim,
                                            },
                                        }}
                                    />
                                    <RootStack.Screen name="MatchProfile" component={MatchProfile} options={{title: "Coach Profile"}} />
                                    <RootStack.Screen name="WebView" component={WebView} />
                                    <RootStack.Screen name="Sessions" component={Sessions} />
                                    <RootStack.Screen name="IntroProfileScreen" component={IntroProfileScreen} options={{title: "Edit Profile"}} options={{title: "Setup Profile"}} />
                                    <RootStack.Screen name="ViewProfile" component={Profile} />
                                    <RootStack.Screen name="EditProfile" component={EditProfile} options={{title: "Edit Profile"}} />
                                    <RootStack.Screen name="ViewGoal" component={ViewGoal} options={{title: "View Goal"}} />
                                    <RootStack.Screen name="GoalAddEdit" component={GoalAddEdit} options={{title: "Edit Goal"}} />
                                    <RootStack.Screen name="CoachCircleList" component={CoachCircleList} options={{title: "Circles"}} />
                                    <RootStack.Screen name="MyClients" component={MyClients} options={{title: "My Clients"}} />
                                    <RootStack.Screen name="ViewClient" component={ViewClient} options={{title: "View Client"}} />
                                    <RootStack.Screen name="ViewClientGoal" component={ViewClientGoal} options={{title: "Client Goal"}} />
                                    <RootStack.Screen
                                        name="ProfileImageUpload"
                                        component={ProfileImageUpload}
                                        options={{
                                            title: "Profile Image",
                                            ...TransitionPresets.SlideFromRightIOS
                                        }}
                                    />
                                    <RootStack.Screen name="Logout" component={Logout} options={{title: "Logout"}} />
                                    <RootStack.Screen name="Settings" component={Settings} options={{title: "Setting"}} />
                                    <RootStack.Screen name="Report" component={Report} options={{title: "Report"}} />
                                    <RootStack.Screen name="ContactSupport" component={ContactSupport} options={{title: "Contact Support"}} />
                                    <RootStack.Screen name="Pay" component={Pay} options={{title: "Subscription"}} />
                                </>
                            )
                                : <RootStack.Screen name="AuthStackScreen" component={AuthStackScreen} options={{ animationTypeForReplace: 'pop' }} />
                        }
                    </RootStack.Navigator>
                </>
        );
    }
};

import * as Analytics from "expo-firebase-analytics";
import { navigationRef, navigationIsReadyRef } from "./navigator";

export default () => {
    React.useEffect(() => { return () => { navigationIsReadyRef.current = false; } }, []);
    const routeNameRef = React.useRef();

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <NavigationContainer
                    ref={navigationRef}
                    onReady={() => { navigationIsReadyRef.current = true; }}
                    onStateChange={async () => {
                        const previousRouteName = routeNameRef.current;
                        let currentRouteName = "Unknown";
                        try {
                            currentRouteName = navigationRef.current.getCurrentRoute().name;
                        } catch (e) { }

                        if (previousRouteName !== currentRouteName) {
                            await Analytics.setCurrentScreen(currentRouteName);
                        }

                        // Save the current route name for later comparison
                        routeNameRef.current = currentRouteName;
                    }}
                >
                    <RootStackScreen />
                </NavigationContainer>
            </PersistGate>
        </Provider>
    );
};
