/**
 * LifeRamp
 *
 * @author      Anees Muzzafer
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { View, Image, StyleSheet, Text, Pressable, TouchableHighlight } from "react-native";
import { useDispatch } from "react-redux";
import { Colors } from "../../themes/colors";
import { FontSize, SpacingH, SpacingW } from "../../themes/sizes";
import MainMediumText from "../text/MainMediumText";
import MainText from "../text/MainText";
import TimeAgo from "./TimeAgo";

import { Posts } from "../../api";
import { removeCommentReaction, addCommentReaction } from "../../state/slices/circleSlice";
import LikeIcon from "../icons/LikeIcon";

export default ({ currentUser, user, uuid, postedAt, reactionCounts, reactions, content, onLongPress, onUserPress= () => {} }) => {

    const dispatch = useDispatch();
    let hasLiked = reactions.find(r => r.user.uuid == currentUser.uuid && r.type == "like");

    const handleCommentLikePress = () => {
        const type = "like";
        if (hasLiked) {
            dispatch(removeCommentReaction({ uuid, type, user: currentUser }))
            dispatch(Posts.removeReactionFromComment({ uuid }));
        } else {
            dispatch(addCommentReaction({ uuid, type, user: currentUser }))
            dispatch(Posts.reactToComment({ uuid, type }));
        }
    };


    return (
        <TouchableHighlight
            onLongPress={onLongPress}
            delayLongPress={200}
            activeOpacity={0.6}
            underlayColor={Colors.SOFT_GREY}
            style={[styles.postCommentWrapper, styles.spacing]}>
            <View style={{}}>
                <View style={styles.CommentUser}>
                    <Pressable style={{ flexDirection: "row", alignItems: "center" }} onPress={() => onUserPress(user.uuid)}>
                        <Image source={{ uri: user.image_profile }} style={styles.CommentUserImage} />
                        <View style={{ paddingTop: 0 }}>
                            <MainMediumText style={{ fontSize: FontSize.SMALL }}>{user.name}</MainMediumText>
                        </View>
                    </Pressable>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <TimeAgo style={{}} date={postedAt} />
                            <LikeIcon
                                style={{ marginLeft: SpacingW.s2, }}
                                width={14}
                                height={16}
                                isLiked={hasLiked}
                                onPress={handleCommentLikePress}
                            />
                    </View>
                </View>
                <View style={{ paddingTop: SpacingH.s1, paddingLeft: 0 }}>
                    <MainText style={{ fontSize: FontSize.SMALL, textAlign: "justify" }}>
                        {content}
                    </MainText>
                </View>
                {
                    (reactionCounts.like > 0) ?
                        <Pressable style={{ paddingTop: SpacingH.s1, paddingLeft: 0 }}>
                            <Text style={{ fontSize: FontSize.VERY_SMALL, color: Colors.LIGHT_GREY }}>
                                {reactionCounts.like} {reactionCounts.like > 1 ? "likes" : "like"}
                            </Text>
                        </Pressable> : null
                }
            </View>
        </TouchableHighlight>
    );
};



const styles = StyleSheet.create({
    spacing: {
        paddingVertical: SpacingH.s2,
        paddingHorizontal: SpacingW.s4,
        backgroundColor: Colors.WHITE_GREY
    },
    postCommentWrapper: {
        borderTopWidth: 1,
        borderTopColor: Colors.SOFT_GREY,
    },
    CommentUser: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: "space-between"
    },
    CommentUserImage: {
        height: 30,
        width: 30,
        borderRadius: 15,
        marginRight: SpacingW.s2,

    },
});
