/**
 * LifeRamp
 *
 * @author      Waris Anwar
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from 'react';
import { Pressable, Animated } from "react-native";
import { Ionicons } from '@expo/vector-icons';
import { Colors } from '../../themes/colors';


export default ({ onPress, style, isLiked, hitSlop }) => {
    const favAnim = React.useRef(new Animated.Value(1)).current;

    const scaleAnim = () => {
        favAnim.setValue(0);
        Animated.spring(favAnim, {
            toValue: 1,
            friction: 3,
            useNativeDriver: true,
        }).start();
    };

    return (
        <Animated.View style={{ transform: [{ scale: favAnim }] }}>
            <Pressable hitSlop={hitSlop} onPress={() => { onPress(); scaleAnim(); }} style={style}>
                {
                    isLiked ?
                        <Ionicons name="md-bookmark" size={20} color={Colors.WHITE} />
                        :
                        <Ionicons name="md-bookmark-outline" size={20} color={Colors.WHITE} />
                }
            </Pressable>
        </Animated.View>
    );
}
