/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, TouchableOpacity, Pressable, View } from "react-native";
import { Image } from "react-native";
import { LinearGradient } from "expo-linear-gradient";

import { Colors, Gradients } from "../../themes/colors";
import { FontSize } from "../../themes/sizes";
import MainText from "../../components/text/MainText";
import HeroText from "../../components/text/HeroText";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import MainMediumText from "../text/MainMediumText";

export default ({ onPress, image_cover, name, pronouns, role, bio }) => (
    <Pressable style={styles.cardWrapper} onPress={onPress}>
        <Image source={{ uri: image_cover }} style={styles.cardImage} resizeMode="cover" />
        <View style={styles.cardInfo}>
            <HeroText style={styles.coachName}>{name}</HeroText>
            <MainMediumText style={styles.coachTitle}>Certified LifeRamp Life Coach  •  {pronouns}</MainMediumText>
            <MainMediumText style={styles.coachTitle}>{role}</MainMediumText>
            <MainText style={styles.coachDetail}>
                {bio}
            </MainText>
            <LinearGradient style={styles.overlay} colors={[Gradients.TRANSPARENT.Start, Gradients.TRANSPARENT.End]} />
        </View>
    </Pressable>
);

const styles = StyleSheet.create({
    cardWrapper: {
        flexDirection: "column",
        height: hp("50%"),
        backgroundColor: Colors.WHITE,
        borderRadius: 1,
        overflow: "hidden",
    },
    cardImage: {
        width: "100%",
        height: "50%",
    },
    cardInfo: {
        flexDirection: "column",
        paddingVertical: 10,
        height: "50%"
    },
    coachName: {
        marginTop: 5,
        marginBottom: 15,
        fontSize: FontSize.MEDIUM,
        paddingHorizontal: 15,
    },
    coachTitle: {
        color: Colors.DARK_GREY,
        marginBottom: 15,
        fontSize: FontSize.SMALL,
        paddingHorizontal: 15,
    },
    coachDetail: {
        color: Colors.DARK_GREY,
        fontSize: FontSize.SMALL,
        paddingHorizontal: 15,
        lineHeight: 22,
        textAlign: "justify",
    },
    overlay: {
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: hp(10),
    }
});
