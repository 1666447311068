/**
 * LifeRamp
 *
 * @author      Afaan Bilal | Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { View, StyleSheet, TouchableOpacity, Alert } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { EvilIcons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";

import * as Analytics from "expo-firebase-analytics";

import { Colors, Gradients } from "../../themes/colors";
import { SpacingH, Size } from "../../themes/sizes";

import CoachProfileComponent from "../../components/reusableScreens/CoachProfileComponent";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import ActionButton from "../../components/buttons/ActionButton";

import { setIsSpinning } from "../../state/slices/spinnerSlice";
import { createOrGetConversation } from "../../chat";

export default ({ navigation }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.profile);
    const coach = useSelector(state => state.user.profile.coaches[0]);

    const calendlyUrlWithUser = coach ? coach.calendly_url + "?name=" + encodeURIComponent(user.name) + "&email=" + encodeURIComponent(user.email) : "";

    const schedule1on1 = () => {
        if(!user.canCreateSession) {
            Alert.alert("Limit reached", "Sorry, you've exhausted your session limit.");
            return;
        }

        Analytics.logEvent("tap_schedule1on1", { screen: "CoachProfile", coach: coach.name });

        navigation.navigate("WebView", { url: calendlyUrlWithUser, schedule: true });
    };

    const messageCoach = async () => {
        Analytics.logEvent("tap_messageCoach", { screen: "CoachProfile", coach: coach.name });

        dispatch(setIsSpinning(true));
        const threadId = await createOrGetConversation(user, coach);
        navigation.navigate("ChatStackScreen", { screen: "Chat", params: { threadId: threadId, user: coach, isCoach: true } });
        dispatch(setIsSpinning(false));
    };

    return (
        <View style={{ flex: 1, backgroundColor: Colors.WHITE }}>
            <TouchableOpacity onPress={() => { navigation.goBack(); }} style={{ position: "absolute", top: 40, left: 10, zIndex: 10 }}>
                <EvilIcons name="chevron-left" size={42} color={Colors.DARK_GREY} />
            </TouchableOpacity>
            <CoachProfileComponent {...coach} />
            <LinearGradient
                colors={[Gradients.TRANSPARENT.Start, Gradients.TRANSPARENT.End]}
                style={styles.bottomBar}>
                <PrimaryButton onPress={schedule1on1} style={{ marginRight: 5 }}>Schedule a 1 on 1</PrimaryButton>
                <ActionButton activeOpacity={0.6} onPress={() => messageCoach()} buttonSize={"sm"} buttonStyle={"navy"}>Message</ActionButton>
            </LinearGradient>
        </View>
    );
}

const styles = StyleSheet.create({
    bottomBar: {
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        paddingVertical: SpacingH.s2,
        paddingTop: Size.h10,
        backgroundColor: Colors.TRANSPARENT
    },
});
