/**
 * LifeRamp
 *
 * @author      Burhan Rashid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, TextInput, View, TouchableOpacity, Platform } from "react-native";

import { Colors } from "../../themes/colors";
import Fonts from "../../themes/fonts";
import { FontSize } from "../../themes/sizes";

import Icon from "react-native-vector-icons/FontAwesome";
import DateTimePicker from "@react-native-community/datetimepicker";

export default ({ onDateSet, editable, minimumDate, ...props }) => {
    const [date, setDate] = React.useState(new Date());
    const [show, setShow] = React.useState(false);

    const onChange = (event, selectedDate) => {
        const currentDate = selectedDate || date;
        setShow(Platform.OS === "ios");
        setDate(currentDate);
        onDateSet(currentDate.toISOString());
    };

    return (
        <View>
            <TouchableOpacity onPress={() => editable && setShow(true)}>
                <TextInput
                    maxLength={5000}
                    {...props}
                    style={[styles.boxInput, props.style]}
                    editable={false}
                />
                <Icon style={[styles.calendar, { color: props.iconColor ? props.iconColor : Colors.BLACK }]} name="calendar" size={24} />
                <View>
                    {show && (
                        <DateTimePicker
                            minimumDate={minimumDate}
                            maximumDate= {new Date(2036, 12, 31)}
                            value={date}
                            mode={"date"}
                            display={Platform.OS === "ios" ? "inline" : "default"}
                            onChange={onChange}
                        />
                    )}
                </View>
            </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
    boxInput: {
        height: 44,
        paddingHorizontal: 12,
        color: Colors.TEXT_PRIMARY,
        fontSize: FontSize.NORMAL,
        borderColor: Colors.TEXT_BLACK,
        borderRadius: 8,
        borderWidth: 1,
        fontFamily: Fonts.Main,
    },
    calendar: {
        position: "absolute",
        right: 15,
        top: 8,
    }
});
