/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { configureStore, getDefaultMiddleware, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";

import spinnerReducer from "./slices/spinnerSlice";
import userReducer from "./slices/userSlice";
import circleReducer from "./slices/circleSlice";
import resourceReducer from "./slices/resourceSlice";
import goalReducer from "./slices/goalSlice";
import chatReducer from "./slices/chatSlice";
import coachingSessionReducer from "./slices/coachingSessionSlice";

const persistConfig = {
    key: "root",
    version: 1,
    storage: AsyncStorage,
    blacklist: ["spinner"],
};

const rootReducer = combineReducers({
    spinner: spinnerReducer,
    user: userReducer,
    circle: circleReducer,
    resource: resourceReducer,
    goal: goalReducer,
    chat: chatReducer,
    coachingSession: coachingSessionReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

import * as Updates from "expo-updates";

export const reset = () => {
    persistor.purge();
    Updates.reloadAsync();
};
