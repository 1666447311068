/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";

import { Colors } from "../../themes/colors";
import { FontSize } from "../../themes/sizes";
import MainText from "../text/MainText";

export default ({ reactions, user }) => (
    <MainText style={{ fontSize: FontSize.VERY_SMALL, color: Colors.LIGHT_GREY }}>
        {
            reactions.length
                ? (
                    reactions.find(r => r.user.uuid == user.uuid)
                        ? "You"
                        : reactions[0].user.firstname
                ) + (
                    reactions.length > 1
                        ? " and " + (reactions.length - 1) + " others"
                        : ""
                ) + " liked this post."
                : ""
        }
    </MainText>
);
