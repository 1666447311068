/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { View, TouchableOpacity } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { EvilIcons } from "@expo/vector-icons";

import * as WebBrowser from "expo-web-browser";

import { Colors } from "../../themes/colors";
import { FontSize, SpacingH } from "../../themes/sizes";
import { GlobalStyles } from "../../themes/styles";

import NonSafeContainerView from "../../components/main/NonSafeContainerView";

import HeroText from "../../components/text/HeroText";
import MainText from "../../components/text/MainText";

import PrimaryButton from "../../components/buttons/PrimaryButton";
import LinkButton from "../../components/buttons/LinkButton";

import LineInput from "../../components/input/LineInput";

import { setIsSpinning } from "../../state/slices/spinnerSlice";
import { User } from "../../api";
import { clearError } from "../../state/slices/userSlice";

export default ({ navigation }) => {
    const dispatch = useDispatch();
    const error = useSelector(state => state.user.error);
    const expoPushToken = useSelector(state => state.user.expoPushToken);

    let [firstname, setFirstName] = React.useState("");
    let [lastname, setLastName] = React.useState("");
    let [email, setEmail] = React.useState("");
    let [password, setPassword] = React.useState("");

    const handleSignUp = () => {
        dispatch(clearError());
        if (!firstname || !lastname || !email || !password) {
            return alert("Please fill in all the fields.");
        }

        if (password.length < 1) {
            return alert("Please choose a longer password (8 or more characters).")
        }

        dispatch(setIsSpinning(true));
        dispatch(User.signUp({ firstname, lastname, email, password, expoPushToken: expoPushToken })).then(() => dispatch(setIsSpinning(false)));
    };

    return (
        <NonSafeContainerView style={GlobalStyles.containerPaddingH}>
            <TouchableOpacity onPress={() => { navigation.goBack(); }} style={{ position: "absolute", top: 40, left: 10, zIndex: 10 }}>
                <EvilIcons name="chevron-left" size={42} color={Colors.BLUE_GREY} />
            </TouchableOpacity>
            <MainText style={{ fontSize: FontSize.NORMAL, marginVertical: SpacingH.s4, color: Colors.WHITE }}>Create an account to begin your coaching journey.</MainText>
            <HeroText style={{ fontSize: FontSize.BIG, marginBottom: SpacingH.s4, color: Colors.WHITE }}>Create an account</HeroText>

            {error != "" && <MainText style={{ color: Colors.HEART_RED }}>{error}</MainText>}

            <LineInput
                placeholder="First name"
                style={{ marginTop: SpacingH.s3 }}
                autoCompleteType={"name"}
                value={firstname}
                onChangeText={(t) => { setFirstName(t) }} />
            <LineInput
                placeholder="Last name"
                style={{ marginTop: SpacingH.s3 }}
                autoCompleteType={"name"}
                value={lastname}
                onChangeText={(t) => { setLastName(t) }} />
            <LineInput
                placeholder="Email"
                textContentType="emailAddress"
                style={{ marginTop: SpacingH.s3 }}
                autoCompleteType={"email"}
                autoCapitalize={"none"}
                keyboardType={"email-address"}
                value={email}
                onChangeText={(t) => { setEmail(t) }} />
            <LineInput
                placeholder="Password"
                textContentType="password"
                style={{ marginTop: SpacingH.s3 }}
                autoCompleteType={"password"}
                autoCapitalize={"none"}
                secureTextEntry={true}
                value={password}
                onChangeText={(t) => { setPassword(t) }} />

            <View style={{ flexDirection: "row", flexWrap: "wrap", alignItems: "baseline", marginTop: SpacingH.s5 }}>
                <MainText style={{ fontSize: FontSize.VERY_SMALL, color: Colors.WHITE }}>By tapping "Let's Go", you agree to our&nbsp;</MainText>
                <LinkButton onPress={() => { WebBrowser.openBrowserAsync("https://liferamp360.com/terms-and-conditions"); }} textStyle={{ fontSize: FontSize.VERY_SMALL }}>Terms of Service</LinkButton>
                <MainText style={{ fontSize: FontSize.VERY_SMALL, color: Colors.WHITE }}>&nbsp;&amp;&nbsp;</MainText>
                <LinkButton onPress={() => { WebBrowser.openBrowserAsync("https://liferamp360.com/privacy-policy"); }} textStyle={{ fontSize: FontSize.VERY_SMALL }}>Privacy Policy</LinkButton>
                <MainText style={{ fontSize: FontSize.VERY_SMALL, color: Colors.WHITE }}>.</MainText>
            </View>
            <View style={[GlobalStyles.stickRight, { marginTop: SpacingH.s5 }]}>
                <PrimaryButton buttonSize={'sm'} onPress={handleSignUp}>Let's Go</PrimaryButton>
            </View>
        </NonSafeContainerView>
    );
}
