/**
 * LifeRamp
 *
 * @author      Chetanya Gupta
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { View, StyleSheet, Switch, FlatList, TouchableOpacity, Platform } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { EvilIcons } from '@expo/vector-icons';

import { GlobalStyles } from "../../../themes/styles";
import { SpacingH, FontSize, SpacingW } from "../../../themes/sizes";
import { Colors } from "../../../themes/colors";
import ContainerView from "../../../components/main/ContainerView"
import SearchInput from "../../../components/input/SearchInput";
import ClientCard from "../../../components/cards/ClientCard";
import MainMediumText from "../../../components/text/MainMediumText";
import HeroSemiText from "../../../components/text/HeroSemiText";

import PlaceHolder from "../../../components/utils/PlaceHolder";

import { createOrGetConversation } from "../../../chat";
import { setIsSpinning } from "../../../state/slices/spinnerSlice";
import { User } from "../../../api";

export default ({ navigation }) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.user);
    const user = useSelector(state => state.user.profile);
    const clients = useSelector(state => state.user.clients);
    const [showActive, setShowActive] = React.useState(true);
    const [search, setSearch] = React.useState("");

    React.useEffect(() => {
        dispatch(User.getCoachDashboardData());
    }, [])

    React.useEffect(() => {
        dispatch(User.refreshMyClients({ showActive, search }));
    }, [showActive, search]);

    const loadMore = () => {
        if (state.loading) return;
        state.clientPage <= state.clientMaxPage && dispatch(User.getMyClients({ page: state.clientPage, showActive, search }));
    };

    const messageClient = async (client) => {
        dispatch(setIsSpinning(true));
        const threadId = await createOrGetConversation(user, client);
        navigation.navigate("ChatStackScreen", { screen: "Chat", params: { threadId: threadId, user: client } });
        dispatch(setIsSpinning(false));
    };

    return (
        <ContainerView>
            <View style={GlobalStyles.containerPaddingH}>
                <TouchableOpacity onPress={() => { navigation.goBack(); }} style={{ position: "absolute", top: 7, left: 10, zIndex: 10 }}>
                    <EvilIcons name="chevron-left" size={42} color={Colors.BLUE_GREY} />
                </TouchableOpacity>
                <View style={{ marginBottom: SpacingH.s1 }}>
                    <HeroSemiText style={{ fontSize: FontSize.BIG, textAlign: "center" }}>My Clients</HeroSemiText>
                </View>
                <View style={{ marginTop: SpacingH.s0 }}>
                    <SearchInput
                        placeholder={"Search clients"}
                        value={search}
                        onChangeText={s => setSearch(s)}
                        isShaded
                    />
                </View>
                <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingVertical: SpacingH.s1 }}>
                    <MainMediumText style={{ fontSize: FontSize.SMALLER, marginLeft: SpacingW.s2 }}>{clients.length || 0} / {state.clientCount ? state.clientCount : 0} Clients</MainMediumText>
                    <View style={styles.switchContainer}>
                        {/* <MainMediumText style={{ fontSize: FontSize.SMALLER }}>Show only Active</MainMediumText>
                        <View style={{ transform: [{ scale: Platform.OS === "ios" ? 0.7 : 1 }], marginTop: -SpacingW.s2, height: 20 }}>
                            <Switch
                                trackColor={{ false: Colors.LIGHT_GREY, true: Colors.DEEP_TEAL }}
                                thumbColor={Colors.WHITE_GREY}
                                ios_backgroundColor={Colors.LIGHT_GREY}
                                onValueChange={() => setShowActive(sA => !sA)}
                                value={showActive}
                                style={{ marginLeft: SpacingW.s2 }}
                            />
                        </View> */}
                    </View>
                </View>
            </View>
            <View style={styles.topContainer}>
                <FlatList
                    style={GlobalStyles.containerPaddingH}
                    data={clients}
                    onRefresh={() => dispatch(User.refreshMyClients({ showActive, search }))}
                    refreshing={state.refreshing || state.loading}
                    onEndReached={loadMore}
                    renderItem={({ item }) => <ClientCard {...item} chatHandler={() => { messageClient(item) }} profileHandler={() => { navigation.navigate('ViewClient', { client: item }) }} />}
                    ListEmptyComponent={<PlaceHolder status={(state.refreshing || state.loading)} type={"regularCard"} />}
                    keyExtractor={item => item.uuid}
                />
            </View>
        </ContainerView>
    );
}

const styles = StyleSheet.create({
    topContainer: {
        flex: 1,
        backgroundColor: Colors.WHITE,
        paddingBottom: SpacingH.s2
    },

    switchContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        marginVertical: SpacingH.s2,
    },
});
