/**
 * LifeRamp
 *
 * @author      Anees Muzzafer Shah
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { Image, StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { Colors } from "../../themes/colors";
import { EvilIcons } from '@expo/vector-icons';

export default ({ navigation, route: { params: { uri, imageSize } } }) => (
    <View style={styles.imageWrapper}>
        <TouchableOpacity onPress={() => { navigation.goBack(); }} style={styles.closeButton}>
            <EvilIcons name="close" size={30} color={Colors.SOFT_WHITE} />
        </TouchableOpacity>
        <Image
            source={{ uri }}
            style={{ width: "100%", height: "100%" }}
            resizeMode="contain"
        />
    </View>
);

const styles = StyleSheet.create({
    imageWrapper: {
        flex: 1,
        backgroundColor: Colors.BLACK,
        justifyContent: "center",
        alignItems: "center",
    },
    closeButton: {
        position: "absolute",
        right: 20,
        top: 30,
        zIndex: 10,
        backgroundColor: "rgba(255,255,255,0.2)",
        padding: 10,
        borderRadius: 20,
    },
});
