/**
 * LifeRamp
 *
 * @author      Moin Khan
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, Pressable, View } from "react-native";
import { LinearGradient } from 'expo-linear-gradient';

import { FontSize, Size, SpacingW } from "../../themes/sizes";
import { Gradients, Colors } from "../../themes/colors";

import MainMediumText from "../text/MainMediumText";

export default ({ children, buttonSize, style, textStyle, btnStyle, onPress }) => (
    <View style={[{ jusifyContent: "center", alignItems: "flex-start" }, style]}>
        <Pressable onPress={onPress}>
            <LinearGradient
                style={[
                    styles.primaryButton,
                    buttonSize === 'xs' && styles.xsButton,
                    buttonSize === 'sm' && styles.smButton,
                    buttonSize === 'lg' && styles.lgButton,
                    btnStyle,
                ]}
                start={[1, 0]}
                end={[0, 1]}
                colors={[Gradients.GREEN_BLUE.Start, Gradients.GREEN_BLUE.End]}>
                <MainMediumText style={[styles.buttonText, textStyle]}>{children}</MainMediumText>
            </LinearGradient>
        </Pressable>

    </View>

);

const styles = StyleSheet.create({
    primaryButton: {
        alignItems: "center",
        justifyContent: "center",
        height: 64,
        borderRadius: 32,
        flexDirection: "row",
        paddingHorizontal: SpacingW.s5,
        minWidth: 172,
    },
    xsButton: {
        // width: 172,
        // width: Size.w30,
        // paddingHorizontal: SpacingW.s5,
        height: 44,
        borderRadius: 22,
    },
    smButton: {
        // width: 172,
        height: 64,
        borderRadius: 32,
    },
    lgButton: {
        // width: 314,
        height: 64,
        borderRadius: 32,
    },
    buttonText: {
        fontSize: FontSize.NORMAL,
    },
});
