/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import { createSlice } from "@reduxjs/toolkit";
import { Resources } from "../../api";
import { setIsLoading, setError } from "../utils";

const initialState = {
    loading: false,
    error: "",

    refreshing: false,

    categoryRefreshing: {},

    uuid: null,
    data: {},

    categories: [],
    resources: [],

    categoryPage: {},
    categoryMaxPage: {},

    favorites: [],
    favoritePage: 2,
    favoriteMaxPage: 2,
};

export const resourceSlice = createSlice({
    name: "resource",
    initialState,
    reducers: {
        markAsFavorite(state, { payload: { uuid } }) {
            const resource = state.resources.find(r => r.uuid === uuid);
            state.resources[state.resources.indexOf(resource)].isFavorite = true;
        },
        unmarkAsFavorite(state, { payload: { uuid } }) {
            const resource = state.resources.find(r => r.uuid === uuid);
            state.resources[state.resources.indexOf(resource)].isFavorite = false;
        },
    },
    extraReducers: {
        [Resources.getResource.pending]: setIsLoading(true),

        [Resources.refreshResources.pending]: (state) => { state.refreshing = true },
        [Resources.getResources.pending]: (state, action) => { state.categoryRefreshing[action.meta.arg.category] = true; },

        [Resources.refreshFavoriteResources.pending]: (state) => { state.refreshing = true },
        [Resources.getFavoriteResources.pending]: setIsLoading(true),

        [Resources.markAsFavorite.pending]: setIsLoading(true),
        [Resources.unmarkAsFavorite.pending]: setIsLoading(true),

        [Resources.getResource.fulfilled]: (state, action) => {
            state.uuid = action.payload.resource.uuid;
            state.data = action.payload.resource;
            setIsLoading(false)(state);
        },

        [Resources.refreshResources.fulfilled]: (state, action) => {
            state.categories = action.payload.categories;

            for (let i = 0; i < state.categories.length; i++) {
                state.categoryPage[state.categories[i].name] = 2;
                state.categoryMaxPage[state.categories[i].name] = state.categories[i].maxPage;
                state.categoryRefreshing[state.categories[i].name] = false;
            }

            state.resources = action.payload.resources;
            state.refreshing = false;
        },
        [Resources.getResources.fulfilled]: (state, action) => {
            const c = action.payload.category
            state.categoryRefreshing[c] = false;

            state.categoryPage[c]++;
            state.resources.push(...action.payload.resources);
            setIsLoading(false)(state);
        },

        [Resources.refreshFavoriteResources.fulfilled]: (state, action) => {
            state.favorites = action.payload.favorites;
            state.favoritePage = 2;
            state.favoriteMaxPage = action.payload.maxPage;
            state.refreshing = false;
        },
        [Resources.getFavoriteResources.fulfilled]: (state, action) => {
            state.favoritePage++;
            state.favoriteMaxPage = action.payload.maxPage;
            state.favorites.push(...action.payload.favorites);
            setIsLoading(false)(state);
        },

        [Resources.markAsFavorite.fulfilled]: (state, action) => {
            const resource = state.resources.find(r => r.uuid === action.payload.favorite.uuid);
            state.resources[state.resources.indexOf(resource)].isFavorite = true;
            state.favorites.unshift(action.payload.favorite);
            setIsLoading(false)(state);
        },
        [Resources.unmarkAsFavorite.fulfilled]: (state, action) => {
            const resource = state.resources.find(r => r.uuid === action.payload.uuid);
            state.resources[state.resources.indexOf(resource)].isFavorite = false;
            state.favorites.splice(state.favorites.indexOf(state.favorites.find(f => f.uuid == resource.uuid)), 1);
            setIsLoading(false)(state);
        },

        [Resources.getResource.rejected]: setError,
        [Resources.refreshResources.rejected]: setError,
        [Resources.getResources.rejected]: setError,
        [Resources.refreshFavoriteResources.rejected]: setError,
        [Resources.getFavoriteResources.rejected]: setError,
        [Resources.markAsFavorite.rejected]: setError,
        [Resources.unmarkAsFavorite.rejected]: setError,
    }
});

export const {
    markAsFavorite,
    unmarkAsFavorite,
} = resourceSlice.actions;

export default resourceSlice.reducer;
