/**
 * LifeRamp
 *
 * @author      Ummar Hamid
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { sV, FontSize } from "../../themes/sizes";

import ContainerView from "../../components/main/ContainerView";

import HeroText from "../../components/text/HeroText";

import UpcomingSessions from "./journey/UpcomingSessions";
import PastSessions from "./journey/PastSessions";
import { materialTopTabs } from "../../themes/styles";

const SessionTabs = createMaterialTopTabNavigator();

export default ({ navigation }) => (
    <ContainerView>
        <HeroText style={{ fontSize: FontSize.BIG, marginBottom: sV(5) }}>Interaction Sessions</HeroText>
        <SessionTabs.Navigator tabBarOptions={materialTopTabs}>
            <SessionTabs.Screen name="UpcomingSessions" component={UpcomingSessions} options={{ title: "Upcoming Sessions" }} />
            <SessionTabs.Screen name="PastSessions" component={PastSessions} options={{ title: "Past Sessions" }} />
        </SessionTabs.Navigator>
    </ContainerView>
);
