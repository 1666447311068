/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { Image, View } from "react-native";
import { useSelector } from "react-redux";
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { SpacingH, FontSize, SpacingW, Size } from "../../themes/sizes";

import ContainerView from "../../components/main/ContainerView";
import HeroText from "../../components/text/HeroText";
import UpcomingSessions from "./journey/UpcomingSessions";
import PastSessions from "./journey/PastSessions";
import MyJourney from "./journey/MyJourney";
import { GlobalStyles, materialTopTabs } from "../../themes/styles";
import { Colors } from "../../themes/colors";

const JourneyTabs = createMaterialTopTabNavigator();

export default ({ navigation }) => {
    const user = useSelector(state => state.user.profile);

    return (
        <ContainerView>
            <View style={[GlobalStyles.containerPaddingH, GlobalStyles.containerMarginTop, { flexDirection: "row", alignItems: "center", justifyContent: "center", marginBottom: SpacingH.s2 }]}>
                <Image source={{ uri: user.image_profile }} style={{ width: 48, height: 48, borderRadius: 24, borderWidth: 1, borderColor: Colors.BLUE_GREY, marginRight: SpacingW.s4 }} />
                <HeroText style={{ fontSize: FontSize.MEDIUM, maxWidth: Size.w70, textAlign: "center" }}>{user.name}</HeroText>
            </View>

            <JourneyTabs.Navigator tabBarOptions={materialTopTabs}>
                <JourneyTabs.Screen name="MyJourney" component={MyJourney} options={{ title: "My Journey" }} />
                <JourneyTabs.Screen name="Upcoming" component={UpcomingSessions} options={{ title: "Upcoming" }} />
                <JourneyTabs.Screen name="PastSessions" component={PastSessions} options={{ title: "Past" }} />
            </JourneyTabs.Navigator>
        </ContainerView>
    );
};
