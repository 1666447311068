/**
 * LifeRamp
 *
 * @author      Anees Muzzafer
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { StyleSheet, View, TextInput } from "react-native";
import { Ionicons } from '@expo/vector-icons';


import BoxInput from "./BoxInput";
import { Colors } from "../../themes/colors";

import Fonts from "../../themes/fonts";
import { FontSize, SpacingH, SpacingW } from "../../themes/sizes";



export default (props) => (
    <View style={props.isShaded ? styles.searchBoxShaded : styles.searchBox} >
        <View style={{ alignSelf: "center", justifyContent: "center", paddingHorizontal: SpacingW.s2 }}>
            <Ionicons name="search-outline" size={18} color={Colors.BLUE_GREY} />
        </View>
        <TextInput
            maxLength={5000}
            placeholder="Search"
            {...props}
            style={[props.isShaded ? styles.boxInputShaded : styles.boxInput, props.style]}
        />

    </View>
);

const styles = StyleSheet.create({
    searchBox: {
        borderWidth: 0.5,
        borderColor: Colors.BLUE_GREY,
        borderRadius: 8,
        flexDirection: "row",
    },
    boxInput: {
        height: 44,
        flex: 1,
        color: Colors.TEXT_PRIMARY,
        fontSize: FontSize.NORMAL,
        fontFamily: Fonts.Main
    },
    searchBoxShaded: {
        backgroundColor: Colors.SOFT_GREY,
        borderRadius: 8,
        flexDirection: "row",
    },
    boxInputShaded: {
        height: 44,
        flex: 1,
        color: Colors.TEXT_PRIMARY,
        fontSize: FontSize.NORMAL,
        fontFamily: Fonts.Main
    },

});
