/**
 * LifeRamp
 *
 * @author      Chetanya Gupta
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { Image, View, StyleSheet, TouchableOpacity } from "react-native";
import { EvilIcons } from '@expo/vector-icons';

import * as WebBrowser from "expo-web-browser";

import { Colors } from "../../themes/colors";
import { SpacingH, FontSize, Size } from "../../themes/sizes";
import { GlobalStyles } from "../../themes/styles";
import ContainerView from "../../components/main/ContainerView";
import MainText from "../../components/text/MainText";
import HeroSemiText from "../../components/text/HeroSemiText";
import MainMediumText from "../../components/text/MainMediumText";

export default ({ navigation }) => (
    <ContainerView hasHeader>
        <View style={GlobalStyles.containerPaddingH}>
            <TouchableOpacity onPress={() => { navigation.goBack(); }} style={{ position: "absolute", top: 22, left: 10, zIndex: 10 }}>
                <EvilIcons name="chevron-left" size={42} color={Colors.BLUE_GREY} />
            </TouchableOpacity>
            <View style={{ marginTop: SpacingH.s2 }}>
                <HeroSemiText style={{ fontSize: FontSize.BIG, textAlign: "center" }}>About</HeroSemiText>
            </View>
        </View>
        <View style={styles.companyContainer}>
            <Image source={require('../../assets/LifeRamp.png')} style={{ width: Size.w50, height: Size.w50 * 0.195, marginBottom: SpacingH.s2 }} resizeMode="contain" />
            <MainMediumText>LifeRamp Inc.</MainMediumText>
            <MainText style={{ textAlign: "center", fontSize: FontSize.SMALL, marginTop: SpacingH.s1 }}>
                1307 Beachview Road {"\n"}
                Annapolis, MD 21403
            </MainText>
        </View>
        <View style={[GlobalStyles.containerPaddingH, { marginTop: SpacingH.s1 }]}>
            <TouchableOpacity onPress={() => { WebBrowser.openBrowserAsync("https://liferamp360.com/how-it-works"); }}>
                <MainMediumText style={styles.aboutListItem}>Our Approach</MainMediumText>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { WebBrowser.openBrowserAsync("https://liferamp360.com/mission-values"); }}>
                <MainMediumText style={styles.aboutListItem}>Mission and Vision</MainMediumText>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { WebBrowser.openBrowserAsync("https://liferamp360.com/leadership-team"); }}>
                <MainMediumText style={styles.aboutListItem}>Leadership Team</MainMediumText>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigation.navigate("OpenSourceSoftware")}>
                <MainMediumText style={styles.aboutListItem}>Open Source Software</MainMediumText>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { WebBrowser.openBrowserAsync("https://liferamp360.com"); }}>
                <MainMediumText style={styles.aboutListItem}>Visit our website</MainMediumText>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { WebBrowser.openBrowserAsync("https://liferamp360.com/terms-and-conditions"); }}>
                <MainMediumText style={styles.aboutListItem}>Terms and Conditions</MainMediumText>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { WebBrowser.openBrowserAsync("https://liferamp360.com/privacy-policy"); }}>
                <MainMediumText style={styles.aboutListItem}>Privacy Policy</MainMediumText>
            </TouchableOpacity>
        </View>
    </ContainerView>
);

const styles = StyleSheet.create({
    aboutListItem: {
        fontSize: FontSize.NORMAL,
        marginVertical: SpacingH.s2,
    },
    companyContainer: {
        alignItems: "center",
        marginTop: SpacingH.s1,
        paddingVertical: SpacingH.s2,
        marginHorizontal: SpacingH.s2,
        borderBottomWidth: 0.5,
        borderBottomColor: Colors.BLUE_GREY,
    },
    eonyxContainer: {
        position: "absolute",
        bottom: SpacingH.s4,
        alignItems: "center",
        width: "100%",
    },
});
