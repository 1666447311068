/**
 * LifeRamp
 *
 * @author      Afaan Bilal
 * @copyright   LifeRamp Inc.
 *
 * Built by Eonyx Infotech LLP.
 * @link https://eonyx.io
 *
 */

import React from "react";
import { Image, View } from "react-native";
import { useDispatch } from "react-redux";

import { FontSize, hp, SpacingH, Size } from "../../themes/sizes";
import { GlobalStyles } from "../../themes/styles";
import { Colors } from "../../themes/colors";

import NonSafeContainerView from "../../components/main/NonSafeContainerView";

import HeroText from "../../components/text/HeroText";
import MainText from "../../components/text/MainText";

import CircleButton from "../../components/buttons/CircleButton";
import LinkButton from "../../components/buttons/LinkButton";
import { clearError } from "../../state/slices/userSlice";

export default ({ navigation }) => {
    const dispatch = useDispatch();
    return (
        <NonSafeContainerView style={[GlobalStyles.containerPaddingH, { paddingBottom: hp("5%") }]}>
            <HeroText style={{ fontSize: FontSize.VERY_BIG, color: Colors.WHITE }}>Step into your future with</HeroText>
            <View style={{ alignItems: "flex-start" }}>
                <Image source={require('../../assets/LifeRamp.png')} style={{ width: FontSize.VERY_BIG / 0.195, height: FontSize.VERY_BIG, marginVertical: SpacingH.s2 }} resizeMode="contain" />
            </View>
            <MainText style={{ fontSize: FontSize.NORMAL, marginBottom: SpacingH.s2, color: Colors.WHITE }}>Everything you need to thrive in a life and career you love.</MainText>
            <LinkButton onPress={() => { dispatch(clearError()); navigation.navigate("SignIn"); }}>I already have an account</LinkButton>
            <View style={[GlobalStyles.stickBottomRight]}>
                <View style={{ alignItems: "center" }}>
                    <HeroText style={{ width: Size.w40, textAlign: "center", marginBottom: SpacingH.s2, fontSize: FontSize.MEDIUM, color: Colors.WHITE }}>Get Started</HeroText>
                    <CircleButton style={{}} onPress={() => { dispatch(clearError()); navigation.navigate("SignUp"); }}>
                        <Image source={require('../../assets/icons/light/arrow.png')} style={{ width: 80, height: 80, marginTop: 0, marginLeft: -6 }} resizeMode="contain" />
                    </CircleButton>
                </View>
            </View>
        </NonSafeContainerView>
    );
};
